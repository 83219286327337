
// TAKES WALLET CONNECTION META AND FORWARDS TO ANALYTICS

import { NetworkType, defaultNetwork } from "../chain/network"

function gtag(){
    window.dataLayer.push(arguments)
};

export const sendWalletConnected = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        if (meta.walletPubkey != null) {
            sendUserId(meta.walletPubkey)
        }

        gtag('event', 'wallet_connected', meta)
    }
}

export const sendReferralLink = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'from_referral_code', meta)
    }
}

export const sendUtmLink = (meta: object) => {
    if (!!window && !!window.dataLayer) {
        gtag('event', 'from_utm_link', meta)
    }
}

export const sendUserId = (userId: string) => {
    const googleAnalyticsId = defaultNetwork == NetworkType.MAINNET ? 'G-2PESVT6NJS' : 'G-5YMB0CGSLM'

    if (!!window && !!window.dataLayer) {
        gtag('config', googleAnalyticsId, {
            'user_id': userId
          }
        )
    }
}