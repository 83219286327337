import React, { FC, ReactNode, useCallback, useContext, useMemo } from 'react'
import { WalletError, WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import {
  SolflareWalletAdapter,
  PhantomWalletAdapter
} from '@solana/wallet-adapter-wallets'
import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack'
import { NetworkContext } from './NetworkContext'

export const getCurrentNetwork = (networkType: string): WalletAdapterNetwork => {
    switch (networkType) {
      case 'mainnet':
        return WalletAdapterNetwork.Mainnet
      case 'testnet':
        return WalletAdapterNetwork.Testnet
      case 'devnet':
        return WalletAdapterNetwork.Devnet
      case 'localnet':
        // TODO - Cater for local connection
        return WalletAdapterNetwork.Devnet
    }
  
    console.error(
      'Not a recognised solana network type, defaulting to devnet. NetworkType: ',
      networkType
    )
    return WalletAdapterNetwork.Devnet
  }

export const WalletContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { networkUrl, network } = useContext(NetworkContext)
  const walletAdapterNetwork = getCurrentNetwork(network)

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({ network: walletAdapterNetwork }),
      new SolflareWalletAdapter({ network: walletAdapterNetwork }),
      // new LedgerWalletAdapter(), // - Commented as issue with imports
      new BackpackWalletAdapter({ network: walletAdapterNetwork }),
    ],
    [walletAdapterNetwork]
  )

  const onError = useCallback((error: WalletError) => {
    console.error('Transaction Error: ', error)
  }, [])

  return (
    <ConnectionProvider endpoint={networkUrl}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect={false}>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  )
}
