import { FC, Fragment, useContext, useEffect, useState } from "react";
import FormItem from "../common/form-item/FormItem";
import Input from "../common/input/Input";
import Button from "../common/button/Button";
import SearchIcon from "./../../assets/icons/search.svg";
import Icon from "../common/icon/Icon";
import IconFont from "../common/iconFont/IconFont";
import { getPlatformGames } from "../../utils/config/utils";
import { IPlatformGame } from "../../types/game";
import { BrowserPreferenceContext } from "../../contexts/BrowserPreferenceContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import CloseIcon from "./../../assets/icons/x.svg";
import { debounce } from "lodash";
import { WarningMessage } from "../../modals/wallet/common/Warning";
import { useNavigate } from "react-router-dom";
import XIcon from "./../../assets/icons/x.svg";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";

interface ISearch {
  onClose: Function;
  hidden: boolean;
  expanded: boolean; // USED ON MOBILE
  setExpanded: Function;
  searchInput: string;
  setSearchInput: Function;
}

export const Search = ({
  onClose,
  hidden,
  expanded = false,
  setExpanded,
  searchInput,
  setSearchInput,
}: ISearch) => {
  // ALL GAMES
  const allGames = getPlatformGames();
  const { language } = useContext(BrowserPreferenceContext);
  // FILTERED GAMES
  const [gameResults, setGameResults] = useState<IPlatformGame[]>([]);
  const [searchError, setSearchError] = useState<{ title: string; message: string }>();

  // RECENT SEARCHES
  const [recentSearches, setRecentSearches] = useLocalStorage("zeebit-game-searches", "");

  // LOADING
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function addSearchResult(searches: string[], search: string) {
      const oldSearch = Array.isArray(searches) ? [...searches] : [];
      if (oldSearch.includes(search.trim()) == false) {
        oldSearch.push(search);
        setRecentSearches(oldSearch);
      }
    }

    const debouncedSearchResults = debounce(addSearchResult, 1500);

    function findGameResults(games: IPlatformGame[], search: string, searches: string[]) {
      try {
        const lowerSearch = search.trim().toLowerCase();

        if (lowerSearch.length == 0) {
          setGameResults([]);
          return;
        }

        const results: IPlatformGame[] = [];

        games.forEach((game) => {
          const gameKeywords = `${game.keywords[language]}`.toLowerCase();

          if (gameKeywords.includes(lowerSearch)) {
            results.push(game);
          }
        });

        // ADD TO SEARCH HISTORY
        debouncedSearchResults(searches, search);

        // SET RESULTS
        setGameResults(results);

        // REMOVE ERROR IF WE HAD ONE
        if (searchError != null) {
          setSearchError(undefined);
        }
      } catch (err) {
        // SEARCH ERROR BANNER - TODO ONCE API CALL FOR SEARCH
        setSearchError({
          title: "Search Error",
          message: "There was an issue with your search, please try again.",
        });
      } finally {
        setLoading(false);
      }
    }

    // FOR ONCE WE HAVE SERVERSIDE SEARCH
    const debouncedSearch = debounce(findGameResults, 500);

    setLoading(true);
    findGameResults(allGames, searchInput || "", recentSearches);
  }, [searchInput, allGames, language]);

  const navigate = useNavigate();

  // CLOSE IF CLICKED OUTSIDE THE CONTAINER

  return (
    <div
      className={`${
        hidden == true ? "hidden" : ""
      } flex sm:py-5 sm:px-5 flex-col items-start gap-y-5 sm:rounded-lg sm:bg-gray-800 transition-all w-full`}
    >
      <div className="hidden sm:flex items-center gap-x-2 self-stretch">
        <FormItem className="flex-1">
          <Input
            autoFocus={true}
            leftInfo={<IconFont className="me-1 text-inherit" name="search" size="md" />}
            classes={{
              label: "h-[42px] flex-row",
              wrapper: "flex-1",
              input: "placeholder:font-normal text-gray-400",
              labelOnFocus: "text-gray-300",
            }}
            type={"string"}
            placeholder={"Search games"}
            value={searchInput || ""}
            onChange={(e) => {
              const term = String(e.target.value);

              setSearchInput(term);
            }}
          />
        </FormItem>
        <Button
          onClick={() => {
            setSearchInput("");
          }}
          variant="gray"
          size="md"
          className="h-[42px]"
        >
          {/* UPDATE WITH ICON */}
          <Icon icon={<XIcon />} size="xl" />
        </Button>
      </div>

      <Input
        autoFocus={true}
        rightInfo={
          expanded == true ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setSearchInput("");
              }}
            >
              <Icon className="text-gray-300" icon={<XIcon />} size="lg" />
            </div>
          ) : (
            ""
          )
        }
        onFocus={() => {
          setExpanded(true);
        }}
        leftInfo={<Icon className="text-gray-300 me-1" icon={<SearchIcon />} size="md" />}
        classes={{
          label: "h-[42px] flex-row",
          wrapper: "flex-1 w-full sm:hidden",
        }}
        type={"string"}
        placeholder={"Search games"}
        value={searchInput || ""}
        onChange={(e) => {
          const term = String(e.target.value);

          setSearchInput(term);
        }}
      />

      {expanded ? (
        <Fragment>
          {loading ? (
            <SearchLoading />
          ) : (
            <Fragment>
              {/* MESSAGE */}
              {(searchInput?.trim()?.length || 0) < 1 ? (
                <div className="flex px-0 py-3 items-start  gap-x-[10px] self-stretch">
                  <div className="text-gray-400 flex-1 text-center">
                    Please enter a term to see results.
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* NO RESULTS */}
              {(searchInput?.trim()?.length || 0) >= 1 && gameResults.length == 0 ? (
                <div className="flex px-0 py-3 items-start  gap-x-[10px] self-stretch">
                  <div className="text-gray-400 flex-1 text-center">No results found.</div>
                </div>
              ) : (
                ""
              )}

              {/* SEARCH ERROR */}
              {searchError != null ? (
                <WarningMessage title={searchError.title} message={searchError.message} link={""} />
              ) : (
                ""
              )}

              {/* SEARCH RESULTS */}
              <div
                className={`flex flex-wrap w-full -m-2 max-h-[50vh] overflow-auto ${SCROLLBAR_CLASSES_BLACK}`}
              >
                {gameResults.map(({ ...props }, index) => {
                  return (
                    <SearchResult
                      onClick={() => {
                        navigate(`/game/${props.gameSpecPubkey}`);

                        if (expanded == true && setExpanded != null) {
                          setExpanded(false);
                        }

                        setSearchInput(undefined);

                        onClose();
                      }}
                      title={props.name[language]}
                      key={index}
                      {...props}
                    />
                  );
                })}
              </div>
            </Fragment>
          )}

          {/* SEARCH HISTORY */}
          <div className="flex flex-col items-start gap-y-4 self-stretch">
            {/* LINE BREAK */}
            <div className="flex h-[1px] self-stretch bg-gray-600" />

            {/* SEARCH HISTORY */}
            <div className="flex flex-col items-start gap-y-3 self-stretch">
              {/* TITLE */}
              <div className="flex justify-between items-start self-stretch text-gray-300">
                <div>Recent Searches</div>
                <p
                  onClick={() => setRecentSearches([])}
                  className="text-gray-300 cursor-pointer hover:text-gray-400"
                >{`Clear Search (#)`}</p>
              </div>

              {/* TAGS */}
              <div className="flex items-start align-start -m-1 self-stretch flex-wrap">
                {recentSearches != null && recentSearches.length > 0
                  ? recentSearches?.map((search, index) => {
                      return (
                        <div key={index} className="flex items-start m-1">
                          {/* TAG */}
                          <div className="flex p-1 ps-2 justify-center items-center gap-x-[2px] rounded-sm bg-gray-700 text-gray-300">
                            <div className="text-sm font-normal">{search}</div>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                const oldSearches = [...recentSearches];
                                oldSearches.splice(index, 1);

                                setRecentSearches(oldSearches);
                              }}
                            >
                              <Icon size="sm" icon={<CloseIcon />} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                {(recentSearches?.length || 0) == 0 ? (
                  <div className="flex px-0 py-3 items-start  gap-x-[10px] self-stretch">
                    <div className="text-gray-400 flex-1 text-sm font-normal">
                      No recent searches.
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

const SearchLoading = () => {
  return (
    <div className="flex w-full -m-2 max-h-[50vh] overflow-x-hidden">
      {[0, 1, 2, 3, 4, 5, 6, 7].map((val, index) => {
        return <LoadingResult key={index} />;
      })}
    </div>
  );
};

const LoadingResult = () => {
  return (
    <div
      className={`m-2 w-[calc(25%-16px)] lg:w-[calc(12.5%-10px)] h-auto aspect-[4/5.5] animate-pulse bg-gray-400 rounded-lg`}
    />
  );
};

export interface SearchResultProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  tag: string;
  className: string;
  onClick: Function;
  cardImageDark?: string;
  isComingSoon?: boolean;
}

const SearchResult: FC<SearchResultProps> = ({
  title,
  tag,
  className,
  onClick,
  cardImageDark,
  isComingSoon,
}) => {
  return (
    <div
      className={`m-2 w-[calc(25%-16px)] lg:w-[calc(12.5%-10px)] h-auto aspect-[4/5.5] transition-all`}
    >
      <div
        role="button"
        onClick={() => {
          if (isComingSoon == false) {
            onClick();
          }
        }}
        className={`rounded-lg h-full w-full bg-gray-800 overflow-hidden flex flex-col p-2 md:p-3 lg:p-4 items-center relative justify-end  ${className} ${
          !isComingSoon ? "cursor-pointer" : "cursor-default"
        } `}
      >
        <div className="absolute inset-0">
          <img src={cardImageDark} />
        </div>
      </div>
    </div>
  );
};
