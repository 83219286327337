import { useCallback, useState } from "react";

import { useTokenAirdrop } from "./useTokenAirdrop";

export const useSolAirdrop = (onSuccessCallback?: Function, onErrorCallback?: Function) => {
  const [isSolAirdropLoading, setIsSolAirdropLoading] = useState(false);

  const { airdropSol } = useTokenAirdrop();
  const handleAirdropSol = useCallback(async () => {

    setIsSolAirdropLoading(true);
    await airdropSol(
      () => {
        setIsSolAirdropLoading(true);
      },
      () => {
        onSuccessCallback?.();
      },
      () => {
        onErrorCallback?.();
      },
    ).finally(() => setIsSolAirdropLoading(false));
  }, [airdropSol, onSuccessCallback, onErrorCallback]);

  return ({
    isSolAirdropLoading,
    handleAirdropSol
  });
};