import FEED_METAS from './feedMeta.json'
import { IFeedMeta } from './types'
import axios from 'axios'

export const getFeedMetaById = (): Map<string, IFeedMeta> => {
    return FEED_METAS.reduce((result, item) => {
        result.set(item.id, item)
        
        return result
    }, new Map<string, any>)
}

export const getFeedMeta = (feedId: string): IFeedMeta | undefined => {
    return FEED_METAS.find((feed) => {
        return feed.id == feedId
    })
}

export const getPriceHistory = async (feedSymbol: string, timeResolution: number, fromEpoch: number, toEpoch: number) => {
    return await axios.get(`https://benchmarks.pyth.network/v1/shims/tradingview/history?symbol=${feedSymbol}&resolution=${timeResolution}&from=${fromEpoch}&to=${toEpoch}`)
}