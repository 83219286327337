import axios from 'axios'
import { HELLO_MOON_GET_DATA, HELLO_MOON_GET_JWT, HELLO_MOON_INDIVIDUAL_WINS, HELLO_MOON_NFT_TRAIT_WINS, HELLO_MOON_PLAYER_CLAIMS, HELLO_MOON_PLAYER_COLLECTS, HELLO_MOON_SET_DATA, HELLO_MOON_STAKING_ACTIVITY, HELLO_MOON_TOKEN, HELLO_MOON_TRAIT_WINS, HM_TOKEN_LIST, HM_TOKEN_PRICES, HM_PYTH_PRICE_DATA, TIME_MAINNET_STAKING_BEGINS } from '../../constants/hellomoon'

import Zeebro from '../../sdk/nftStaking/zeebro'
import { NetworkType, defaultNetwork } from '../chain/network'
import { ClaimableStatus, CollectableStatus } from '../../sdk/betStream'
import { ClaimType } from '../../hooks/rewards/claims'
import { Token } from '@solflare-wallet/utl-sdk'

export interface IJwtPayload {
    message: string,
    signature: string,
    wallet: string,
}

export interface IJwtResponse {
    jwt: string
}

export const getJwt = async (payload: IJwtPayload): Promise<IJwtResponse> => {
    const response = await axios({
        method: 'post',
        url: HELLO_MOON_GET_JWT,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: payload,
    })

    return response.data
}

export interface IHMGetData {
    isPrivate: boolean,
    jwt: string,
    wallet: string
}

export interface IHMGetDataResp {
    emailAddress: string, 
    acceptPlatformNotifications: string, 
    acceptProjectUpdateNotifications: string
}

export const getData = async (payload: IHMGetData): Promise<IHMGetDataResp> => {

    const response = await axios({
        method: 'post',
        url: HELLO_MOON_GET_DATA,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: payload,
    })

    return response.data
}

export interface IHMSetData {
    isPrivate: boolean,
    jwt: string,
    wallet: string
    username?: string
    emailAddress?: string
    discordUsername?: string,
    twitterUsername?: string,
    telegramUsername?: string,
    acceptPlatformNotifications?: boolean,
    acceptProjectUpdateNotifications?: boolean,
    web3AuthData?: string,
    additionalInfo?: string
}

export interface IHMSetDataResp {

}

export const setData = async (payload: IHMSetData): Promise<IHMSetDataResp> => {

    const response = await axios({
        method: 'post',
        url: HELLO_MOON_SET_DATA,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: payload,
    })

    return response.data
}

export interface IHMGetStakingActivityFilter {
    nftMint?: string
    timeFrom?: number
    timeTo?: number
    owner?: string
    player?: string
    page?: number
    pageSize?: number
}

export enum StakingActivity {
    STAKED = "Staked",
    UN_STAKED = "UnStaked"
}

export interface IHMGetStakingActivityResp {
    event_type: StakingActivity,
    transaction_time: string,
    transaction_id: string,
    nftmint: string,
    nftid: number,
    player: string,
    owner: string,
}

export const getStakingActivity = async (filters: IHMGetStakingActivityFilter): Promise<IHMGetStakingActivityResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios({
        method: 'post',
        url: HELLO_MOON_STAKING_ACTIVITY,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface IStakingActivity {
    activity: StakingActivity
    time: Date
    nftMint: string
    owner: string
    signature: string
}

export const getFormattedStakingActivities = async (filters: IHMGetStakingActivityFilter): Promise<IStakingActivity[]> => {
    const stakingActivities = await getStakingActivity(filters)

    return stakingActivities.map((record) => {
        return {
            activity: record.event_type,
            time: new Date(record.transaction_time),
            nftMint: record.nftmint,
            owner: record.owner,
            signature: record.transaction_id
        }
    })
}

// GET JACKPOT WINS
export interface IHMGetJackpotWinsFilter {
    timeFrom?: number // epoch
    timeTo?: number // epoch
    nftMint?: string
    nftMints?: string[]
    page?: number
    pageSize?: number
}

export interface IHMGetJackpotWinsResp {
    event_type: string,
    event_time: string,
    transaction_id: string,
    timestamp: string,
    nftid: number,
    isstaked: boolean,
    nftmint: string,
    amount: string,
    instancenonce: number,
    position: number
    distribution: string
    main: string
}

export const getJackpotWins = async (filters: IHMGetJackpotWinsFilter): Promise<IHMGetJackpotWinsResp[]> => {

    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios({
        method: 'post',
        url: HELLO_MOON_INDIVIDUAL_WINS,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface IJackpotWin {
    time: Date,
    signature: string,
    nftId: number,
    isStaked: boolean,
    nftMint: string,
    amount: number,
    position: number
    distribution: string
    main: string
    zeebro?: Zeebro
}

export const getFormattedJackpotWins = async (filters: IHMGetJackpotWinsFilter, decimals: number = 6): Promise<IJackpotWin[]> => {
    const jackpotWins = await getJackpotWins(filters)

    return jackpotWins?.map((record) => {
        return {
            time: new Date(record.event_time),
            nftMint: record.nftmint,
            nftId: record.nftid,
            isStaked: record.isstaked,
            amount: parseInt(record.amount, 16),
            position: record.position,
            distribution: record.distribution,
            main: record.main,
            signature: record.transaction_id
        }
    })
}

// TRAIT WINS
export interface IHMGetTraitWinsFilter {
    attributeTraitPairs?: { traitId: string, attributeId: string }[] // TODO CHECK WITH HELLOMOON
    traitId?: number
    attributeId?: number
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
}

export interface IHMGetTraitWinsResp {
    event_type: string,
    event_time: string,
    transaction_id: string,
    traitid: number,
    amountperstaker: string,
    attributeid: number,
    countstaked: number,
    instancenonce: number,
    position: number,
    distribution: string,
    main: string,
    countpopulation: number,
}

export const getTraitWins = async (filters: IHMGetTraitWinsFilter): Promise<IHMGetTraitWinsResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }

    const response = await axios({
        method: 'post',
        url: HELLO_MOON_TRAIT_WINS,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface ITraitWin {
    time: Date,
    signature: string,
    traitId: number,
    amountPerStaker: number,
    attributeId: number,
    countStaked: number,
    position: number,
    distribution: string,
    countPopulation: number,
    image?: string
}

export const getFormattedTraitWins = async (filters: IHMGetTraitWinsFilter, decimals: number = 6): Promise<ITraitWin[]> => {
    // GET TRAIT WINS FOR ALL ATTRIBUTE / TRAIT PAIRS

    const traitWins = await getTraitWins(filters)

    return traitWins.map((record) => {
        return {
            time: new Date(record.event_time),
            signature: record.transaction_id,
            traitId: record.traitid,
            attributeId: record.attributeid,
            amountPerStaker: parseInt(record.amountperstaker, 16),
            countStaked: record.countstaked,
            position: record.position,
            distribution: record.distribution,
            countPopulation: record.countpopulation
        }
    })
}

// NFT TRAIT WINS
export interface IHMGetNftTraitWinsFilter {
    traitId?: number
    attributeId?: number
    traitIds?: number
    attributeIds?: number
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
    nftMint?: string
    nftMints?: string[]
}

export interface IHMGetNftTraitWinsResp {
    event_type: string,
    block_time: number,
    time: string,
    transaction_id: string,
    traitid: number,
    nftid: number,
    isstaked: boolean,
    nftmint: string,
    amount: number,
    amountperstaker: number,
    attributeid: number,
    countstaked: number,
    instancenonce: number,
    position: number,
    distribution: string,
    main: string,
    countpopulation: number,
}

export const getNftTraitWins = async (filters: IHMGetNftTraitWinsFilter): Promise<IHMGetNftTraitWinsResp[]> => {
    if (defaultNetwork == NetworkType.MAINNET && filters.timeFrom == null) {
        filters.timeFrom = TIME_MAINNET_STAKING_BEGINS
    }
    
    const response = await axios({
        method: 'post',
        url: HELLO_MOON_NFT_TRAIT_WINS,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface INftTraitWin {
    time: Date,
    signature: string,
    traitId: number,
    nftId: number,
    isStaked: boolean,
    nftMint: string,
    amount: number,
    amountPerStaker: number,
    attributeId: number,
    countStaked: number,
    position: number,
    distribution: string,
    countPopulation: number,
    zeebro?: Zeebro
}

export const getFormattedNftTraitWins = async (filters: IHMGetNftTraitWinsFilter): Promise<INftTraitWin[]> => {
    // GET TRAIT WINS FOR ALL ATTRIBUTE / TRAIT PAIRS
    const nftTraitWins = await getNftTraitWins(filters)

    return nftTraitWins.map((record) => {
        return {
            time: new Date(record.time),
            signature: record.transaction_id,
            traitId: record.traitid,
            attributeId: record.attributeid,
            amountPerStaker: record.amountperstaker,
            countStaked: record.countstaked,
            position: record.position,
            distribution: record.distribution,
            countPopulation: record.countpopulation,
            amount: record.amount,
            nftId: record.nftid,
            nftMint: record.nftmint,
            isStaked: record.isstaked,
        }
    })
}

// Player Claims
export interface IHMPlayerClaimsFilter {
    player?: string
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
}

export enum CLAIM_EVENT_TYPE {
    REWARD_FOREFIT="RewardForfeit",
    REWARD_CLAIMED="RewardClaimed"
}

export interface IHMPlayerClaimsResp {
    player: string,
    timestamp: string,
    spreaddays: number,
    house: string,
    tokenamountupfront: number,
    tokenamountspread: number,
    relatesto: number, // epoch 
    valuebase: number,
    rewardtype: string,
    platform: string
    token: string,
    owner: string,
    event_type: CLAIM_EVENT_TYPE
}

export const getPlayerClaims = async (filters: IHMPlayerClaimsFilter): Promise<IHMPlayerClaimsResp[]> => {
    const response = await axios({
        method: 'post',
        url: HELLO_MOON_PLAYER_CLAIMS,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data
}

export const daysInPeriod = (rewardType: string) => {
    switch(rewardType) {
        case ClaimType.DAILY:
            return 1
        case ClaimType.WEEKLY:
            return 7
        case ClaimType.MONTHLY:
            return 28
        case ClaimType.RAKEBACK:
            return 1
        case ClaimType.LEVEL_UP:
            return 0 // NOT ACTUALLY 1 Day....
        case ClaimType.REFERRAL:
            return 1
        default:
            console.error(`Unknown bonus type`, { rewardType })
            return 1
    }
}

export interface IPlayerClaim {
    timestamp: string,
    spreadDays: number,
    house: string,
    tokenAmountUpFront: number,
    tokenAmountSpread: number,
    relatesTo: Date, // reward date
    valueBase: number,
    type: ClaimType,
    platform: string
    token: string,
    owner: string,
    eventType: CLAIM_EVENT_TYPE
    status: ClaimableStatus
    startDate: Date // CLAIMABLE START
    endDate: Date // CLAIMABLE END
}

export const getFormattedPlayerClaims = async (filters: IHMPlayerClaimsFilter): Promise<IPlayerClaim[]> => {
    const playerClaims = await getPlayerClaims(filters)

    return playerClaims.map((record, index) => {
        const rewardTypeKey = Object.keys(JSON.parse(record.rewardtype))[0]
        const days = daysInPeriod(rewardTypeKey)
        
        let dateFrom: Date | undefined
        let dateTo: Date | undefined

        // FOR LEVEL UP WE USE TIMESTAMP
        if (rewardTypeKey == ClaimType.LEVEL_UP) {
            dateFrom = new Date(record.timestamp)
            dateTo = new Date(record.timestamp)
        } else {
            dateFrom = new Date(record.relatesto * 1000)
            dateFrom.setDate(dateFrom.getDate() + days)
    
            dateTo = new Date(record.relatesto * 1000)
            dateTo.setDate(dateTo.getDate() + (days * 2))
            dateTo.setSeconds(dateTo.getSeconds() - 1)
        }

        return {
            owner: record.owner,
            token: record.token,
            type: rewardTypeKey,
            platform: record.platform,
            valueBase: record.valuebase,
            tokenAmountSpread: record.tokenamountspread,
            tokenAmountUpFront: record.tokenamountupfront,
            house: record.house,
            spreadDays: record.spreaddays,
            timestamp: record.timestamp,
            startDate: dateFrom,
            endDate: dateTo,
            relatesTo: new Date(record.relatesto * 1000),
            eventType: record.event_type,
            status: record.event_type == CLAIM_EVENT_TYPE.REWARD_CLAIMED ? ClaimableStatus.CLAIMED: ClaimableStatus.FOREFIT
        }
    })
}

// Player Collects
export interface IHMPlayerCollectsFilter {
    player?: string
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
}

export interface IHMPlayerCollectsResp {
    player: string,
    eventtime: string,
    house: string,
    platform: string,
    token: string,
    owner: string,
    amount: number,
    rewarddate: number,
    event_type: string
}

export const getPlayerCollects = async (filters: IHMPlayerCollectsFilter): Promise<IHMPlayerCollectsResp[]> => {
    const response = await axios({
        method: 'post',
        url: HELLO_MOON_PLAYER_COLLECTS,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data
}

export interface IPlayerCollect {
    player: string,
    eventTime: Date,
    house: string,
    platform: string,
    token: string,
    tokenIcon: string
    owner: string,
    amount: number,
    rewardDate: Date,
    eventType: string
    amountUi: number
    amountUsd: number
    amountUsdUi: number
}

export const getFormattedPlayerCollects = async (filters: IHMPlayerCollectsFilter): Promise<IPlayerCollect[]> => {
    const playerCollects = await getPlayerCollects(filters)

    return playerCollects.map((record) => {
        return {
            owner: record.owner,
            token: record.token,
            tokenIcon: '',
            platform: record.platform,
            house: record.house,
            eventType: record.event_type,
            status: record.event_type == "RewardCalendarCollection" ? CollectableStatus.COLLECTED: CollectableStatus.FOREFIT,
            amount: record.amount,
            rewardDate: new Date(record.rewarddate * 1000),
            eventTime: new Date(record.eventtime),
            player: record.player,
            amountUi: 0,
            amountUsdUi: 0,
            amountUsd: 0
        }
    })
}

export interface IHmToken {
    id: string,
    mint: string,
    name: string,
    symbol: string,
    decimals: number,
    slug: string,
    coingeckoId: string,
    lifetimeSwapCount: number   
}

export interface IGetTokenListFilter {
    mint?: string[],
    symbol?: string,
    name?: string,
    page?: number,
    limit?: number,
    paginationToken?: string
}

export const getTokenList = async (filters?: IGetTokenListFilter): Promise<IHmToken[]> => {
    const response = await axios({
        method: 'post',
        url: HM_TOKEN_LIST,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface IHmTokenPrice {
    mints: string,
    price: number,
    volume: number,
    startTime: number
}

export interface IGetLatestTokenPricesFilter {
    mints?: string[],
    page?: number,
    limit?: number,
    paginationToken?: string
}

export const getLatestTokenPrices = async (filters?: IGetLatestTokenPricesFilter): Promise<IHmTokenPrice[]> => {
    const response = await axios({
        method: 'post',
        url: HM_TOKEN_PRICES,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data.data
}

export interface ITokenMeta {
    name: string
    symbol: string
    price: number
    decimals: number
    mint: string
}

export interface IHmTokenMeta {
    meta: IHmToken | undefined,
    price: IHmTokenPrice | undefined,
    token?: Token
}

interface IHmPriceData {
    seriesId?: string
    ethereumId?: string
}

export interface IHmPrice {
    f: string 
    id: string
    p: number
    s: number
    t: number
}

export const getHmPriceData = async (filters: IHmPriceData): Promise<IHmPrice[]> => {
    const response = await axios({
        method: 'post',
        url: HM_PYTH_PRICE_DATA,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: `Bearer ${HELLO_MOON_TOKEN}`
        },
        data: filters,
    })

    return response.data
}