import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import GameTokenSpec from "./gameTokenSpec";
import GameSpec from "./gameSpec";
import { IFormattedTokenInstanceState, IInstanceConfig } from "./types";

export enum GameInstanceStatus {
    Uninitialized = 0,
    Active = 1,
    ResultProvided = 2,
    Settled = 3,
    ErrorConfirmed = 4,
    Refunded = 5
}

export enum GameInstanceStatusString {
    Uninitialized = "uninitialized",
    Active = "active",
    ResultProvided = "resultProvided",
    Settled = "settled",
    ErrorConfirmed = "errorConfirmed",
    Refunded = "refunded"
}

export default class GameTokenInstance {

    private _gameTokenSpec: GameTokenSpec;
    private _pubkey: PublicKey;
    private _state: any;

    constructor( 
        gameTokenSpec: GameTokenSpec,
        pubkey: PublicKey,
    ) {
        this._gameTokenSpec = gameTokenSpec; 
        this._pubkey = pubkey;   
    };

    static async load(
        gameTokenSpec: GameTokenSpec,
        pubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const gameTokenInstance = new GameTokenInstance(
            gameTokenSpec,
            pubkey,
        )
        await gameTokenInstance.loadState(commitmentLevel);
        
        return gameTokenInstance
    };

    static loadFromBuffer(
        gameTokenSpec: GameTokenSpec,
        pubkey: PublicKey,
        accountBuffer: Buffer
    ) {
        // WHEN ACCOUNT CLOSES BUFFER COMES BACK EMPTY
        const state = accountBuffer.length != 0 ? gameTokenSpec.program.coder.accounts.decode(
            "GameTokenInstance",
            accountBuffer
        ): undefined;

        const gameTokenInstance = new GameTokenInstance(
            gameTokenSpec,
            pubkey,
        );
        gameTokenInstance._state = state;
        return gameTokenInstance
    };

    static async loadFromBufferWithoutGameTokenSpec(
        gameSpec: GameSpec,
        pubkey: PublicKey,
        accountBuffer: Buffer
    ) {
        const state = gameSpec.program.coder.accounts.decode(
            "GameTokenInstance",
            accountBuffer
        );
        let gameTokenSpecPubkey = state.gameTokenSpec;
        let gameTokenSpec = await GameTokenSpec.load(
            gameSpec,
            gameTokenSpecPubkey,
        );
        const gameTokenInstance = new GameTokenInstance(
            gameTokenSpec,
            pubkey,
        );
        gameTokenInstance._state = state;
        return gameTokenInstance
    };

    async loadState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.program.account.gameTokenInstance.fetchNullable(
            this._pubkey,
            commitmentLevel
        );
        
        this._state = state;
    }

    get gameSpec() {
        return this._gameTokenSpec.gameSpec
    }

    get gameTokenSpec() {
        return this._gameTokenSpec
    }

    get program() {
        return this._gameTokenSpec.program
    }

    get publicKey() {
        return this._pubkey
    }

    get state() {
        return this._state
    }

    get exists() {
        return (this._state ? true : false)
    }

    get lookupTablePubkey(): PublicKey {
        return this._state ? this._state.lookupTable : undefined
    }

    get settleTimestamp(): number | undefined {
        return this._state ? Number(this._state.config.jackpot.settleTimestamp) : undefined
    }

    get settleDatetime(): Date | undefined {
        return this._state ? new Date(this.settleTimestamp * 1000) : undefined
    }

    get lastBetTimestamp(): number | undefined {
        return this._state ? Number(this._state.config.jackpot.lastBetTimestamp) : undefined
    }

    get lastBetDatetime(): Date | undefined {
        return this.lastBetTimestamp ? new Date(this.lastBetTimestamp * 1000) : undefined
    }

    get formattedJackpotConfig(): IInstanceConfig | undefined {
        return this.state != null ? {
            jackpot: {
                ...this.state.config.jackpot,
                denominator: Number(this.state.config.jackpot.denominator),
                lastBetTimestamp: Number(this.state.config.jackpot.lastBetTimestamp),
                settleTimetamp: Number(this.state.config.jackpot.settleTimetamp),
                shares: this.state.config.jackpot.sharesPerThousand.map((share: number) => {
                    return share / 1_000
                })
            }

        }: undefined
    }

    get statusString() {
        return this.state?.status != null ? Object.keys(this.state.status)[0]: undefined
    }

    get resultString() {
        return this.state?.result != null ? Object.keys(this.state.result)[0]: undefined
    }

    get formattedState(): IFormattedTokenInstanceState | undefined {
        return this.state != null ? {
            ...this.state,
            betSlots: this.state.betSlots.map((slot: any) => {
                return {
                    ...slot,
                    betConfig: {
                        jackpot: {
                            ...slot.betConfig.jackpot,
                            lowerIncl: Number(slot.betConfig.jackpot.lowerIncl),
                            upperExcl: Number(slot.betConfig.jackpot.upperExcl),
                            wager: Number(slot.betConfig.jackpot.wager)
                        }
                    },
                }
            }),
            config: this.formattedJackpotConfig,
            instanceNonce: Number(this.state.instanceNonce),
            resultString: this.resultString,
            statusString: this.statusString
        }: undefined
    }

    deriveLutCloseListPubkey() {
        var seeds = [
            anchor.utils.bytes.utf8.encode("lut_close_list"),
            this.gameSpec.deriveGlobalSignerPubkey().toBuffer(),
        ]
        const [pk, _] = PublicKey.findProgramAddressSync(
            seeds,
            this.gameSpec.program.programId
        );
        return pk
    }
}
