import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export enum PlinkoDifficultyLevel {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export default class Plinko extends Game {
  constructor(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    super(casinoProgram, house, gameSpecPubkey, GameType.Plinko, commitmentLevel);
  }

  static async load(casinoProgram: anchor.Program, house: House, gameSpecPubkey: PublicKey) {
    const game = new Plinko(casinoProgram, house, gameSpecPubkey);
    await game.loadState();
    return game;
  }

  async placeBetIx(
    player: PlayerAccount,
    inputs: object,
    wager: number,
    tokenMintPubkey: PublicKey,
    clientSeed: Buffer,
    owner: PublicKey,
    referrer?: PublicKey,
  ) {
    const instanceRequest = {
      plinko: {
        numRows: inputs.numberOfRows,
        difficultyLevel: inputs.difficultyLevel,
        numBalls: inputs.numberOfBalls,
      },
    };

    const betRequest = {
      plinko: {
        wager: new anchor.BN(wager),
      },
    };

    const numberOfBets = inputs.numberOfBalls;

    return await this.initAndBetSoloIxn(
      player,
      tokenMintPubkey,
      numberOfBets,
      betRequest,
      instanceRequest,
      clientSeed,
      owner,
      referrer,
    );
  }

  get maxMultiplier() {
    return this.gameConfig
      ? Number(this.gameConfig.plinko.maxMultiplierPerMillion) / 1_000_000
      : null;
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.plinko.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.plinko.roundingDenominator) / 1_000_000 : null;
  }

  getMultiplier(inputs: object) {
    // TODO - CHECK ON ROUNDING UNIT
    // if (this.multiplierRoundingUnit == null) {
    //   return inputs.maxMultiplier;
    // }

    // return (inputs.maxMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;

    return inputs.maxMultiplier;
  }

  getHouseEdge(inputs: object) {
    // TODO - CHECK ON ROUNDING UNIT
    // if (this.multiplierRoundingUnit == null) {
    //   return inputs.maxMultiplier;
    // }

    // return (inputs.maxMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;
    if (inputs == null || "houseEdge" in inputs == false) {
      return;
    }

    return inputs.houseEdge;
  }

  getProbability(inputs: object) {
    const multiplier = this.getMultiplier(inputs);
    const houseEdge = this.getHouseEdge(inputs);

    if (houseEdge == null) {
      return 1 / multiplier;
    }

    return (1 - houseEdge) / multiplier;
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollars = 0;

    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollars += (1 - multiplier * probability) * bet.wager;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollars: edgeDollars,
      edgePercentage: edgeDollars / totalWager,
    };
  }
}
