import IconButton from "../common/button/IconButton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { NavContext } from "../../contexts/NavContext";
import { RightNavType } from "../../types/nav";
import { useWalletModal } from "../../hooks/modals/useWalletModal";
import { WalletTab } from "../../modals/WalletModal";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

export const BottomNav = () => {
  const navigate = useNavigate();
  const {
    leftPanelOpen,
    toggleLeftPanel,
    rightPanelOpen,
    toggleRightPanel,
    setRightPanelType,
    closeAllBars,
    toggleUserProfilePanel,
    userProfilePanelOpen,
  } = useContext(NavContext);
  const { loggedIn } = useContext(WrappedWalletContext);
  const { setWalletTab, showWalletModal } = useWalletModal();

  return (
    <div className="z-40 flex fixed md:hidden w-full bottom-0 h-top-bar-height bg-gray-800 items-center justify-between px-4 bottom-nav-shadow">
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="hamburger_sm"
        onClick={() => {
          if (leftPanelOpen == true) {
            toggleLeftPanel(false);
          } else {
            closeAllBars();
            toggleLeftPanel(true);
          }
        }}
      />
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="home"
        onClick={() => {
          closeAllBars();
          navigate(``);
        }}
      />
      {loggedIn ? (
        <IconButton
          onClick={() => {
            closeAllBars();
            setWalletTab(WalletTab.FUND);
            showWalletModal();
          }}
          className="bg-gray-800"
          size="lg"
          iconName="wallet"
        />
      ) : (
        ""
      )}
      {loggedIn ? (
        <IconButton
          onClick={() => {
            if (userProfilePanelOpen == true) {
              toggleUserProfilePanel(false);
            } else {
              closeAllBars();
              toggleUserProfilePanel(true);
            }
          }}
          className="bg-gray-800"
          size="lg"
          iconName="user"
        />
      ) : (
        ""
      )}
      <IconButton
        className="bg-gray-800"
        size="lg"
        iconName="chat"
        onClick={() => {
          if (rightPanelOpen == true) {
            toggleRightPanel(false);
          } else {
            closeAllBars();
            setRightPanelType(RightNavType.CHAT);
            toggleRightPanel(true);
          }
        }}
      />
    </div>
  );
};
