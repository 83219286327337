import React, { FC, useRef, useMemo, ReactNode } from "react";
import BasicSlider, { SliderProps as BasicSliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
// import 'react-range-slider-input/dist/style.css';
import "./styles.css";
import Icon from "../icon/Icon";

export interface SliderProps extends BasicSliderProps {
  vertical?: boolean;
  value: number;
  icon?: ReactNode;
  height?: number;
  gradient?: boolean;
  showValue?: boolean;
}

const Slider: FC<SliderProps> = ({
  value,
  max,
  min,
  icon,
  disabled,
  step = 1,
  className,
  height,
  gradient,
  vertical = false,
  showValue = true,
  ...props
}) => {
  const caseOfOne = useMemo(
    () => Number(max) === Number(min) && Number(min) === Number(value),
    [value, max, min],
  );
  const isDisabled = useMemo(() => disabled || caseOfOne, [disabled, caseOfOne]);

  return (
    <div
      className={`flex items-center justify-center gap-2 ${
        vertical ? "h-full flex-col " : "w-full "
      } ${className}`}
    >
      <BasicSlider
        {...props}
        style={{
          height: vertical ? `${height}px` : "",
        }}
        className={`Slider ${gradient ? "Slider-gradient" : ""}`}
        vertical={vertical}
        disabled={isDisabled}
        max={max}
        step={step}
        min={caseOfOne ? 0 : min}
        value={value}
      />
      {icon}
      {!!value && showValue && !icon && <span className={`text-sm text-gray-300`}>×{value}</span>}
    </div>
  );
};

export default Slider;
