import { useEffect, useMemo, useRef, useState } from "react";
import { IChainBalance } from "../contexts/BalanceContext";
import { Connection, PublicKey } from "@solana/web3.js";
import { getAssociatedTokenAddress, unpackAccount } from "@solana/spl-token";

export interface ITokenLoaded {
    wallet: string | undefined,
    mint: string | undefined
}

export const useTokenBalance = (tokenMint: PublicKey | undefined, walletPubkey: PublicKey | null, client: Connection | undefined) => {
    const updateWs = useRef<number>()
    const pubkeyMetaRef = useRef<ITokenLoaded>()

    const [userTokenBalance, setUserTokenBalance] = useState<IChainBalance>()
    const userTokenBalanceRef = useRef<IChainBalance>()
    useEffect(() => {
        userTokenBalanceRef.current = userTokenBalance
    }, [userTokenBalance])

    useEffect(() => {
        async function loadTokenBalance(mint: PublicKey, walletPubkey: PublicKey, client: Connection) {
            const ata = await getAssociatedTokenAddress(mint, walletPubkey);
            let balance = {
                decimals: 0,
                basis: 0,
                uiAmount: 0,
                identifier: mint.toString()
            }

            try {
                const balanceResp = await client.getTokenAccountBalance(ata)
                balance = {
                    decimals: balanceResp.value.decimals,
                    basis: Number(balanceResp.value.amount),
                    uiAmount: balanceResp.value.uiAmount || 0,
                    identifier: mint.toString()
                }

                setUserTokenBalance(balance)

                // CLOSE WS IF ITS OPEN
                if (updateWs.current != null) {
                    try {
                        client.removeAccountChangeListener(updateWs.current)
                    } catch (err) {
                        console.error({
                            err
                        })
                    }
                }

                const sub = client.onAccountChange(
                    ata,
                    async (updatedAccountInfo) => {
                      const account = unpackAccount(ata, updatedAccountInfo);
                      const tokenBasis = Number(account.amount);
                      const decimals = userTokenBalanceRef.current?.decimals || 6
                      const mint = pubkeyMetaRef.current?.mint || ''
          
                      setUserTokenBalance({
                        identifier: mint,
                        basis: tokenBasis,
                        uiAmount: tokenBasis / Math.pow(10, decimals),
                        decimals: decimals,
                      });
                    },
                    "confirmed",
                  );
                  updateWs.current = sub;
            } catch (err) {
                console.warn({
                    err
                })
            }

            setUserTokenBalance(balance)
        }

        if (client == null) {
            return
        } else if (tokenMint == null || walletPubkey == null) {
            if (updateWs.current != null) {
                try {
                    client.removeAccountChangeListener(updateWs.current)
                    updateWs.current = undefined
                    pubkeyMetaRef.current = undefined

                    setUserTokenBalance(undefined)
                } catch (err) {
                    console.error({
                        err
                    })
                }
            }
            return
        }

        const mintString = tokenMint.toString()
        const walletString = walletPubkey.toString()
        const loadedMeta = pubkeyMetaRef.current

        if (loadedMeta != null && loadedMeta.mint == mintString && loadedMeta.wallet == walletString) {
            // ALREADY LOADED AND WS OPEN...
            return
        }

        pubkeyMetaRef.current = {
            mint: mintString,
            wallet: walletString
        }

        loadTokenBalance(tokenMint, walletPubkey, client)
    }, [tokenMint, walletPubkey, client])

    return useMemo(() => {
        return {
            userTokenBalance: userTokenBalance
        }
    }, [userTokenBalance])
}