import Zeebro from '../../sdk/nftStaking/zeebro'
import { ClaimableStatus } from '../../sdk/betStream'
import { ClaimType } from '../../hooks/rewards/claims'
import { Token } from '@solflare-wallet/utl-sdk'

export interface IJwtPayload {
    message: string,
    signature: string,
    wallet: string,
}

export interface IJwtResponse {
    jwt: string
}

export interface IGetDataPayload {
    isPrivate: boolean,
    jwt: string,
    wallet: string
}

export interface IGetDataResp {
    emailAddress: string,
    acceptPlatformNotifications: boolean,
    acceptProjectUpdateNotifications: boolean
}

export interface ISetDataPayload {
    isPrivate: boolean,
    jwt?: string,
    wallet: string
    username?: string
    emailAddress?: string
    discordUsername?: string,
    twitterUsername?: string,
    telegramUsername?: string,
    acceptPlatformNotifications?: boolean,
    acceptProjectUpdateNotifications?: boolean,
    web3AuthData?: string,
    additionalInfo?: string
}

export interface ISetDataResp {

}

export interface IGetStakingActivityFilter {
    nftMint?: string
    timeFrom?: number
    timeTo?: number
    owner?: string
    player?: string
    page?: number
    pageSize?: number
    chain?: string
}

export enum StakingActivity {
    STAKED = "Staked",
    UN_STAKED = "UnStaked"
}

export interface IGetStakingActivityResp {
    event_type: StakingActivity,
    event_timestamp: number,
    signature: string,
    nftmint: string,
    nftid: number,
    player: string,
    owner: string,
}

export interface IStakingActivity {
    activity: StakingActivity
    time: Date
    nftMint: string
    owner: string
    signature: string
}

// GET JACKPOT WINS
export interface IGetJackpotWinsFilter {
    timeFrom?: number // epoch
    timeTo?: number // epoch
    nftMint?: string
    nftMints?: string[]
    page?: number
    pageSize?: number
    chain?: string
}

export interface IGetJackpotWinsResp {
    event_type: string,
    event_time: string,
    transaction_id: string,
    timestamp: string,
    nftid: number,
    isstaked: boolean,
    nftmint: string,
    amount: string,
    instancenonce: number,
    position: number
    distribution: string
    main: string
}

export interface IJackpotWin {
    time: Date,
    signature: string,
    nftId: number,
    isStaked: boolean,
    nftMint: string,
    amount: number,
    position: number
    distribution: string
    main: string
    zeebro?: Zeebro
}

// TRAIT WINS
export interface IGetTraitWinsFilter {
    traitId?: number
    attributeId?: number
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
    chain?: string
}

export interface IGetTraitWinsResp {
    event_type: string,
    event_time: string,
    transaction_id: string,
    traitid: number,
    amountperstaker: string,
    attributeid: number,
    countstaked: number,
    instancenonce: number,
    position: number,
    distribution: string,
    main: string,
    countpopulation: number,
}

export interface ITraitWin {
    time: Date,
    signature: string,
    traitId: number,
    amountPerStaker: number,
    attributeId: number,
    countStaked: number,
    position: number,
    distribution: string,
    countPopulation: number,
    image?: string
}

// NFT TRAIT WINS
export interface IGetNftTraitWinsFilter {
    traitId?: number
    attributeId?: number
    traitIds?: number
    attributeIds?: number
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
    nftMint?: string
    nftMints?: string[]
    chain?: string
}

export interface IGetNftTraitWinsResp {
    event_type: string,
    block_time: number,
    time: string,
    transaction_id: string,
    traitid: number,
    nftid: number,
    isstaked: boolean,
    nftmint: string,
    amount: number,
    amountperstaker: number,
    attributeid: number,
    countstaked: number,
    instancenonce: number,
    position: number,
    distribution: string,
    main: string,
    countpopulation: number,
}

export interface INftTraitWin {
    time: Date,
    signature: string,
    traitId: number,
    nftId: number,
    isStaked: boolean,
    nftMint: string,
    amount: number,
    amountPerStaker: number,
    attributeId: number,
    countStaked: number,
    position: number,
    distribution: string,
    countPopulation: number,
    zeebro?: Zeebro
}

// Player Claims
export interface IPlayerClaimsFilter {
    player?: string
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
    chain?: string
}

export enum CLAIM_EVENT_TYPE {
    REWARD_FOREFIT = "RewardForfeit",
    REWARD_CLAIMED = "RewardClaimed"
}

export interface IPlayerClaimsResp {
    player: string,
    timestamp: string,
    spreaddays: number,
    house: string,
    tokenamountupfront: number,
    tokenamountspread: number,
    relatesto: number, // epoch 
    valuebase: number,
    rewardtype: string,
    platform: string
    token: string,
    owner: string,
    event_type: CLAIM_EVENT_TYPE
}

export const daysInPeriod = (rewardType: string) => {
    switch (rewardType) {
        case ClaimType.DAILY:
            return 1
        case ClaimType.WEEKLY:
            return 7
        case ClaimType.MONTHLY:
            return 28
        case ClaimType.RAKEBACK:
            return 1
        case ClaimType.LEVEL_UP:
            return 0 // NOT ACTUALLY 1 Day....
        case ClaimType.REFERRAL:
            return 1
        default:
            console.error(`Unknown bonus type`, { rewardType })
            return 1
    }
}

export interface IPlayerClaim {
    timestamp: string,
    spreadDays: number,
    house: string,
    tokenAmountUpFront: number,
    tokenAmountSpread: number,
    relatesTo: Date, // reward date
    valueBase: number,
    type: ClaimType,
    platform: string
    token: string,
    owner: string,
    eventType: CLAIM_EVENT_TYPE
    status: ClaimableStatus
    startDate: Date // CLAIMABLE START
    endDate: Date // CLAIMABLE END
}


// Player Collects
export interface IPlayerCollectsFilter {
    player?: string
    timeTo?: number
    timeFrom?: number
    page?: number
    pageSize?: number
    chain?: string
}

export interface IPlayerCollectsResp {
    player: string,
    eventtime: string,
    house: string,
    platform: string,
    token: string,
    owner: string,
    amount: number,
    rewarddate: number,
    event_type: string
}

export interface IPlayerCollect {
    player: string,
    eventTime: Date,
    house: string,
    platform: string,
    token: string,
    tokenIcon: string
    owner: string,
    amount: number,
    rewardDate: Date,
    eventType: string
    amountUi: number
    amountUsd: number
    amountUsdUi: number
}

export interface IToken {
    id: string,
    mint: string,
    name: string,
    symbol: string,
    decimals: number,
    slug: string,
    coingeckoId: string,
    lifetimeSwapCount: number
}

export interface IGetTokenListFilter {
    mint?: string[],
    symbol?: string,
    name?: string,
    page?: number,
    limit?: number,
    paginationToken?: string
}

export interface ITokenPrice {
    mints: string,
    price: number,
    volume: number,
    startTime: number
}

export interface IGetLatestTokenPricesFilter {
    mints?: string[],
    page?: number,
    limit?: number,
    paginationToken?: string
}

export interface ITokenMeta {
    name: string
    symbol: string
    price: number
    decimals: number
    mint: string
}

export interface ITokenMeta {
    meta: IToken | undefined,
    price: ITokenPrice | undefined,
    token?: Token
}

interface IHmPriceData {
    seriesId?: string
    ethereumId?: string
}

export interface IHmPrice {
    f: string
    id: string
    p: number
    s: number
    t: number
}

export interface ILeaderboardResult {
    player: string
    sum_payout_base: number
    sum_payout_token: number
    sum_wager_base: number
    sum_wager_token: number
    total_bets: number
    wins: number
    losses: number
    pushes: number
}

export interface ILeaderboardFilters {
    startTime?: number
    endTime?: number
    owner?: string
    token?: string
    gameSpec?: string
    page?: number
    pageSize?: number
    chain?: string
}