import { useParams } from "react-router-dom";

import { PermisionlessProvider } from "../contexts/PermisionlessContext";

interface IPermisionlessContainer {
  children: any
}

export const PermisionlessContainer = ({ children }: IPermisionlessContainer) => {
  const { type, mintPubkey } = useParams();

  return (
    <PermisionlessProvider mintPubkey={mintPubkey} type={type}>
      {children}
    </PermisionlessProvider>
  );
};
