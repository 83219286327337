import React, {useContext, useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";

import Button from "../common/button/Button";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import rewardsList from "../../config/rewards_list.json";
import { PlayerContext } from "../../contexts/PlayerContext";
import Scrollbar from "../common/scrollbar/Scrollbar";
import {
  getClaimNotifications, getCollectNotifications, INotification
} from "../../utils/notifications";
import { useRewardButtonClick } from "../../hooks/rewards/useRewardButtonClick";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { loadNotifications } from "../../hooks/rewards/notifications";
import { PlatformContext } from "../../contexts/PlatformContext";
import { getS3StaticFolderUrl } from "../../utils/config/utils";


export enum NotificationState {
  NEW = 'new',
  SEEN = 'seen'
}

export enum NotificationVariant {
  RECEIVED = 'received',
  AVAILABLE = 'available',
  MISSED = 'missed'
}

export enum NotificationType {
  CLAIM = 'claim',
  COLLECT = 'collect'
}

const EMPTY_PERIODS_QUANTITY = 5;
const LOADING_NOTIFICATIONS_CARDS = 5;

export const Notifications = () => {
  const { setProfileTab, toggleRightPanel } = useContext(NavContext);
  const { claimReward } = useRewardButtonClick();
  const navigate = useNavigate();
  const {
    claims,
    collects,
  } = useContext(PlayerContext)

  const allNotifications = useMemo(() => {
    const combined = [...getClaimNotifications(claims), ...getCollectNotifications(collects)]

    return orderBy(combined, ['date'], ['desc'])
  }, [claims, collects]);

  useEffect(() => {
    if (claims?.length) {
      setLastLoadedDate(claims?.[0]?.startDay || new Date())
    }
  }, [claims]);

  const [ lastLoadedDate, setLastLoadedDate ] =
    useState<Date>();
  const [ emptyPeriods, setEmptyPeriods ] = useState(EMPTY_PERIODS_QUANTITY);
  const [ isNotificationsLoading, setIsNotificationsLoading ] = useState(true);
  const [ loadedNotifications, setLoadedNotifications] = useState<INotification[]>([]);
  const intersectionObserverElementRef = useIntersectionObserver(() => {
    setLastLoadedDate(prevLastLoadedDate => new Date(prevLastLoadedDate.setDate(prevLastLoadedDate.getDate() - 7)));
    setIsNotificationsLoading(true);
  });

  const { playerAccount, } = useContext(PlayerContext);
  const { platform, tokensByIdentifier } = useContext(PlatformContext);

  useEffect(() => {
    (async () => {
      if (
        platform?.house
        && playerAccount
        && tokensByIdentifier
        && lastLoadedDate
        && lastLoadedDate < new Date()
        && emptyPeriods
      ) {
        const latestDate = new Date(lastLoadedDate);
        const earliestDate = new Date(latestDate.setDate(latestDate.getDate() - 7));
        setIsNotificationsLoading(true);

        const newNotifications = await loadNotifications(
          playerAccount,
          earliestDate,
          lastLoadedDate,
          true,
          true,
          platform?.house,
          tokensByIdentifier,
          platform
        );
        setLoadedNotifications(
          prevNotifications => ([...prevNotifications, ...newNotifications])
        );

        if (newNotifications.length) {
          setIsNotificationsLoading(false);
        } else {
          setLastLoadedDate(earliestDate);
          setEmptyPeriods(period => period - 1);
        }
      }
    })();
  }, [platform?.house, playerAccount, tokensByIdentifier, lastLoadedDate, emptyPeriods]);

  return <div className="flex flex-col max-h-[calc(100vh-62px)] mr-3">
    <div className="flex w-full justify-between items-center p-3 pr-1">
      Notifications
      <div className="flex">
        <Button
          className="hidden sm:flex"
          variant="secondary-gray"
          size="sm"
          onClick={() => {
            setProfileTab(ProfileTab.REWARDS_CALENDAR);
            navigate(`/profile`);
          }}
        >
          <div className="flex">
            <img
              src={getS3StaticFolderUrl("/static/ranks-and-rewards/calendar-collectible.png")}
              className="w-[18px] mr-2"
            />
            Calendar
          </div>
        </Button>
        <Button
          className="sm:hidden"
          variant="secondary-gray"
          size="sm"
          onClick={() => {
            setProfileTab(ProfileTab.REWARDS_CALENDAR);
            navigate(`/profile`);
            toggleRightPanel();
          }}
        >
          <div className="flex">
            <img
              src={getS3StaticFolderUrl("/static/ranks-and-rewards/calendar-collectible.png")}
              className="w-[18px] mr-2"
            />
            Calendar
          </div>
        </Button>
      </div>
    </div>
    <Scrollbar handleColor="var(--gray-300)">
      <div
        data-id="rewards-notifications"
        className="inline-flex w-full flex-col items-center gap-y-2 justify-items-start p-3 pr-1 pt-0"
      >
        {
          [
            ...allNotifications,
            ...loadedNotifications
          ].map(notification => {
            const rewardIconUrl = rewardsList.find(
              reward => reward.type === notification.reward
            )?.iconUrl;

            const getDate = (date: Date) => {
              const month = date.toLocaleDateString('en-US', { month: 'short' });
              const dayOfMonth = date.getDate();
              var hours = date.getHours();
              var minutes = date.getMinutes();
              var seconds = date.getSeconds();
              const time = `
                ${hours > 9 ? hours : `0${hours}`
                }:${minutes > 9 ? minutes : `0${minutes}`
                }:${seconds > 9 ? seconds : `0${seconds}`
                }
              `;

              return (
                <div className="flex w-full justify-between text-xs">
                  <div>{dayOfMonth}&nbsp;{month}</div>
                  <div>{time}</div>
                </div>
              );
            }
            const notificationContent = {
              available: {
                value: (
                  <span>
                    <span className="inline-flex font-semibold">
                      <img
                        src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                        className="w-[14px] h-[14px] rounded-full mr-0.5 relative top-0.5"
                        alt="token"
                      />
                      {notification.amount}
                    </span>
                    &nbsp;in rewards became&nbsp;
                    <a
                      className="text-brand-pink-dark cursor-pointer"
                      onClick={claimReward}
                    >
                      {notification.type === "collect" ? "collectible" : "claimable"}
                    </a>.
                  </span>
                ),
                iconUrl: getS3StaticFolderUrl(rewardIconUrl || "/static/ranks-and-rewards/collect-now-hand.png")
              },
              received: {
                value: (
                  <span>
                    <span className="inline-flex font-semibold text-green-500">
                      <img
                        src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                        className="w-[14px] h-[14px] rounded-full mr-0.5 relative top-0.5"
                        alt="token"
                      />
                      {notification.amount}
                    </span>
                    &nbsp;in rewards have been {notification.type === "collect" ? "collected" : "claimed"}.
                  </span>
                ),
                iconUrl: getS3StaticFolderUrl(rewardIconUrl || "/static/ranks-and-rewards/collected-hand.png")
              },
              missed: {
                value: (
                  <span>
                    <span className="inline-flex font-semibold text-rose-500">
                      <img
                        src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                        className="w-[14px] h-[14px] rounded-full mr-0.5 relative top-0.5"
                        alt="token"
                      />
                      {notification.amount}
                    </span>
                    &nbsp;in rewards have been missed.
                  </span>
                ),
                iconUrl: getS3StaticFolderUrl(rewardIconUrl || "/static/ranks-and-rewards/collect-missed-hand.png")
              },
            };

            return (
              <div
                key={`${notification.amount}-${notification.date}`}
                className={`
                  flex w-full gap-y-1 p-3 rounded-lg
                  text-sm font-normal
                  ${notification.variant === "available" ? "bg-gray-500 text-gray-200" : "bg-gray-700 text-gray-300"}
                `}
              >
                <div className="h-full bg-gray-800 border-2 border-gray-800 mr-3 rounded object-contain">
                  <img
                    src={notificationContent[notification.variant].iconUrl}
                    className="w-[46px] aspect-square"
                    style={{ ...(notification.variant === "missed" && { filter: "grayscale()" }) }}
                  />
                </div>
                <div className="flex w-full flex-col gap-y-1">
                  <div>
                    {notificationContent[notification.variant].value}
                  </div>
                  {getDate(notification.date)}
                </div>

              </div>
            );
          })
        }
        {!isNotificationsLoading && <div data-id="loading-observer" ref={intersectionObserverElementRef}></div>}
        {
          isNotificationsLoading && !!emptyPeriods
            ? Array(LOADING_NOTIFICATIONS_CARDS).fill(null).map((_, index) => (
              <div
                key={index}
                className="flex w-full gap-y-1 p-3 rounded-lg text-sm font-normal bg-gray-700"
              >
                <div className="h-full w-[46px] aspect-square bg-gray-600 mr-3 rounded animate-pulse" />
                <div className="flex w-full flex-col gap-y-1 animate-pulse">
                  <div className="flex w-full flex-col gap-y-1 justify-between">
                    <div className="w-full bg-gray-600 h-4 rounded" />
                    <div className="w-full bg-gray-600 h-4 rounded" />
                  </div>
                  <div className="flex w-full gap-y-1 justify-between" >
                    <div className="flex bg-gray-600 h-4 w-1/4 rounded" />
                    <div className="flex bg-gray-600 h-4 w-1/3 rounded" />
                  </div>
                </div>
              </div>
            ))
            : (
              <div className="flex flex-col items-center gap-y-1 py-10 text-gray-400">
                <div className="font-semibold text-base">
                  {!allNotifications.length ? "No notifications to show" : "End of notifications"}
                </div>
              </div>
            )
        }
      </div>
    </Scrollbar>
  </div>
};
