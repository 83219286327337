import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ProgramContext } from "./ProgramContext";
import BetStream from "../sdk/betStream";
import { Program } from "@coral-xyz/anchor";
import { IBetHistory, IGameHistory } from "../sdk/types";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

export interface IBetMeta {
  id: string | undefined;
  gameResult: IGameHistory | undefined;
  betResult: IBetHistory | undefined;
}

export interface IBetstreamingContext {
  betStream: BetStream | undefined;
}

export const BetstreamingContext = createContext<IBetstreamingContext>({} as IBetstreamingContext);

interface Props {
  children: any;
}

export const BetstreamingProvider = ({ children }: Props) => {
  const { meta } = useContext(ProgramContext);

  const [betStream, setBetStream] = useState<BetStream>();

  useEffect(() => {
    async function loadBetstream(
      casinoProgram: Program,
      randomnessProgram: Program,
      cashierProgram: Program,
      speculateProgram: Program | undefined,
      permisionlessProgram: Program | undefined
    ) {
      try {
        const newStream = new BetStream(casinoProgram, randomnessProgram, cashierProgram, speculateProgram, permisionlessProgram);
        setBetStream(newStream);
      } catch (err) {
        console.warn("Error loading the betstream", err);
      }
    }

    if (
      meta == null ||
      meta.casinoProgram == null ||
      meta.randomnessProgram == null ||
      meta.cashierProgram == null ||
      (defaultNetwork != NetworkType.MAINNET && meta.speculateProgram == null) ||
      (defaultNetwork != NetworkType.MAINNET && meta.permisionlessProgram == null)
    ) {
      setBetStream(undefined);
      return;
    }

    loadBetstream(meta.casinoProgram, meta.randomnessProgram, meta.cashierProgram, meta.speculateProgram, meta.permisionlessProgram);
  }, [meta]);

  return (
    <BetstreamingContext.Provider
      value={useMemo(
        () => ({
          betStream: betStream,
        }),
        [betStream],
      )}
    >
      {children}
    </BetstreamingContext.Provider>
  );
};
