import { ReactNode } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import { twMerge } from "tailwind-merge";

interface IAutoImageCarousel {
  traits: { name: string; id: number; image: string }[];
  className?: string;
  onClick?: (id: number) => void;
  items?: ReactNode[];
  animationSpeed?: number;
  slideVisibilityDuration?: number;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  pagination?: boolean;
}

export const AutoImageCarousel = ({
  traits,
  className,
  onClick = () => {},
  items = [],
  animationSpeed = 750,
  slideVisibilityDuration = 1500,
  slidesPerView = 1,
  spaceBetween = 0,
  pagination = false
}: IAutoImageCarousel) => {
  return (
    <Swiper
      className={twMerge(
        "h-[120px] w-[120px] shrink-0 cursor-pointer justify-end overflow-hidden rounded-md",
        className,
      )}
      modules={[Autoplay, Pagination]}
      pagination={pagination && {
        clickable: true,
      }}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      autoplay={{ delay: slideVisibilityDuration }}
      loop={(traits || items).length > 12}
      speed={animationSpeed}
    >
      {(traits || items)?.map((item, index) => {
        return (
          <SwiperSlide key={index} onClick={() => onClick(item.id)}>
            {
              traits?.length
                ? <img className="h-full w-full" src={item.image}/>
                : item
            }
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
