import React, { FC, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import NavItem from "../common/navigation-item/NavItem";
import { BinaryOptionsContext } from "../../contexts/BinaryOptionsContext";
import IconUrl from "../common/icon/Icon";
import { formatZeebitNumber, NumberType } from "../../utils/currency/formatting";
import IconFont from "../common/iconFont/IconFont";
import { useBinaryOptionsFeedPrices } from "../../hooks/binaryOptions/useBinaryOptionsPrices";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

export interface BinaryOptionsNavProps {
  toggleLeftPanel: (value?: boolean | undefined) => void;
  closeAfterClick?: boolean;
  leftBarIsOpen?: boolean;
  activePage?: string | undefined;
  collapsed: boolean;
}

const BinaryOptionsNav: FC<BinaryOptionsNavProps> = ({
  toggleLeftPanel,
  closeAfterClick = false,
  activePage = undefined,
  collapsed,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { availableFeeds, binaryOption } = useContext(BinaryOptionsContext);
  const { feedPricesByFeed } = useBinaryOptionsFeedPrices(binaryOption);

  return (
    <NavItem
      isSelected={location.pathname === "/options"}
      isTopLevel
      key="options"
      iconName="transactions"
      collapsed={collapsed}
      title={"Options"}
      dropdownVisibleDefault={true}
    >
      {availableFeeds?.filter?.((feed) => {
        return feed?.context?.attributes.base != null
      }).map?.((feed) => {
        const priceDirection = feedPricesByFeed?.get(feed?.feedIdHex) || [];
        const lastPrice = priceDirection[priceDirection?.length - 1]?.priceUi;
        const previousPrice = priceDirection[priceDirection?.length - 2]?.priceUi;
        const direction = lastPrice > previousPrice ? "up" : "down";
        const tokenShortName = feed?.context?.attributes.base;

        return (
          <NavItem
            isSelected={activePage != null && activePage == "options"}
            isTopLevel
            key={`options-${tokenShortName}`}
            collapsed={false}
            title={
              <div className="-mb-1 flex w-full justify-between">
                <div className="flex">
                  <IconUrl
                    iconUrl={getS3StaticFolderUrl(
                      `/static/tokens/${tokenShortName?.toLocaleLowerCase()}.svg`
                    )}
                    className="mr-2 h-[18px] w-[18px] [&>img]:rounded-full"
                  />
                  <div className="text-md mt-0.5 flex justify-start font-semibold capitalize">
                    {tokenShortName}
                  </div>
                </div>
                <div
                  className={`
                      text-md mt-0.5 flex justify-start font-normal text-gray-200
                      ${direction === "up" ? "text-teal-400" : "text-rose-400"}
                    `}
                >
                  {formatZeebitNumber(lastPrice || 0, NumberType.TOKEN_AMOUNT_WITH_PRECISION)}
                  <span className={direction === "up" ? "text-teal-400" : "text-rose-400"}>
                    <IconFont
                      className="relative bottom-1 z-10 text-[22px]"
                      name={direction === "up" ? "arrow_over" : "arrow_under"}
                    />
                  </span>
                </div>
              </div>
            }
            onClick={() => {
              navigate(`/options?token=${tokenShortName}`);
              if (closeAfterClick == true) {
                toggleLeftPanel(false);
              }
            }}
          />
        );
      })}
    </NavItem>
  );
};

export default BinaryOptionsNav;
