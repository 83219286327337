import { NetworkType, defaultNetwork } from "../utils/chain/network"

// TODO - MOVE TO ENV VARS FOR DEPLOYMENT DEVNET/MAINNET

// BET RESULT AGGREGATION
export const HELLO_MOON_TOKEN = '22ffc301-fa90-4d55-b08f-914e49062b8d'
const HELLO_MOON_ENDPOINT_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/betresults-agg'
const HELLO_MOON_ENDPOINT_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/mainnet/betresults-agg'

export const HELLO_MOON_ENDPOINT = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_ENDPOINT_MAINNET: HELLO_MOON_ENDPOINT_DEVNET

// DATA CACHING
// GET JWT
const HELLO_MOON_GET_JWT_DEVNET = 'https://rest-api.hellomoon.io/v0/auth/create_jwt'
export const HELLO_MOON_GET_JWT = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_GET_JWT_DEVNET: HELLO_MOON_GET_JWT_DEVNET
// GET DATA
const HELLO_MOON_GET_DATA_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/get-data'
export const HELLO_MOON_GET_DATA = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_GET_DATA_DEVNET: HELLO_MOON_GET_DATA_DEVNET
// SET DATA
const HELLO_MOON_SET_DATA_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/set-data'
export const HELLO_MOON_SET_DATA = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_SET_DATA_DEVNET: HELLO_MOON_SET_DATA_DEVNET


// NFT STAKING ACTIVITY
const HELLO_MOON_STAKING_ACTIVITY_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/staked-mints/devnet'
const HELLO_MOON_STAKING_ACTIVITY_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/staked-mints/mainnet' 
export const HELLO_MOON_STAKING_ACTIVITY = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_STAKING_ACTIVITY_MAINNET: HELLO_MOON_STAKING_ACTIVITY_DEVNET

// NFT TRAIT WINS
const HELLO_MOON_TRAIT_WINS_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/trait-selected/devnet'
const HELLO_MOON_TRAIT_WINS_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/trait-selected/mainnet' 
export const HELLO_MOON_TRAIT_WINS = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_TRAIT_WINS_MAINNET: HELLO_MOON_TRAIT_WINS_DEVNET

// NFT INDIVIDUAL WINS
const HELLO_MOON_INDIVIDUAL_WINS_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/individual-selection/devnet'
const HELLO_MOON_INDIVIDUAL_WINS_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/individual-selection/mainnet'
export const HELLO_MOON_INDIVIDUAL_WINS = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_INDIVIDUAL_WINS_MAINNET: HELLO_MOON_INDIVIDUAL_WINS_DEVNET

// NFT TRAIT WINS
const HELLO_MOON_TRAIT_NFT_WINS_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/trait-selected-and-updated/devnet'
const HELLO_MOON_TRAIT_NFT_WINS_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/trait-selected-and-updated/mainnet'
export const HELLO_MOON_NFT_TRAIT_WINS = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_TRAIT_NFT_WINS_MAINNET: HELLO_MOON_TRAIT_NFT_WINS_DEVNET

// PLAYER CLAIMS
const HELLO_MOON_PLAYER_CLAIMS_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/reward-claim/devnet'
const HELLO_MOON_PLAYER_CLAIMS_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/reward-claim/mainnet'
export const HELLO_MOON_PLAYER_CLAIMS = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_PLAYER_CLAIMS_MAINNET: HELLO_MOON_PLAYER_CLAIMS_DEVNET

// PLAYER COLLECTS
const HELLO_MOON_PLAYER_COLLECTS_DEVNET = 'https://rest-api.hellomoon.io/v0/zeebit/calendar-reward/devnet'
const HELLO_MOON_PLAYER_COLLECTS_MAINNET = 'https://rest-api.hellomoon.io/v0/zeebit/calendar-reward/mainnet'
export const HELLO_MOON_PLAYER_COLLECTS = defaultNetwork == NetworkType.MAINNET ? HELLO_MOON_PLAYER_COLLECTS_MAINNET: HELLO_MOON_PLAYER_COLLECTS_DEVNET

export const TIME_MAINNET_STAKING_BEGINS = 1708905660

// TOKEN DATA
export const HM_TOKEN_LIST = 'https://rest-api.hellomoon.io/v0/token/list'
export const HM_TOKEN_PRICES = 'https://rest-api.hellomoon.io/v0/token/price/batched'
export const HM_PYTH_PRICE_DATA = "https://rest-api.hellomoon.io/v0/zeebit/get-pyth-data"
