import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import Player, { FormattedImpliedRewardInfo } from "../../sdk/playerAccount"
import { IPlatformRank } from "../../contexts/PlatformContext"
import { BASE_TOAST_CONFIG, BaseToast } from "../../components/toast/BaseToast"
import { ComputeBudgetProgram, Transaction } from "@solana/web3.js"
import { ToasterContext } from "../../contexts/ToasterContext"
import { ProgramContext } from "../../contexts/ProgramContext"
import { NetworkContext } from "../../contexts/NetworkContext"
import Platform from "../../sdk/platform"
import { BetstreamingContext } from "../../contexts/BetstreamingContext"
import { ITokenCheckMeta } from "../../contexts/BalanceContext"
import { HouseContext } from "../../contexts/HouseContext"
import { IClaimableMeta, ICollectable } from "../../sdk/betStream"
import House from "../../sdk/house"
import { loadCollectsCalendar } from "./collects"
import { loadClaimsCalendar } from "./claims"
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext"

export const MS_IN_DAY = 1000 * 60 * 60 * 24

export interface IMergedRewards {
    daily: {
        rate: number,
        collectible: FormattedImpliedRewardInfo | null
        minimumRank: IPlatformRank | undefined
    },
    weekly: {
        rate: number,
        collectible: FormattedImpliedRewardInfo | null
        minimumRank: IPlatformRank | undefined
    },
    monthly: {
        rate: number,
        collectible: FormattedImpliedRewardInfo | null
        minimumRank: IPlatformRank | undefined
    },
    levelUp: {
        rate: number,
        collectible: FormattedImpliedRewardInfo | null
    },
    rakeback: {
        rate: number,
        rates: {
            base: number,
            boostRate: number,
            boostOnClaimRate: number,
            stakingAddOn: number,
            nftAddOn: number,
            boostUntil: Date | null,
            boostOnClaimDurationSeconds: number
        },
        collectible: FormattedImpliedRewardInfo | null
    },
    collectable?: {
        availableToCollectNow: number,
        futureCollectable: number,
        availableToCollectNowUi: number,
        futureCollectableUi: number
    }, // TODO
    totalAccruingUi: number
    totalCollectibleUi: number
    earliestAccruingStart: Date | null
    earliestAccruingEnd: Date | null
}

export interface IRewardsMeta {
    dueLevelUp: boolean // xp > rank xp
    merged: IMergedRewards
}

export interface IClaim { }
export interface ICollect { }
export interface IRewardCalendarMeta {
    claims: IClaim[]
    collects: ICollect[]
}

export const useRewardsMeta = (playerAccount: Player | undefined, platformRanks: IPlatformRank[] | undefined, platform: Platform | undefined, tokensByIdentifier: Map<string, ITokenCheckMeta> | undefined) => {
    // STATE FOR REWARDS
    const [rewardsMeta, setRewardsMeta] = useState<IRewardsMeta>()
    const [counter, setCounter] = useState(0)

    // EMPTY OUT REWARDS IF WALLET DISCONNECTS
    const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
    useEffect(() => {
        if (walletPubkey == null && rewardsMeta != null) {
            setRewardsMeta(undefined)
        }
    }, [walletPubkey])

    useEffect(() => {
        function getEarliestAccruingMeta(meta: IRewardsMeta) {
            // LOOK ACCROSS ALL METAS AND GET EARLIST ACCRUING
            let earliestStart: Date | null = null
            let earliestEnd: Date | null = null
            const currentDate = new Date()

            Array.from([meta.merged.daily, meta.merged.weekly, meta.merged.monthly, meta.merged.rakeback]).forEach((rate) => {
                // TODO - CHECK THIS CONDITION
                if (rate.rate == 0 || rate.collectible?.currentPeriodEndDate == null || currentDate > rate.collectible?.currentPeriodEndDate) {
                    return
                }

                if (earliestEnd == null || earliestEnd > rate.collectible?.currentPeriodEndDate) {
                    earliestEnd = rate.collectible?.currentPeriodEndDate
                    earliestStart = rate.collectible.currentPeriodStartDate

                    return
                }
            })

            return {
                earliestStart: earliestStart,
                earleintEnd: earliestEnd
            }
        }

        function getMinimumRanksForRewards(ranks: IPlatformRank[] | undefined) {
            if (ranks == null) {
                return
            }

            let daily: IPlatformRank | undefined
            let weekly: IPlatformRank | undefined
            let monthly: IPlatformRank | undefined

            for (let i = 0; i <= ranks.length; i++) {
                const rank = ranks[i]

                if (daily == null && rank.benefit.dailyBonusAccrualRatePerThousand > 0) {
                    daily = rank
                }

                if (weekly == null && rank.benefit.weeklyBonusAccrualRatePerThousand > 0) {
                    weekly = rank
                }

                if (monthly == null && rank.benefit.monthlyBonusAccrualRatePerThousand > 0) {
                    monthly = rank
                }

                if (weekly != null && daily != null && monthly != null) {
                    return {
                        weekly: weekly,
                        daily: daily,
                        monthly: monthly
                    }
                }
            }
        }

        if (playerAccount == null || playerAccount.state == null || platformRanks == null) {
            return
        }

        // CURRENT BENEFITS BASED ON ACCOUNT STATE
        const benefitRates = playerAccount.benefits || {}

        // CLAIMABLE / ACCRUING
        const collectable = playerAccount.collectable || {}

        // MINIMUM FOR WEEKLY/DAILY REWARDS
        const minimumRanksForRewards = getMinimumRanksForRewards(platformRanks)
        const rewardToken = playerAccount.platform.rewardTokenConfig

        let meta = {
            dueLevelUp: playerAccount.dueLevelUp,
            merged: {
                daily: {
                    rate: benefitRates.dailyBonusAccrualRate,
                    collectible: collectable.daily,
                    minimumRank: benefitRates.dailyBonusAccrualRate == 0 ? minimumRanksForRewards?.daily : undefined
                },
                weekly: {
                    rate: benefitRates.weeklyBonusAccrualRate,
                    collectible: collectable.weekly,
                    minimumRank: benefitRates.weeklyBonusAccrualRate == 0 ? minimumRanksForRewards?.weekly : undefined
                },
                monthly: {
                    rate: benefitRates.monthlyBonusAccrualRate,
                    collectible: collectable.monthly,
                    minimumRank: benefitRates.monthlyBonusAccrualRate == 0 ? minimumRanksForRewards?.monthly : undefined
                },
                levelUp: {
                    rate: benefitRates.levelUpBonusAccrualRate,
                    collectible: collectable.levelUp // NO NEED FOR MINIMUM RANK AS AVAILABLE ON FIRST
                },
                rakeback: {
                    rate: benefitRates.rakebackBaseRate,
                    rates: {
                        base: benefitRates.rakebackBaseRate,
                        boostRate: benefitRates.rakebackBoost,
                        boostOnClaimRate: benefitRates.rakebackBoostOnClaim,
                        stakingAddOn: benefitRates.rakebackStakingAddOn,
                        nftAddOn: benefitRates.rakebackNftAddOn,
                        boostUntil: benefitRates.rakebackBoostUntil,
                        boostOnClaimDurationSeconds: benefitRates.rakebackBoostOnClaimDurationSeconds
                    },
                    collectible: collectable.rakeback
                },
                collectable: {
                    availableToCollectNow: playerAccount.rewardCalendar?.availableToCollect || 0,
                    futureCollectable: playerAccount.rewardCalendar?.futureCollectable || 0,
                    availableToCollectNowUi: (playerAccount.rewardCalendar?.availableToCollect || 0) / Math.pow(10, rewardToken?.houseToken.decimals || 6),
                    futureCollectableUi: (playerAccount.rewardCalendar?.futureCollectable || 0) / Math.pow(10, rewardToken?.houseToken.decimals || 6)
                },
                totalAccruingUi: collectable?.totalAccruing,
                totalCollectibleUi: collectable?.totalCollectible,
                earliestAccruingStart: null,
                earliestAccruingEnd: null
            }
        }

        const earliestMeta = getEarliestAccruingMeta(meta)

        meta.merged.earliestAccruingStart = earliestMeta.earliestStart
        meta.merged.earliestAccruingEnd = earliestMeta.earleintEnd

        setRewardsMeta(meta)

        setCounter(counter + 1)
    }, [playerAccount, platformRanks])

    // STATE FOR REWARD CALENDAR
    const { house } = useContext(HouseContext)

    const [loadingCollects, setLoadingCollects] = useState(false)
    const [collects, setCollects] = useState<ICollectable[]>()

    const [loadingClaims, setLoadingClaims] = useState(false)
    const [claims, setClaims] = useState<IClaimableMeta[]>()

    useEffect(() => {
        async function handleLoadingCollects(player: Player, earliestDate: Date, latestDate: Date, house: House, tokensByIdentifier: Map<string, ITokenCheckMeta>, platform: Platform) {
            try {
                setLoadingCollects(true)

                const newCollects = await loadCollectsCalendar(player, earliestDate, latestDate, house, tokensByIdentifier, platform)
                setCollects(newCollects)
            } catch (err) {
                console.error(`Issue loading collects.`)
                console.error({
                    err
                })
            } finally {
                setLoadingCollects(false)
            }
        }
        // COLLECTS
        if (playerAccount == null || house == null || tokensByIdentifier == null || loadingCollects == true || platform == null) {
            return
        }

        const earliestDate = new Date()
        earliestDate.setUTCHours(0, 0, 0)
        earliestDate.setUTCDate(earliestDate.getUTCDate() - 3)

        const latestDate = new Date()
        latestDate.setUTCHours(0, 0, 0)
        latestDate.setUTCDate(latestDate.getUTCDate() + 26)

        handleLoadingCollects(playerAccount, earliestDate, latestDate, house, tokensByIdentifier, platform)
    }, [playerAccount, tokensByIdentifier, house, platform])

    useEffect(() => {
        async function handleLoadClaimsCalendar(player: Player, earliestDate: Date, latestDate: Date, house: House, tokensByIdentifier: Map<string, ITokenCheckMeta>, platform: Platform) {
            try {
                setLoadingClaims(true)
                const updatedClaims = await loadClaimsCalendar(player, earliestDate, latestDate, house, tokensByIdentifier, platform)
                setClaims(updatedClaims)
            } catch (err) {
                console.error(`Error loading the claims calendar`)
                console.error({
                    err
                })
            } finally {
                setLoadingClaims(false)
            }
        }

        // CLAIMS
        if (playerAccount == null || house == null || tokensByIdentifier == null || loadingClaims == true || platform == null) {
            return
        }

        const earliestDate = new Date()
        earliestDate.setUTCHours(0, 0, 0)
        earliestDate.setUTCDate(earliestDate.getUTCDate() - 3)

        const latestDate = new Date()
        latestDate.setUTCHours(0, 0, 0)
        latestDate.setUTCDate(latestDate.getUTCDate() + 26)

        handleLoadClaimsCalendar(playerAccount, earliestDate, latestDate, house, tokensByIdentifier, platform)
    }, [playerAccount, tokensByIdentifier, house, platform, loadClaimsCalendar])

    // ACTIONS TO CLAIM, COLLECT, LEVEL UP
    const { client, recentBlockhash, networkCounter } = useContext(NetworkContext);
    const { meta } = useContext(ProgramContext);
    const toast = useContext(ToasterContext);

    const claimReward = useCallback(async () => {
        try {
            const tx = new Transaction();
            const rewardTokenPubkey = platform.rewardTokenPubkey;

            if (rewardTokenPubkey == null) {
                return;
            }

            const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
                bytes: 8*32*1024
            })
    
            tx.add(setHeapLimitIx)

            const hasAta = await playerAccount?.checkPlayerHasAta(rewardTokenPubkey);

            // CHECK IF THE USER HAS ATA FOR TOKEN
            if (hasAta == false) {
                const addAtaIx = await playerAccount.createAssociatedTokenAccountIxn(
                    rewardTokenPubkey,
                    walletPubkey,
                );

                tx.add(addAtaIx);
            }

            const claimIx = await playerAccount.claimRewardIx();

            tx.add(claimIx);

            const sig = await solanaRpc?.sendAndConfirmTransaction(
                tx,
                client,
                walletPubkey,
                meta?.errorByCodeByProgram,
                recentBlockhash
            );

            toast(
                <BaseToast type={"success"} message={"Successfully claimed rewards."} />,
                BASE_TOAST_CONFIG,
            );

            return sig
        } catch (err) {
            console.warn("Issue claiming rewards", err);
            toast(
                <BaseToast type={"error"} message={"There was an issue claiming rewards."} />,
                BASE_TOAST_CONFIG,
            );
        }
    }, [playerAccount, platform, walletPubkey, meta, client, toast, recentBlockhash, networkCounter]);

    const collectReward = useCallback(async () => {
        try {
            const tx = new Transaction();
            const rewardTokenPubkey = platform.rewardTokenPubkey;

            if (rewardTokenPubkey == null || playerAccount?.rewardCalendar == null) {
                return
            }

            const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
                bytes: 8*32*1024
            })
    
            tx.add(setHeapLimitIx)

            const hasAta = await playerAccount?.checkPlayerHasAta(rewardTokenPubkey);

            // CHECK IF THE USER HAS ATA FOR TOKEN
            if (hasAta == false) {
                const addAtaIx = await playerAccount.createAssociatedTokenAccountIxn(
                    rewardTokenPubkey,
                    walletPubkey,
                );

                tx.add(addAtaIx);
            }

            const collectIx = await playerAccount.rewardCalendar.collectIx();

            tx.add(collectIx);

            const sig = await solanaRpc?.sendAndConfirmTransaction(
                tx,
                client,
                walletPubkey,
                meta?.errorByCodeByProgram,
                recentBlockhash
            );

            toast(
                <BaseToast type={"success"} message={"Successfully collected rewards."} />,
                BASE_TOAST_CONFIG,
            );

            return sig
        } catch (err) {
            console.warn("Issue collecting rewards", err);
            toast(
                <BaseToast type={"error"} message={"There was an issue collecting rewards."} />,
                BASE_TOAST_CONFIG,
            );
        }
    }, [playerAccount, platform, walletPubkey, meta, client, toast, recentBlockhash, networkCounter]);

    const { betStream } = useContext(BetstreamingContext)

    const handleClaimRewardsAndResponse = useCallback(async () => {
        let sig: string | undefined

        const isDueLevelUp = rewardsMeta != null && rewardsMeta.dueLevelUp == true
        const hasClaimableRewards = rewardsMeta?.merged.totalCollectibleUi != null && rewardsMeta?.merged.totalCollectibleUi > 0
        const hasCollectableRewards = playerAccount?.rewardCalendar != null && playerAccount.rewardCalendar.availableToCollect > 0

        // IF THERE IS SOMETHING TO CLAIM
        if (isDueLevelUp || hasClaimableRewards) {
            sig = await claimReward()
        } else if (hasCollectableRewards) {
            sig = await collectReward()
        } else {
            return Promise.reject("Nothing to claim, collect or level up.")
        }

        if (sig == null) {
            return Promise.reject("There was an issue claiming or collecting your rewards.")
        }

        // LOAD TX, PARSE LOGS TO SEE WHAT HAPPENED
        return await betStream?.parseRewardsTransaction(sig, platformRanks, tokensByIdentifier, house, playerAccount)
    }, [claimReward, collectReward, client, betStream, platformRanks, tokensByIdentifier, rewardsMeta, house, playerAccount, platform])

    return useMemo(() => {
        return {
            rewardsMeta: rewardsMeta,
            handleClaimRewardsAndResponse: handleClaimRewardsAndResponse,
            claims: claims,
            collects: collects
        }
    }, [rewardsMeta, handleClaimRewardsAndResponse, counter, claims, collects])
}