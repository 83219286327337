import React, { useContext, useMemo } from "react";
import { PlayerContext } from "../../contexts/PlayerContext";
import Button from "../../components/common/button/Button";

export const useGetRewardsButton = (includeClaim: boolean = true, includeCollect: boolean = true, includeLevelUp: boolean = false, handleClick?: Function, isLoading?: boolean) => {
  const { rewardsMeta } = useContext(PlayerContext);

  const hasClaimableAmount = includeClaim && rewardsMeta?.merged.totalCollectibleUi != null && rewardsMeta?.merged.totalCollectibleUi > 0
  const hasLevelUp = includeLevelUp && rewardsMeta?.dueLevelUp != null && rewardsMeta?.dueLevelUp == true
  const hasCollectableAmount = includeCollect && rewardsMeta?.merged.collectable?.availableToCollectNow != null && rewardsMeta?.merged.collectable?.availableToCollectNow > 0
  const areRewardsAvailable = hasClaimableAmount || hasCollectableAmount || hasLevelUp;

  return {
    rewardsButton: areRewardsAvailable ? <GetRewardsButton isLoading={!!isLoading ? isLoading: false} includeClaim={includeClaim} includeCollect={includeCollect} includeLevelUp={includeLevelUp} handleClick={handleClick} />: null,
    areRewardsAvailable,
  };
};

interface IGetRewardsButton {
  includeClaim?: boolean
  includeCollect?: boolean
  includeLevelUp?: boolean
  handleClick?: Function
  isLoading: boolean
}

export const GetRewardsButton = ({
  includeClaim = true,
  includeCollect = true,
  includeLevelUp = false,
  handleClick,
  isLoading
}: IGetRewardsButton) => {
  const { playerAccount, rewardsMeta } = useContext(PlayerContext);

  const claimRewardButton = useMemo(() => {
    const hasClaimableAmount = includeClaim && rewardsMeta?.merged.totalCollectibleUi != null && rewardsMeta?.merged.totalCollectibleUi > 0
    const hasLevelUp = includeLevelUp && rewardsMeta?.dueLevelUp != null && rewardsMeta?.dueLevelUp == true
    const hasCollectableAmount = includeCollect && rewardsMeta?.merged.collectable?.availableToCollectNow != null && rewardsMeta?.merged.collectable?.availableToCollectNow > 0

    let buttonText = ''

    if (hasClaimableAmount) {
      if (hasClaimableAmount && hasLevelUp && hasCollectableAmount) {
        buttonText = `Level Up, Claim & Collect`
      } else if (hasClaimableAmount && hasLevelUp) {
        buttonText = `Level Up & Claim`
      } else if (hasClaimableAmount && hasCollectableAmount) {
        buttonText = `Claim & Collect`
      } else if (hasClaimableAmount) {
        buttonText = `Claim`
      }
    } else if (hasCollectableAmount) {
      if (hasLevelUp) {
        buttonText = `Level Up & Collect`
      } else {
        buttonText = `Collect`
      }
    } else if (hasLevelUp) {
      buttonText = `Level Up`
    }

    if (hasClaimableAmount || hasCollectableAmount || hasLevelUp) {
      return (
        <Button
          variant="primary"
          size="sm"
          isLoading={isLoading}
          onClick={() => {
            handleClick?.()
          }}
        >
          {buttonText}
        </Button>
      );
    } else {
      return (
        <Button
          variant="primary"
          size="sm"
          disabled={true}
        >
          Nothing to claim
        </Button>
      );
    }
  }, [isLoading, rewardsMeta, playerAccount, includeClaim, includeCollect, includeLevelUp]);

  return claimRewardButton;
};

export default GetRewardsButton;




