import { PublicKey } from "@solana/web3.js";

import translations from "../../config/translation.json";
import game_list from "../../config/game_list.json";
import token_info from "../../config/token_info.json";
import supported_translations from "../../config/supported_translations.json";
import zeebro_jsons_devnet from "../../config/metplex/zeebros_json_devnet.json"
import zeebro_jsons_mainnet from "../../config/metplex/zeebros_json_mainnet.json"

import { AverLanguage } from "../../types/language";
import { ICasinoToken } from "../../types/token";
import { NetworkType, defaultNetwork } from "../chain/network";
import { IPlatformGame } from "../../types/game";
import {isIOS} from "../env/env";

export const supportedLanguages = (language?: AverLanguage) => {
  if (!language) return supported_translations;
  return supported_translations.find((lang) => lang.code === language);
};

export const translateSequence = (sequence: string, language: AverLanguage): string => {
  try {
    return translations[sequence.toUpperCase()][language.toUpperCase()];
  } catch (e) {
    console.warn(`Issue retrieving translation for ${sequence} in ${language}.`, e);
    return sequence;
  }
};

export const getPlatformTokens = (environment: NetworkType): ICasinoToken[] => {
  try {
    return token_info[environment];
  } catch (e) {
    console.warn(`Error loading the platform tokens for ${environment}.`, e);
    return [];
  }
};

export const getPlatformTokenMetaByPubkey = (): Map<string, ICasinoToken> => {
  const tokens = getPlatformTokens(defaultNetwork);
  const metaByPubkey = new Map<string, ICasinoToken>();
  tokens.forEach((token) => {
    metaByPubkey.set(token.pubkey, token);
  });

  return metaByPubkey;
};

export const getPlatformGames = (): IPlatformGame[] => {
  try {
    const isIosDevice = isIOS()
    return game_list[defaultNetwork]
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map(gameObj => {
        // Hide slots for iOS devices
        if (isIosDevice == true && ["slotsThree"].includes(gameObj.enum)) {
          return {
            ...gameObj,
            isActive: false,
            isNew: false,
            isComingSoon: true
          };
        }

        return gameObj;
      });
  } catch (e) {
    console.warn(`Error loading the platform games.`, e);
    return [];
  }
};

interface IPeriodsBeginningTimestamps {
  beginningOfMonth: number;
  beginningOfDay: number;
  beginningOfWeek: number;
}
export const getTimestampsOfBeginningOfPeriods = (): IPeriodsBeginningTimestamps => {
  const currentDate = new Date();

  const beginningOfMonth = new Date(currentDate);
  beginningOfMonth.setUTCDate(1);
  beginningOfMonth.setUTCHours(0, 0, 0, 0);
  const beginningOfMonthTimestamp = beginningOfMonth.getTime();

  const beginningOfWeek = new Date(currentDate);
  const diff = (currentDate.getUTCDay() + 6) % 7;
  beginningOfWeek.setUTCDate(currentDate.getUTCDate() - diff);
  beginningOfWeek.setUTCHours(0, 0, 0, 0);
  const beginningOfWeekTimestamp = beginningOfWeek.getTime();

  currentDate.setUTCHours(0, 0, 0, 0);
  const beginningOfDayTimestamp = currentDate.getTime();

  return {
    beginningOfMonth: beginningOfMonthTimestamp,
    beginningOfWeek: beginningOfWeekTimestamp,
    beginningOfDay: beginningOfDayTimestamp,
  };
};

export const getBaseDecimals = () => {
  const tokens = getPlatformTokens(defaultNetwork);
  const baseToken = tokens.find((token) => {
    return Boolean(token.isBase);
  });

  return baseToken?.decimals || 6;
};

export const getTokenMetaByMint = (tokenMintPubkey: PublicKey): ICasinoToken | undefined => (
  getPlatformTokens(defaultNetwork)
    .find((token) => (token.pubkey === tokenMintPubkey?.toString?.()))
);

// SHORT TERM FIX TO USE MAGIC EDEN IMAGE URLS AS IPFS IS DOWN
export const getMetaplexZeebroJsonByMint = () => {
  const zeebrosMap: any = defaultNetwork == NetworkType.MAINNET ? zeebro_jsons_mainnet : { ...zeebro_jsons_devnet, ...zeebro_jsons_mainnet }
  
  // TODO - UPDATE THE SOURCE JSON FILE
  // Object.keys(zeebrosMap).forEach((key) => {
  //   const url: string = zeebrosMap[key].image

  //   zeebrosMap[key] = {
  //     "image": formatToMagicEdenImage(url)
  //   }
  // })

  return zeebrosMap
}

const formatToMagicEdenImage = (imageUrl: string) => {
  // Regex pattern to extract the CID and the filename before the extension
  const regex = /https:\/\/([a-zA-Z0-9]+)\.ipfs\.nftstorage\.link\/(\d+)\.png/;

  const match = imageUrl.match(regex);
  let zeebNumber: string
  let baseUrl: string

  if (match) {
    baseUrl = match[1];
    zeebNumber = match[2];

    console.log({
      baseUrl,
      zeebNumber
    })
  } else {
    console.log('No match found. Please check the URL format.');
    return ""
  }

  const url = `https://img-cdn.magiceden.dev/rs:fill:400:0:0/plain/https%3A%2F%2F${baseUrl}.ipfs.nftstorage.link%2F${zeebNumber}.png%3Fext%3Dpng`

  return url
}

export const getS3StaticFolderUrl = (url: string): string => {
  return url
}
// TODO - ENABLE ONCE WE SORT THE CICD PIPELINE WITH STATIC FOLDER -> S3
//   defaultNetwork === NetworkType.MAINNET
//     ? `https://d1485wcwgcq4m5.cloudfront.net/dApp${url}`
//     : url
// );
