import { PublicKey } from "@solana/web3.js";

import { ENV_NAME } from "../../utils/env/env";

export const IS_MAINNET: boolean = ENV_NAME == 'MAINNET'

export const NFT_CRANKER = IS_MAINNET ? new PublicKey('8hfyiv9XuheUZSLPb3o3UoxvR3DQGsoB4QMk9uNjLvPz'): new PublicKey('7DcLHYEtsTvqzhvSuNXCQAmaJH58KoQiqdo5ps5QuqvY')
export const BASE_TOKEN_ORACLE_PUBKEY = new PublicKey('BjUgj6YCnFBZ49wF54ddBVA9qu8TeqkFtkbqmZcee8uW');

export const HELIUS_URL = IS_MAINNET ? 'https://mainnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79': 'https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79'

// RPC ENDPOINTS
const RPC_ENDPOINTS_MAINNET = ['https://mainnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79', 'https://fragrant-patient-film.solana-mainnet.quiknode.pro/7f8315e41796433f2bdfcd96d5a628317b0ed94b/']
const RPC_ENDPOINTS_DEVNET = ['https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79', 'https://black-sparkling-hill.solana-devnet.quiknode.pro/140c5f0950de18bebf55d516e84e889f633b68fe/']
export const RPC_WRITE_ENPOINTS = IS_MAINNET ? RPC_ENDPOINTS_MAINNET: RPC_ENDPOINTS_DEVNET

// JITO ENDPOINTS
const JITO_MAINNET = "https://amsterdam.mainnet.block-engine.jito.wtf/api/v1/transactions"
const JITO_DEVNET = "https://dallas.testnet.block-engine.jito.wtf"
export const JITO_ENDPOINT = IS_MAINNET ? JITO_MAINNET: JITO_DEVNET