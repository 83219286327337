import {ClaimableStatus, CollectableStatus, IClaimableMeta, ICollectable} from "../../sdk/betStream";
import {formatZeebitNumber, NumberType} from "../currency/formatting";
import {
  NotificationState,
  NotificationType,
  NotificationVariant,
} from "../../components/right-nav/Notifications";

export enum RewardType {
  RAKEBACK = 'rakeback',
  LEVEL_UP_BONUS = 'levelUpBonus',
  DAILY_BONUS = 'dailyBonus',
  WEEKLY_BONUS = 'weeklyBonus',
  MONTHLY_BONUS = 'monthlyBonus'
}

export interface INotification {
  type: NotificationType,
  amount: string,
  date: Date,
  reward: RewardType,
  variant: NotificationVariant,
  status: ClaimableStatus | CollectableStatus,
  state: string // TODO
}

const toClaimVariant = (claimableStatus: ClaimableStatus): NotificationVariant => {
  switch (claimableStatus) {
    case ClaimableStatus.CLAIMED:
      return NotificationVariant.RECEIVED
    case ClaimableStatus.FOREFIT:
      return NotificationVariant.MISSED
    case ClaimableStatus.CLAIMABLE:
      return NotificationVariant.AVAILABLE
    default:
      throw new Error(`Unknown claim variant ${claimableStatus}`)
  }
}

export const getClaimNotifications = (claims?:  IClaimableMeta[]): INotification[] => {
  const allClaims: INotification[] = []
  const daysWithClaims = claims?.filter((claim) => claim.claimables.length > 0)

  daysWithClaims?.forEach((claimable) => {
    claimable.claimables.forEach((claim) => {
      if ((claim.valueBaseUi || 0) > 0 && claim.status != null && [ClaimableStatus.CLAIMABLE, ClaimableStatus.CLAIMED, ClaimableStatus.FOREFIT].includes(claim.status)) {
        allClaims.push({
          type: NotificationType.CLAIM,
          amount: formatZeebitNumber(claim.valueBaseUi || 0, NumberType.TOKEN_AMOUNT, 7, 4),
          date: claimable.startDay,
          reward: claim.type,
          variant: toClaimVariant(claim.status),
          status: claim.status,
          state: NotificationState.NEW // TODO
        })
      }
    })
  })

  return allClaims
};

const toCollectVariant = (collectableStatus: CollectableStatus): NotificationVariant => {
  switch (collectableStatus) {
    case CollectableStatus.COLLECTED:
      return NotificationVariant.RECEIVED
    case CollectableStatus.FOREFIT:
      return NotificationVariant.MISSED
    case CollectableStatus.COLLECTABLE:
      return NotificationVariant.AVAILABLE
    default:
      throw new Error(`Unknown collect variant ${collectableStatus}`)
  }
};

export const getCollectNotifications = (collects?: ICollectable[]): INotification[] => {
  const allCollects: INotification[] = []
  const daysWithCollects = collects?.filter((day) => day.amountUi > 0)

  daysWithCollects?.forEach((collect) => {
    if (collect.amountUi > 0 && collect.status != null && [CollectableStatus.COLLECTABLE, CollectableStatus.COLLECTED, CollectableStatus.FOREFIT].includes(collect.status)) {
      allCollects.push({
        type: NotificationType.COLLECT,
        amount: formatZeebitNumber(collect.amountUi, NumberType.TOKEN_AMOUNT, 7, 4),
        date: collect.startDay,
        status: collect.status,
        variant: toCollectVariant(collect.status),
        state: NotificationState.NEW // viewed
      })
    }
  })

  return allCollects
};