import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

const SCATTER_SYMBOL = 0;
const WILDCARD_SYMBOL = 1;


export type SlotsThreeUiResult = {
    grid: number[][]
    allLines: number[][],
    playedLines: number[][],
    multipliersPerPlayedLine: number[],
    payoutsPerPlayedLine: number[],
    cappedAggregateMultiplier: number,
    cappedAggregatePayout: number,
}

export default class SlotsThree extends Game {

    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        super(
            casinoProgram,
            house,
            gameSpecPubkey,
            GameType.SlotsThree,
            commitmentLevel
        )
    }

    static async load(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        const game = new SlotsThree(
            casinoProgram,
            house,
            gameSpecPubkey,
        )
        await game.loadState();
        return game
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: any,
        wager: number, // wager per line
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey
    ): Promise<TransactionInstruction> {

        const numberBets = inputs.numSpins

        const instanceRequest = {
            slotsThree: {
                numSpins: numberBets
            }
        };

        const betRequest = {
            slotsThree: {
                numLines: inputs.numLines,
                wagerPerSpin: new anchor.BN(inputs.wagerBasis)
            }
        };

        return await this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }


    getMultiplierForSymbolAndCount(
        keySymbol: number,
        matchCount: number,
        includesWildcard: boolean
    ) {
        if (matchCount == 0) {
            return 0
        } else {
            // Wildcard multiplier applies if there's a wildcard present; and it's not a wildcard line or a scatter
            var wildcardMultiplier = 1.0;
            if (includesWildcard && !(keySymbol == WILDCARD_SYMBOL || keySymbol == SCATTER_SYMBOL)) {
                wildcardMultiplier = this.gameConfig?.slotsThree.includesWilcardMultiplierPerThousand / 1000;
            };
            // Basic multiplier from paytable
            let baseMultiplier = (this.gameConfig.slotsThree.payTable as number[][])[keySymbol][(matchCount - 1)] / 1000000; // i.e. count 1 @ idx 0
            // Apply the wildcard multiplier if applicable
            return (baseMultiplier * wildcardMultiplier)
        }
    }

    getMultiplierForLine(
        line: number[]
    ) {
        // Ignore scatters lines
        // - lines starting with a scatter symbol cannot be a valid winning line
        if (line[0] == SCATTER_SYMBOL) {
            return 0
        }

        // Get starting value
        const keySymbol = line[0];
        var countMatch = 0;
        var includesWildcard = false;

        // If the second value is not the same as the key_symbol or a wildcard, then it's out
        if (line[1] != keySymbol && line[1] != WILDCARD_SYMBOL) {
            countMatch = 1;
        } else {
            if ((line[1] == keySymbol || line[1] == WILDCARD_SYMBOL) && (line[2] == keySymbol || line[2] == WILDCARD_SYMBOL)) {
                countMatch = 3;
                if (line[1] == WILDCARD_SYMBOL || line[2] == WILDCARD_SYMBOL) {
                    includesWildcard = true
                }
            } else {
                countMatch = 2;
                if (line[1] == WILDCARD_SYMBOL) {
                    includesWildcard = true
                }
            }
        }

        return this.getMultiplierForSymbolAndCount(
            keySymbol,
            countMatch,
            includesWildcard
        );

    }



    generateResultsForUi(
        grid: number[][],
        numberOfLines: number,
        wagerPerLine: number
    ) {
        const gameConfig = this.gameConfig;

        // Get scatter count
        var scatterCount = 0;
        for (let r = 0; r < grid.length; r++) {
            for (let c = 0; c < grid[r].length; c++) {
                if (grid[r][c] == SCATTER_SYMBOL) {
                    scatterCount += 1;
                }
            }
        }

        var lineMultipliers: number[] = new Array(numberOfLines + 1).fill(0);
        var lines: number[][] = []
        // Lookup scatter bonus 
        lineMultipliers[0] = this.getMultiplierForSymbolAndCount(SCATTER_SYMBOL, scatterCount, true);

        // Iterate across all of the lines
        for (let i = 1; i <= numberOfLines; i++) {
            const winLine = gameConfig.slotsThree.winLines[i - 1];
            const line = [
                grid[winLine[0]][0],
                grid[winLine[1]][1],
                grid[winLine[2]][2]
            ];
            lines.push(line);
            lineMultipliers[i] = this.getMultiplierForLine(
                line
            );
        }
        const totalWager = wagerPerLine * numberOfLines;
        const payoutsPerLine = lineMultipliers.map((m, i) => (i == 0 ? m * wagerPerLine * numberOfLines : wagerPerLine * m));
        const uncappedAggregateMultiplier = payoutsPerLine.reduce((partialSum, a) => partialSum + a, 0);
        const cappedAggregatePayout = Math.min(this.gameConfig.slotsThree.maxMultiplierPerMillion * totalWager, uncappedAggregateMultiplier);
        const cappedAggregateMultiplier = cappedAggregatePayout / totalWager;

        return {
            grid: grid,
            allLines: lines,
            playedLines: lines.slice(0, numberOfLines),
            multipliersPerPlayedLine: lineMultipliers,
            payoutsPerPlayedLine: payoutsPerLine,
            cappedAggregateMultiplier: cappedAggregateMultiplier,
            cappedAggregatePayout: cappedAggregatePayout,
        } as SlotsThreeUiResult

    }

    get formattedConfig() {
        if (this.gameConfig == null || this.gameConfig.slotsThree == null) {
            return
        }

        return {
            ...this.gameConfig.slotsThree,
            minSpins: 1,
            edgePercentage: this.gameConfig.slotsThree.edgePerMillion / 1_000_000,
            includesWilcardMultiplier: this.gameConfig.slotsThree.includesWilcardMultiplierPerThousand / 1_000,
            maxMultiplier: this.gameConfig.slotsThree.maxMultiplierPerMillion / 1_000_000
        }
    }

    getMultiplier(bet: object) {
        return this.formattedConfig.maxMultiplier
    }

    getProbability(bet: object) {
        return (1 / this.getMultiplier(bet)) * (1 - this.formattedConfig.edgePercentage)
    }

    getBetMetas(bets: any[]) {
        let totalPayout = 0;
        let totalProfit = 0;
        let totalWager = 0;
        let dollarEdge = 0;

        bets.forEach((bet) => {
            const multiplier = this.getMultiplier(bet);
            const payoutOnBet = multiplier * bet.wager;
            const probability = this.getProbability(bet);

            // SET PAYOUT/PROBABILITY
            bet.payout = payoutOnBet;
            bet.probability = probability;
            bet.multiplier = multiplier;

            // INCREMENT METRICS
            totalPayout += payoutOnBet;
            totalProfit += payoutOnBet - bet.wager;
            totalWager += bet.wager;
            dollarEdge += (1 - multiplier * probability) * bet.wager;
        });

        return {
            payout: totalPayout,
            profit: totalProfit,
            wager: totalWager,
            numberOfBets: bets.length,
            bets: bets,
            edgePercentage: this.formattedConfig.edgePercentage,
        };
    }
}