import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { Commitment, Finality, PublicKey } from "@solana/web3.js";
import { IBetMeta } from "../contexts/BetstreamingContext";
import { IGameHistory, IRandomnessResponse } from "./types";
import { IPlatformRank } from "../contexts/PlatformContext";
import { ITokenCheckMeta } from "../contexts/BalanceContext";
import House from "./house";
import Player from "./playerAccount";
import { BoBet } from "../pages/BinaryOptionPage";
import { NetworkType, defaultNetwork } from "../utils/chain/network";
import { PermisionlessBet } from "../hooks/permisionless/usePermisionlessBetHistory";
import { isIOS } from "../utils/env/env";
import { IS_MAINNET } from "../admin/sdk/constants";


export interface IGameMeta {
  gameResult: IGameHistory | undefined;
  bets: IBetMeta[];
}

export interface IClaimableTotal {
  valueBase?: number,
  valueUsd?: number,
  tokenAmountSpread?: number,
  tokenAmountUpFront?: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  tokenIcon?: string
}

export enum ClaimableStatus {
  NOTHING_CLAIMED = 'nothingClaimed',
  NOTHING_TO_CLAIM = 'nothingToClaim',
  ACCRUING = 'accruing',
  CLAIMABLE = 'claimable',
  FOREFIT = 'forefit',
  CLAIMED = 'claimed'
}

export interface IClaimable {
  token: string,
  type: string,
  valueBase: number,
  tokenAmountSpread: number,
  tokenAmountUpFront: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  spreadDays?: number
  tokenIcon?: string
  status?: ClaimableStatus
  startDate: Date,
  endDate: Date,
  tooltip?: string
}

export interface IClaimableMeta {
  totals: IClaimableTotal,
  claimables: IClaimable[]
  startDay: Date
  status: ClaimableStatus
}

export enum CollectableStatus {
  NOTHING_COLLECTED = 'nothingCollected',
  NOTHING_TO_COLLECT = 'nothingToCollect',
  COLLECTABLE = 'collectable',
  COLLECTABLE_IN_FUTURE = 'collectableInFuture',
  FOREFIT = 'forefit',
  COLLECTED = 'collected'
}

export interface ICollectable {
  amount: number
  token: string
  amountUi: number
  amountUsdUi: number
  startDay?: Date
  status?: CollectableStatus
  remaining?: {
    amount: number
    token: string
    amountUi: number
    amountUsdUi: number
    numberDays: number
  } | undefined
  tooltip?: string
}

export interface IRewardTransactionMeta {
  rakebackBoost?: {
    boostRate: number
    boostUntil: Date
  },
  levelUp?: {
    newRankId: number
    benefits: object
    newRank?: IPlatformRank
    valueBaseUi?: number
    valueUsdUi?: number
    tokenIcon: string
    claimable?: IClaimable | undefined
  },
  collected?: ICollectable,
  claimed?: IClaimable[]
  claimedTotals?: IClaimableTotal
  referral?: IClaimable
}

export default class BetStream {
  private _program: Program;
  private _eventParser: anchor.EventParser;
  private _randomnessProgram: Program;
  private _randomnessParser: anchor.EventParser; // USED TO CHECK FOR RANDOMNESS PROOF
  private _cashierProgram: Program;
  private _cashierParser: anchor.EventParser; // USED TO CHECK FOR RANDOMNESS PROOF
  private _speculateProgram: Program;
  private _speculateParser: anchor.EventParser // USED TO CHECK THE PERMISIONLESS BETS
  private _permisionlessProgram: Program;
  private _permisionlessParser: anchor.EventParser // USED TO CHECK THE PERMISIONLESS BETS

  constructor(
    casinoProgram: anchor.Program,
    randomnessProgram: anchor.Program,
    cashierProgram: anchor.Program,
    speculateProgram: Program | undefined,
    permisionlessProgram: Program | undefined
  ) {
    this._program = casinoProgram;
    this._eventParser = new anchor.EventParser(
      this.program.programId,
      new anchor.BorshCoder(this.program.idl),
    );
    this._randomnessProgram = randomnessProgram;
    this._randomnessParser = new anchor.EventParser(
      randomnessProgram.programId,
      new anchor.BorshCoder(randomnessProgram.idl),
    );
    this._cashierProgram = cashierProgram;
    this._cashierParser = new anchor.EventParser(
      cashierProgram.programId,
      new anchor.BorshCoder(cashierProgram.idl),
    );

    if (speculateProgram != null) {
      this._speculateProgram = speculateProgram;
      this._speculateParser = new anchor.EventParser(
        speculateProgram.programId,
        new anchor.BorshCoder(speculateProgram.idl),
      );
    }

    if (permisionlessProgram != null) {
      this._permisionlessProgram = permisionlessProgram;
      this._permisionlessParser = new anchor.EventParser(
        permisionlessProgram.programId,
        new anchor.BorshCoder(permisionlessProgram.idl),
      );
    }
  }

  get program() {
    return this._program;
  }

  get eventParser() {
    return this._eventParser;
  }

  get speculateProgram() {
    return this._speculateProgram
  }

  get permisionlessProgram() {
    return this._permisionlessProgram
  }

  get permisionlessParser() {
    return this._permisionlessParser
  }

  async loadHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfBets: number = 10,
    filter?: Function,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    let lastTransactionSeen = null;
    let numberOfBets = 0;
    let finished = false;

    let metas: IBetMeta[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfBets && finished == false) {
      try {
        let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

        // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

        if (transactionSignatures.length <= 50) {
          finished = true;
        }

        transactionSignatures = transactionSignatures.slice(0, 50);

        const transactionLogs = await this.program.provider.connection.getParsedTransactions(
          transactionSignatures.map((txnSig) => txnSig.signature),
          {
            maxSupportedTransactionVersion: 0,
            commitment: finalityLevel
          },
        );

        const isIosDevice = isIOS()

        transactionLogs.forEach((txnLogs) => {
          const bets = this.parseTxLogs(txnLogs);
          const betsFiltered = bets.filter((meta) => {
            if (filter != null) {
              return filter(meta) && meta.gameResult != null && !(isIosDevice == true && 'slotsThree' == Object.keys(meta.gameResult.gameType)[0]);
            } else {
              return meta.betResult != null && meta.gameResult != null && !(isIosDevice == true && 'slotsThree' == Object.keys(meta.gameResult.gameType)[0]);
            }
          });

          if (betsFiltered.length > 0) {
            metas.push(...betsFiltered);
            numberOfBets = metas.length;
          }
        });

        if (numberOfBets >= maxNumberOfBets) {
          break;
        }

        if (iteration >= maxIterations) {
          break;
        }

        if (transactionSignatures.length > 0) {
          lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
        }

        iteration += 1;
      } catch (err) {
        console.error("Issue with betstream", { err })
        finished = true
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.betResult?.timestamp * 1000) - new Date(b.betResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfBets) {
      metas = metas.slice(-maxNumberOfBets);
    }

    return metas;
  }

  async loadGameHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfGames: number = 10,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    var lastTransactionSeen = null;
    var numberOfBets = 0;
    var finished = false;

    let metas: IGameMeta[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfGames && finished == false) {
      let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
        pubkeyFilter,
        {
          before: lastTransactionSeen,
        },
        finalityLevel,
      );

      // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

      if (transactionSignatures.length <= 50) {
        finished = true;
      }

      transactionSignatures = transactionSignatures.slice(0, 50);

      const transactionLogs = await this.program.provider.connection.getParsedTransactions(
        transactionSignatures.map((txnSig) => txnSig.signature),
        {
          maxSupportedTransactionVersion: 0,
          commitment: finalityLevel
        },
      );

      transactionLogs.forEach((txnLogs) => {
        const gameMeta = this.toGameMeta(txnLogs);

        if (gameMeta.gameResult != null && gameMeta.bets.length > 0) {
          metas = [...metas].concat(...[gameMeta]);
          numberOfBets = metas.length;
        }
      });
      if (transactionSignatures.length != 0) {
        lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
      }

      if (numberOfBets >= maxNumberOfGames) {
        break;
      }

      iteration += 1;
      if (iteration >= maxIterations) {
        break;
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.gameResult?.timestamp * 1000) - new Date(b.gameResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfGames) {
      metas = metas.slice(-maxNumberOfGames);
    }

    return metas;
  }

  async loadBet(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    const bets = this.parseTxLogs(transactionLogs);
    const betsFiltered = bets.filter((meta) => {
      return meta.betResult != null && meta.gameResult != null;
    });

    return betsFiltered;
  }

  async loadRandomness(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    const randomness = this.parseTxLogsForRandomness(transactionLogs);

    return randomness;
  }

  parseTxLogs(txnLogs: any) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null when parsing...`)
      return []
    }

    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const transactionSignature = txnLogs?.transaction.signatures[0];

    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of events) {

      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId.toNumber()}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return betMetas.map((meta) => {
      meta.gameResult = gameResult;

      return meta;
    });
  }

  toGameMeta(txnLogs: any): IGameMeta {
    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return {
      gameResult: gameResult,
      bets: betMetas.map((meta) => {
        meta.gameResult = gameResult;

        return meta;
      }),
    };
  }

  parseTxLogsForCashier(txnLogs: any, filter?: Function) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null in parseTxLogsForCashier`)
      return []
    }
    const randomEvents = this._cashierParser.parseLogs(txnLogs.meta.logMessages);
    const parsedEvents: { name: string; timestamp: string; data: string }[] = [];

    for (let event of randomEvents) {
      if (filter != null) {
        const passesFilter = filter(event);

        if (passesFilter == true) {
          parsedEvents.push({
            name: event.name,
            timestamp: new Date(event.data.timestamp.toNumber() * 1000).toISOString(),
            data: JSON.stringify(event.data),
          });
        }
      } else {
        parsedEvents.push({
          name: event.name,
          timestamp: new Date(event.data.timestamp.toNumber() * 1000).toISOString(),
          data: JSON.stringify(event.data),
        });
      }
    }

    return parsedEvents;
  }

  parseTxLogsForRandomness(txnLogs: any) {
    let randomnessResult: IRandomnessResponse | undefined = undefined;
    const randomEvents = this._randomnessParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of randomEvents) {
      // RANDOMNESS RESPONSE EVENT
      if ((event.name = "ResponseEvent")) {
        randomnessResult = event.data;
      }
    }

    return randomnessResult;
  }

  parseNewLog(txnLogs: any): IBetMeta[] {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return betMetas.map((bm) => {
      bm.gameResult = gameResult;

      return bm;
    });
  }

  toNewGameMeta(txnLogs: any): IGameMeta {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return {
      bets: betMetas.map((bm) => {
        bm.gameResult = gameResult;

        return bm;
      }),
      gameResult: gameResult,
    };
  }

  async loadCashierHistory(
    pubkeyFilter: PublicKey, // reward calendar, player account...
    filter?: Function,
    maxNumberOfRecords: number = 10,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    var lastTransactionSeen = null;
    var numberOfRecords = 0;
    var finished = false;

    let metas: any[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfRecords < maxNumberOfRecords && finished == false) {
      let transactionSignatures =
        await this._cashierProgram.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

      // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

      if (transactionSignatures.length <= 50) {
        finished = true;
      }

      transactionSignatures = transactionSignatures.slice(0, 50);

      const transactionLogs = await this._cashierProgram.provider.connection.getParsedTransactions(
        transactionSignatures.map((txnSig) => txnSig.signature),
        {
          maxSupportedTransactionVersion: 0,
          commitment: finalityLevel
        },
      );

      transactionLogs.forEach((txnLogs) => {
        const events = this.parseTxLogsForCashier(txnLogs, filter);

        if (events != null && events.length > 0) {
          metas = metas.concat(events);
          numberOfRecords = metas.length;
        }
      });
      if (transactionSignatures.length != 0) {
        lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
      }

      if (numberOfRecords >= maxNumberOfRecords) {
        break;
      }

      iteration += 1;
      if (iteration >= maxIterations) {
        break;
      }
    }

    return metas;
  }

  get randomnessParser() {
    return this._randomnessParser
  }

  get cashierParser() {
    return this._cashierParser
  }

  static toClaimedTotals(claimables: IClaimable[], house: House, tokenByIdentifier: Map<string, ITokenCheckMeta>): IClaimableTotal {
    let totals: IClaimableTotal = {
      valueBase: 0,
      valueUsd: 0,
      tokenAmountSpread: 0,
      tokenAmountUpFront: 0,
      valueBaseUi: 0,
      valueUsdUi: 0,
      tokenAmountSpreadUi: 0,
      tokenAmountUpFrontUi: 0,
      tokenIcon: ''
    }

    if (claimables == null || claimables.length == 0) {
      return totals
    }

    let token: PublicKey | null

    claimables.forEach((claimable) => {
      if (token == null) {
        token = new PublicKey(claimable.token)
      }
      totals.valueBase += (claimable.valueBase || 0)
      totals.tokenAmountSpread += (claimable.tokenAmountSpread || 0)
      totals.tokenAmountUpFront += (claimable.tokenAmountUpFront || 0)
      totals.valueBaseUi += (claimable.valueBaseUi || 0)
      totals.tokenAmountSpreadUi += (claimable.tokenAmountSpreadUi || 0)
      totals.tokenAmountUpFrontUi += (claimable.tokenAmountUpFrontUi || 0)
    })

    const tokenContext = tokenByIdentifier.get(token?.toString())
    totals.tokenIcon = tokenContext?.context?.imageDarkPng

    totals.valueUsdUi = house.approximateTokenAmountToBase(token, totals.valueBaseUi || 0)

    return totals
  }

  async parseRewardsTransaction(txSig: string, platformRanks: IPlatformRank[], tokenByIdentifier: Map<string, ITokenCheckMeta>, house: House, playerAccount: Player): Promise<IRewardTransactionMeta> {

    const transaction = await this._cashierProgram.provider.connection?.getParsedTransaction(txSig, { commitment: "confirmed", maxSupportedTransactionVersion: 0 })
    const parsedLogs = this?.cashierParser.parseLogs(transaction?.meta?.logMessages || [])

    let levelUpClaimable
    let txMeta: IRewardTransactionMeta = {
      rakebackBoost: undefined,
      levelUp: undefined,
      claimed: undefined,
      collected: undefined
    }

    for (let event of parsedLogs) {
      if (event.name == "RewardClaimed") {
        if (txMeta.claimed == null) {
          txMeta.claimed = []
        }

        const valueBase = Number(event.data.valueBase)
        const tokenAmountSpread = Number(event.data.tokenAmountSpread)
        const tokenAmountUpFront = Number(event.data.tokenAmountUpFront)
        const token = tokenByIdentifier?.get(event?.data?.token?.toString())
        const valueUsd = house.approximateTokenAmountToBase(event?.data?.token, valueBase)
        const claimable = {
          valueBase: valueBase,
          tokenAmountSpread: tokenAmountSpread,
          tokenAmountUpFront: tokenAmountUpFront,
          token: event?.data?.token?.toString(),
          type: Object.keys(event?.data?.rewardType)[0],
          valueBaseUi: valueBase / Math.pow(10, token?.houseToken?.decimals || 6),
          tokenAmountSpreadUi: tokenAmountSpread / Math.pow(10, token?.houseToken?.decimals || 6),
          tokenAmountUpFrontUi: tokenAmountUpFront / Math.pow(10, token?.houseToken?.decimals || 6),
          spreadDays: event.data.spreadDays,
          valueUsdUi: valueUsd / Math.pow(10, token?.houseToken?.decimals || 6),
          tokenIcon: token?.context?.imageDarkPng
        }
        txMeta.claimed?.push(claimable)

        if (claimable.type == 'levelUpBonus') {
          levelUpClaimable = claimable
        }
      } else if (event.name == "RewardCalendarCollection") {
        const token = tokenByIdentifier?.get(event?.data?.token?.toString())
        const amount = Number(event.data.amount)
        txMeta.collected = {
          amount: amount,
          token: event?.data?.token?.toString(),
          tokenIcon: token?.context?.imageDarkPng,
          amountUi: amount / Math.pow(10, token?.houseToken?.decimals || 6),
          amountUsdUi: house.approximateTokenAmountToBase(event?.data?.token, amount) / Math.pow(10, token?.houseToken?.decimals || 6)
        }
      } else if (event.name == "RakebackBoostActivated") {
        txMeta.rakebackBoost = {
          boostRate: event?.data?.boostPerThousand / 1000,
          boostUntil: new Date(Number(event.data.boostUntil) * 1000)
        }
      } else if (event.name == "LeveledUp") {
        txMeta.levelUp = {
          newRankId: event.data.newRank,
          benefits: Object.values(event?.data?.benefits)[0],
          newRank: platformRanks != null ? platformRanks[event.data.newRank] : undefined,
          claimable: levelUpClaimable
        }
      } else {
        console.warn(`Not a known event`)
      }
    }

    // SET LEVEL UP CLAIMABLE
    if (txMeta != null && txMeta.levelUp != null && txMeta.levelUp.claimable == null) {
      txMeta.levelUp.claimable = levelUpClaimable
    }

    // SET TOTALS FOR CLAIMABLES
    txMeta.claimedTotals = BetStream.toClaimedTotals(txMeta.claimed || [], house, tokenByIdentifier)

    // SUBTRACT CLAIMED UP FRONT FROM COLLECTS AND ENSURE DOESNT SHOW ON UI IF 0
    if (!!txMeta.collected && !!txMeta.claimedTotals.tokenAmountUpFront) {
      const collectedOutsideClaim = txMeta.collected.amount - (txMeta.claimedTotals.tokenAmountSpread || 0)

      if (collectedOutsideClaim <= 0.001) {
        txMeta.collected = undefined
      }
    }

    // IF THERE IS A COLLECT LOAD THE REWARDS CALENDAR
    // POSSIBLY LOAD THE REWARDS CALENDARS IF THERE WAS A COLLECT

    if (txMeta?.collected != null) {
      const platform = playerAccount.platform
      const updatedPlayer = await playerAccount?.loadRewardCalendars()
      const rewardCalendars = updatedPlayer?.rewardCalendars
      // RELOAD THE REWARD CALENDARS HERE
      const updatedRewardCalendar = rewardCalendars != null && rewardCalendars.length > 0 ? rewardCalendars[0] : null

      const remainingCollectable = (updatedRewardCalendar?.futureCollectable || 0) - (updatedRewardCalendar?.availableToCollect || 0)
      const remainingCollectableUi = remainingCollectable / Math.pow(10, platform?.rewardTokenConfig?.houseToken.decimals || 6)
      const remainingCollectableUsd = house?.approximateTokenAmountToBase(new PublicKey(platform?.rewardTokenConfig?.pubkey || ''), remainingCollectable) || 0
      const remainingCollectableUsdUi = remainingCollectableUsd / Math.pow(10, platform?.rewardTokenConfig?.houseToken.decimals || 6)

      txMeta.collected.remaining = {
        amount: remainingCollectable,
        amountUi: remainingCollectableUi,
        amountUsdUi: remainingCollectableUsdUi,
        numberDays: 28,
        token: platform?.rewardTokenConfig?.pubkey || ''
      }
    }

    return txMeta
  }

  // BINARY OPTIONS

  async loadBoBetsForSignatures(txSignatures: string[], finalityLevel: Finality = "confirmed"): Promise<BoBet[]> {
    // LOAD THE LOGS FOR THE SIGS
    const transactionLogs = await this.speculateProgram.provider.connection.getParsedTransactions(
      txSignatures,
      {
        maxSupportedTransactionVersion: 0,
        commitment: finalityLevel
      },
    );

    let bets: BoBet[] = []

    // PARSE THE LOGS INTO BETS
    transactionLogs.forEach((txnLogs) => {
      const betsFromLogs = this.parseBoLogs(txnLogs);

      betsFromLogs.forEach((bet) => {
        if (bet.settledEvent == null) {
          return
        }

        bets.push(bet)
      })
    });

    // IF THEY HAVE A SETTLED EVENT RETURN THEM
    return bets
  }

  async loadBoHistory(
    pubkeyFilter: PublicKey, // speculateProgram, player, specific feed...?
    maxNumberOfBets: number = 10,
    filter?: Function,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    let lastTransactionSeen = null;
    let numberOfBets = 0;
    let finished = false;
    
    let bets: BoBet[] = []

    let iteration = 0;
    let maxIterations = 10;

    while (numberOfBets < maxNumberOfBets && finished == false) {
      try {
        let transactionSignatures = await this.speculateProgram.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

        // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

        if (transactionSignatures.length <= 50) {
          finished = true;
        }

        transactionSignatures = transactionSignatures.slice(0, 50);

        const transactionLogs = await this.speculateProgram.provider.connection.getParsedTransactions(
          transactionSignatures.map((txnSig) => txnSig.signature),
          {
            maxSupportedTransactionVersion: 0,
            commitment: finalityLevel
          },
        );

        transactionLogs.forEach((txnLogs) => {
          const betsFromLogs = this.parseBoLogs(txnLogs);

          betsFromLogs.forEach((bet) => {
            if (bet.settledEvent == null) {
              return
            }

            bets.push(bet)
          })
        });

        numberOfBets = bets.length;

        if (numberOfBets >= maxNumberOfBets) {
          break;
        }

        if (iteration >= maxIterations) {
          break;
        }

        if (transactionSignatures.length > 0) {
          lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
        }

        iteration += 1;
      } catch (err) {
        console.error("Issue with betstream", { err })
        finished = true
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records
    bets.sort( (a, b) =>
      Number(a.settledEvent.openTimestamp) - Number(b.settledEvent.openTimestamp))

    if (bets.length > maxNumberOfBets) {
      bets = bets.slice(-maxNumberOfBets);
    }

    return bets;
  }

  parseBoLogs(txnLogs: any) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null when parsing...`)
      return []
    }

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const events = this._speculateParser.parseLogs(txnLogs.meta.logMessages);

    return this.toBoBets(events, transactionSignature)
  }

  parseNewBoLog(txnLogs: any): BoBet[] {
    const events = this._speculateParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    
    return this.toBoBets(events, transactionSignature)
  }

  toBoBets(events: any, transactionSignature: string) {
    const betWithinTxByInstance = new Map<string, BoBet>()

    const betsWithinTx: BoBet[] = [];

    for (let event of events) {
      const data = event.data
      const name = event.name

      if (name == "BoInstanceCreated") {
        const instance = (data.boInstance as PublicKey).toString()
        let bet = betWithinTxByInstance.get(instance) || {
          createdEvent: undefined,
          settledEvent: undefined,
          openedEvent: undefined,
          voidedEvent: undefined
        }

        bet.createdEvent = { ...data, transactionSignature }
       
        betWithinTxByInstance.set(instance, bet)
      } else if (name == "BoInstanceOpened") {
        const instance = (data.boInstance as PublicKey).toString()
        let bet = betWithinTxByInstance.get(instance) || {
          createdEvent: undefined,
          openedEvent: undefined,
          settledEvent: undefined,
          voidedEvent: undefined
        }

        bet.openedEvent = { ...data, transactionSignature }
       
        betWithinTxByInstance.set(instance, bet)
      } else if (name == "BoInstanceSettled") {
        const instance = (data.boInstance as PublicKey).toString()
        let bet = betWithinTxByInstance.get(instance) || {
          createdEvent: undefined,
          settledEvent: undefined,
          openedEvent: undefined,
          voidedEvent: undefined
        }

        bet.settledEvent = { ...data, transactionSignature }
       
        betWithinTxByInstance.set(instance, bet)
      } else if (name == "BoInstanceRequestVoid") {
        const instance = (data.boInstance as PublicKey).toString()
        let bet = betWithinTxByInstance.get(instance) || {
          createdEvent: undefined,
          settledEvent: undefined,
          openedEvent: undefined,
          voidedEvent: undefined
        }

        bet.voidedEvent = { ...data, transactionSignature }
       
        betWithinTxByInstance.set(instance, bet)
      }
    }

    for (let value of betWithinTxByInstance.values()) {
      if (value.settledEvent != null) {
        betsWithinTx.push(value)
      }
    }

    return betsWithinTx
  }


  // PERMISIONLESS

  async loadPermisionlessBetsForSignatures(txSignatures: string[], finalityLevel: Finality = "confirmed"): Promise<PermisionlessBet[]> {
    // LOAD THE LOGS FOR THE SIGS
    const transactionLogs = await this.permisionlessProgram.provider.connection.getParsedTransactions(
      txSignatures,
      {
        maxSupportedTransactionVersion: 0,
        commitment: finalityLevel
      },
    );

    let bets: PermisionlessBet[] = []

    // PARSE THE LOGS INTO BETS
    transactionLogs.forEach((txnLogs) => {
      const betsFromLogs = this.parsePermisionlessLogs(txnLogs);

      betsFromLogs.forEach((bet) => {
        bets.push(bet)
      })
    });

    return bets
  }

  async loadPermisionlessBets(
    pubkeyFilter: PublicKey, // speculateProgram, player, specific feed...?
    maxNumberOfBets: number = 10,
    filter?: Function,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    let lastTransactionSeen = null;
    let numberOfBets = 0;
    let finished = false;
    
    let bets: PermisionlessBet[] = []

    let iteration = 0;
    let maxIterations = 10;

    while (numberOfBets < maxNumberOfBets && finished == false) {
      try {
        let transactionSignatures = await this._permisionlessProgram.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

        // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

        if (transactionSignatures.length <= 50) {
          finished = true;
        }

        transactionSignatures = transactionSignatures.slice(0, 50);

        const transactionLogs = await this._permisionlessProgram.provider.connection.getParsedTransactions(
          transactionSignatures.map((txnSig) => txnSig.signature),
          {
            maxSupportedTransactionVersion: 0,
            commitment: finalityLevel
          },
        );

        transactionLogs.forEach((txnLogs) => {
          const betsFromLogs = this.parsePermisionlessLogs(txnLogs);

          betsFromLogs.forEach((bet) => {
            bets.push(bet)
          })
        });

        numberOfBets = bets.length;

        if (numberOfBets >= maxNumberOfBets) {
          break;
        }

        if (iteration >= maxIterations) {
          break;
        }

        if (transactionSignatures.length > 0) {
          lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
        }

        iteration += 1;
      } catch (err) {
        console.error("Issue with betstream", { err })
        finished = true
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records
    bets = bets.sort((a, b) => {
      return Number(a.settledEvent.timestamp) - Number(b.settledEvent.timestamp)
    })

    if (bets.length > maxNumberOfBets) {
      bets = bets.slice(-maxNumberOfBets);
    }

    return bets;
  }

  parsePermisionlessLogs(txnLogs: any) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null when parsing...`)
      return []
    }

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const events = this._permisionlessParser.parseLogs(txnLogs.meta.logMessages);

    return this.toPermisionlessBets(events, transactionSignature)
  }

  parseNewPermisionlessLog(txnLogs: any): PermisionlessBet[] {
    const events = this._permisionlessParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    
    return this.toPermisionlessBets(events, transactionSignature)
  }

  toPermisionlessBets(events: any, transactionSignature: string) {
    const betsWithinTx: PermisionlessBet[] = [];

    for (let event of events) {
      const data = event.data
      const name = event.name

      if (name == 'BetSettled') {

        betsWithinTx.push({
          settledEvent: {
            ...data,
            betConfig: {
              jackpot: {
                ...data.betConfig.jackpot,
                lowerIncl: Number(data.betConfig.jackpot.lowerIncl),
                upperExcl: Number(data.betConfig.jackpot.upperExcl),
                wager: Number(data.betConfig.jackpot.wager),
              }
            },
            outcomeString: Object.keys(data.outcome)[0],
            payout: Number(data.payout),
            protocolRake: Number(data.protocolRake),
            sponsorRake: Number(data.sponsorRake),
            timestamp: Number(data.timestamp),
            signature: transactionSignature,
          }
        })
      }
    }

    return betsWithinTx
  }
}
