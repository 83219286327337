import { IS_MAINNET } from "../../admin/sdk/constants";

// 2 SUPABASE INSTANCES, ONE FOR EACH ENVIRONMENT
const SUPABASE_BASE_URL = IS_MAINNET ? "https://alxcukagwoxnhtfcfhmk.supabase.co/functions/v1": "https://ilsxpbsiveyicfvbsoxz.supabase.co/functions/v1";

export const GET_JWT_ENDPOINT = `${SUPABASE_BASE_URL}/get-jwt`;
export const GET_DATA_ENDPOINT = `${SUPABASE_BASE_URL}/get-data`;
export const SET_DATA_ENDPOINT = `${SUPABASE_BASE_URL}/set-data`;
export const GET_STAKING_ACTIVITIES_ENDPOINT = `${SUPABASE_BASE_URL}/get-staking-activities`;
export const GET_JACKPOT_WINS_ENDPOINT = `${SUPABASE_BASE_URL}/get-jackpot-wins`;
export const GET_TRAIT_WINS_ENDPOINT = `${SUPABASE_BASE_URL}/get-trait-wins`;
export const GET_NFT_TRAIT_WINS_ENDPOINT = `${SUPABASE_BASE_URL}/get-nft-trait-wins`;
export const GET_PLAYER_CLAIMS_ENDPOINT = `${SUPABASE_BASE_URL}/get-player-claims`;
export const GET_PLAYER_COLLECTS_ENDPOINT = `${SUPABASE_BASE_URL}/get-player-collects`;
export const GET_LEADERBOARD_PLAYERS_ENDPOINT = `${SUPABASE_BASE_URL}/player-leaderboard`;
export const GET_PYTH_TOKEN_PRICES = `${SUPABASE_BASE_URL}/get-latest-pyth-token-prices`;

export const TIME_MAINNET_STAKING_BEGINS = 1708905660