import React, { useContext, useMemo, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useNavigate } from "react-router-dom";

import Icon from "../common/icon/Icon";
import rewardsList from "../../config/rewards_list.json";
import TableGrid from "../common/table-grid/TableGrid";
import { headerCellWithTooltip } from "../zeebros-page/commonComponents";
import IconFont from "../common/iconFont/IconFont";
import { PlayerContext } from "../../contexts/PlayerContext";
import { formatZeebitNumber, NumberType } from "../../utils/currency/formatting";
import Tooltip from "../common/tooltip/Tooltip";
import { useRankMeta } from "../../hooks/useRankMeta";
import { useGetRewardsButton } from "./GetRewardsButton";
import { Countdown, RunningNumbersAnimation } from "../common";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import { useRewardButtonClick } from "../../hooks/rewards/useRewardButtonClick";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

export const RewardsDropdown: React.FC = () => {
  const { rewardsMeta } = useContext(PlayerContext);
  const { setProfileTab } = useContext(NavContext);
  const { rank, rankMeta } = useRankMeta();
  const navigate = useNavigate();
  const [updater, setUpdater] = useState(0);

  const { isLoading, claimReward } = useRewardButtonClick()


  const { rewardsButton } = useGetRewardsButton(true, true, false, claimReward, isLoading);

  const dropdownMeta = useMemo(() => {
    const isRakebackBoostActivated = !!rewardsMeta?.merged?.rakeback?.rates?.boostUntil
      && rewardsMeta?.merged?.rakeback?.rates?.boostUntil > new Date();

    const secondsTillTheEndOfBoostPeriod = Math.floor((new Date(rewardsMeta?.merged?.rakeback?.rates?.boostUntil) - new Date()) / 1000);
    const remainingBoostPercentage = Math.floor((secondsTillTheEndOfBoostPeriod * 100) / (rewardsMeta?.merged?.rakeback?.rates?.boostOnClaimDurationSeconds || 1));
    const rakebackRatesBonusAddOn = rewardsMeta?.merged?.rakeback?.rates?.boostRate + rewardsMeta?.merged?.rakeback?.rates?.nftAddOn;
    let remainingRewardPercentage = Math.floor((rank?.xpAllTime * 100) / (rankMeta?.nextRank?.threshold || 1));

    const earliestAccruingEndDateMs = rewardsMeta?.merged.earliestAccruingEnd?.getTime()
    const earliestAccruingStartDateMs = rewardsMeta?.merged.earliestAccruingStart?.getTime()

    if (earliestAccruingEndDateMs && earliestAccruingStartDateMs) {
      const nowMs = Date.now()

      if (nowMs > earliestAccruingEndDateMs) {
        remainingRewardPercentage = 100
      } else {
        const totalMsOfPeriod = earliestAccruingEndDateMs - earliestAccruingStartDateMs
        const msCompleted = nowMs - earliestAccruingStartDateMs

        remainingRewardPercentage = Math.floor((msCompleted / totalMsOfPeriod) * 100)
      }
    }

    return {
      isRakebackBoostActivated: isRakebackBoostActivated,
      remainingBoostPercentage: remainingBoostPercentage,
      rakebackRatesBonusAddOn: rakebackRatesBonusAddOn,
      remainingRewardPercentage: remainingRewardPercentage
    }
  }, [rewardsMeta, rank, rankMeta, updater])

  const {
    isRakebackBoostActivated,
    remainingBoostPercentage,
    rakebackRatesBonusAddOn,
    remainingRewardPercentage
  } = dropdownMeta
  
  if (rewardsMeta == null) {
    // TODO LOADING STATE HERE
    return ""
  }

  const dropdownTrigger = (
    <div
      className={`
        flex items-center border rounded-md
        cursor-pointer
        ${isRakebackBoostActivated ? "border-lime-500" : "border-pink-700"}
        px-1 py-0.5 mt-0 aspect-square gap-x-2
        lg:px-3 md:py-0 md:pt-0 md:mt-0.5 md:aspect-auto bg-no-repeat
      `}
      style={{
        background: (
          isRakebackBoostActivated
            ? "linear-gradient(90deg, rgba(33, 77, 60, 0.65) 0%, #108423 100%)"
            : "linear-gradient(90deg, rgba(165, 80, 255, 0.2) 0%, #C100D2 100%)"
        ),
        backgroundRepeat: `no-repeat`,
        backgroundSize: `${isRakebackBoostActivated ? remainingBoostPercentage : remainingRewardPercentage}%`,
      }}
    >
      <Icon
        iconUrl={getS3StaticFolderUrl(
          isRakebackBoostActivated
            ? "/static/ranks-and-rewards/accumulating-icon-green.png"
            : "/static/ranks-and-rewards/accumulating-icon.png"
        )}
        className="rounded mb-0 w-[30px] h-[30px]"
      />
      <div className="hidden sm:flex flex-col">
        <div
          className={`
            text-[10px] font-normal leading-3s -mb-1
            ${isRakebackBoostActivated ? "text-lime-500" : "text-pink-300"}
          `}
        >
          REWARDS ACCRUING
        </div>
        <div className="text-base font-semibold">
          <RunningNumbersAnimation
            key={rewardsMeta?.merged?.totalAccruingUi}
            stringToAnimate={`
              $${formatZeebitNumber(rewardsMeta?.merged?.totalAccruingUi || 0, NumberType.DOLLAR_AMOUNT)}
            `}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex w-full max-w-sm items-center">
      <div className="flex justify-center items-center [&>div]:rounded-lg">
        {
          isRakebackBoostActivated
          && (
            <div className="hidden md:flex">
              <Tippy
                placement="bottom"
                interactive={true}
                className={`
                  [&>.tippy-arrow]:text-green-700 [&>.tippy-content]:p-0
                  bg-green-700 rounded-lg text-white px-3 py-2 cursor-pointer
                `}
                content={(
                  <div className="md:flex flex-col w-full justify-center text-sm">
                    <div className="font-semibold">
                      +{
                      formatZeebitNumber(
                        rakebackRatesBonusAddOn,
                        NumberType.PROBABILITY,
                        rakebackRatesBonusAddOn < 0.1 ? 2 : 3
                      )
                    }% Rakeback Boost
                    </div>
                    <div className="flex w-full font-normal justify-center">
                      <Countdown
                        targetDate={rewardsMeta?.merged?.rakeback?.rates?.boostUntil || new Date()}
                        onExpire={() => setUpdater(updaterVal => updaterVal + 1)}
                      />
                    </div>
                  </div>
                )}
              >
                <span tabIndex={0} className="flex items-center mr-2">
                  <BoostButton percentage={remainingBoostPercentage} size={38} />
                </span>
              </Tippy>
            </div>
          )
        }
        <Tippy
          // visible
          placement="bottom"
          interactive={true}
          popperOptions={{
            modifiers: [],
            strategy: 'fixed',
          }}
          className={`
            [&>.tippy-arrow]:text-gray-700 [&>.tippy-content]:p-0
            bg-gray-700 rounded-lg text-white w-[600px] max-w-[360px] sm:max-w-[450px] md:max-w-[550px]
            shadow-black-regular
          `}
          content={(
            <div className="w-full pt-1">
              <div className="p-3 pt-0">
                <RewardsTable />
                <div className="flex w-full mt-3 [&>button]:w-full [&>button]:mb-2">
                  {
                    rewardsButton
                  }
                </div>
                <div className="flex w-full text-md font-normal mt-1 justify-center items-center text-gray-300">
                  <button
                    className="flex"
                    onClick={() => {
                      setProfileTab(ProfileTab.REWARDS_CALENDAR);
                      navigate(`/profile`);
                    }}
                  >
                    <IconFont size="sm" name="calendar" />
                    &nbsp;OPEN CALENDAR
                  </button>
                </div>
              </div>
            </div>
          )}
        >
          <span tabIndex={0} className="flex items-center gap-x-1 relative">
            <div
              key={rewardsMeta?.merged?.totalAccruingUi}
              className={`
                h-full rounded-md
                ${isRakebackBoostActivated && !rewardsButton ? "bg-lime-600" : "bg-brand-mixed-gradient-1"}
                shimmer-animation
                absolute left-0 z-10
              `}
              style={{ width: `100%`, visibility: "hidden", opacity: 0 }}
            />
            <div className="hidden md:flex">
              {
                rewardsButton || dropdownTrigger
              }
            </div>
            <div className="md:hidden">
              {dropdownTrigger}
            </div>
          </span>
        </Tippy>
      </div>
    </div>
  )
}

export const RewardsTable = () => {
  const { rewardsMeta } = useContext(PlayerContext);
  const { rank, rankMeta } = useRankMeta();
  const navigate = useNavigate();

  const rakebackRates = {
    base: 0,
    boostRate: 0,
    stakingAddOn: 0,
    nftAddOn: 0,
    boostUntil: null,
    ...(rewardsMeta?.merged?.rakeback?.rates || {})
  };
  const rakebackCollectible = {
    amountAvailableToCollectUi: 0,
    amountCurrentlyAccruingUi: 0,
    currentPeriodEndDate: new Date(),
    ...(rewardsMeta?.merged?.rakeback?.collectible || {})
  };
  const rakebackRatesBonus = rakebackRates.base + rakebackRates.boostRate + rakebackRates.nftAddOn;
  const isRakebackBoostActivated = !!rewardsMeta?.merged?.rakeback?.rates?.boostUntil
    && rewardsMeta?.merged?.rakeback?.rates?.boostUntil > new Date();
  const xpTillNextLevel = (rankMeta?.nextRank?.threshold || 0) - rank?.xpAllTime;

  const dropdownRowsList = [
    {
      key: "Rakeback",
      name: "Rakeback",
      accruing: rakebackCollectible.amountCurrentlyAccruingUi,
      claimable: rakebackCollectible.amountAvailableToCollectUi,
      endDate: rakebackCollectible.currentPeriodEndDate,
      percentage: (
        <>
          {
            formatZeebitNumber(rakebackRates.base, NumberType.PROBABILITY, rakebackRates.base < 0.1 ? 2 : 3)
          }% Bonus
        </>
      ),
      rewardPropName: 'impliedRakeback',
      bonus: (
        <Tippy
          placement="bottom"
          interactive={true}
          className={`
            [&>.tippy-content]:p-0
            ${
              isRakebackBoostActivated
                ? "[&>.tippy-arrow]:text-green-700 bg-green-700"
                : "[&>.tippy-arrow]:text-gray-500 bg-gray-500"
            }
            rounded-lg text-white px-3 py-2
          `}
          content={(
            <div className="w-full justify-center text-sm">
              <div className="flex flex-col font-semibold">
                <div className="w-full justify-center flex">
                  Base Rate: {
                    formatZeebitNumber(
                      rakebackRates.base,
                      NumberType.PROBABILITY,
                      rakebackRates.base < 0.1 ? 2 : 3
                    )
                  }%
                </div>
                <div className="w-full justify-center flex">
                  Zeebro Bonus: {
                    formatZeebitNumber(
                      rakebackRates.nftAddOn,
                      NumberType.PROBABILITY,
                      rakebackRates.nftAddOn < 0.1 ? 2 : 3
                    )
                  }%
                </div>
                {
                  isRakebackBoostActivated
                  && (
                    <div className="w-full justify-center flex">
                      Boost Bonus: {
                      formatZeebitNumber(
                        rakebackRates.boostRate,
                        NumberType.PROBABILITY,
                        rakebackRates.boostRate < 0.1 ? 2 : 3
                      )
                    }%
                    </div>
                  )
                }
              </div>
              {
                isRakebackBoostActivated
                && (
                  <div className="flex w-full font-normal justify-center">
                    <Countdown targetDate={new Date(rewardsMeta?.merged?.rakeback?.rates?.boostUntil)}/>
                  </div>
                )
              }
            </div>
          )}
        >
          <span tabIndex={0} className="cursor-pointer">
            <div
              className={`
                flex items-center rounded-sm
                ${isRakebackBoostActivated ? "bg-green-700 text-xs font-semibold" : "text-gray-300 text-md"}
              `}
            >
              {
                isRakebackBoostActivated
                && <img
                  src={getS3StaticFolderUrl("/static/ranks-and-rewards/thunder-item.png")}
                  className={`w-[12px] hidden md:flex`}
                />
              }
              &nbsp;{
              formatZeebitNumber(
                rakebackRatesBonus,
                NumberType.PROBABILITY,
                  rakebackRatesBonus < 0.1 ? 2 : 3
                )
              }%&nbsp;Bonus&nbsp;
              <IconFont
                name="information"
                className={isRakebackBoostActivated ? "text-lime-500" : "text-gray-300"}
              />
            </div>
          </span>
        </Tippy>
      )
    },
    {
      key: "Daily",
      name: "Daily",
      accruing: rewardsMeta?.merged?.daily?.collectible?.amountCurrentlyAccruingUi,
      claimable: rewardsMeta?.merged?.daily?.collectible?.amountAvailableToCollectUi,
      endDate: rewardsMeta?.merged?.daily?.collectible?.currentPeriodEndDate,
      percentage: (
        <>
          {
            formatZeebitNumber(
              rewardsMeta?.merged?.daily?.rate || 0,
              NumberType.PROBABILITY,
              rewardsMeta?.merged?.daily?.rate < 0.1 ? 2 : 3
            )
          }% Bonus
        </>
      ),
      minimumRank: rewardsMeta?.merged?.daily?.minimumRank?.name,
    },
    {
      key: "Weekly",
      name: "Weekly",
      accruing: rewardsMeta?.merged?.weekly?.collectible?.amountCurrentlyAccruingUi,
      claimable: rewardsMeta?.merged?.weekly?.collectible?.amountAvailableToCollectUi,
      endDate: rewardsMeta?.merged?.weekly?.collectible?.currentPeriodEndDate,
      percentage: (
        <>
          {
            formatZeebitNumber(
              rewardsMeta?.merged?.weekly?.rate || 0,
              NumberType.PROBABILITY,
              (rewardsMeta?.merged?.weekly?.rate || 0) < 0.1 ? 2 : 3
            )
          }% Bonus
        </>
      ),
      minimumRank: rewardsMeta?.merged?.weekly?.minimumRank?.name,
    },
    {
      key: "Monthly",
      name: "Monthly",
      accruing: rewardsMeta?.merged?.monthly?.collectible?.amountCurrentlyAccruingUi,
      claimable: rewardsMeta?.merged?.monthly?.collectible?.amountAvailableToCollectUi,
      endDate: rewardsMeta?.merged?.monthly?.collectible?.currentPeriodEndDate,
      percentage: (
        <div className="text-nowrap">
          {
            formatZeebitNumber(
              rewardsMeta?.merged?.monthly?.rate || 0,
              NumberType.PROBABILITY,
              (rewardsMeta?.merged?.monthly?.rate || 0) < 0.1 ? 2 : 3
            )
          }% Bonus
        </div>
      ),
      minimumRank: rewardsMeta?.merged?.monthly?.minimumRank?.name,
    },
    {
      key: "Level-Up",
      name: "Level Up",
      accruing: rewardsMeta?.merged?.levelUp?.collectible?.amountCurrentlyAccruingUi,
      claimable: rewardsMeta?.merged?.levelUp?.collectible?.amountAvailableToCollectUi,
      endDate: rewardsMeta?.merged?.levelUp?.collectible?.currentPeriodEndDate,
      percentage: (
        <div className="text-nowrap">
          {
            formatZeebitNumber(
              rewardsMeta?.merged?.levelUp?.rate || 0,
              NumberType.PROBABILITY,
              (rewardsMeta?.merged?.levelUp?.rate || 0) < 0.1 ? 2 : 3
            )
          }% Bonus
        </div>
      ),
      xpTillTheNextLevel: (
        <Tooltip key="xp-till-nex-level" content={xpTillNextLevel > 0 ? "XP are required to get to the next level": "You are due a level up."}>
          <div className="flex">
            {
              xpTillNextLevel > 0 ? `${formatZeebitNumber(xpTillNextLevel, NumberType.XP_AMOUNT)} XP`: 'Due Level Up'
            }
            &nbsp;<IconFont name="information" />
          </div>
        </Tooltip>
      )
    },
    {
      key: "Collectable",
      name: "Collectable",
      percentage: "Past Rewards",
      collectableClaimable: (
        <div
          className={`flex flex-col justify-center ${rewardsMeta?.merged?.collectable?.availableToCollectNowUi ? "" : "text-gray-300"}`}>
          <div className="flex justify-center">
            <img
              src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
              className="w-[14px] h-[14px] rounded-full mr-0.5 relative top-0.5"
              alt="token"
            />
            {formatZeebitNumber(rewardsMeta?.merged?.collectable?.availableToCollectNowUi || 0, NumberType.DOLLAR_AMOUNT)}
          </div>
          <div>Now</div>
        </div>
      ),
      collectableAccruing: (
        <div
          className={`
            flex w-full flex-col justify-center
            ${(rewardsMeta?.merged?.collectable?.availableToCollectNowUi || 0) > 0 ? "" : "text-gray-300"}
          `}
        >
          <div className="flex justify-center">
            <img
              src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
              className="w-[14px] h-[14px] rounded-full mr-0.5 relative top-0.5"
              alt="token"
            />
            {formatZeebitNumber(rewardsMeta?.merged?.collectable?.futureCollectableUi || 0, NumberType.DOLLAR_AMOUNT)}
          </div>
          <div className="flex justify-center">Total</div>
        </div>
      ),
      iconUrl: getS3StaticFolderUrl("/static/ranks-and-rewards/collectible-coins.png"),
    },
  ];

  const formattedRewardsList = dropdownRowsList.map((item, index) => ({
    id: item.key,
    reward: (
      <div className="flex">
        <div>
          <img
            src={
              item.iconUrl
              || rewardsList.find(reward => reward.key === item.key)?.iconUrl
            }
            className="bg-gray-700 border-2 border-gray-700 mr-2 rounded mb-0 w-[40px] h-[40px]"
            style={{...(item.minimumRank && {filter: "grayscale()"})}}
          />

        </div>
        <div className="flex flex-col">
          {
            item.collectableClaimable
              ? <div>{item.name}</div>
              : (
                <button
                  className="flex hover:text-gray-300"
                  onClick={() => {
                    navigate(
                      window.location.pathname,
                      { state: { selectedReward: item.key } }
                    );
                  }}
                >
                  {item.name}
                </button>
              )
          }
          {
            item.bonus
            || <div className="text-gray-300">{item.percentage}</div>
          }
        </div>
      </div>
    ),
    claimable: {
      value: (
        item.collectableClaimable
        || <div className={`flex flex-col justify-center`}>
          <div className={`flex justify-center ${(item.claimable || 0) > 0 ? "" : "text-gray-300"}`}>
            ${formatZeebitNumber(item.claimable || 0, NumberType.DOLLAR_AMOUNT)}
          </div>
          {
            !!item.claimable
            && !!item.endDate
            && <div>
              <Countdown targetDate={item.endDate} />
            </div>
          }
        </div>
      ),
      classes: item.minimumRank ? "hidden" : "",
    },
    accruing: {
      value: item.minimumRank
        ? (
          <div
            className={`
              flex justify-center items-center w-full h-full
              rounded-md bg-gray-500 text-xs sm:text-base text-gray-200
            `}
          >
            <IconFont size="md" name="locked" />
            &nbsp;Unlocks at level {item.minimumRank}
          </div>
        )
        : (
          item.collectableAccruing
          || <div className="flex flex-col justify-center w-full">
            <div className={`flex justify-center ${item.accruing ? "" : "text-gray-300"}`}>
              ${formatZeebitNumber(item.accruing || 0, NumberType.DOLLAR_AMOUNT)}
            </div>
            {
              !!item.accruing
              && !!item.endDate
              && <div className="flex text-gray-300 w-full justify-center">
                <Countdown targetDate={item.endDate} />
              </div>
            }
            {
              item.xpTillTheNextLevel
              && <div className="flex text-gray-300 w-full justify-center">
                {
                  item.xpTillTheNextLevel
                }
              </div>
            }
          </div>
        ),
      classes: item.minimumRank ? "col-span-2" : ""
    },
    classes: "[&>div]:bg-gray-600 [&>div]:py-2",
  }));

  const columnsList = [
    {
      header: headerCellWithTooltip("REWARD", "You can learn more about each of the reward types by clicking on them.\n" +
        "The final row - \"Collectable\" - shows the amount of previous rewards claimed which have been added to your reward calendar and can be collected now or in the future. For more information on Collections, see the Rewards Calendar."),
      accessor: "reward",
      headerClasses: "pb-0 sm:text-md first:justify-center last:justify-center",
    },
    {
      header: headerCellWithTooltip("CLAIMABLE", "For each of the rewards types, this is the amount that can be claimed right now. The countdown below the value shows how long you have remaining to claim this reward before it expires."),
      accessor: "claimable",
      headerClasses: "w-full pb-0 sm:text-md",
      classes: "-mx-2 sm:px-2"
    },
    {
      header: headerCellWithTooltip("ACCRUING", "The amount shown here is the value currently accruing in the reward for the next cycle. The countdown timer tells you when this next reward will become available to claim.\n" +
        "In the case of level-up reward, your this will become available when you next level-up."),
      accessor: "accruing",
      headerClasses: "pb-0 sm:text-md first:justify-center last:justify-center",
    },
  ];

  return (
    <TableGrid
      rows={formattedRewardsList}
      columns={columnsList}
      variant="regular"
      emptyMessage=" "
      onRowClick={(rowId) => {
        if (rowId === "Collectable") {
          return;
        }

        navigate(
          window.location.pathname,
          { state: { selectedReward: rowId } }
        );
      }}
    />
  );
};

interface BoostButtonProps {
  percentage: number;
  size: number;
}

const BoostButton: React.FC<BoostButtonProps> = ({ percentage, size }) => {
  const radius = (size - 4) / 2;
  const circumference = 2 * Math.PI * radius;
  const dashoffset = (percentage / 100) * circumference;

  return (
    <div className="relative animate-pulse">
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
        <circle
          className="stroke-current text-lime-400 fill-green-900"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={2}
          fill="none"
        />
        <circle
          className="stroke-current text-green-700"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={2}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div className="flex w-full h-full absolute top-0 left-0 items-center justify-center">
        <img
          src={getS3StaticFolderUrl("/static/ranks-and-rewards/thunder-item.png")}
          className={`w-[65%] h-[65%]`}
        />
      </div>
    </div>
  );
};
