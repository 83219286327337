export const ENV_NAME: string = process.env.ENV_NAME?.toUpperCase() || "DEVNET";
export const WEB3_AUTH_REDIRECT: string = process.env.WEB3_AUTH_REDIRECT || "https://dev.zeebit.io";

export const isIOS = () => {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};