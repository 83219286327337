import { IPlatformRank } from "../../contexts/PlatformContext";

export interface IRankMeta {
  currentRank: IPlatformRank;
  nextRank: IPlatformRank;
  percentage: number;
  missedLevelUp: boolean;
}

export const getRankMeta = (
  rankMeta: {
    rank: number; // actual rank coming from player
    xpAllTime: number;
  },
  platformRanks: IPlatformRank[],
): IRankMeta => {
  // WANT TO FIND CURRENT AND NEXT RANK
  if (rankMeta?.xpAllTime == 0) {
    return {
      currentRank: platformRanks[0],
      nextRank: platformRanks[1],
      percentage: 0,
      missedLevelUp: false,
    };
  }

  const nextRankIndex = platformRanks.findIndex((currentRank) => {
    return currentRank.threshold > rankMeta?.xpAllTime;
  });

  if (nextRankIndex > 0) {
    const actualCurrentRank = rankMeta.rank; // value from playerAcc
    const nextRank = platformRanks[nextRankIndex];
    const currentRank = platformRanks[nextRankIndex - 1];

    // CHECK IF STILL NEEDS LEVEL UP
    if (nextRankIndex - 1 > actualCurrentRank) {
      return {
        currentRank: platformRanks[actualCurrentRank],
        nextRank: platformRanks[actualCurrentRank + 1],
        percentage: 100,
        missedLevelUp: true,
      };
    }

    const totalInRank = nextRank.threshold - currentRank.threshold;
    const completed = rankMeta?.xpAllTime - currentRank.threshold;
    const percentage = Math.floor((completed / totalInRank) * 100);

    return {
      currentRank: currentRank,
      nextRank: nextRank,
      percentage: percentage,
      missedLevelUp: false,
    };
  }

  // ON THE TOP RANK
  const finalRank = platformRanks[platformRanks.length - 1];

  return {
    currentRank: finalRank,
    nextRank: finalRank,
    percentage: 100,
  };
};
