import Icon from "../common/icon/Icon";
import Input from "../common/input/Input";
import { Search } from "./Search";
import { SearchBox } from "./SearchBox";
import SearchIcon from "./../../assets/icons/search.svg";

interface ISearchMobile {
  searchInput: string;
  setSearchInput: Function;
  searchExpanded: boolean;
  setSearchExpanded: Function;
  onClose: Function;
}

export const SearchMobile = ({
  searchInput,
  setSearchInput,
  searchExpanded,
  setSearchExpanded,
  onClose,
}: ISearchMobile) => {
  if (searchExpanded == false) {
    return (
      <Input
        onFocus={() => {
          setSearchExpanded(true);
        }}
        leftInfo={<Icon className="text-gray-300 me-1" icon={<SearchIcon />} size="md" />}
        classes={{
          label: "h-[42px] flex-row",
          wrapper: "flex-1 w-full sm:hidden",
        }}
        type={"string"}
        placeholder={"Search games"}
        value={searchInput || ""}
        onChange={(e) => {
          const term = String(e.target.value);

          setSearchInput(term);
        }}
      />
    );
  }

  return (
    <SearchBox
      show={searchExpanded}
      onClickOutside={(e) => {
        setSearchExpanded(false);
      }}
    >
      <Search
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        hidden={false}
        setExpanded={() => {}}
        expanded={true}
        onClose={(e) => {
          onClose();
        }}
      />
    </SearchBox>
  );
};
