import * as anchor from "@coral-xyz/anchor";
import { AddressLookupTableProgram, PublicKey, SystemProgram } from "@solana/web3.js";
import { getAssociatedTokenAddressSync } from '@solana/spl-token';
import { listenForTransaction } from "../utils";
import House from "../house";
import { sha256 } from 'js-sha256';
import * as base58 from "bs58"; 
import GameTokenSpec from "./gameTokenSpec";
import GameTokenInstance from "./gameTokenInstance";
import LutCloseList from "./lutCloseList";

export type JackpotGameSpecConfig = {
    maxSharesOfPool: number, 
    protocolRakePerThousand: number, 
    maxSponsorRakePerThousand: number,
    minTimeInterval: anchor.BN,
    maxTimeInterval: anchor.BN,
}
export type GameSpecConfig = JackpotGameSpecConfig | null;

export default class GameSpec {

    private _program: anchor.Program;
    private _pubkey: PublicKey;
    private _state: any;
    private _eventParser: anchor.EventParser;
    private _lutCloseList: LutCloseList;

    constructor( 
        program: anchor.Program,
        pubkey: PublicKey,
    ) {
        this._program = program; 
        this._eventParser = new anchor.EventParser(
            this.program.programId,
            new anchor.BorshCoder(this.program.idl)
        );
        this._pubkey = pubkey;   
        
    };

    static async load(
        program: anchor.Program,
        pubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const gameSpec = new GameSpec(
            program,
            pubkey,
        )
        await Promise.all([
            gameSpec.loadState(commitmentLevel),
            gameSpec.loadLutCloseList(commitmentLevel)
        ]);
        return gameSpec
    };

    async loadState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.program.account.gameSpec.fetchNullable(
            this._pubkey,
            commitmentLevel
        );
        if (state) {
            this._state = state;
            
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    async loadLutCloseList(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        this._lutCloseList = await LutCloseList.load(
            this.program,
            LutCloseList.deriveMainClostListPubkey(this.program.programId)
        );
    }

    get program() {
        return this._program
    }

    get publicKey() {
        return this._pubkey
    } 

    get state() {
        return this._state
    }

    get gameTypeString() {
        return this._state ? Object.keys(this._state.gameType)[0] : undefined
    }

    get statusString() {
        return this._state != null ? Object.keys(this._state.status)[0] : undefined
    }

    get treasuryPubkey(): PublicKey {
        return this._state ? this._state.treasury : undefined
    }

    get maxBetsPerIxn(): number | undefined {
        return this.state != null ? this.state.maxBetsPerIxn: undefined
    }

    get maxBetsPerTokenInstance(): number | undefined {
        return this.state != null ? this.state.maxBetsPerTokenInstance: undefined
    }

    get jackpotConfig() {
        if (this.state?.config?.jackpot == null) {
            return 
        }

        return {
            ...this.state.config.jackpot,
            maxTimeInterval: Number(this.state.config.jackpot.maxTimeInterval),
            minTimeInterval: Number(this.state.config.jackpot.minTimeInterval),
            protocolRake: this.state.config.jackpot.protocolRakePerThousand / 1_000,
            maxSponsorRake: this.state.config.jackpot.maxSponsorRakePerThousand / 1_000,
        }
    }

    static deriveRandomDispatcherPubkey(
        randomProgramId: PublicKey
    ) {
        const [randomnessDispatcherSignerPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("dispatcher"),
            ],
            randomProgramId
        );
        return randomnessDispatcherSignerPubkey;
    }

    static deriveGlobalSignerPubkey(
        programId: PublicKey
    ) {
        const [globalSignerPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("global_signer"),
            ],
            programId
        );
        return globalSignerPubkey
    }

    deriveGlobalSignerPubkey() {
        return GameSpec.deriveGlobalSignerPubkey(this.program.programId)
    }

    async closeAnyLutsThatCanBe() {
        await this._lutCloseList.closeAnyLutsThatCanBe();
    }

    static deriveGameSpecPubkey(
        programId: PublicKey,
        housePubkey: PublicKey,
        gameTypeEnum: number,
        variantPubkey?: PublicKey,
    ) {
        var seeds = [
            anchor.utils.bytes.utf8.encode("game_spec"),
            housePubkey.toBuffer(),
            new anchor.BN(gameTypeEnum).toArrayLike(Buffer, 'le', 1),
        ];
        if (variantPubkey != null && variantPubkey != undefined && variantPubkey.toString() != SystemProgram.programId.toString()) {
            seeds.push(variantPubkey.toBuffer())
        }

        const [pk, _] = PublicKey.findProgramAddressSync(
            seeds,
            programId
        );
        return pk
    }

    deriveTreasuryVaultPubkey(
        tokenMintPubkey: PublicKey
    ) {
        return getAssociatedTokenAddressSync(tokenMintPubkey, this.treasuryPubkey, false)
    }

    deriveGameVaultPubkey(
        tokenMintPubkey: PublicKey
    ) {
        return getAssociatedTokenAddressSync(tokenMintPubkey, this.deriveGlobalSignerPubkey(), true)
    }

    static getInstructionDiscriminatorFromSnakeCase(
        ixnNameSnakeCase: string
    ): number[] {
        const preimage = `${"global"}:${ixnNameSnakeCase}`;
        const discriminatorBytes = Buffer.from(sha256.digest(preimage)).slice(0, 8);
        var discriminatorU8s: number[] = [];
        discriminatorBytes.forEach((b) => { discriminatorU8s.push(Number(b)) });
        return discriminatorU8s
    }

    static deriveGameTokenSpecDiscriminator() {
        return Buffer.from(sha256.digest("account:GameTokenSpec")).subarray(0, 8);
    }

    static deriveGameTokenInstanceDiscriminator() {
        return Buffer.from(sha256.digest("account:GameTokenInstance")).subarray(0, 8);
    }

    async fetchManyGameTokenSpecs(
    ): Promise<GameTokenSpec[]> {
        const pkAndBuffers = await this.program.provider.connection.getProgramAccounts(
            this.program.programId,
            {
              filters: [
                {
                    memcmp: {
                        offset: 0, // Anchor account discriminator for Player type
                        bytes: base58.encode(GameSpec.deriveGameTokenSpecDiscriminator())
                    },
                }
              ],
            }
        );
        const gameTokenSpecs = pkAndBuffers.map((pkAndBuffer) => (
            GameTokenSpec.loadFromBuffer(
                this,
                pkAndBuffer.pubkey,
                pkAndBuffer.account.data
            )
        ));
        return gameTokenSpecs
    }

    async fetchManyGameSpecTokenInstances(
        statusEnum?: number
    ): Promise<GameTokenInstance[]> {
        var filters = [
            {
                memcmp: { // Anchor account discriminator for this type
                    offset: 0, 
                    bytes: base58.encode(GameSpec.deriveGameTokenInstanceDiscriminator())
                },
            }
        ];
        if (statusEnum != null && statusEnum != undefined) {
            filters.push({
                memcmp: { // Status is after 3x pubkeys
                    offset: 8+(2*32), 
                    bytes: base58.encode(Buffer.from([statusEnum]))
                },
            });
        }
        const pkAndBuffers = await this.program.provider.connection.getProgramAccounts(
            this.program.programId,
            { filters }
        );
        
        const gameTokenInstances = Promise.all(pkAndBuffers.map((pkAndBuffer) => (
            GameTokenInstance.loadFromBufferWithoutGameTokenSpec(
                this,
                pkAndBuffer.pubkey,
                pkAndBuffer.account.data
            )
        )));
        return gameTokenInstances
    }

}
