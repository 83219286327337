import { useContext } from "react";
import { RightNavType } from "../../types/nav";
import { SideBarMobile } from "../left-nav/SideBarMobile";
import { Chat } from "./Chat";
import { Notifications } from "./Notifications";
import { NavContext } from "../../contexts/NavContext";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";

interface IMessenginPanel {
  panelOpen: boolean;
}

export function RightNavMobile({ panelOpen }: IMessenginPanel) {
  const { rightPanelType } = useContext(NavContext);

  return (
    <SideBarMobile sidebarOpen={panelOpen}>
      <div className={`flex grow flex-col p-0 overflow-y-auto ${SCROLLBAR_CLASSES}`}>
        <nav className={`flex flex-col w-full h-full justify-start`}>
          {rightPanelType == RightNavType.CHAT ? <Chat /> : ""}
          {rightPanelType == RightNavType.NOTIFICATIONS ? <Notifications /> : ""}
        </nav>
      </div>
    </SideBarMobile>
  );
}
