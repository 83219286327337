import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { BaseModal } from "./BaseModal";
import XIcon from "../assets/icons/x.svg";
import Icon from "../components/common/icon/Icon";
import GoogleIcon from "../assets/icons/google.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import DiscordIcon from "../assets/icons/discoard.svg";
import Button from "../components/common/button/Button";
import { WALLET_ADAPTERS } from "@web3auth/base";
import TickIcon from "../assets/icons/tick.svg";
import SolanaIcon from "../assets/icons/solana-bg.svg";

import { Loading } from "./Loading";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";

export enum WalletModalType {
  REGISTER = "register",
  CONNECT = "connect",
}

interface Props {
  open: boolean;
  closeModal: (value: boolean) => void;
  tab?: WalletModalType;
  setTab?: (value: WalletModalType) => void;
}

// MODAL USED TO CONNECT OR REGISTER WALLET
export default function WalletConnectionModal({ open, closeModal, tab, setTab }: Props) {
  const { loggedIn, connect, select } = useContext(WrappedWalletContext);
  const [showSolanaWallets, setShowSolanaWallets] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLoginWithLoading = useCallback(
    async (walletAdapter: string, provider?: string) => {
      setLoading(true);

      try {
        if (provider == null) {
          select(walletAdapter)
          return
        }

        await connect(walletAdapter, provider);
      } catch (err) {
        console.warn("Issue logging into social wallet.", err);
        setLoading(false);
      }
    },
    [connect],
  );

  useEffect(() => {
    if (loggedIn == true) {
      if (open == true) {
        closeModal(true);
      }

      if (showSolanaWallets == true) {
        setShowSolanaWallets(false);
      }

      if (loading == true) {
        setLoading(false);
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (showSolanaWallets == true) {
      setShowSolanaWallets(false);
    }
  }, [tab]);

  return (
    <BaseModal open={open} hideModal={closeModal}>
      {loading == true ? (
        <Loading title="Loading" message="please wait a few seconds..." />
      ) : (
        <div className="flex w-[80vw] flex-col items-start gap-y-3 md:w-[520px]">
          {/* SELECTED TAB */}
          <TabSelecter type={tab} hideModal={closeModal} setType={setTab} />

          {showSolanaWallets == false ? (
            <SelectWalletOrUsername
              handleLoginWithLoading={handleLoginWithLoading}
              setShowSolanaWallets={setShowSolanaWallets}
              type={tab}
            />
          ) : (
            <SelectSolanaWallet
              handleLoginWithLoading={handleLoginWithLoading}
              setShowSolanaWallets={setShowSolanaWallets}
            />
          )}
        </div>
      )}
    </BaseModal>
  );
}

interface ITabSelecter {
  type: WalletModalType;
  hideModal: (value: boolean) => void;
  setType: Function;
}

const TabSelecter = ({ type, hideModal, setType }: ITabSelecter) => {
  return (
    <div className="flex items-center justify-between self-stretch">
      <div className="flex items-start gap-x-3">
        <div
          onClick={() => setType(WalletModalType.REGISTER)}
          className={`flex cursor-pointer items-center rounded-md p-3 ${type == WalletModalType.REGISTER
              ? "bg-gray-700 text-white"
              : "text-gray-300 hover:bg-gray-700 hover:text-white"
            }`}
        >
          Register
        </div>
        <div
          onClick={() => setType(WalletModalType.CONNECT)}
          className={`flex cursor-pointer items-center rounded-md p-3 ${type == WalletModalType.CONNECT
              ? "bg-gray-700 text-white"
              : "text-gray-300 hover:bg-gray-700 hover:text-white"
            }`}
        >
          Connect
        </div>
      </div>
      <div className="cursor-pointer" onClick={hideModal}>
        <Icon size={"lg"} icon={<XIcon />} />
      </div>
    </div>
  );
};

interface IWalletSelecter {
  type: WalletModalType;
  loginSocial: (wallet: string, provider?: string | undefined) => Promise<void>;
}

const WalletSelecter = ({ type, loginSocial }: IWalletSelecter) => {
  return (
    <div className="flex flex-col items-end gap-y-3 self-stretch rounded-md border-2 border-solid border-brand-purple-light bg-gray-700 p-4">
      <div className="flex flex-col items-start gap-y-3 self-stretch">
        <div className="flex items-start justify-between self-stretch">
          <div className="flex items-center gap-x-2 self-center">
            <div>{type == WalletModalType.REGISTER ? "Easy Register" : "Welcome Back"}</div>
          </div>
          {type == WalletModalType.REGISTER ? (
            <div className="flex items-center justify-center rounded-full bg-gray-600 px-3 py-1 text-xs">
              Best Experience
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex flex-col items-center gap-y-3 self-stretch">
        <div className="flex flex-col items-start gap-y-1.5 self-stretch">
          <div className="flex items-start gap-x-2 self-stretch">
            <Button
              className="h-[42px] flex-1 self-stretch"
              onClick={async () => {
                await loginSocial(WALLET_ADAPTERS.OPENLOGIN, "google");
              }}
              variant="regular"
              size="md"
            >
              <div className="h-[20px] w-[20px]">
                <GoogleIcon />
              </div>
            </Button>
            <Button
              className="h-[42px] flex-1 self-stretch"
              onClick={async () => {
                await loginSocial(WALLET_ADAPTERS.OPENLOGIN, "twitter");
              }}
              variant="regular"
              size="md"
            >
              <div className="h-[20px] w-[20px]">
                <TwitterIcon />
              </div>
            </Button>
            <Button
              className="h-[42px] flex-1 self-stretch"
              onClick={async () => {
                await loginSocial(WALLET_ADAPTERS.OPENLOGIN, "discord");
              }}
              variant="regular"
              size="md"
            >
              <div className="h-[20px] w-[20px]">
                <DiscordIcon />
              </div>
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-1 self-stretch">
          <div className="flex items-start gap-x-4 self-stretch">
            <Check text={"Instant signing"} isChecked={true} />
            <Check text={"Connect from any device"} isChecked={true} />
          </div>
          <div className="flex items-start gap-x-4 self-stretch">
            <Check text={"Fully non-custodial"} isChecked={true} />
            <Check text={"Easy Funding"} isChecked={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface ICheck {
  text: string;
  isChecked: boolean;
}

const Check = ({ text, isChecked }: ICheck) => {
  return (
    <div className="flex flex-1 items-end gap-x-1 text-sm font-normal text-gray-300">
      <div className="flex self-center">
        <Icon size={"md"} icon={isChecked ? <TickIcon /> : <XIcon />} />
      </div>
      <div>{text}</div>
    </div>
  );
};

interface ISelectWithTerms {
  title: string;
  button: any;
  leftTerms: ICheck[];
  rightTerms: ICheck[];
}
const SelectWithTerms = ({ title, button, leftTerms, rightTerms }: ISelectWithTerms) => {
  return (
    <div className="flex flex-1 flex-col items-start space-y-3 rounded-lg border-2 border-solid border-gray-600 p-4">
      <div className="flex items-center self-stretch">{title}</div>
      {button}
      <div className="flex items-start gap-x-1 self-stretch">
        <div className="flex flex-1 flex-col items-start gap-y-[4px]">
          {leftTerms.map((term, index) => {
            return <Check key={index} text={term.text} isChecked={term.isChecked} />;
          })}
        </div>
        <div className="flex flex-1 flex-col items-start gap-y-[4px]">
          {rightTerms.map((term, index) => {
            return <Check key={index} text={term.text} isChecked={term.isChecked} />;
          })}
        </div>
      </div>
    </div>
  );
};

interface ISolanaWalletSelector {
  setShowSolanaWallets: Function;
}

const SolanaWalletSelector = ({ setShowSolanaWallets }: ISolanaWalletSelector) => {
  return (
    <div className="flex items-start gap-x-3 self-stretch">
      <SelectWithTerms
        title={"Solana wallet"}
        button={
          <Button
            className="h-[42px] self-stretch"
            onClick={() => {
              setShowSolanaWallets(true);
            }}
            variant="gray"
            size="md"
            icon={<Icon icon={<SolanaIcon />} size="md" inheritStroke={false} inheritFill={true} />}
          >
            Solana Wallets
          </Button>
        }
        leftTerms={[
          {
            isChecked: true,
            text: "Connect your existing wallet",
          },
          {
            isChecked: true,
            text: "100% self-custody always",
          },
        ]}
        rightTerms={[
          {
            isChecked: false,
            text: "All actions require approval",
          },
          {
            isChecked: false,
            text: "Not available on all devices",
          },
        ]}
      />
    </div>
  );
};

export const WalletDetection = () => {
  const { wallets, select } = useContext(WrappedWalletContext)

  const detectedWallets = wallets.filter((wallet) => {
    return wallet.adapter.name.toLowerCase() in window
  })

  if (detectedWallets.length == 0) {
    return (
      <div className="flex flex-col items-center justify-center self-stretch rounded-md bg-gray-700 px-3 py-5 text-gray-400">
        None Detected
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start gap-y-3 self-stretch rounded-md border-2 border-solid border-brand-purple-light p-3 pb-4">
      <div>Detected</div>
      {detectedWallets.map((wallet, index) => {
        return (
          <Button
            key={index}
            onClick={async () => {
              select(wallet.adapter.name)
            }}
            size="md"
            variant="regular"
            className="h-[42px] self-stretch"
            icon={<Icon inheritFill={false} inheritStroke={false} size="md" icon={<img src={wallet.adapter.icon} />} />}
          >
            {wallet.adapter.name}
          </Button>
        );
      })}
    </div>
  );
};

export const SupportedWallets = () => {
  const { wallets } = useContext(WrappedWalletContext)

  return (
    <div className="flex flex-col items-start gap-y-3 self-stretch rounded-lg border-2 border-solid border-gray-600 p-3 pb-4">
      <div>Supported Wallets</div>

      {wallets.map((wallet, index) => {
        return <Button
          key={index}
          disabled={true}
          size="md"
          variant="gray"
          className="h-[42px]  self-stretch"
          icon={<Icon inheritFill={false} inheritStroke={false} size="md" icon={<img src={wallet.adapter.icon} />} />}
        >
          {wallet.adapter.name}
        </Button>
      })}
    </div>
  );
};

interface ISelectSolanaWallet {
  handleLoginWithLoading: Function;
  setShowSolanaWallets: Function;
}

const SelectSolanaWallet = ({
  handleLoginWithLoading,
  setShowSolanaWallets,
}: ISelectSolanaWallet) => {
  return (
    <Fragment>
      <WalletDetection login={handleLoginWithLoading} />
      <SupportedWallets login={handleLoginWithLoading} />
      <div className="flex flex-col items-start gap-y-3 self-stretch">
        <Button
          onClick={() => {
            setShowSolanaWallets(false);
          }}
          size="md"
          variant="gray"
          className="self-stretch"
        >
          Back
        </Button>
        <div className="text-sm text-gray-300">
          <span className="font-normal">Self-custodial wallet by </span>Web3Auth
        </div>
      </div>
    </Fragment>
  );
};

interface ISelectWalletOrUsername {
  handleLoginWithLoading: Function;
  setShowSolanaWallets: Function;
  type: WalletModalType;
}
const SelectWalletOrUsername = ({
  handleLoginWithLoading,
  setShowSolanaWallets,
  type,
}: ISelectWalletOrUsername) => {
  return (
    <Fragment>
      {/* CHOOSE WALLET (SOCIAL) */}
      <WalletSelecter type={type} loginSocial={handleLoginWithLoading} />

      {/* CHOOSE WALLET SOLANA */}
      <SolanaWalletSelector setShowSolanaWallets={setShowSolanaWallets} />

      {/* CHOOSE WALLET SOLANA */}
      <div className="text-sm text-gray-300">
        <span className="font-normal">Self-custodial wallet by </span>Web3Auth
      </div>
    </Fragment>
  );
};
