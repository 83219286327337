import { useContext, useMemo } from "react";
import { getRankMeta } from "../utils/config/ranks";
import {IPlatformRank, PlatformContext} from "../contexts/PlatformContext";
import { PlayerContext } from "../contexts/PlayerContext";
import Player from "../sdk/playerAccount";

export const useRankMeta = (currentPlayer?: Player) => {
  const { playerAccount } = useContext(PlayerContext);
  const { platformRanks } = useContext(PlatformContext);
  const player = currentPlayer ?? playerAccount;
  const ranksBenefitsObj = platformRanks?.reduce((benefits, rank) => {
    const rankName = rank.name.split(" ")[0];
    benefits[rankName] = {...rank, name: rankName};

    return benefits
  }, {} as { [key: string]: IPlatformRank });

  const sortedPlatformRanks = Object.values((ranksBenefitsObj || {}))
    .sort((a, b) => a.threshold - b.threshold);

  const rank = useMemo(() => {
    if (player?.xpAllTime != null) {
      return {
        rank: player.rank,
        xpAllTime: player.xpAllTime,
      };
    }

    return {
      rank: 0,
      xpAllTime: 0,
    };
  }, [player]);

  const rankMeta = useMemo(() => {
    if (platformRanks == null || rank == null) {
      return;
    }

    return getRankMeta(rank, platformRanks);
  }, [rank, platformRanks]);

  return {
    rankMeta: rankMeta,
    rank: rank,
    platformRanks: sortedPlatformRanks,
  };
};
