import { PriceServiceConnection } from "@pythnetwork/price-service-client";
import BinaryOption from "../../sdk/binaryOptions/binaryOption";
import { HERMES_ENDPOINT } from "../../sdk/constants";
import { useEffect, useMemo, useRef, useState } from "react";
import { getFeedMetaById } from "../../sdk/binaryOptions/utils";
import { FeedPrice } from "../../contexts/BinaryOptionsContext";

export const useBinaryOptionsFeedPrices = (binaryOption: BinaryOption | undefined, MAX_FEED_PRICES: number = 10) => {
    // FEED PRICES
  const feedPricesByFeedRef = useRef<Map<string, FeedPrice[]>>(new Map())
  const [feedPricesByFeed, setFeedPriceByFeed] = useState<Map<string, FeedPrice[]>>(new Map())
  
  useEffect(() => {
    // CODE TO CONNECT TO MULTIPLE FEEDS -> WILL NEED A MAP TO HANDLE PRICES AS THEY COME IN
    async function openWsConnectionForAllPrices(bOption: BinaryOption, hermesConnection: PriceServiceConnection) {
      // SET ERROR HANDLER
      hermesConnection.onWsError = (err) => {
        console.error(
          `ERROR WITH THE WS`
        )
        console.error({
          err
        })
      }

      // CLOSE ANY OPEN WSS
      hermesConnection.closeWebSocket();

      const allFeeds = (bOption?.formattedBoFeeds || []).filter((feed) => {
        return feed.feedIdHex != null
      })?.map((feed) => feed.feedIdHex)

      // OPEN WSS FOR FEED
      const feedmetaById = getFeedMetaById()

      await hermesConnection.subscribePriceFeedUpdates(allFeeds, (priceFeed) => {
        const price = priceFeed.getPriceNoOlderThan(60)
        const feedMeta = feedmetaById.get(priceFeed.id)
        
        // // FORMAT PRICE
        const feedPrice: FeedPrice = { 
          priceBasis: Number(price?.price), 
          priceUi: Number(price?.price) * Math.pow(10, price?.expo || 0),
          publishTime: price?.publishTime || 0,
          publishDate: new Date((price?.publishTime || 0) * 1000),
          vaa: priceFeed.vaa,
          feedSymbol: feedMeta?.attributes.base,
          ...priceFeed
        }

        let prices = feedPricesByFeedRef.current.get(priceFeed.id) || []
        prices = [...prices, feedPrice]
        
        if (prices.length > MAX_FEED_PRICES) {
          prices = prices.slice(-MAX_FEED_PRICES)
        }

        feedPricesByFeedRef.current.set(priceFeed.id, prices)
        setFeedPriceByFeed(new Map(feedPricesByFeedRef.current))
      });
    }

    if (binaryOption == null) {
      return
    }

    // OPEN WS CONNECTION
    const hermesConnection = new PriceServiceConnection(
      HERMES_ENDPOINT,
      {
          priceFeedRequestConfig: { binary: true },
      });
    openWsConnectionForAllPrices(binaryOption, hermesConnection)
    
    // When using the subscription, make sure to close the websocket upon termination to finish the process gracefully.
    return () => {
      try {
        hermesConnection.closeWebSocket();
      } catch (err) {
        console.error(`Error Closing the WS`)
        console.warn({
          err
        })
      }
    }
  }, [binaryOption])

  return useMemo(() => {
    return {
        feedPricesByFeed: feedPricesByFeed
    }
  }, [feedPricesByFeed])
}