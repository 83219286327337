import { BN, BorshCoder, EventParser, Program, utils } from "@coral-xyz/anchor";
import { PublicKey, Commitment, SystemProgram, TransactionInstruction, TransactionError } from '@solana/web3.js';
import { sha256 } from 'js-sha256';
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { RANDOM_PROGRAM_PUBKEY, WORMHOLE_PROGRAM_ID, HERMES_ENDPOINT } from "../constants";
import { PriceServiceConnection } from '@pythnetwork/price-service-client';
import base58 from "bs58";
import { parseAccumulatorUpdateData } from "@pythnetwork/price-service-sdk";

import House from "../house";
import PlayerAccount from "../playerAccount";
import { BoDirection } from "./enums";
import { IFeedMeta, IFormattedFeed } from "./types";
import { getFeedMetaById } from "./utils";
import { BoBet } from "../../pages/BinaryOptionPage";

const VAA_SIGNATURE_SIZE = 66;
const DEFAULT_REDUCED_GUARDIAN_SET_SIZE = 5;

export type WormholeDataSource = {
    chain: number,
    emitter: PublicKey
};

export type TimeIntervalConfig = {
    active: boolean,
    padding: 0,
    seconds: number,
    multiplierPerMillion: BN,
    maxConfToAcceptBet: BN,
};

export enum BoInstanceStatus {
    Uninitialized = 0,
    Placed = 1,
    OpenPriceProvided = 2,
    SettlePriceProvided = 3,
    Settled = 4,
    AwaitingVoiding = 5,
    ErrorConfirmed = 6,
    Voided = 7
}

export enum BoInstanceStatusString {
    Uninitialized = 'uninitialized',
    Placed = 'placed',
    OpenPriceProvided = 'openPriceProvided',
    SettlePriceProvided = 'settlePriceProvided',
    Settled = 'settled',
    AwaitingVoiding = 'awaitingVoiding',
    ErrorConfirmed = 'errorConfirmed',
    Voided = 'voided'
}

export default class BinaryOption {

    private _program: Program;
    private _house: House
    private _pubkey: PublicKey;
    private _boSpecState: any;
    private _boFeedListState: any;
    private _boSpecStateLoaded: boolean;
    private _eventParser: EventParser
    private _commitmentLevel: Commitment;
    private _hermesConnection: PriceServiceConnection;
    private _feedMetaById: Map<string, IFeedMeta>
    private _boSpecPubkey: PublicKey


    constructor(
        speculateProgram: Program,
        house: House,
        boSpecPubkey: PublicKey,
        commitmentLevel: Commitment = "processed"
    ) {
        this._boSpecPubkey = boSpecPubkey
        this._boSpecStateLoaded = false
        this._program = speculateProgram;
        this._eventParser = new EventParser(
            this.program.programId,
            new BorshCoder(this.program.idl)
        );
        this._house = house;
        this._pubkey = boSpecPubkey;
        this._commitmentLevel = commitmentLevel;
        this._hermesConnection = new PriceServiceConnection(
            HERMES_ENDPOINT,
            {
                priceFeedRequestConfig: { binary: true },
            });
        this._feedMetaById = getFeedMetaById()
    };


    static async load(
        speculateProgram: Program,
        house: House,
        boSpecPubkey: PublicKey,
    ) {
        const boSpec = new BinaryOption(
            speculateProgram,
            house,
            boSpecPubkey,
        )
        await boSpec.loadState();
        return boSpec
    }

    async loadState(
        commitmentLevel: Commitment = "processed"
    ) {
        const boSpecState = await this.program.account.boSpec.fetchNullable(
            this.publicKey,
            commitmentLevel
        );
        const boFeedListState = await this.program.account.boFeedList.fetchNullable(
            BinaryOption.deriveBoFeedListPubkey(this.program.programId, this.publicKey),
            commitmentLevel
        );
        this._boSpecState = boSpecState;
        this._boFeedListState = boFeedListState;
        this._boSpecStateLoaded = true;
        return
    }


    static deriveBoSpecAccountDiscriminator() {
        return Buffer.from(sha256.digest("account:BoSpec")).subarray(0, 8);
    }

    static deriveBoFeedListAccountDiscriminator() {
        return Buffer.from(sha256.digest("account:BoFeedList")).subarray(0, 8);
    }

    static deriveBoInstanceAccountDiscriminator() {
        return Buffer.from(sha256.digest("account:BoInstance")).subarray(0, 8);
    }

    static deriveBoSpecPubkey(
        programId: PublicKey,
        housePubkey: PublicKey,
    ) {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("bo_spec"),
                housePubkey.toBuffer(),
            ],
            programId
        );
        return pk
    }

    static deriveBoFeedListPubkey(
        programId: PublicKey,
        boSpecPubkey: PublicKey,
    ) {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("bo_feed_list"),
                boSpecPubkey.toBuffer(),
            ],
            programId
        );
        return pk
    }

    async getHermesPriceUpdateAndVaa(
        feedId: Buffer,
        publishTime?: number
    ) {
        if (publishTime == undefined) {
            const resp = await this._hermesConnection.getLatestPriceFeeds([feedId.toString("hex")]);
            if (resp) {
                return resp[0]
            } else {
                return null
            }
        } else {
            const resp = await this._hermesConnection.getPriceFeed(feedId.toString("hex"), publishTime);
            if (resp) {
                return resp
            } else {
                return null
            }
        }
    }

    static getGuardianSetIndex(vaa: Buffer) {
        return vaa.readUInt32BE(1);
    }

    static trimSignatures(
        vaa: Buffer,
        n: number = DEFAULT_REDUCED_GUARDIAN_SET_SIZE
    ): Buffer {
        const currentNumSignatures = vaa[5];
        if (n > currentNumSignatures) {
            throw new Error(
                "Resulting VAA can't have more signatures than the original VAA"
            );
        }
        const trimmedVaa = Buffer.concat([
            vaa.subarray(0, 6 + n * VAA_SIGNATURE_SIZE),
            vaa.subarray(6 + currentNumSignatures * VAA_SIGNATURE_SIZE),
        ]);
        trimmedVaa[5] = n;
        return trimmedVaa;
    }

    static prepareVaaAndMerklePriceUpdates(
        priceUpdateData: string
    ): [Buffer, any, number] {
        const accumulatorUpdateData = parseAccumulatorUpdateData(
            Buffer.from(priceUpdateData, "base64")
        );
        const guardianSetIndex = BinaryOption.getGuardianSetIndex(accumulatorUpdateData.vaa);
        const trimmedVaa = BinaryOption.trimSignatures(accumulatorUpdateData.vaa);

        return [trimmedVaa, accumulatorUpdateData.updates, guardianSetIndex]

    }

    static getGuardianSetPda(
        guardianSetIndex: number
    ) {
        const guardianSetIndexBuf = Buffer.alloc(4);
        guardianSetIndexBuf.writeUInt32BE(guardianSetIndex, 0);
        return PublicKey.findProgramAddressSync(
            [Buffer.from("GuardianSet"), guardianSetIndexBuf],
            WORMHOLE_PROGRAM_ID
        )[0];
    };

    get house() {
        return this._house
    }

    get program() {
        return this._program
    }

    get publicKey() {
        return this._pubkey
    }

    get boSpecState() {
        return this._boSpecState
    }

    get boSpecPubkey() {
        return this._boSpecPubkey
    }

    get boFeedListState() {
        return this._boFeedListState
    }

    get boFeeds() {
        return this.boFeedListState?.feeds
    }

    get formattedBoFeeds(): IFormattedFeed[] | undefined {
        return this.boFeeds?.map((feed) => {
            let feedBuffer = Buffer.from(feed.feedId)
            let feedIdHex = feedBuffer.toString("hex")
            const context = this._feedMetaById?.get(feedIdHex)

            if (context == null) {
                feedBuffer = undefined
                feedIdHex = undefined
            }

            return {
                ...feed,
                feedId: feedBuffer,
                feedIdHex: feedIdHex,
                maxWagerInTokenMinUnits: Number(feed.maxWagerInTokenMinUnits),
                timeIntervals: feed.timeIntervalsOffered.map((interval) => {
                    return {
                        ...interval,
                        maxConfToAcceptBet: Number(interval.maxConfToAcceptBet),
                        multiplier: interval.multiplierPerMillion / Math.pow(10, 6)
                    }
                }),
                activeTimeIntervals: feed.timeIntervalsOffered?.filter((interval) => {
                    return interval.active == true
                }).map((interval) => {
                    return {
                        ...interval,
                        maxConfToAcceptBet: Number(interval.maxConfToAcceptBet),
                        multiplier: interval.multiplierPerMillion / Math.pow(10, 6)
                    }
                }),
                context: context,
                maxDirectionalExposureBase: Number(feed.maxDirectionalExposureBase),
                exposure: {
                    placedDownExposureBase: Number(feed.exposure.placedDownExposureBase),
                    placedUpExposureBase: Number(feed.exposure.placedUpExposureBase),
                }
            }
        })
    }

    get eventParser() {
        return this._eventParser
    }

    get supportedTokens() {
        return this._boSpecState ? this._boSpecState.tokens : null
    }

    get supportedTokenMints(): PublicKey[] {
        if (!this._boSpecState) {
            return []
        } else {
            return this._boSpecState.tokens.map((tkn) => (tkn.pubkey))
        }
    }

    get providers() {
        return this._boSpecState ? this._boSpecState.providers : null
    }

    get status() {
        return this._boSpecState ? Object.keys(this._boSpecState.status)[0]: null
    }

    get minWagerInTokenMinUnits() {
        return this._boSpecState ? Number(this._boSpecState.minWagerInTokenMinUnits) : null
    }

    get instanceNonce() {
        return this._boSpecState ? Number(this._boSpecState.instanceNonce) : null
    }

    get activeInstances() {
        return this._boSpecState ? Number(this._boSpecState.activeInstances) : null
    }

    get activeBets() {
        return this._boSpecState ? Number(this._boSpecState.activeBets) : null
    }

    get pastInstances() {
        return this._boSpecState ? Number(this._boSpecState.pastInstances) : null
    }

    get pastBets() {
        return this._boSpecState ? Number(this._boSpecState.pastBets) : null
    }

    get numActiveTokens() {
        return this._boSpecState ? this._boSpecState.numActiveTokens : null
    }

    get authorityPubkey() {
        return this._boSpecState ? this._boSpecState.authority : null
    }

    get lookupTablePubkey() {
        return this._boSpecState ? this._boSpecState.lookupTable : null
    }

    get cashierProgramPubkey() {
        return this._boSpecState ? this._boSpecState.cashierProgram : null
    }

    get cashierSignerPubkey() {
        return this._boSpecState ? this._boSpecState.cashierSigner : null
    }

    get wormholeProgramId() {
        return this._boSpecState ? this._boSpecState.wormholeProgram : null
    }

    get hermesConnection() {
        return this._hermesConnection
    }

    subscribeToOptionEvents(
        pubkeyFilter: PublicKey,
        onInstanceCreated: Function,
        onInstanceOpened: Function,
        onInstanceSettled: Function,
        onInstanceVoided: Function,
        onError?: Function
    ) {
        let socketId: number 

        const handleLogs = (logs: {
            err: TransactionError | null;
            logs: string[];
            signature: string;
        }, context: {
            slot: number;
        }) => {
            if (logs.err != null) {
                console.error('Error in BO WS Listener', logs.err)
                onError?.(logs.err)
                return
            }

            const events = this.eventParser.parseLogs(logs.logs);
            const signature = logs.signature;

            for (let event of events) {
                
                if (event.name == "BoInstanceCreated") {
                    // FORMAT THE DATA
                    const data = event.data

                    const formattedInstanceCreated = {
                        ...data,
                        expectedEdge: Number(data.expectedEdge),
                        fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                        instanceNonce: Number(data.instanceNonce),
                        liability: Number(data.liability),
                        openConf: Number(data.openConf),
                        openPrice: Number(data.openPrice),
                        openTimestamp: Number(data.openTimestamp),
                        potentialPayout: Number(data.potentialPayout),
                        settleReward: Number(data.settleReward),
                        settleTimestamp: Number(data.settleTimestamp),
                        direction: Object.keys(data.direction)[0],
                        status: Object.keys(data.status)[0],
                        timestamp: Number(data.timestamp),
                        wager: Number(data.wager),
                        signature: signature
                    }
                    onInstanceCreated(formattedInstanceCreated)
                } else if (event.name == "BoInstanceOpened") {
                    // FORMAT THE DATA
                    const data = event.data

                    const formattedInstanceOpened = {
                        ...data,
                        expectedEdge: Number(data.expectedEdge),
                        fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                        instanceNonce: Number(data.instanceNonce),
                        liability: Number(data.liability),
                        openConf: Number(data.openConf),
                        openEmaPrice: Number(data.openEmaPrice),
                        openPrice: Number(data.openPrice),
                        openTimestamp: Number(data.openTimestamp),
                        potentialPayout: Number(data.potentialPayout),
                        settleReward: Number(data.settleReward),
                        settleTimestamp: Number(data.settleTimestamp),
                        direction: Object.keys(data.direction)[0],
                        status: Object.keys(data.status)[0],
                        strikePrice: Number(data.strikePrice),
                        timestamp: Number(data.timestamp),
                        wager: Number(data.wager),
                        signature: signature
                    }
                    onInstanceOpened(formattedInstanceOpened)
                } else if (event.name == "BoInstanceSettled") {
                    const data = event.data

                    const formattedInstanceSettled = {
                        ...data,
                        fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                        openConf: Number(data.openConf),
                        openEmaPrice: Number(data.openEmaPrice),
                        openPrice: Number(data.openPrice),
                        openTimestamp: Number(data.openTimestamp),
                        requestedSettleTimestamp: Number(data.requestedSettleTimestamp),
                        settlePrice: Number(data.settlePrice),
                        direction: Object.keys(data.direction)[0],
                        timestamp: Number(data.timestamp),
                        wager: Number(data.wager),
                        payout: Number(data.payout),
                        signature: signature
                    }

                    onInstanceSettled(formattedInstanceSettled)

                    try {
                        if (socketId != null) {
                            this.program.provider.connection.removeOnLogsListener(socketId)
                        }
                    } catch (err) {
                        console.warn(`Issue closing logs listener`, { err })
                    }
                } else if (event.name == "BoInstanceVoided") {
                    const data = event.data

                    const formattedInstanceVoided = {
                        ...data,
                        signature: signature
                    }

                    onInstanceVoided(formattedInstanceVoided)

                    try {
                        if (socketId != null) {
                            this.program.provider.connection.removeOnLogsListener(socketId)
                        }
                    } catch (err) {
                        console.warn(`Issue closing logs listener`, { err })
                    }
                }
            }
        };

        socketId = this.program.provider.connection.onLogs(pubkeyFilter, handleLogs, this._commitmentLevel);

        return socketId
    }

    toBetFromLogs = (logs: string[], signature: string): BoBet | undefined => {

        let bet: BoBet = {
            createdEvent: undefined,
            openedEvent: undefined,
            settledEvent: undefined,
            voidedEvent: undefined
        }

        const events = this.eventParser.parseLogs(logs);

        for (let event of events) {
            if (event.name == "BoInstanceCreated") {
                // FORMAT THE DATA
                const data = event.data

                const formattedInstanceCreated = {
                    ...data,
                    expectedEdge: Number(data.expectedEdge),
                    fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                    instanceNonce: Number(data.instanceNonce),
                    liability: Number(data.liability),
                    openConf: Number(data.openConf),
                    openPrice: Number(data.openPrice),
                    openTimestamp: Number(data.openTimestamp),
                    potentialPayout: Number(data.potentialPayout),
                    settleReward: Number(data.settleReward),
                    settleTimestamp: Number(data.settleTimestamp),
                    direction: Object.keys(data.direction)[0],
                    status: Object.keys(data.status)[0],
                    timestamp: Number(data.timestamp),
                    wager: Number(data.wager),
                    signature: signature
                }

                bet.createdEvent = formattedInstanceCreated
            } else if (event.name == "BoInstanceOpened") {
                // FORMAT THE DATA
                const data = event.data

                const formattedInstanceOpened = {
                    ...data,
                    expectedEdge: Number(data.expectedEdge),
                    fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                    instanceNonce: Number(data.instanceNonce),
                    liability: Number(data.liability),
                    openConf: Number(data.openConf),
                    openEmaPrice: Number(data.openEmaPrice),
                    openPrice: Number(data.openPrice),
                    openTimestamp: Number(data.openTimestamp),
                    potentialPayout: Number(data.potentialPayout),
                    settleReward: Number(data.settleReward),
                    settleTimestamp: Number(data.settleTimestamp),
                    direction: Object.keys(data.direction)[0],
                    status: Object.keys(data.status)[0],
                    strikePrice: Number(data.strikePrice),
                    timestamp: Number(data.timestamp),
                    wager: Number(data.wager),
                    signature: signature
                }

                bet.openedEvent = formattedInstanceOpened
            } else if (event.name == "BoInstanceSettled") {
                const data = event.data

                const formattedInstanceSettled = {
                    ...data,
                    fxRate: Number(data.fxRatePerBillion) / Math.pow(10, 9),
                    openConf: Number(data.openConf),
                    openEmaPrice: Number(data.openEmaPrice),
                    openPrice: Number(data.openPrice),
                    openTimestamp: Number(data.openTimestamp),
                    requestedSettleTimestamp: Number(data.requestedSettleTimestamp),
                    settlePrice: Number(data.settlePrice),
                    direction: Object.keys(data.direction)[0],
                    timestamp: Number(data.timestamp),
                    wager: Number(data.wager),
                    payout: Number(data.payout),
                    signature: signature
                }

                bet.settledEvent = formattedInstanceSettled
            } else if (event.name == "BoInstanceVoided") {
                const data = event.data

                const formattedInstanceVoided = {
                    ...data,
                    signature: signature
                }

                bet.voidedEvent = formattedInstanceVoided
            }
        }

        return bet
    };

    getMinWager(
        tokenMintPubkey: PublicKey,
    ) {
        if (this.minWagerInTokenMinUnits != null) {
            // Rust Implementation: let minimum_wager = args.min_units_required as u64 * house_token.min_bet_unit;
            const houseTokenConfig = this.house.getTokenConfigAndStatistics(tokenMintPubkey);
            return Number(houseTokenConfig.minBetUnit) * this.minWagerInTokenMinUnits
        } else {
            return null
        }

    }

    getMaxWager(
        tokenMintPubkey: PublicKey,
        multiplier: number,
        expectedEdgeRate: number
    ) {
        // Rust Implementation: 
        // let risk_capital_required = args.liability - args.wager;
        // let bankroll = house_token.available_trading_balance + house_token.open_liability_balance;
        // let kelly_maximum = bankroll * args.expected_edge / args.wager; // i.e. expected_edge/wager is our aggregate margin on these/this bet(s) and Kelly says 
        const houseTokenConfig = this.house.getTokenConfigAndStatistics(tokenMintPubkey);
        const risk_capital_available = Number(houseTokenConfig.availableTradingBalance)
        const bankroll = Number(houseTokenConfig.availableTradingBalance) + Number(houseTokenConfig.openLiabilityBalance);
        const kelly_maximum_risk_capital = bankroll * expectedEdgeRate;
        const kelly_maximum_wager = kelly_maximum_risk_capital / (multiplier - 1);
        const hard_maximum_wager = risk_capital_available / (multiplier - 1);
        
        return Math.min(kelly_maximum_wager, hard_maximum_wager);
    }

    deriveCallbackTablePubkey(
        randomnessRequestPubkey: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("callback_table"),
                randomnessRequestPubkey.toBuffer()
            ],
            RANDOM_PROGRAM_PUBKEY
        );
        return pk
    }


    static deriveGlobalSignerPubkey(
        programId: PublicKey
    ) {
        const [globalSignerPubkey, _] = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("global_signer"),
            ],
            programId
        );
        return globalSignerPubkey
    }

    deriveGlobalSignerPubkey() {
        return BinaryOption.deriveGlobalSignerPubkey(
            this.program.programId
        );
    }

    deriveBoInstancePubkey(
        playerPubkey: PublicKey,
        feedId: Buffer
    ) {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                utils.bytes.utf8.encode("bo_instance"),
                this.publicKey.toBuffer(),
                playerPubkey.toBuffer(),
                feedId
            ],
            this.program.programId
        );
        return pk;
    }

    async placeBoBetIx(
        walletPubkey: PublicKey,
        playerAccount: PlayerAccount,
        feedId: Buffer,
        timeIntervalSeconds: number,
        direction: BoDirection,
        tokenMintPubkey: PublicKey,
        wager: number,
        referrer: PublicKey // NEED TO PASS THE REFERRER IN INCASE OF CUSTOM REFERRAL
    ): Promise<TransactionInstruction> {
        const callerNonce = playerAccount.instanceNonce;
        const boInstancePubkey = this.deriveBoInstancePubkey(playerAccount.publicKey, feedId);

        const playerTokenAccountPubkey = await playerAccount.deriveTokenAccountPubkey(tokenMintPubkey);
        const houseTokenPubkey = this.house.deriveHouseTokenAccountPubkey(tokenMintPubkey);
        const houseTokenVault = await this.house.deriveHouseTokenVault(tokenMintPubkey);
        const oraclePubkey = this.house.getTokenConfigAndStatistics(tokenMintPubkey)?.oracle;

        return await this.program.methods.placeBoBetSolo({
            callerNonce: callerNonce,
            feedId: feedId,
            wager: new BN(wager),
            timeIntervalSeconds: timeIntervalSeconds,
            direction: direction
        }).accounts({
            owner: walletPubkey,
            player: playerAccount.publicKey,
            boSpec: this.publicKey,
            boFeedList: BinaryOption.deriveBoFeedListPubkey(this.program.programId, this.publicKey),
            boInstance: boInstancePubkey,
            tokenMint: tokenMintPubkey,
            tokenAccount: playerTokenAccountPubkey,
            vault: houseTokenVault,
            oracle: oraclePubkey,
            house: this.house.publicKey,
            houseToken: houseTokenPubkey,
            platform: playerAccount.platform.publicKey,
            platformPayer: playerAccount.platform.derivePlatformPayerPubkey(),
            referrer: referrer,
            globalSigner: this.deriveGlobalSignerPubkey(),
            speculateProgram: this.program.programId,
            cashierProgram: this.house.program.programId,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID
        }).instruction();
    };


    async onLogs(
        logs: any,
        context: any,
    ) {
        if (logs.err) {
            // Skip error'd transactions
        } else {
            const events = this.eventParser.parseLogs(
                logs.logs
            );
            
        };
    }

    listenForLogs(
        callbackFunction: (logs: any, context: any) => void,
        commitmentLevel: Commitment = "processed"
    ) {
        const websocketId = this.program.provider.connection.onLogs(
            this.publicKey,
            callbackFunction,
            commitmentLevel,
        );
    };

    static async fetchAllBoInstances(
        speculateProgram: Program,
        playerPubkey?: PublicKey,
        boSpecPubkey?: PublicKey,
        feedId?: Buffer,
        boInstanceStatus?: number 
    ): Promise<[PublicKey, any][]> {
        const filters = [
            {
                memcmp: {
                    offset: 0, // Anchor account discriminator for account type
                    bytes: base58.encode(BinaryOption.deriveBoInstanceAccountDiscriminator())
                },
            }
        ];
        if (boSpecPubkey) {
            filters.push({
                memcmp: {
                    offset: 8, 
                    bytes: base58.encode(boSpecPubkey.toBuffer()), 
                },
            });
        }
        if (playerPubkey) {
            filters.push({
                memcmp: {
                    offset: 40, 
                    bytes: base58.encode(playerPubkey.toBuffer()), 
                },
            });
        }
        if (feedId) {
            filters.push({
                memcmp: {
                    offset: 104, 
                    bytes: base58.encode(feedId), 
                },
            });
        }
        if (boInstanceStatus) {
            filters.push({
                memcmp: {
                    offset: 136, 
                    bytes: base58.encode(Buffer.from([boInstanceStatus as number])), 
                },
            });
        }
        const boInstancePubkeyAndBuffers = await speculateProgram.provider.connection.getProgramAccounts(
            speculateProgram.programId,
            {
              filters
            }
        );
        return boInstancePubkeyAndBuffers.map((boInstancePubkeyAndBuffer) => (
            [
                boInstancePubkeyAndBuffer.pubkey,
                speculateProgram.coder.accounts.decode(
                    "BoInstance",
                    boInstancePubkeyAndBuffer.account.data
                )
            ]
        ));
    }

    async fetchAllBoInstances(
        playerPubkey?: PublicKey,
        feedId?: Buffer,
        boInstanceStatus?: number
    ): Promise<[PublicKey, any][]> {
        return BinaryOption.fetchAllBoInstances(
            this.program,
            playerPubkey,
            this.publicKey,
            feedId,
            boInstanceStatus
        )
    }
}