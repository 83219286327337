import React, { useContext, Suspense, lazy } from "react";
import { Tab, TabSize } from "../components/common/tab/Tab";
import { NftStakingContext } from "../contexts/NftStakingContext";
import { HomepagePreloader } from "./preloaders/HomepagePreloader";

const CollectionTab = lazy(() => import('../components/zeebros-page/CollectionTab'));
const MyZeebrosTab = lazy(() => import('../components/zeebros-page/MyZeebrosTab'));
const GeneralTab = lazy(() => import('../components/zeebros-page/GeneralTab'));

export enum ZeebrosPageTabs {
  General = "general",
  Collection = "collection",
  MyZeebros = "myZeebros",
}
export const ZeebrosPage = () => {
  const {currentTab, setCurrentTab} = useContext(NftStakingContext);

  return (
    <div className={`scroll-fix mx-auto flex max-w-7xl flex-col gap-5 `}>
      {/* TABS */}
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {
        currentTab === ZeebrosPageTabs.Collection
          ? <Suspense fallback={<HomepagePreloader />}><CollectionTab /></Suspense> : null
      }
      {
        currentTab === ZeebrosPageTabs.MyZeebros
          ? <Suspense fallback={<HomepagePreloader />}><MyZeebrosTab /></Suspense> : null
      }
      {
        currentTab === ZeebrosPageTabs.General
          ? <Suspense fallback={<HomepagePreloader />}><GeneralTab setCurrentTab={setCurrentTab} /></Suspense> : null
      }
    </div>
  );
};

export default ZeebrosPage;

const Tabs = ({ currentTab, setCurrentTab }) => {
  return (
    <div className="inline-flex items-start gap-x-3 overflow-auto">
      <Tab
        active={currentTab === ZeebrosPageTabs.General}
        size={TabSize.MD}
        onClick={() => {
          setCurrentTab(ZeebrosPageTabs.General);
        }}
      >
        <div>General</div>
      </Tab>
      <Tab
        active={currentTab === ZeebrosPageTabs.Collection}
        size={TabSize.MD}
        onClick={() => {
          setCurrentTab(ZeebrosPageTabs.Collection);
        }}
      >
        <div>Collection</div>
      </Tab>
      <Tab
        active={currentTab === ZeebrosPageTabs.MyZeebros}
        size={TabSize.MD}
        onClick={() => {
          setCurrentTab(ZeebrosPageTabs.MyZeebros);
        }}
      >
        <div>My Zeebros</div>
      </Tab>
    </div>
  );
};
