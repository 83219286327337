import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { Program } from "@coral-xyz/anchor";

import BinaryOption from "../sdk/binaryOptions/binaryOption";
import { ProgramContext } from "./ProgramContext";
import { HouseContext } from "./HouseContext";
import House from "../sdk/house";
import { PlayerContext } from "./PlayerContext";
import { BoDirection } from "../sdk/binaryOptions/enums";
import { NetworkContext } from "./NetworkContext";
import { IFormattedFeed, ITimeInterval } from "../sdk/binaryOptions/types";
import { confirmTransaction } from "../utils/solana/utils";
import { WrappedWalletContext } from "./WrappedWalletContext";

export interface FeedPrice {
  priceBasis: number, 
  priceUi: number,
  publishTime: number,
  publishDate: Date,
  vaa?: string,
  feedSymbol?: string,
}

export interface IBinaryOptionsContext {
  binaryOptionLoaded: boolean
  binaryOption: BinaryOption | undefined
  placeBoBet: (feedId: Buffer, timeIntervalSeconds: number, direction: BoDirection, tokenMintPubkey: PublicKey, wager: number, onSuccessfulSendCallback?: Function, onErrorCallback?: Function, onUserRejected?: Function) => Promise<string | undefined>
  availableFeeds: IFormattedFeed[] | undefined
  selectedFeed: IFormattedFeed | undefined
  setSelectedFeed: React.Dispatch<React.SetStateAction<IFormattedFeed | undefined>>
  selectedTimeInterval: ITimeInterval | undefined
  setSelectedTimeInterval: React.Dispatch<React.SetStateAction<ITimeInterval | undefined>>
}

export const BinaryOptionsContext = createContext<IBinaryOptionsContext>({} as IBinaryOptionsContext);

interface Props {
  binaryOptionsSpecPubkeyString?: string,
  children: any;
}

export const BinaryOptionsProvider = ({ binaryOptionsSpecPubkeyString, children }: Props) => {
  const [binaryOptionLoaded, setBinaryOptionLoaded] = useState(false)
  const [binaryOption, setBinaryOption] = useState<BinaryOption>()
  const { meta } = useContext(ProgramContext)
  const { house } = useContext(HouseContext)
  const { playerAccount, playerMeta, loadPlayerAccountAndStartWs, counter } = useContext(PlayerContext)
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)
  const { client, recentBlockhash, networkCounter } = useContext(NetworkContext)

  // FEEDS
  const availableFeeds: IFormattedFeed[] | undefined = useMemo(() => {
    return binaryOption?.formattedBoFeeds
  }, [binaryOption])
  const [selectedFeed, setSelectedFeed] = useState<IFormattedFeed>()
  const [selectedInterval, setSelectedInterval] = useState<ITimeInterval>()

  useEffect(() => {
    // SET THE DEFAULT TIME INTERVAL
    if (selectedFeed == null && availableFeeds != null && availableFeeds?.length > 0) {
      const firstAvailableFeed = availableFeeds[0]

      if (firstAvailableFeed.activeTimeIntervals != null && firstAvailableFeed.activeTimeIntervals.length > 0) {
        setSelectedInterval(firstAvailableFeed.activeTimeIntervals[0])
      } else {
        setSelectedInterval(undefined)
      }
    }
  }, [availableFeeds])

  useEffect(() => {
    async function loadBinaryOptions(boSpecPubkey: PublicKey, house: House, specProgram: Program) {
      const binaryOptions = await BinaryOption.load(specProgram, house, boSpecPubkey)

      setBinaryOption(binaryOptions)

      if (binaryOptionLoaded == false) {
        setBinaryOptionLoaded(true)
      }
    }

    if (house == null || meta?.speculateProgram == null) {
      return
    }

    const binaryOptionsSpec = binaryOptionsSpecPubkeyString != null ? new PublicKey(binaryOptionsSpecPubkeyString) : BinaryOption.deriveBoSpecPubkey(meta.speculateProgram.programId, house.publicKey)

    loadBinaryOptions(binaryOptionsSpec, house, meta.speculateProgram)
  }, [binaryOptionsSpecPubkeyString, house, meta])

  // METHOD TO PLACE BET
  const placeBoBet = useCallback(
    async (
      feedId: Buffer,
      timeIntervalSeconds: number,
      direction: BoDirection,
      tokenMintPubkey: PublicKey,
      wager: number,
      onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
      onErrorCallback?: Function,
      onUserRejected?: Function,
    ) => {
      if (
        playerAccount == null ||
        walletPubkey == null ||
        solanaRpc == null ||
        binaryOption == null ||
        playerAccount.platform == null
      ) {
        console.warn(
          "Issue with player acc, wallet pubkey or solana rpc.",
          playerAccount,
          walletPubkey,
          solanaRpc,
          binaryOption,
        );
        
        return Promise.reject("Issue with player acc, wallet pubkey or solana rpc.");
      }

      // CHECK TO ENSURE THERE IS NOT AN OPEN GAME INSTANCE

      try {
        const tx = new Transaction();

        // INIT PLAYER IF DOESNT EXIST
        const playerExists = await playerAccount.checkPlayerAccountInitialized();
        const referrer =
          playerExists == false && playerMeta != null && playerMeta.referralAccount != null
            ? new PublicKey(playerMeta.referralAccount)
            : playerAccount.publicKey;

        if (!playerExists) {
          const username =
            playerMeta != null && playerMeta.username != null && playerMeta.username.length > 0
              ? playerMeta.username
              : walletPubkey.toString().slice(0, 10);

          const initPlayer = await playerAccount.initializePlayerAccountIxn(
            username,
            playerAccount.house.latestTermsVersion,
            playerAccount.platform.latestTermsVersion as number,
            referrer,
            walletPubkey,
          );

          tx.add(initPlayer);
        } else {
          // CHECK IF WE NEED TO FRONT RUN WITH SYNC OR CLAIM REWARDS
          const preIxn = await playerAccount.getPreInstruction()

          if (preIxn != null) {
            tx.add(preIxn)
          }
        }

        // Place BoBet
        const initAndBetSoloIx = await binaryOption.placeBoBetIx(
          walletPubkey,
          playerAccount,
          feedId,
          timeIntervalSeconds,
          direction,
          tokenMintPubkey,
          wager,
          referrer
        );

        tx.add(initAndBetSoloIx);

        const sig = await solanaRpc.sendTransaction(
          tx,
          client,
          walletPubkey,
          meta?.errorByCodeByProgram,
          recentBlockhash,
          binaryOption.lookupTablePubkey
        );

        if (onSuccessfulSendCallback) {
          onSuccessfulSendCallback(sig);
        }

        // NOW LOAD THE NEW PLAYER ACCOUNT
        if (playerExists == false) {
          // NEED TO CONFIRM THE TX BEFORE LOADING STATE
          const confirmedSig = await confirmTransaction(sig, client, recentBlockhash);

          await loadPlayerAccountAndStartWs();
        }

        return sig;
      } catch (err) {
        // PUT IN FOR CRUDE TESTING OF ISSUES, TO BE REMOVED
        if (err == null || err?.message == "User rejected the request.") {
          if (onUserRejected != null) {
            onUserRejected();
          }
          return;
        }

        console.warn("Error placing bet", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      }
    },
    [
      solanaRpc,
      walletPubkey,
      playerAccount,
      counter,
      binaryOption,
      client,
      meta,
      house,
      playerMeta,
      recentBlockhash,
      networkCounter,
    ],
  );

  return (
    <BinaryOptionsContext.Provider
      value={useMemo(
        () => ({
          binaryOption: binaryOption,
          placeBoBet: placeBoBet,
          availableFeeds: availableFeeds,
          selectedFeed: selectedFeed,
          setSelectedFeed: setSelectedFeed,
          selectedTimeInterval: selectedInterval,
          setSelectedTimeInterval: setSelectedInterval,
          binaryOptionLoaded: binaryOptionLoaded
        }),
        [binaryOption, placeBoBet, availableFeeds, selectedFeed, selectedInterval, binaryOptionLoaded],
      )}
    >
      {children}
    </BinaryOptionsContext.Provider>
  );
};
