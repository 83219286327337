import React, { FC, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IPlatformGame } from "../../types/game";
import { BrowserPreferenceContext } from "../../contexts/BrowserPreferenceContext";
import NavItem from "../common/navigation-item/NavItem";
import Zeebros from "../../assets/zeebros/zeebros_z.png";
import { GITBOOK_URL } from "../../constants/socials";
import { IconName } from "../common/iconFont/IconFont";
import { NavContext, ProfileTab } from "../../contexts/NavContext";
import Scrollbar from "../common/scrollbar/Scrollbar";
import { NftStakingContext } from "../../contexts/NftStakingContext";
import { ZeebrosPageTabs } from "../../pages/ZeebrosPage";
import { RankTabs } from "../modals/UserRankModal";
import { defaultNetwork, NetworkType } from "../../utils/chain/network";
import BinaryOptionsNav from "./BinaryOptionsNav";

export interface NavProps {
  leftBarIsOpen: boolean;
  games: IPlatformGame[];
  getIconName: (url: string) => IconName;
  toggleLeftPanel: (value?: boolean | undefined) => void;
  closeAfterClick?: boolean;
  activePage?: string | undefined;
  footerContent?: any;
}

const Nav: FC<NavProps> = ({
  leftBarIsOpen,
  games,
  toggleLeftPanel,
  getIconName,
  closeAfterClick = false,
  activePage = undefined,
  footerContent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { language } = useContext(BrowserPreferenceContext);
  const { setProfileTab } = useContext(NavContext);
  const { setCurrentTab } = useContext(NftStakingContext);

  return (
    <Scrollbar handleColor="var(--gray-700)">
      <div className={`flex grow-0 flex-col md:grow `}>
        <nav
          className={`${leftBarIsOpen ? "mr-2" : ""} mb-2  flex flex-col`}
          onClick={() => {
            if (leftBarIsOpen == false) {
              toggleLeftPanel();
            }
          }}
        >
          <ul role="list" className="flex flex-1 flex-col gap-y-1">
            <NavItem
              isSelected={(activePage != null && activePage == "home") || pathName === "/"}
              isTopLevel
              key={"home"}
              iconName="home"
              collapsed={!leftBarIsOpen}
              title={"Home"}
              onClick={() => {
                navigate(``);
                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />
            <NavItem
              isSelected={
                (activePage != null && activePage == "zeebros") || pathName === "/zeebros"
              }
              isTopLevel
              key={"zeebros"}
              icon={<img src={Zeebros} className="drop-shadow-white-glow" />}
              collapsed={!leftBarIsOpen}
              title={"Zeebros NFT"}
              onClick={() => {
                setCurrentTab(ZeebrosPageTabs.General);
                navigate(`/zeebros`);
                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />
            {defaultNetwork != NetworkType.MAINNET && (
              <BinaryOptionsNav
                toggleLeftPanel={toggleLeftPanel}
                closeAfterClick={closeAfterClick}
                activePage={activePage}
                collapsed={!leftBarIsOpen}
              />
            )}
            <NavItem
              isSelected={false}
              isTopLevel
              key="games"
              iconName="play"
              collapsed={!leftBarIsOpen}
              title={"Games"}
              dropdownVisibleDefault={true}
            >
              {games.map(({ isComingSoon, name, isNew, gameSpecPubkey, icon }, index) => (
                <NavItem
                  isSelected={
                    (activePage != null && activePage == gameSpecPubkey) ||
                    pathName === `/game/${gameSpecPubkey}`
                  }
                  key={name[language]}
                  iconName={getIconName ? getIconName(icon) : ""}
                  collapsed={!leftBarIsOpen}
                  title={name[language]}
                  isNew={isNew}
                  isSoon={isComingSoon}
                  onClick={() => {
                    navigate(`/game/${gameSpecPubkey}`);

                    if (closeAfterClick == true) {
                      toggleLeftPanel(false);
                    }
                  }}
                />
              ))}
            </NavItem>
            {defaultNetwork != NetworkType.MAINNET && (
              <NavItem
                isSelected={
                  (activePage != null && activePage == "home") || pathName === "/jackpot/1"
                }
                isTopLevel
                key={"jackpot"}
                iconName="jackpot"
                collapsed={!leftBarIsOpen}
                title={"Jackpot"}
                onClick={() => {
                  navigate(`/jackpot/1`); // 1 for Jackpots
                  if (closeAfterClick == true) {
                    toggleLeftPanel(false);
                  }
                }}
              />
            )}
            <NavItem
              isTopLevel
              isSelected={pathName === "/leaderboard"}
              key={"Leaderboard"}
              iconName="leadrboard"
              collapsed={!leftBarIsOpen}
              title={"Leaderboard"}
              onClick={() => {
                navigate(`/leaderboard`);

                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />

            <NavItem
              isTopLevel
              isSelected={false}
              key={"Referrals"}
              iconName="link"
              collapsed={!leftBarIsOpen}
              title={"Referrals"}
              onClick={() => {
                setProfileTab(ProfileTab.REFERRALS);
                navigate(`/profile`);

                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />

            {/*<NavItem*/}
            {/*  isTopLevel*/}
            {/*  isSelected={false}*/}
            {/*  key={"promotions"}*/}
            {/*  iconName="gift"*/}
            {/*  collapsed={!leftBarIsOpen}*/}
            {/*  title={"Promotions"}*/}
            {/*  onClick={() => {*/}
            {/*    navigate(`/promotions`);*/}

            {/*    if (closeAfterClick == true) {*/}
            {/*      toggleLeftPanel(false);*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
            <NavItem
              isTopLevel
              isSelected={false}
              key={"ranks_and_rewards"}
              iconName="gift"
              collapsed={!leftBarIsOpen}
              title={"Ranks & Rewards"}
              onClick={() => {
                navigate(window.location.pathname, {
                  state: {
                    isUserRankModalOpen: true,
                    selectedRankModalTab: RankTabs.RANK_LIST,
                  },
                });
              }}
            />
            <NavItem
              isSelected={false}
              isTopLevel
              key={"help"}
              iconName="information"
              collapsed={!leftBarIsOpen}
              title={"Help"}
              onClick={() => {
                window.open(GITBOOK_URL);

                if (closeAfterClick == true) {
                  toggleLeftPanel(false);
                }
              }}
            />

            {footerContent != null ? footerContent : ""}
          </ul>
        </nav>
      </div>
    </Scrollbar>
  );
};

export default Nav;
