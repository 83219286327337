import { useContext } from "react";
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { Button } from "../common/button/Button";
import IconButton from "../common/button/IconButton";
import { WalletDropdown } from "./WalletDropdown";
import { RewardsDropdown } from "../rewards/RewardsDropdown";
import { NavContext } from "../../contexts/NavContext";
import { RightNavType } from "../../types/nav";
import { UserDropdown } from "./UserDropdown";
import { WalletModalType } from "../../modals/WalletConnectionModal";
import { useWalletConnectionModal } from "../../hooks/modals/useWalletConnectionModal";
import { WalletTab } from "../../modals/WalletModal";
import { useNavigate } from "react-router";
import { useWalletModal } from "../../hooks/modals/useWalletModal";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import { PlayerContext } from "../../contexts/PlayerContext";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

export function TopNav() {
  const navigate = useNavigate();
  const { loggedIn } = useContext(WrappedWalletContext);
  const {
    toggleRightPanel,
    toggleLeftPanel,
    setRightPanelType,
    rightPanelType,
    rightPanelOpen,
    searchToggle,
  } = useContext(NavContext);
  const { isNewNotificationsAvailable, setIsNewNotificationsAvailable } = useContext(PlayerContext);

  // MODALS
  const { setWalletConnectionTab, showWalletConnectModal } = useWalletConnectionModal(
    WalletModalType.CONNECT,
  );
  const { setWalletTab, showWalletModal } = useWalletModal();

  const [_, setNotificationsLastSeenTime] = useLocalStorage("zeebit-notifications-last-seen-time", new Date().getTime().toString());

  return (
    <ul className="flex justify-between h-top-bar-height bg-gray-800 px-4 py-0 items-center relative z-40 shadow-black-regular">
      {/* HAMBURGER AND LOGO */}
      <li className="text-white flex align-center">
        <div
          role="button"
          className="hidden sm:block h-6 w-6 my-auto"
          onClick={() => toggleLeftPanel()}
        >
          <HamburgerIcon />
        </div>
        {/* TODO - REPLACE WITH LOGO */}
        <div
          className="flex align-center cursor-pointer ml-3"
          onClick={() => {
            navigate(``);
          }}
        >
          <div className="">
            <div className="hidden sm:block w-[170px]">
              <img src={getS3StaticFolderUrl("/static/zeebit-beta-full.svg")} />
            </div>

            <img src={getS3StaticFolderUrl("/static/zeebit-beta-mobile.svg")} className="flex sm:hidden w-[90px]" />
          </div>
        </div>
      </li>

      {/* USERS TOKENS AND WALLET DROPDOWN */}
      <li className="hidden sm:block">
        {loggedIn ? (
          <WalletDropdown
            openGasModal={() => {
              setWalletTab(WalletTab.GAS);
              showWalletModal();
            }}
            setWalletModalOpen={showWalletModal}
          />
        ) : (
          ""
        )}
      </li>
      {/* CONNECT, REGISTER, OR PROFILE */}
      <li className="flex">
        {
          loggedIn
          && <div className="hidden md:flex pr-0 lg:pr-4">
            <RewardsDropdown />
          </div>
        }
        <div className={`hidden sm:flex items-center justify-center text-white`}>
          <IconButton
            className={"bg-gray-800 !p-1 lg:!p-3"}
            size="lg"
            iconName="search"
            onClick={(e) => {
              searchToggle();
            }}
          />
          {loggedIn ? (
            <div>
              <IconButton
                className={`
                  bg-gray-800 relative !p-1 lg:!p-3
                  ${
                    isNewNotificationsAvailable
                      ? "after:absolute after:w-1.5 after:h-1.5 after:bg-red-600 after:rounded-full after:top-2 after:right-2"
                      : ""
                  }
                `}
                size="lg"
                iconName="bell"
                onClick={() => {
                  if (!rightPanelOpen) {
                    setNotificationsLastSeenTime(new Date().getTime().toString());
                  }
                  if (rightPanelType != RightNavType.NOTIFICATIONS) {
                    setRightPanelType(RightNavType.NOTIFICATIONS);

                    if (rightPanelOpen == false) {
                      toggleRightPanel();
                      setIsNewNotificationsAvailable(false);
                    }
                  } else {
                    toggleRightPanel();
                    setIsNewNotificationsAvailable(false);
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}
          <IconButton
            className={"bg-gray-800 !p-1 lg:!p-3"}
            size="lg"
            iconName="chat"
            onClick={() => {
              if (rightPanelType != RightNavType.CHAT) {
                setRightPanelType(RightNavType.CHAT);

                if (rightPanelOpen == false) {
                  toggleRightPanel();
                }
              } else {
                toggleRightPanel();
              }
            }}
          />
        </div>
        {!loggedIn ? (
          <div className="flex space-x-2 sm:ps-2">
            <Button
              size={"md"}
              variant={"gray"}
              children={"Connect"}
              onClick={() => {
                setWalletConnectionTab(WalletModalType.CONNECT);
                showWalletConnectModal();
              }}
            />
            <Button
              size={"md"}
              variant={"primary"}
              children={"Register"}
              onClick={() => {
                setWalletConnectionTab(WalletModalType.REGISTER);
                showWalletConnectModal();
              }}
            />
          </div>
        ) : (
          <>
            <div className="hidden sm:block">
              <UserDropdown />
            </div>
            <div className="flex sm:hidden">
              <WalletDropdown
                openGasModal={() => {
                  setWalletTab(WalletTab.GAS);
                  showWalletModal();
                }}
                setWalletModalOpen={showWalletModal}
                className={loggedIn ? "[&>div]:-right-12" : ""}
              />
              {
                loggedIn
                && <div className="ml-2">
                  <RewardsDropdown />
                </div>
              }
            </div>
          </>
        )}
      </li>
    </ul>
  );
}
