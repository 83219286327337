import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IProgramMeta, ProgramContext } from "./ProgramContext";
import { Program } from "@coral-xyz/anchor";
import GameSpec from "../sdk/permisionless/gameSpec";
import { PERMISSIONLESS_PROGRAM_PUBKEY } from "../sdk/permisionless/constants";
import House from "../sdk/house";
import { CASHIER_PROGRAM_PUBKEY, HOUSE_ID } from "../sdk/constants";
import GameTokenSpec from "../sdk/permisionless/gameTokenSpec";
import { NetworkContext } from "./NetworkContext";
import { PlayerContext } from "./PlayerContext";
import GameTokenInstance from "../sdk/permisionless/gameTokenInstance";
import { listenForTransaction } from "../sdk/utils";
import { Commitment, PublicKey } from "@solana/web3.js";
import { usePermisionlessTokenSpecUpdates } from "../hooks/permisionless/usePermisionlessTokenSpecUpdates";
import { IChainBalance } from "./BalanceContext";
import { useTokenBalance } from "../hooks/useTokenBalance";
import Player from "../sdk/playerAccount";
import { PlatformContext } from "./PlatformContext";
import { ITokenMeta, getTokenMetaByMint } from "../sdk/permisionless/utils";
import { WrappedWalletContext } from "./WrappedWalletContext";

export interface IPermisionlessContext {
  gameSpec: GameSpec | undefined
  gameTokenSpecs: GameTokenSpec[] | undefined
  selectedTokenSpec: GameTokenSpec | undefined
  selectedTokenInstance: GameTokenInstance | undefined
  tokenInstances: GameTokenInstance[] | undefined
  metaByMint: Map<string, ITokenMeta> | undefined
  placeBet: (betRequest: any, onSuccessSend: Function, onConfirm: Function, onError: Function) => Promise<string | undefined>
  userTokenBalance: IChainBalance | undefined,
  loadPlayersForWalletPubkeys: (walletPubkeys: PublicKey[]) => Promise<Map<string, Player | undefined>>
  refreshGameMetas: () => Promise<void>
}

export const PermisionlessContext = createContext<IPermisionlessContext>({} as IPermisionlessContext);

interface Props {
  type: string | undefined
  mintPubkey: string | undefined
  children: any;
}

export const PermisionlessProvider = ({ children, type, mintPubkey }: Props) => {
  // CONTEXT
  const { meta } = useContext(ProgramContext)
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext)
  const { client, recentBlockhash } = useContext(NetworkContext)
  const { clientSeed } = useContext(PlayerContext);

  // STATE
  const [gameSpec, setGameSpec] = useState<GameSpec>()
  const [gameTokenSpecs, setGameTokenSpecs] = useState<GameTokenSpec[]>()
  const [tokenInstances, setTokenInstances] = useState<GameTokenInstance[]>()
  const [metaByMint, setMetaByMint] = useState<Map<string, ITokenMeta> | undefined>()

  // MAP USED TO REDUCE LOADS OF PLAYERS USED ACCROSS PERMISIONLESS CONTEXT
  const playerByPubkey = useRef<Map<string, Player>>(new Map())
  const { platform } = useContext(PlatformContext)

  const loadPlayersForWalletPubkeys = async (walletPubkeys: PublicKey[]): Promise<Map<string, Player | undefined>> => {
    if (platform == null) {
      return Promise.reject("Issue with the platform when loading players.")
    }

    const playerByPubkeyResult = new Map<string, Player | undefined>()
    
    // CHECK FOR MISSING PLAYERS
    const missingPlayers = walletPubkeys.reduce((result, item) => {
      const playerPubkey = Player.derivePlayerAccountPubkey(item, platform)

      if (playerByPubkey.current.has(playerPubkey.toString())) {
        playerByPubkeyResult.set(item.toString(), playerByPubkey.current.get(playerPubkey.toString()))

        return result
      } else {
        result.push(playerPubkey)
      }

      return result
    }, new Array<PublicKey>())

    // LOAD MISSING PLAYERS
    const players = await Player.loadMultiple(missingPlayers, platform)
    players.forEach((player) => {
      playerByPubkeyResult.set(player.ownerPubkey.toString(), player)

      playerByPubkey.current.set(player.publicKey.toString(), player)
    })

    return playerByPubkeyResult
  }

  const selectedTokenSpec = useMemo(() => {
    if (mintPubkey == null || gameTokenSpecs == null) {
      return undefined
    }

    return gameTokenSpecs.find((spec) => {
      return spec.tokenMintPubkey.toString() == mintPubkey
    })
  }, [mintPubkey, gameTokenSpecs])
  const { updatedSpec, updatedTokenInstance } = usePermisionlessTokenSpecUpdates(selectedTokenSpec, client)

  // METHODS TO REFRESH
  async function loadTokenInstances(spec: GameSpec) {
    const tokenInstances = await spec.fetchManyGameSpecTokenInstances()

    return tokenInstances
  }
  async function loadGameTokenSpecs(spec: GameSpec) {
    return await spec.fetchManyGameTokenSpecs()
  }

  async function loadGameSpec(permisonlessProgram: Program, type: string) {
    const typeNum = Number(type)

    return await GameSpec.load(
      permisonlessProgram,
      GameSpec.deriveGameSpecPubkey(
        PERMISSIONLESS_PROGRAM_PUBKEY,
        House.deriveHousePubkey(HOUSE_ID, CASHIER_PROGRAM_PUBKEY),
        typeNum,
        undefined,
      )
    );
  }

  async function loadTokenMeta(specs: GameTokenSpec[]) {
    const mints = specs.map((spec) => {
      return spec.tokenMintPubkey.toString()
    })

    const meta = await getTokenMetaByMint(mints)

    setMetaByMint(meta)
  }

  async function loadGameMeta(permisonlessProgram: Program, type: string) {
    const spec = await loadGameSpec(permisonlessProgram, type)
    const tokenSpecs = await loadGameTokenSpecs(spec)
    const tokenInstances = await loadTokenInstances(spec)

    await loadTokenMeta(tokenSpecs)

    setGameSpec(spec)
    setGameTokenSpecs(tokenSpecs)
    setTokenInstances(tokenInstances)
  }

  const refreshGameMetas = useCallback(async () => {
    try {
      await loadGameMeta(meta.permisionlessProgram, type)
    } catch (err) {
      console.error(`There was an issue refreshing the game meta. ${err}`)
    }
  }, [meta, type])


  // LOADERS
  useEffect(() => {
    if (meta == null || meta.permisionlessProgram == null || type == null) {
      return
    }

    loadGameMeta(meta.permisionlessProgram, type)
  }, [meta, type])

  // EVERY N SECONDS LOAD TOKEN SPECS, AND INSTANCES
  // REFS USED FOR CYCLICAL LOAD
  const metaRef = useRef<IProgramMeta>()
  const typeRef = useRef<number>()
  useEffect(() => {
    metaRef.current = meta
    typeRef.current = type != null ? Number(type) : undefined
  }, [meta, type])

  useEffect(() => {
    async function refreshData() {
      if (metaRef.current?.permisionlessProgram == null || typeRef.current == null) {
        return
      }

      try {
        const updatedSpec = await GameSpec.load(
          metaRef.current?.permisionlessProgram,
          GameSpec.deriveGameSpecPubkey(
            PERMISSIONLESS_PROGRAM_PUBKEY,
            House.deriveHousePubkey(HOUSE_ID, CASHIER_PROGRAM_PUBKEY),
            typeRef.current,
            undefined,
          )
        );
        const updatedTokenSpecs = await updatedSpec.fetchManyGameTokenSpecs()
        const updatedTokenInstances = await updatedSpec.fetchManyGameSpecTokenInstances()

        setGameSpec(updatedSpec)
        setGameTokenSpecs(updatedTokenSpecs)
        setTokenInstances(updatedTokenInstances)
      } catch (err) {
        console.error({
          err
        })
      }
    }

    // CYCLICAL LOAD
    const interval = setInterval(refreshData, 10_000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  // METHODS
  const placeBet = useCallback(async (betRequest: any, onSuccessSend?: Function, onConfirm?: Function, onError?: Function, commitment: Commitment = 'processed'): Promise<string | undefined> => {
    try {
      // FORM THE TX
      const clientSeedBuffer = Buffer.from(clientSeed)
      const placeBetTx = await updatedSpec.placeBetTx(walletPubkey, betRequest, clientSeedBuffer)
      // SEND THE TX
      const sig = await solanaRpc?.sendTransaction(placeBetTx, client, walletPubkey, meta?.errorByCodeByProgram, recentBlockhash)
      onSuccessSend?.(sig)

      // CONFIRM THE TX
      listenForTransaction(client, sig, commitment, onConfirm, onError)

      return sig
    } catch (err) {
      console.error({
        err
      })
      onError?.(err)

      return Promise.reject("There was an issue placing the bet.")
    }
  }, [updatedSpec, gameSpec, walletPubkey, solanaRpc, client, meta, recentBlockhash, clientSeed])


  // USERS TOKEN BALANCE FOR SELECTED MINT...
  const { userTokenBalance } = useTokenBalance(updatedSpec?.tokenMintPubkey, walletPubkey, client)

  return (
    <PermisionlessContext.Provider
      value={useMemo(
        () => ({
          gameSpec: gameSpec,
          gameTokenSpecs: gameTokenSpecs,
          placeBet: placeBet,
          selectedTokenSpec: updatedSpec,
          selectedTokenInstance: updatedTokenInstance,
          tokenInstances: tokenInstances,
          metaByMint: metaByMint,
          userTokenBalance: userTokenBalance,
          loadPlayersForWalletPubkeys: loadPlayersForWalletPubkeys,
          refreshGameMetas: refreshGameMetas
        }),
        [gameSpec, gameTokenSpecs, placeBet, selectedTokenSpec, tokenInstances, metaByMint, updatedTokenInstance, userTokenBalance, refreshGameMetas],
      )}
    >
      {children}
    </PermisionlessContext.Provider>
  );
};
