import { web3 } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import {
  GameStatus,
  GameTokenStatus,
  HouseStatus,
  PlatformStatus,
  PlatformTokenStatus,
  TokenStatus,
} from "./enums";
import { ENV_NAME } from "../utils/env/env";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

// RPC ENDPOINT
const RPC_ENDPOINT_URL_DEVNET = 'https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79';
const RPC_ENDPOINT_URL_MAINNET = 'https://mainnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79';
export const RPC_ENDPOINT_URL =
  ENV_NAME == "MAINNET" ? RPC_ENDPOINT_URL_MAINNET : RPC_ENDPOINT_URL_DEVNET;

// DEVNET PROGRAMS
export const CASHIER_PROGRAM_PUBKEY_DEVNET = new PublicKey(
  "8KQsqqTdYzVjReMg8K1PTjCn6kAdy9XCu4Ncn9K7TANJ",
);
export const CASINO_PROGRAM_PUBKEY_DEVNET = new PublicKey(
  "FQmxhcPZFCcwjXbtWbqhev9bRgjFT733mCBguKydBFeW",
);
export const RANDOM_PROGRAM_PUBKEY_DEVNET = new PublicKey(
  "CxukdqqPpsxeXECDVGSK43YZ7RSeDDreHW4ZMwXKNHXv",
);
export const NFT_STAKING_PROGRAM_PUBKEY_DEVNET = new PublicKey("ZEEW4zzN1Len2Rf7aBPWftTBnUFyeGkXcFFydpqtmQm");
export const SPECULATE_PROGRAM_PUBKEY_DEVNET = new PublicKey('HzNDruzE1U7t9askwWsG2yDjhMeQPbjefeJ8Jomb8GyC');
export const WORMHOLE_PROGRAM_ID_DEVNET = new PublicKey("HDwcJBJXjL9FpJ7UBsYBtaDjsBUhuLCUYoz3zr8SWWaQ");

// MAINNET PROGRAMS
export const CASHIER_PROGRAM_PUBKEY_MAINNET = new PublicKey(
  "ZEEJ7VnnrRun9HJY4bEdmh3gxvuLjx8coEc8U8Vryd5",
);
export const CASINO_PROGRAM_PUBKEY_MAINNET = new PublicKey(
  "ZEEdBQeHnxok4KVFbJovDjVyZf316UJPprKXYrujZ75",
);
export const RANDOM_PROGRAM_PUBKEY_MAINNET = new PublicKey(
  "ZEEX2PvFMBN1bZX6RoqFdexTCjr4FiHGqtjMHBEx1E7",
);
export const NFT_STAKING_PROGRAM_PUBKEY_MAINNET = new PublicKey("ZEEW4zzN1Len2Rf7aBPWftTBnUFyeGkXcFFydpqtmQm");
export const SPECULATE_PROGRAM_PUBKEY_MAINNET = new PublicKey('HzNDruzE1U7t9askwWsG2yDjhMeQPbjefeJ8Jomb8GyC');
export const WORMHOLE_PROGRAM_ID_MAINNET = new PublicKey("HDwcJBJXjL9FpJ7UBsYBtaDjsBUhuLCUYoz3zr8SWWaQ");

// PROGRAMS
export const CASHIER_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? CASHIER_PROGRAM_PUBKEY_MAINNET : CASHIER_PROGRAM_PUBKEY_DEVNET;
export const CASINO_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? CASINO_PROGRAM_PUBKEY_MAINNET : CASINO_PROGRAM_PUBKEY_DEVNET;
export const RANDOM_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? RANDOM_PROGRAM_PUBKEY_MAINNET : RANDOM_PROGRAM_PUBKEY_DEVNET;
export const NFT_STAKING_PROGRAM_PUBKEY =
  ENV_NAME == "MAINNET" ? NFT_STAKING_PROGRAM_PUBKEY_DEVNET : NFT_STAKING_PROGRAM_PUBKEY_DEVNET;
export const SPECULATE_PROGRAM_PUBKEY = ENV_NAME == 'MAINNET' ? SPECULATE_PROGRAM_PUBKEY_MAINNET: SPECULATE_PROGRAM_PUBKEY_DEVNET;
export const WORMHOLE_PROGRAM_ID = ENV_NAME == 'MAINNET' ? WORMHOLE_PROGRAM_ID_MAINNET: WORMHOLE_PROGRAM_ID_DEVNET;

// DISPATCHER
export const DISPATCHER_PUBKEY_DEVNET = new PublicKey(
  "2TBz13695xddMJieEWiHpt479gg3dir8dbV53zKXKsPD",
);
export const DISPATCHER_PUBKEY_MAINNET = new PublicKey(
  "8eDP5Ct2ghnBaEQcUk7QVyDiufGuiB7YRfmiuJqnduNT",
);
export const DISPATCHER_PUBKEY =
  ENV_NAME == "MAINNET" ? DISPATCHER_PUBKEY_MAINNET : DISPATCHER_PUBKEY_DEVNET;

export const HERMES_ENDPOINT = "https://hermes.pyth.network";

// HOUSE
export const HOUSE_PUBKEY_DEVNET = new PublicKey("4NfQGSEXdBLWKRCe1Gf9vpT2K96YEH8Ngv53jMHBRAJE");
export const HOUSE_PUBKEY_MAINNET = new PublicKey("4C6shFwJWCt3KeH6uKwStHNuKsikx9Z6h1KpbNmdfHM9");
export const HOUSE_PUBKEY = ENV_NAME == "MAINNET" ? HOUSE_PUBKEY_MAINNET : HOUSE_PUBKEY_DEVNET;

// PLATFORM
export const PLATFORM_PUBKEY_DEVNET = new PublicKey("9XRS3W4x5duKWwdMKRW2M3Z2uftUD4zXqdiUU4UR3pDj");
export const PLATFORM_PUBKEY_MAINNET = new PublicKey(
  "6M12JUJ7hUVjwqK2JnfXcmp5ZyApV6WBb1ZdV2gGCTzr",
);
export const PLATFORM_PUBKEY =
  ENV_NAME == "MAINNET" ? PLATFORM_PUBKEY_MAINNET : PLATFORM_PUBKEY_DEVNET;

// HOUSE ID
export const HOUSE_ID_DEVNET: number = 1234;
export const HOUSE_ID_MAINNET: number = 0;
export const HOUSE_ID: number = ENV_NAME == "MAINNET" ? HOUSE_ID_MAINNET : HOUSE_ID_DEVNET;

// PLATFORM ID
export const PLATFORM_ID_DEVNET: number = 1234;
export const PLATFORM_ID_MAINNET: number = 0;
export const PLATFORM_ID: number = ENV_NAME == "MAINNET" ? HOUSE_ID_MAINNET : HOUSE_ID_DEVNET;

// USDC MINT
export const TOKEN_MINT_PUBKEY_DEVNET = new PublicKey(
  "78fsY3SCaoZY8jVFoZdHEciZdjY9qRyXWTc6HtYYvorA",
);
export const TOKEN_MINT_PUBKEY_MAINNET = new PublicKey(
  "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
);
export const TOKEN_MINT_PUBKEY =
  ENV_NAME == "MAINNET" ? TOKEN_MINT_PUBKEY_MAINNET : TOKEN_MINT_PUBKEY_DEVNET;

export const UNIX_DAY_IN_SECONDS = 86_400;

export const DEFAULT_CONFIRM_OPTIONS = {
  skipPreflight: true,
  commitment: "processed",
  preflightCommitment: "processed",
  // maxRetries: 10,
  // minContextSlot:
} as web3.ConfirmOptions;

export const HOUSE_STATUS_TAKING_BETS: HouseStatus[] = [
  HouseStatus.Active,
  HouseStatus.OutFlowsSuspended,
];
export const HOUSE_TOKEN_STATUS_TAKING_BETS: TokenStatus[] = [
  TokenStatus.Active,
  TokenStatus.OutFlowsSuspended,
];
export const GAME_STATUS_TAKING_BETS: GameStatus[] = [GameStatus.Active];
export const TOKEN_STATUS_TAKING_BETS: GameTokenStatus[] = [GameTokenStatus.Active];

export const PLATFORM_STATUS_TAKING_BETS: PlatformStatus[] = [PlatformStatus.Active];
export const PLATFORM_TOKEN_STATUS_TAKING_BETS: PlatformTokenStatus[] = [
  PlatformTokenStatus.Active,
];

export const LOOKUP_TABLE_MAINNET = new PublicKey('6mJpvbjzKQGQTjJtHrUpe56EFVusYQH1x4FVyH5Hbcx9')
export const LOOKUP_TABLE_DEVNET = new PublicKey('CNXv1V2nUJcViGvUi6umK7VjFYPK8sAAQmXu4JJzXtqt')
export const ZEEBIT_LOOKUP_TABLE = defaultNetwork == NetworkType.MAINNET ? LOOKUP_TABLE_MAINNET: LOOKUP_TABLE_DEVNET
