import { NetworkType, defaultNetwork } from "../../utils/chain/network"
import { getLatestTokenPrices, getTokenList } from "../../utils/hellomoon/hellomoon"
import DEVNET_TOKENS from "./../../config/permisionless/token_meta.json"
import { getS3StaticFolderUrl } from "../../utils/config/utils";

// METHOD TO GET TOKEN METADATA
export interface ITokenMeta {
    name: string | undefined
    symbol: string | undefined
    price: number | undefined
    decimals: number | undefined
    mint: string
    icon: string | undefined
}

// SEARCH FOR A TOKEN BASED OFF THE PUBKEY
export const searchForToken = async (mintPubkey: string): Promise<ITokenMeta | undefined> => {
    // FOR DEVNET LOOK IN LOCAL MAP AS NOTHING WILL RETURN FROM API
    if (defaultNetwork != NetworkType.MAINNET) {
        const devnetTokenMap = getDevnetTokenMetaByMint()
        
        return devnetTokenMap.get(mintPubkey)
    }
    
    const metas = await getTokenList({
        mint: [mintPubkey]
    })

    const meta = metas[0]

    const prices = await getLatestTokenPrices({
        mints: [mintPubkey]
    })

    const price = prices[0]

    // TODO - FIND ICON
    const icon = getS3StaticFolderUrl("/static/tokens/usdc.png")

    return {
        mint: mintPubkey,
        name: meta.name,
        symbol: meta.symbol,
        price: price.price,
        decimals: meta.decimals,
        icon: icon
    }
}


// SEARCH FOR TOKEN METAS BASED OFF THE PUBKEYS
export const getTokenMetaByMint = async (mintPubkeys: string[]): Promise<Map<string, ITokenMeta>> => {
    // ON DEVNET NO NEED TO CALL API
    if (defaultNetwork != NetworkType.MAINNET) {
        return getDevnetTokenMetaByMint()
    }

    // CREATE EMPTY MAP
    const metaByMint = mintPubkeys.reduce((result, item) => {
        result.set(item, {
          name: undefined,
          symbol: undefined,
          price: undefined,
          decimals: undefined,
          icon: undefined,
          mint: item
        })
        return result
      }, new Map<string, ITokenMeta>())

      // ADD METAS AND PRICES
      const metas = await getTokenList({
        mint: mintPubkeys
      })

      metas.forEach((meta) => {
        const metaFromMap: ITokenMeta = metaByMint.get(meta.mint) || {
            name: undefined,
            symbol: undefined,
            price: undefined,
            decimals: undefined,
            icon: undefined,
            mint: meta.mint
          }

        metaFromMap.name = meta.name
        metaFromMap.symbol = meta.symbol
        metaFromMap.decimals = meta.decimals

        // UPDATE TOKEN ICON
        metaFromMap.icon = getS3StaticFolderUrl("/static/tokens/usdc.png")

        metaByMint.set(meta.mint, metaFromMap)
      })

      const prices = await getLatestTokenPrices({
        mints: mintPubkeys
      })

      prices.forEach((price) => {
        const metaFromMap: ITokenMeta = metaByMint.get(price.mints) || {
            name: undefined,
            symbol: undefined,
            price: undefined,
            decimals: undefined,
            icon: undefined,
            mint: price.mints
          }

        metaFromMap.price = price.price

        metaByMint.set(price.mints, metaFromMap)
      })

      return metaByMint
}

// DEVNET META BY MINT - DEVNET TOKENS NOT AVAILABLE FROM API...
const getDevnetTokenMetaByMint = () => {
    const tokens = DEVNET_TOKENS

    return tokens.reduce((result, item) => {
        result.set(item.mint, item)
        
        return result
    }, new Map<string, ITokenMeta>())
}