import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export default class Limbo extends Game {
  constructor(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    super(casinoProgram, house, gameSpecPubkey, GameType.Limbo, commitmentLevel);
  }

  static async load(casinoProgram: anchor.Program, house: House, gameSpecPubkey: PublicKey) {
    const game = new Limbo(casinoProgram, house, gameSpecPubkey);
    await game.loadState();
    return game;
  }

  async placeBetIx(
    player: PlayerAccount,
    inputs: object,
    wager: number,
    tokenMintPubkey: PublicKey,
    clientSeed: Buffer,
    owner: PublicKey,
    referrer?: PublicKey,
  ) {
    const instanceRequest = {
      limbo: {},
    };

    const betRequest = {
      limbo: {
        targetMultiplierPerMillion: new anchor.BN(inputs.targetMultiplier * 1_000_000),
        wager: new anchor.BN(wager),
      },
    };

    const numberOfBets = 1;

    return await this.initAndBetSoloIxn(
      player,
      tokenMintPubkey,
      numberOfBets,
      betRequest,
      instanceRequest,
      clientSeed,
      owner,
      referrer,
    );
  }

  get maxMultiplier() {
    return this.gameConfig
      ? Number(this.gameConfig.limbo.maxMultiplierPerMillion) / 1_000_000
      : null;
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.limbo.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.limbo.roundingDenominator) / 1_000_000 : null;
  }

  getMultiplier(inputs: object) {
    if (this.multiplierRoundingUnit == null) {
      return inputs.targetMultiplier;
    }

    return (inputs.targetMultiplier / this.multiplierRoundingUnit) * this.multiplierRoundingUnit;
  }

  getProbability(inputs: object) {
    if (this.houseEdge == null) {
      return 1 / inputs.targetMultiplier;
    }

    return (1 - this.houseEdge) / inputs.targetMultiplier;
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;

    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wager;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      edgePercentage: edgeDollar / totalWager,
    };
  }
}
