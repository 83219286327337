import { orderBy } from "lodash"
import { ITokenCheckMeta } from "../../contexts/BalanceContext"
import { IClaimableMeta, ICollectable } from "../../sdk/betStream"
import House from "../../sdk/house"
import Platform from "../../sdk/platform"
import Player from "../../sdk/playerAccount"
import { INotification, getClaimNotifications, getCollectNotifications } from "../../utils/notifications"
import { loadClaimsCalendar } from "./claims"
import { loadCollectsCalendar } from "./collects"

// METHOD WHICH LOADS CLAIMS, COLLECTS, AND FORMATS TO NOTIFICATIONS WITHIN DATE RANGE
export const loadNotifications = async (
  playerAccount: Player,
  earliestDate: Date,
  latestDate: Date,
  includeClaims: boolean = true,
  includeCollects: boolean = true,
  house: House,
  tokensByIdentifier: Map<string, ITokenCheckMeta>,
  platform: Platform
): Promise<INotification[]> => {
    let claims: IClaimableMeta[] | undefined = []
    let collects: ICollectable[] | undefined = []

    if (includeClaims) {
        claims = await loadClaimsCalendar(playerAccount, earliestDate, latestDate, house, tokensByIdentifier, platform)
    }

    if (includeCollects) {
        collects = await loadCollectsCalendar(playerAccount, earliestDate, latestDate, house, tokensByIdentifier, platform)
    }

    const allNotifications = [
        ...getClaimNotifications(claims),
        ...getCollectNotifications(collects)
    ]

    return orderBy(allNotifications, ['date'], ['desc'])
}