import { useCallback, useContext, useMemo, useState } from "react";
import { IRewardTransactionMeta } from "../../sdk/betStream";
import { useModal } from "react-modal-hook";
import RewardsModal from "../../components/rewards/RewardsModal";
import { PlayerContext } from "../../contexts/PlayerContext";

export const useRewardButtonClick = () => {
    const { handleClaimRewardsAndResponse } = useContext(PlayerContext)
    const [isLoading, setIsLoading] = useState(false);
    const [rewardsResponse, setRewardsResponse] = useState<IRewardTransactionMeta>();

    const [showRewardsModal, hideRewardsModal] = useModal(
        ({ in: open }): JSX.Element => (
            <RewardsModal
                // key prop is needed here to open new modal every time
                key={isLoading.toString()}
                visible={open}
                hideModal={hideRewardsModal}
                rewardsResponse={rewardsResponse}
                isLoading={isLoading}
            />
        ),
        [isLoading, rewardsResponse],
    );

    const claimReward = useCallback(async () => {
        try {
            setIsLoading(true);
            showRewardsModal();

            const rewardsResponse = await handleClaimRewardsAndResponse();

            setRewardsResponse(rewardsResponse);
        } catch (err) {
            console.error({err})
        } finally {
            setIsLoading(false)
        }
    }, [handleClaimRewardsAndResponse]);

    return useMemo(() => {
        return {
            claimReward: claimReward,
            isLoading: isLoading
        }
    }, [claimReward, isLoading])
}