export enum AuthorityStatus {
  Suspended = 0,
  Active = 1,
}

export enum TokenStatus {
  Inactive = 0,
  Active = 1,
  OutFlowsSuspended = 2,
  InFlowsSuspended = 3,
}

export enum TokenType {
  BaseTradingCurrency = 0,
  OtherTradingCurrency = 1,
  WrappedSolTradingCurrency = 2,
  OtherWrappedTradingCurrency = 3,
  PlayTradingCurrency = 4,
  ProjectTokenTradingCurrency = 5,
  ProjectTokenNonTradingCurrency = 6,
}

export enum HouseStatus {
  Uninitialized = 1,
  Active = 2,
  Frozen = 3,
  OutFlowsSuspended = 4,
  InFlowsSuspended = 5,
}

export enum CallingProgramStatus {
  Inactive = 0,
  Active = 1,
  OutFlowsSuspended = 2,
  InFlowsSuspended = 3,
}

export enum LpStatus {
  Inactive = 1,
  Active = 2,
  OutFlowsSuspended = 3,
  InFlowsSuspended = 4,
}

export enum AvatarSource {
  None = 0,
  Nft = 1,
  Arweave = 2,
  Ipfs = 3,
}

// ------- CASINO ENUMS ------

export enum GameType {
  Unspecified = 0,
  CoinFlip = 1,
  Limbo = 2,
  Plinko = 3,
  Dice = 4,
  Roulette = 5,
  Mines = 6,
  Crash = 7,
  Keno = 8,
  Baccarat = 9,
  SlotsThree = 10,
  Wheel = 11,
  Hurdles = 12,
  BinaryOption = 101,
}

export enum GameInstanceTrigger {
  User = 0,
  Time = 1,
  Slot = 2,
}

export enum GameTokenStatus {
  Inactive = 0,
  Active = 1,
}

export enum GameStatus {
  Uninitialized = 0,
  Configured = 1,
  Active = 2,
  NoNewBets = 3,
  Inactive = 4,
}

export enum GameInstanceStatus {
  Uninitialized = 0,
  Active = 1,
  ResultProvided = 2,
  Settled = 3,
  ErrorConfirmed = 4,
  Refunded = 5,
}

export enum BetOutcome {
  Open = 0,
  Won = 1,
  Lost = 2,
  Push = 3,
  Canceled = 4,
}

// ------- RANDOMNESS ENUMS ------

export enum RandomnessDispatcherStatus {
  Inactive = 0,
  Active = 1,
  NoNewRequests = 2,
}

export enum RandomnessAuthorityStatus {
  Inactive = 0,
  Active = 1,
}

export enum RandomnessCallerStatus {
  Inactive = 0,
  Active = 1,
}

export enum RandomnessProviderStatus {
  Inactive = 0,
  Active = 1,
  NoNewRequests = 2,
}

export enum RequestStatus {
  Uninitialized = 0,
  Awaiting = 1,
  Complete = 2,
  Error = 3,
}

export enum PlatformStatus {
  Inactive = 0,
  Active = 1,
  NoNewPlayers = 2,
  NoNewPlayersOrBets = 3,
}

export enum PlatformTokenStatus {
  NoNewActivity = 0,
  Active = 1,
}

export enum GameResultStatus {
  OPEN = "open",
  WON = "won",
  LOST = "lost",
  PUSH = "push",
  CANCELLED = "cancelled",
}

export enum RewardType {
  Undefined = 0,
  Referral = 1,
  Rakeback = 2,
  DailyBonus = 3,
  WeeklyBonus = 4,
  MonthlyBonus = 5,
  LevelUpBonus = 6,
}
