import React from "react";

import Button from "../../components/common/button/Button";
import { TitleAndSubTitle } from "./common/TitleAndSubtitle";
import { AutoImageCarousel } from "../../components/common/carousel/AutoImageCarousel";
import { Loading } from "../Loading";
import { defaultNetwork, NetworkType } from "../../utils/chain/network";
import { useSolAirdrop } from "../../hooks/useSolAirdrop";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

interface ISolExplainedProps {
  incrementStep: Function;
  handleClose: Function;
};

export const SolExplained: React.FC<ISolExplainedProps> = ({
  incrementStep,
  handleClose,
}) => {
  const { isSolAirdropLoading, handleAirdropSol } = useSolAirdrop(handleClose);

  if (isSolAirdropLoading) {
    return <Loading title="Loading" message="Please wait, airdropping sol..." />;
  }

  return (
    <div className="w-full flex flex-col gap-y-5 -mt-1">
      <AutoImageCarousel
        className={`
        w-full h-[200px] md:h-[380px] pb-6
        [&>.swiper-pagination]:absolute [&>.swiper-pagination]:-bottom-1
        [&>.swiper-pagination>.swiper-pagination-bullet]:bg-gray-100
        [&>.swiper-pagination>.swiper-pagination-bullet]:mx-2
        [&>.swiper-pagination>.swiper-pagination-bullet]:w-2
        [&>.swiper-pagination>.swiper-pagination-bullet]:h-2
        [&>.swiper-pagination>.swiper-pagination-bullet-active]:bg-gray-50
      `}
        animationSpeed={10}
        slideVisibilityDuration={3000}
        pagination={true}
        traits={[
          { image: getS3StaticFolderUrl("/static/sol-explained-banners/send-sol.png") },
          { image: getS3StaticFolderUrl("/static/sol-explained-banners/sol-gas.png") },
          { image: getS3StaticFolderUrl("/static/sol-explained-banners/sol-gas-amount.png") },
        ]}
      />

      <div className="flex text-center -mt-5">
        <TitleAndSubTitle
          title="You need Solana (SOL) to play"
          subtitle={`Zeebit is fully decentralised and powered by the Solana blockchain. All actions including bets are processed by the network and incur a small ‘gas’ cost in SOL, Solana’s native token. Without SOL in connected wallet you wont be able to play games, transfer funds and more.`}
          className="text-gray-50"
        />
      </div>

      <div className="flex items-start gap-x-3 self-stretch mt-3">
        <Button className="flex-1 bg-gray-500" variant="gray" size="md" onClick={handleClose}>
          Close
        </Button>
        {
          defaultNetwork == NetworkType.DEVNET
          && <Button className="flex-1" variant="primary" size="md" onClick={handleAirdropSol}>
            Airdrop SOL
          </Button>
        }
        <Button className="flex-1" variant="secondary" size="md" onClick={incrementStep}>
          Fund Wallet
        </Button>
      </div>
    </div>
  );
}
