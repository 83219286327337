import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

export default class LutCloseList {

    private _program: anchor.Program;
    private _pubkey: PublicKey;
    private _state: any;
    private _eventParser: anchor.EventParser;

    constructor( 
        program: anchor.Program,
        pubkey: PublicKey,
    ) {
        this._program = program; 
        this._eventParser = new anchor.EventParser(
            this.program.programId,
            new anchor.BorshCoder(this.program.idl)
        );
        this._pubkey = pubkey;   
    };

    static async load(
        program: anchor.Program,
        pubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const gameSpec = new LutCloseList(
            program,
            pubkey,
        )
        await gameSpec.loadState(commitmentLevel);
        return gameSpec
    };

    async loadState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.program.account.lutCloseList.fetchNullable(
            this._pubkey,
            commitmentLevel
        );
        if (state) {
            this._state = state;
            
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        return
    }

    get program() {
        return this._program
    }

    get publicKey() {
        return this._pubkey
    }

    get state() {
        return this._state
    }

    static deriveGlobalSignerPubkey(
        programId: PublicKey
    ) {
        const [globalSignerPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("global_signer"),
            ],
            programId
        );
        return globalSignerPubkey
    }

    static deriveLutCloseListPubkey(
        lutAuthorityPubkey: PublicKey,
        programId: PublicKey,
    ) {
        var seeds = [
            anchor.utils.bytes.utf8.encode("lut_close_list"),
            lutAuthorityPubkey.toBuffer(),
        ]
        const [pk, _] = PublicKey.findProgramAddressSync(
            seeds,
            programId
        );
        return pk
    }

    static deriveMainClostListPubkey(
        programId: PublicKey,
    ) {
        const globalSignerPubkey = LutCloseList.deriveGlobalSignerPubkey(programId);
        return LutCloseList.deriveLutCloseListPubkey(globalSignerPubkey, programId);
    }
}
