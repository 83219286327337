import React from "react";

interface IRankingModalsStepper {
  steps: number;
  currentStep: number;
  className?: string;
}

export const RewardsModalsStepper: React.FC<IRankingModalsStepper> = ({
 steps, currentStep, className = ""
}) => {
  const stepsArray = new Array(steps).fill("");
  const stepsBackgrounds = [
    "linear-gradient(90deg, #9032F5 0%, #FF65E6 300%)",
    "linear-gradient(90deg, #9032F5 -100%, #FF65E6 200%)",
    "linear-gradient(90deg, #A550FF -200%, #FC78FF 200%)",
    "linear-gradient(90deg, #A550FF -300%, #FC78FF 100%)",
  ];

  return (
    <div
      data-id="modal-stepper"
      className={`
        flex w-full gap-x-2 h-1.5
        ${className}
      `}
    >
      {
        stepsArray.map((_, index) => (
          <div
            key={index}
            className={`
              flex w-full h-full rounded
              ${index <= currentStep ? "" : "bg-gray-900"}
            `}
            style={{
              background: index <= currentStep ? stepsBackgrounds[index] : "",
            }}
          />
        ))
      }
    </div>
  );
};

export default RewardsModalsStepper;
