import React, { useContext, useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { BrowserPreferenceContext } from "../contexts/BrowserPreferenceContext";
import { SideBar } from "../components/left-nav/SideBar";
import { TopNav } from "../components/top-nav/TopNav";
import { RightNav } from "../components/right-nav/RightNav";
import { BottomNav } from "../components/bottom-nav/BottomNav";
import { NavContext } from "../contexts/NavContext";
import { SCROLLBAR_CLASSES_BLACK } from "../styles/commonClasses";
import { useOnboardingHandler } from "../hooks/useOnboardingHandler";
import { Footer } from "../components/footer/footer";
import { Search } from "../components/search/Search";
import { SearchBox } from "../components/search/SearchBox";
import { BannerContext } from "../contexts/BannerContext";
import { Banner } from "../components/banners/Banner";
import { useModal } from "react-modal-hook";
import { GeoblockModal } from "../modals/GeoblockModal";
import { ErrorHandlingContext } from "../contexts/ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { useRankMeta } from "../hooks/useRankMeta";
import LevelUpModal from "../components/modals/LevelUpModal";
import Referral from "../sdk/referral";
import { PlatformContext } from "../contexts/PlatformContext";
import { PlayerContext } from "../contexts/PlayerContext";
import { ProgramContext } from "../contexts/ProgramContext";
import { sendReferralLink, sendUtmLink } from "../utils/analytics/googleAnalytics";

export const MainContainer = () => {
  const { theme} = useContext(BrowserPreferenceContext);
  const { rightPanelOpen, leftPanelOpen, searchOpen, searchToggle } = useContext(NavContext);
  const { playerAccount, playerMeta, setPlayerMeta, isLevelUpModalClosed } =
    useContext(PlayerContext);
  const { warnings } = useContext(BannerContext);
  const { locationValidation } = useContext(ErrorHandlingContext);
  const { platform } = useContext(PlatformContext);
  const { meta } = useContext(ProgramContext);

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchParams] = useSearchParams();
  const { rankMeta } = useRankMeta();
  const [showLevelUpModal, hideLevelUpModal] = useModal(
    ({ in: open }): JSX.Element => <LevelUpModal visible={open} hideModal={hideLevelUpModal} />,
    [],
  );

  // CHECK IF REFERRED - USED WHEN INITING PLAYER ACC
  useEffect(() => {
    const referralId = searchParams.get("referral_id");

    if (referralId == null || platform == null || meta?.cashierProgram == null) {
      return;
    }

    const referralAccount = Referral.deriveReferralPubkey(
      platform.publicKey,
      referralId,
      meta.cashierProgram.programId,
    );

    const pMeta = playerMeta || {};
    pMeta.referralAccount = referralAccount.toString();
    
    setPlayerMeta(pMeta);

    // SEND TO GA
    sendReferralLink({
      referral_id: referralId,
      referral_account: referralAccount.toString()
    })
  }, [searchParams, platform, meta, playerMeta]);

  // CHECK IF REFERRED BY UTM
  useEffect(() => {
    const utmId = searchParams.get("utm_id");

    if (utmId == null || platform == null || meta?.cashierProgram == null) {
      return;
    }

    // SEND TO GA
    sendUtmLink({
      utm_id: utmId
    })
  }, [searchParams, platform, meta]);

  useOnboardingHandler();

  // CHECK IF GEOBLOCKED
  const [showGeoblockModal, hideGeoblockModal] = useModal(
    ({ in: open }) => (
      <GeoblockModal
        open={open}
        hideModal={() => {
          hideGeoblockModal();
        }}
      />
    ),
    [],
  );

  useEffect(() => {
    if (locationValidation.errorMessageByType.has(ErrorType.IP_RESTRICTED)) {
      showGeoblockModal();
    }
  }, [locationValidation]);

  useEffect(() => {
    if (
      rankMeta?.nextRank.name &&
      playerAccount?.xpAllTime &&
      rankMeta?.nextRank && playerAccount.dueLevelUpAndCollectibles &&
      !isLevelUpModalClosed
    ) {
      showLevelUpModal();
    }
  }, [rankMeta]);

  return (
    <>
      {/* TOP NAV ACCROSS FULL WIDTH */}
      <TopNav />

      <div>
        {/* LEFT SIDEBAR OR EXPANDABLE ON MOBILE */}
        <SideBar />

        {/* TODO - ADD CONDITIONALS FOR OPENING AND CLOSING THE NAVS ON DESKTOP */}
        <div
          className={`
          ${rightPanelOpen ? "md:pr-right-bar-width" : "md:pr-0"}
          ${leftPanelOpen ? "md:pl-left-bar-width" : "md:pl-left-bar-collapsed-width"} 
          transition-all`}
        >
          <main className="relative">
            {/* WARNING BANNERS */}
            {warnings?.map((warning, index) => <Banner key={index}>{warning.message}</Banner>)}

            {/* SEARCH */}
            {searchOpen ? (
              <div
                className={`absolute z-50 hidden w-full bg-search-bg sm:block ${
                  searchOpen ? "h-full p-4 pr-3 sm:p-6 lg:px-6 lg:py-5" : "h-0"
                } transition-all`}
              >
                {/* WRAPPER USED TO PICK UP CLICKS OUTSIDE TO CLOSE */}
                <SearchBox
                  show={searchOpen}
                  onClickOutside={() => {
                    searchToggle(false);
                  }}
                >
                  <Search
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    hidden={false}
                    setExpanded={() => {}}
                    expanded={true}
                    onClose={() => {
                      searchToggle(false);
                    }}
                  />
                </SearchBox>
              </div>
            ) : (
              ""
            )}

            <div
              className={`
                scroll-fix max-h-screen-minus-both-bars overflow-y-scroll bg-global-bg p-4 pr-3 sm:p-6 md:h-screen-minus-bar md:max-h-screen-minus-bar lg:px-6 lg:py-5
                ${SCROLLBAR_CLASSES_BLACK}
              `}
            >
              <Outlet />

              <Footer />
              {warnings?.map(() => (
                <div data-id="banners-height-compensator" className="h-12"></div>
              ))}
            </div>
          </main>
        </div>

        {/* RIGHT SIDE BAR */}
        <RightNav />

        <BottomNav />
      </div>

      <ToastContainer
        className="max-sm:mx-3 max-sm:mb-20 "
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      />
    </>
  );
};
