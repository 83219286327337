import React, { useState, useContext, useCallback, useEffect } from "react";

import Button from "../../components/common/button/Button";
import { Term } from "./common/Term";
import { TitleAndSubTitle } from "./common/TitleAndSubtitle";
import Input from "../../components/common/input/Input";
import { useUserMeta } from "../../hooks/useUserMeta";
import { PlayerContext } from "../../contexts/PlayerContext";
import FormItem from "../../components/common/form-item/FormItem";
import Player from "../../sdk/playerAccount";
import { isValidEmail } from "../../utils/string/string";
import { NetworkContext } from "../../contexts/NetworkContext";
import { DataCachingContext } from "../../contexts/DataCachingContext";
import { CasinoSwitch, CasinoSwitchSize } from "../../components/common/switch/switch";

interface ICustomiseProfileProps {
  incrementStep: Function;
  onClose: Function;
  hasSolTokens: boolean;
}

export const CustomizeProfileStep: React.FC<ICustomiseProfileProps> = ({
  incrementStep, onClose, hasSolTokens
}) => {
  const { playerMeta, setPlayerMeta } = useContext(PlayerContext);

  const [emailError, setEmailError] = useState<string>();
  const setEmail = useCallback(
    (email: string) => {
      // VALIDATE THE EMAIL
      if (isValidEmail(email)) {
        setEmailError(undefined);

        let newPlayerMeta = playerMeta || {};
        newPlayerMeta.email = email;

        setPlayerMeta(newPlayerMeta);
      } else {
        setEmailError("Please enter a valid email");
      }
    },
    [playerMeta],
  );

  const { playerAccount } = useContext(PlayerContext);
  const { client } = useContext(NetworkContext);

  const [usernameError, setUsernameError] = useState();
  const setUsername = useCallback(
    async (username: string) => {
      // VALIDATE THE USERNAME
      if (username == null || username.length == 0) {
        setUsernameError(undefined)

        if (playerMeta != null && playerMeta.username != null && playerMeta.username.length > 0) {
          let newPlayerMeta = playerMeta || {};
          newPlayerMeta.username = username;

          setPlayerMeta(newPlayerMeta);
        }

        return
      }

      try {
        const isValid = Player.validate_username(username);
        setUsernameError(undefined);
      } catch (err) {
        setUsernameError(err.message);
        return;
      }

      let newPlayerMeta = playerMeta || {};
      newPlayerMeta.username = username;

      setPlayerMeta(newPlayerMeta);
    },
    [playerMeta, playerAccount, client],
  );

  const handleSkip = useCallback(() => {
    // FLUSH THE INPUTS AND INCREMENT THE STEP
    let newPlayerMeta = playerMeta || {};
    newPlayerMeta.email = undefined;
    newPlayerMeta.username = undefined;

    incrementStep();
  }, [playerMeta, incrementStep]);

  const { updateCachedData } = useContext(DataCachingContext)

  const handleContinue = useCallback(async () => {
    // FLUSH THE INPUTS AND INCREMENT THE STEP
    let newPlayerMeta = playerMeta || {};
    const username = newPlayerMeta.username;

    if (username != null && username.length > 0) {
      const codePubkey = playerAccount?.deriveUsernameAccountPubkey(username);
      const account = await client?.getAccountInfo(codePubkey);
      if (account != null) {
        setUsernameError("Username already in use, please try another.");
        return;
      }
    }

    if (newPlayerMeta != null && newPlayerMeta.email != null && newPlayerMeta.email.length > 0) {
      try {
        const updatedData = await updateCachedData({
          emailAddress: newPlayerMeta.email,
          username: newPlayerMeta.username,
          acceptPlatformNotifications: newPlayerMeta.accountRelatedEmails,
          acceptProjectUpdateNotifications: newPlayerMeta.promotionalEmails
        })
      } catch (err) {
        console.error("Issue updating the cached data", err)
      }
    }

    incrementStep();
  }, [playerMeta, incrementStep, client, playerAccount, updateCachedData]);

  const [usernameVisible, setUsernameVisible] = useState(false);
  const [platformUpdates, setPlatformUpdates] = useState(false);
  const [marketingUpdates, setMarketingUpdates] = useState(false);
  const { avatar, playerAccountPublicKey } = useUserMeta();

  useEffect(() => {
    const meta = playerMeta || {};
    meta.accountRelatedEmails = platformUpdates;
    meta.promotionalEmails = marketingUpdates;
    meta.showUsername = usernameVisible;

    setPlayerMeta(meta);
  }, [usernameVisible, platformUpdates, marketingUpdates]);

  return (
    <>
      <TitleAndSubTitle
        title="Customise your profile"
        subtitle="Personalise your appearance and notifications settings"
        className="text-gray-50"
      />

      <div className="flex flex-col items-start gap-y-4 self-stretch mt-4">
        <div className="flex flex-col items-start gap-y-1 self-stretch relative">
          <div className="flex justify-between items-end self-stretch absolute right-2 top-1.5 text-gray-200">
            <div className="flex px-2 py-1.5 justify-center items-center rounded-sm bg-gray-700 text-xs font-normal">
              Optional
            </div>
          </div>
          <div className="flex items-start self-stretch">
            <FormItem error={usernameError} className="flex-1 [&>div>label]:border-none">
              <Input
                error={usernameError}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                name="username"
                type="string"
                placeholder="Username"
                variant="regular"
                classes={{
                  label: "font-normal mb-2",
                  wrapper: "flex-1",
                }}
              />
            </FormItem>
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-1 self-stretch relative">
          <div className="flex justify-between items-end items-end self-stretch absolute right-2 top-1.5 text-gray-200">
            <div className="flex px-2 py-1.5 justify-center items-center rounded-sm bg-gray-700 text-xs font-normal">
              Optional
            </div>
          </div>
          <div className="flex items-start self-stretch">
            <FormItem error={emailError} className="flex-1 [&>div>label]:border-none">
              <Input
                error={emailError}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                name="email"
                type="email"
                placeholder="Email"
                variant="regular"
                classes={{
                  label: "font-normal mb-2",
                  wrapper: "flex-1",
                }}
              />
            </FormItem>
          </div>
        </div>

        <div className="flex flex-col items-start gap-y-2 flex-1 self-stretch">
          <div className="flex items-center gap-3 self-stretch text-sm">
            <div className="flex flex-col items-start flex-1">
              <div className="text-md font-semibold">Show Username</div>
              <div className="font-normal text-gray-300">Other players will see your username</div>
            </div>
            <CasinoSwitch
              label="Private Profile"
              checked={usernameVisible}
              onChange={setUsernameVisible}
              disabled={false}
              size={CasinoSwitchSize.LARGE}
            />
          </div>
        </div>
        <div
          className={`
            flex flex-col items-start gap-y-2 self-stretch mt-5
            [&>div]:rounded-md [&>div]:bg-gray-600 [&>div]:p-3 [&>div>div]:self-start
            [&>div]:text-gray-50
          `}
        >
          <Term
            text={"I would like to receive platform notifications and updates about my account."}
            setAccepted={setPlatformUpdates}
            accepted={platformUpdates}
          />
          <Term
            text={"I would like to receive promotional emails, bonuses and updates. "}
            setAccepted={setMarketingUpdates}
            accepted={marketingUpdates}
          />
        </div>
      </div>
      <div className="flex items-start gap-x-3 self-stretch">
        <Button
          className="flex-1 bg-gray-500"
          variant="gray"
          size="md"
          onClick={hasSolTokens ? onClose : handleSkip}
        >
          Skip
        </Button>
        {
          hasSolTokens
            ? (
              <Button
                disabled={usernameError != null || emailError != null}
                className="flex-1"
                variant="secondary"
                size="md"
                onClick={onClose}
              >
                Lets Play
              </Button>
            )
            : (
              <Button
                disabled={usernameError != null || emailError != null}
                className="flex-1 disabled:bg-gray-600 disabled:text-gray-300"
                variant="secondary"
                size="md"
                onClick={handleContinue}
              >
                Continue
              </Button>
            )
        }

      </div>
    </>
  );
};
