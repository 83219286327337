import * as anchor from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { sha256 } from "js-sha256";
import * as base58 from "bs58";
import { PublicKey } from "@solana/web3.js";

import Player from "./playerAccount";
import Platform from "./platform";

export enum ReferralStatus {
  ACTIVE = "active",
  NOT_ACTIVE = "not active",
}

export default class Referral {
  private _player: Player | undefined;
  private _referralPubkey: PublicKey;
  private _state: any;
  private _stateLoaded: boolean;

  constructor(player: Player | undefined, referralPubkey: PublicKey) {
    this._stateLoaded = false;
    this._player = player;
    this._referralPubkey = referralPubkey;
  }

  static async load(
    player: Player | undefined,
    referralPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const referral = new Referral(player, referralPubkey);
    await referral.loadState(commitmentLevel);
    return referral;
  }

  static async loadWithoutPlayer(
    referralPubkey: PublicKey,
    platform: Platform,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    // LOAD STATE
    const state = await Referral.loadStateWithoutPlayer(platform, referralPubkey)

    // LOAD PLAYER
    const player = await Player.load(platform, state?.owner as PublicKey, commitmentLevel)
    
    // INIT REFERRAL
    const referral = new Referral(player, referralPubkey);
    referral._state = state
    referral._stateLoaded = true

    return referral;
  }

  static loadFromBuffer(player: Player, referralPubkey: PublicKey, accountBuffer: Buffer) {
    const referral = new Referral(player, referralPubkey);
    referral._state = player.program.coder.accounts.decode("Referral", accountBuffer);
    referral._stateLoaded = true;
    return referral;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this.program.account.referral.fetchNullable(
      this.publicKey,
      commitmentLevel,
    );
    this._state = state;
    this._stateLoaded = true;
    return;
  }

  static async loadStateWithoutPlayer(platform: Platform, pubkey: PublicKey, commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await platform.program.account.referral.fetchNullable(
      pubkey,
      commitmentLevel,
    );
    return state;
  }

  get player() {
    return this._player;
  }

  get ownerPubkey() {
    return this.player?.ownerPubkey;
  }

  get platform() {
    return this.player?.platform;
  }

  get house() {
    return this.player?.platform.house;
  }

  get program() {
    return this.player?.platform.house.program;
  }

  get publicKey() {
    return this._referralPubkey;
  }

  get state() {
    return this._state;
  }

  get identifier() {
    return anchor.utils.bytes.utf8
      .decode(Buffer.from(this._state.identifier))
      .replaceAll("\x00", "");
  }

  get listTokenMints(): PublicKey[] {
    return this._state
      ? this._state.tokens.map((tkn) =>
          this.house?.getTokenPubkeyFromHouseTokenIdx(tkn.houseTokenIdx),
        )
      : null;
  }

  get listTokens(): [] {
    return this._state
      ? this._state.tokens.map(
          (tkn) => (tkn.pubkey = this.house?.getTokenPubkeyFromHouseTokenIdx(tkn.houseTokenIdx)),
        )
      : [];
  }

  get bespokeTerms(): boolean {
    return this._state.bespokeTerms;
  }

  get created(): Date {
    return new Date(this._state.created.toNumber() * 1000);
  }

  get daySpreadForClaimRemainder(): number {
    return this.state.daySpreadForClaimRemainder;
  }

  get defaultReferralRatePerThousand(): number {
    return this._state.defaultReferralRatePerThousand;
  }

  get percentage(): number {
    return this.defaultReferralRatePerThousand / 1000;
  }

  get enhancedAccrualExpiryDate(): Date {
    return new Date(this._state.enhancedAccrualExpiryDate.toNumber() * 1000);
  }

  get enhancedReferralRatePerThousand(): number {
    return this._state.enhancedReferralRatePerThousand;
  }

  get lastActivity(): Date {
    return new Date(this._state.lastActivity.toNumber() * 1000);
  }

  get minReferredPlayersToCollect(): number {
    return this._state.minReferredPlayersToCollect.toNumber();
  }

  get minReferredWagerValueToCollect(): number {
    return this._state.minReferredWagerValueToCollect.toNumber();
  }

  get referralRewardAccruedBase(): number {
    return this._state.referralRewardAccruedBase.toNumber();
  }

  get referralRewardDrawndownBase(): number {
    return this._state.referralRewardDrawndownBase.toNumber();
  }

  get referredPlayerBetCount(): number {
    return this._state.referredPlayerBetCount.toNumber();
  }

  get referredPlayerCount(): number {
    return this._state.referredPlayerCount.toNumber();
  }

  get referredPlayerWageredBase(): number {
    return this._state.referredPlayerWageredBase.toNumber();
  }

  get status(): ReferralStatus {
    const stateStatus = this._state.status;

    if ("active" in stateStatus) {
      return ReferralStatus.ACTIVE;
    }

    return ReferralStatus.NOT_ACTIVE;
  }

  get statusString() {
    return Object.keys(this.state.status)[0]
  }

  get upFrontClaimPerThousand(): number {
    return this._state.upFrontClaimPerThousand;
  }

  get accrualExpiryDate(): Date | null {
    try {
      return new Date(this.state.accrualExpiryDate.toNumber() * 1000);
    } catch (err) {
      return null;
    }
  }

  static serializeIdentifierToBytes20(identifier: string): Buffer {
    if (identifier.length > 20) {
      throw Error(
        `Identifier cannot be >20 characters in length: ${identifier} (${identifier.length} characters)`,
      );
    }
    return Buffer.concat([anchor.utils.bytes.utf8.encode(identifier)], 20);
  }

  static deriveReferralPubkey(
    platformPubkey: PublicKey,
    identifier: string,
    programId: PublicKey,
  ): PublicKey {
    const identifierBytes20 = Referral.serializeIdentifierToBytes20(identifier);
    const [referralPubkey, _] = PublicKey.findProgramAddressSync(
      [anchor.utils.bytes.utf8.encode("referral"), platformPubkey.toBuffer(), identifierBytes20],
      programId,
    );
    return referralPubkey;
  }

  static deriveReferralAccountDiscriminator() {
    return Buffer.from(sha256.digest("account:Referral")).subarray(0, 8);
  }

  static async fetchAllReferralAccountsForPlayer(player: Player): Promise<Referral[]> {
    const referralAccountPubkeysAndBuffers =
      await player.program.provider.connection.getProgramAccounts(player.program.programId, {
        filters: [
          {
            memcmp: {
              offset: 0, // Anchor account discriminator for RequestAccount type
              bytes: base58.encode(Referral.deriveReferralAccountDiscriminator()),
            },
          },
          {
            memcmp: {
              offset: 8, // 8 (discriminator)
              bytes: base58.encode(player.ownerPubkey.toBuffer()),
            },
          },
          {
            memcmp: {
              offset: 40, // 8 (discriminator) + 32 (owner)
              bytes: base58.encode(player.platform.publicKey.toBuffer()),
            },
          },
        ],
      });
    const referrals = referralAccountPubkeysAndBuffers.map((referralAccountPubkeysAndBuffer) =>
      Referral.loadFromBuffer(
        player,
        referralAccountPubkeysAndBuffer.pubkey,
        referralAccountPubkeysAndBuffer.account.data,
      ),
    );
    return referrals;
  }

  async fetchAllReferredPlayers(): Promise<Player[]> {
    // i.e. get all of the players who have been referred by this scheme
    const referredPlayerPubkeyAndBuffers =
      await this.program.provider.connection.getProgramAccounts(this.program.programId, {
        filters: [
          {
            memcmp: {
              offset: 0, // Anchor account discriminator for Player type
              bytes: base58.encode(Player.derivePlayerAccountDiscriminator()),
            },
          },
          {
            memcmp: {
              offset: 72, // 8 (discriminator) + 32 (owner) + 32 (house)
              bytes: base58.encode(this.platform.publicKey.toBuffer()),
            },
          },
          {
            memcmp: {
              offset: 104, // 8 (discriminator) + 32 (owner) + 32 (house) + 32 (platform)
              bytes: base58.encode(this.publicKey.toBuffer()),
            },
          },
        ],
      });
    const players = referredPlayerPubkeyAndBuffers.map((referredPlayerPubkeyAndBuffer) =>
      Player.loadFromBuffer(
        this.player.platform,
        referredPlayerPubkeyAndBuffer.pubkey,
        referredPlayerPubkeyAndBuffer.account.data,
      ),
    );
    return players;
  }

  static async fetchAllReferredPlayers(
    program: anchor.Program,
    platform: Platform,
    referralPubkey: PublicKey,
  ): Promise<Player[]> {
    // i.e. get all of the players who have been referred by this scheme
    const referredPlayerPubkeyAndBuffers = await program.provider.connection.getProgramAccounts(
      program.programId,
      {
        filters: [
          {
            memcmp: {
              offset: 0, // Anchor account discriminator for Player type
              bytes: base58.encode(Player.derivePlayerAccountDiscriminator()),
            },
          },
          {
            memcmp: {
              offset: 72, // 8 (discriminator) + 32 (owner) + 32 (house)
              bytes: base58.encode(platform.publicKey.toBuffer()),
            },
          },
          {
            memcmp: {
              offset: 104, // 8 (discriminator) + 32 (owner) + 32 (house) + 32 (platform)
              bytes: base58.encode(referralPubkey.toBuffer()),
            },
          },
        ],
      },
    );
    const players = referredPlayerPubkeyAndBuffers.map((referredPlayerPubkeyAndBuffer) =>
      Player.loadFromBuffer(
        platform,
        referredPlayerPubkeyAndBuffer.pubkey,
        referredPlayerPubkeyAndBuffer.account.data,
      ),
    );
    return players;
  }

  static async initialiszeReferralIx(player: Player, identifier: string) {
    const referralPubkey = Referral.deriveReferralPubkey(
      player.platform.publicKey,
      identifier,
      player.program.programId,
    );

    const identifierBytes = Referral.serializeIdentifierToBytes20(identifier);

    return await player.program.methods
      .referralInitialize({
        identifierBytes: identifierBytes,
      })
      .accounts({
        house: player.house.publicKey,
        platform: player.platform.publicKey,
        platformPayer: player.platform.derivePlatformPayerPubkey(),
        owner: player.ownerPubkey,
        player: player.publicKey,
        referral: referralPubkey,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
  }

  async claimIx() {
    const rewardTokenPubkey = this.platform.rewardTokenPubkey;
    if (rewardTokenPubkey == null) {
      throw new Error("The platform does not have a reward token configured");
    }
    const rewardHouseTokenPubkey = this.house.deriveHouseTokenAccountPubkey(rewardTokenPubkey);
    const oraclePubkey = this.house.getTokenConfigAndStatistics(rewardTokenPubkey)?.oracle;
    const rewardCalendarPubkey = this.player.deriveRewardCalendarPubkey(rewardTokenPubkey);

    return await this.program.methods
      .referralClaim({})
      .accounts({
        owner: this.ownerPubkey,
        house: this.house.publicKey,
        houseToken: rewardHouseTokenPubkey,
        platform: this.player.platform.publicKey,
        platformPayer: this.player.platform.derivePlatformPayerPubkey(),
        player: this.player.publicKey,
        referral: this.publicKey,
        rewardCalendar: rewardCalendarPubkey,
        tokenMint: rewardTokenPubkey,
        tokenAccountOrWallet: await this.player.deriveTokenAccountPubkey(rewardTokenPubkey),
        vault: await this.house.deriveHouseTokenVault(rewardTokenPubkey),
        oracle: oraclePubkey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
  }

  // IX TO CLOSE REFERRAL SCHEME
  async closeIx() {
    return await this.program.methods
      .closeReferral({})
      .accounts({
        platform: this.player.platform.publicKey,
        platformPayer: this.player.platform.derivePlatformPayerPubkey(),
        referral: this.publicKey,
        systemProgram: anchor.web3.SystemProgram.programId,
      })
      .instruction();
  }

  static validateIdentifier(identifier: string): boolean {
    const acceptableBytes = [];
    const identifierBytes = anchor.utils.bytes.utf8.encode(identifier);
    if (identifierBytes.length < 8) {
      throw new Error("Identifier too short. Must be >8 characters");
    }
    if (identifier.length > 20) {
      throw new Error("Identifier too long. Must be <20 characters");
    }
    identifierBytes.forEach((c) => {
      if (!((c >= 48 && c <= 57) || (c >= 65 && c <= 90) || (c >= 97 && c <= 122) || c == 95)) {
        throw new Error("Invalid character only a-z, A-Z, 0-9, and _ values allowed");
      }
    });
    return true;
  }
}
