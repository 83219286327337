import React, { useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import { useLocation, useNavigate } from "react-router-dom";

import { useUserMeta } from "../../../hooks/useUserMeta";
import Icon from "../icon/Icon";
import PentagonIcon from "../../../assets/icons/pentagon.svg";
import { useRankMeta } from "../../../hooks/useRankMeta";
import { NumberType, formatZeebitNumber } from "../../../utils/currency/formatting";
import { useUserOverlayModal } from "../../../hooks/modals/useUserOverlayModal";
import UserRankModal from "../../modals/UserRankModal";
import Button from "../button/Button";
import LevelUpModal from "../../modals/LevelUpModal";
import { PlayerContext } from "../../../contexts/PlayerContext";
import { RankIcon } from "../../rewards/RankIcon";
import { RunningNumbersAnimation } from "../running-numbers-animation";
import { CurrentPlayerAvatar } from "../player-avatar";
import { WrappedWalletContext } from "../../../contexts/WrappedWalletContext";

export const UserProfileWidget = () => {
  const { userName, playerAccountPublicKey } = useUserMeta();
  const { isLevelUpModalClosed, changeIsLevelUpModalState, playerAccount } =
    useContext(PlayerContext);

  const { rank, rankMeta } = useRankMeta();
  const { getOpenUserOverlayModalButton } = useUserOverlayModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedReward, setSelectedReward] = useState<string | undefined>(undefined);
  const [selectedRankModalTab, setSelectedRankModalTab] = useState();

  useEffect(() => {
    const selectedRewardFromState = location.state?.selectedReward;
    const selectedRankModalTabFromState = location.state?.selectedRankModalTab;
    const isUserRankModalOpen = location.state?.isUserRankModalOpen;

    if (selectedRewardFromState || isUserRankModalOpen) {
      if (selectedRankModalTabFromState) {
        setSelectedRankModalTab(selectedRankModalTabFromState);
      } else {
        setSelectedReward(selectedRewardFromState);
      }

      showUserRankModal();
      navigate(
        window.location.pathname,
        {
          state: {
            ...location.state,
            selectedReward: undefined,
            selectedRankModalTab: undefined,
            isUserRankModalOpen: undefined
          }
        }
      );
    }

    return () => {
      setSelectedRankModalTab(undefined);
    }
  }, [location]);

  const [showUserRankModal, hideUserRankModal] = useModal(
    ({ in: open }): JSX.Element => <UserRankModal
      selectedReward={selectedReward}
      selectedRankModalTab={selectedRankModalTab}
      setSelectedReward={setSelectedReward}
      visible={open}
      hideModal={() => {
        hideUserRankModal();
        setSelectedReward(undefined);
      }}
      navigate={navigate}
    />,
    [selectedReward, selectedRankModalTab],
  );
  const [showLevelUpModal, hideLevelUpModal] = useModal(
    ({ in: open }): JSX.Element => <LevelUpModal visible={open} hideModal={hideLevelUpModal} />,
    [],
  );
  const { walletPubkey } = useContext(WrappedWalletContext);

  return (
    <button onClick={showUserRankModal}>
      <div data-id="user-profile-widget" className="flex flex-col gap-2 rounded-lg bg-gray-700 p-3">
        <div className="flex justify-between text-xs">
          {getOpenUserOverlayModalButton(
            playerAccountPublicKey,
            <div className="flex items-center gap-2">
              <div className="overflow-hidden rounded-sm ">
                <CurrentPlayerAvatar
                  playerAvatarKey={playerAccount?.avatar}
                  playerPublicKey={playerAccount?.ownerPubkey}
                  height={16}
                  width={16}
                />
              </div>
              <div>{userName}</div>
            </div>,
            undefined,
            walletPubkey,
          )}
          <div className="flex items-center gap-1">
            <RankIcon
              rankName={rankMeta?.currentRank?.name}
              size="md"
              defaultIcon={
                <Icon
                  className="mb-[3px]"
                  size="xs"
                  inheritFill
                  inheritStroke={false}
                  icon={<PentagonIcon />}
                />
              }
            />
            {rankMeta?.currentRank.name}
          </div>
        </div>
        {
          !!rank?.xpAllTime
          && (
            <div
              key={rank?.xpAllTime}
              className="flex h-1.5 w-full overflow-hidden rounded-full bg-gray-900 relative"
            >
              <div
                className="h-full rounded-full bg-brand-mixed-gradient"
                style={{ width: `${rankMeta?.percentage}%` }}
              />
              <div
                className="flex h-full rounded-full bg-brand-mixed-gradient shimmer-animation absolute left-0"
                style={{ width: `100%`, opacity: 0 }}
              />
            </div>
          )
        }

        <div className="flex justify-between text-xs font-normal text-gray-300">
          <div>
            <RunningNumbersAnimation
              key={rank?.xpAllTime}
              stringToAnimate={formatZeebitNumber(rank?.xpAllTime, NumberType.XP_AMOUNT)}
            />
            {` / ${formatZeebitNumber(rankMeta?.nextRank.threshold || 0, NumberType.XP_AMOUNT)} XP`}
          </div>

          <div className="flex items-center gap-1 ">
            <RankIcon
              rankName={rankMeta?.nextRank?.name}
              size="md"
              defaultIcon={
                <Icon
                  className="mb-[3px]"
                  inheritFill
                  inheritStroke={false}
                  size="xs"
                  icon={<PentagonIcon />}
                />
              }
            />
            {rankMeta?.nextRank.name}
          </div>
        </div>
        {isLevelUpModalClosed &&
        playerAccount?.dueLevelUpAndCollectibles ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showLevelUpModal();
              changeIsLevelUpModalState(false);
            }}
            variant="regular"
          >
            Level Up
          </Button>
        ) : (
          ""
        )}
      </div>
    </button>
  );
};
