import { ReactNode, useContext, useCallback, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import { PublicKey } from "@solana/web3.js";

import UserOverlayModal from "../../components/modals/UserOverlayModal";
import Player from "../../sdk/playerAccount";
import { PlatformContext } from "../../contexts/PlatformContext";

export const useUserOverlayModal = () => {
  const { platform } = useContext(PlatformContext);
  const [player, setPlayer] = useState<Player>();
  const navigate = useNavigate();

  const [showModal, hideModal] = useModal(
    ({ in: open }): JSX.Element => (
      <UserOverlayModal
        navigate={navigate}
        player={player}
        visible={open}
        hideModal={hideModal}
      />
    ),
    [player],
  );

  const setContentAndShowModal = useCallback(
    async (playerPubKey: PublicKey | undefined, walletPubkey?: PublicKey | null) => {
      if (!platform || !playerPubKey) {
        return;
      }

      const playerData = await Player.loadFromPlayerPubkey(platform, playerPubKey, walletPubkey)

      setPlayer(playerData);
      showModal();
    },
    [showModal, platform],
  );

  const handleOpenUserOverlayModal =
    (playerPubKey: PublicKey | undefined, callBack?: Function, ownerPubkey?: PublicKey | null) =>
    (event: MouseEvent) => {
      event.stopPropagation();
      setContentAndShowModal(playerPubKey, ownerPubkey);
      callBack?.()
    };
  const getOpenUserOverlayModalButton =
    (playerPubKey: PublicKey | undefined, buttonContent: ReactNode, callBack?: Function, ownerPubkey?: PublicKey | null) => (
      <button onClick={handleOpenUserOverlayModal(playerPubKey, callBack, ownerPubkey)}>
        {buttonContent}
      </button>
    );

  return useMemo(() => {
    return {
      setContentAndShowModal,
      getOpenUserOverlayModalButton
    };
  }, [setContentAndShowModal]);
};
