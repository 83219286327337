import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PlayerContext } from "./PlayerContext";
import Player from "../sdk/playerAccount";
import Referral from "../sdk/referral";

export interface IReferralContext {
    referralById: Map<string, Referral> | undefined,
    referralCodes: Referral[] | undefined,
    referredPlayers: Player[] | undefined,
    refreshReferralCodes: () => Promise<void> | undefined
}

export const ReferralContext = createContext<IReferralContext>({} as IReferralContext);

interface Props {
    children: any;
}

export const ReferralProvider = ({ children }: Props) => {
    const { playerAccount } = useContext(PlayerContext)
    const [referredPlayers, setReferredPlayers] = useState<Player[]>();
    const [referralCodes, setReferralCodes] = useState<Referral[]>();

    const refreshReferralCodes = useCallback(async () => {
        const referrals = await playerAccount?.fetchReferralSchemes();

        setReferralCodes(referrals);
    }, [playerAccount]);

    useEffect(() => {
        async function loadReferralCodesForPlayer(player: Player) {
            const referrals = await player.fetchReferralSchemes();

            setReferralCodes(referrals);
        }

        if (playerAccount == null) {
            setReferralCodes([]);
        } else {
            loadReferralCodesForPlayer(playerAccount);
        }
    }, [playerAccount]);

    useEffect(() => {
        async function loadReferredPlayers(referrals: Referral[]) {
            const allPlayers: Player[] = [];
            const allPromises = referrals.map((referral) => {
                return referral.fetchAllReferredPlayers();
            });
            const referredPlayerSchemes = await Promise.all(allPromises);

            referredPlayerSchemes.forEach((referredPlayers: Player[]) => {
                allPlayers.push(...referredPlayers);
            });

            setReferredPlayers(allPlayers);
        }

        if (referralCodes != null) {
            loadReferredPlayers(referralCodes);
        } else {
            setReferredPlayers(undefined);
        }
    }, [referralCodes]);

    const referralById = useMemo(() => {
        if (referralCodes == null) {
            return;
        }

        return referralCodes.reduce((result, item) => {
            if (item != null && item.publicKey != null) {
                result.set(item.publicKey.toString(), item);
            }

            return result;
        }, new Map<string, Referral>());
    }, [referralCodes]);

    return (
        <ReferralContext.Provider
            value={useMemo(
                () => ({
                    referralById: referralById,
                    referralCodes: referralCodes,
                    referredPlayers: referredPlayers,
                    refreshReferralCodes: refreshReferralCodes
                }),
                [referralById, referralCodes, referredPlayers, refreshReferralCodes],
            )}
        >
            {children}
        </ReferralContext.Provider>
    );
};