import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export const GRID_SIZE = 25

export default class Mines extends Game {
    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed",
    ) {
        super(casinoProgram, house, gameSpecPubkey, GameType.Mines, commitmentLevel);
    }

    static async load(casinoProgram: anchor.Program, house: House, gameSpecPubkey: PublicKey) {
        const game = new Mines(casinoProgram, house, gameSpecPubkey);
        await game.loadState();

        return game;
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: object,
        wager: number,
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey,
    ) {
        const instanceRequest = {
            mines: {
                numMines: inputs.numberOfMines
            }
        };

        const betRequest = {
            mines: {
                guessGrid: inputs.guessGrid,
                wager: new anchor.BN(wager)
            }
        };

        const numberOfBets = 1;

        return this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberOfBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }

    get formattedConfig() {
        return {
            edgePercentage: Number(this.gameConfig.mines.edgePerMillion) / Math.pow(10, 6),
            maxMultiplier: Number(this.gameConfig.mines.maxMultiplierPerMillion) / Math.pow(10, 6),
            roundingDenominator: Number(this.gameConfig.mines.roundingDenominator)
        }
    }

    getProbability(inputs: object) {
        const numberOfMines = inputs.numberOfMines
        const numberSelected = inputs.numberSelected


        var numerator = 1.0;
        var denominator = 1.0;
        for (let i=0; i<numberSelected; i++) { 
            numerator = numerator * ( GRID_SIZE - numberOfMines - i);
            denominator = denominator * ( GRID_SIZE - i);
        }
        const probability = numerator / denominator;


        return probability
    }

    getMultiplier(
        inputs: object,
    ) { 
        const edge = this.formattedConfig.edgePercentage 
        const roundingDenominator = this.formattedConfig.roundingDenominator / 1_000_000

        const probability = this.getProbability(inputs);
        const multiplier_raw = (1 - edge) / probability;
        const multiplier = Math.floor(multiplier_raw / roundingDenominator) * roundingDenominator;

        return multiplier
    };

    getBetMetas(bets: object[]): { bets: object[]; wager: number; profit: number; payout: number; edgePercentage: number; } {
        let totalWager = 0
        let totalProfit = 0
        let totalPayout = 0
        let edgeDollar = 0

        bets.forEach((bet) => {
            const multiplier = this.getMultiplier(bet);

            const payoutOnBet = multiplier * bet.wager;

            // SET PAYOUT/PROBABILITY
            bet.payout = payoutOnBet;
            bet.probability = this.getProbability(bet);
            bet.multiplier = multiplier;

            // INCREMENT METRICS
            totalPayout += payoutOnBet;
            totalProfit += payoutOnBet - bet.wager;
            totalWager += bet.wager;
        })
        const factor = Math.pow(10, 6)

        return {
            payout: Math.round(totalPayout * factor) / factor,
            profit: Math.round(totalProfit * factor) / factor,
            wager: Math.round(totalWager * factor) / factor,
            bets: bets,
            edgePercentage: this.formattedConfig.edgePercentage,
        }
    }
}