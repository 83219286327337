const SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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"; 

export const sendNudgeForPlaceBet = async (message: object) => {
  const messageString = JSON.stringify(message);

  const response = await fetch(SLACK_WEBHOOK_URL, {
    mode: "no-cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: messageString,
    }),
  });
};

const STAKING_NUDGE_URL = "https://hooks.slack.com/services/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"

export const sendStakingNudge = async (message: object) => {
  const messageString = JSON.stringify(message);

  const response = await fetch(STAKING_NUDGE_URL, {
    mode: "no-cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: messageString,
    }),
  });
};
