import { Fragment, useContext } from "react";
import { RightNavDesktop } from "./RightNavDesktop";
import { NavContext } from "../../contexts/NavContext";

export function RightNav() {
  const { rightPanelOpen } = useContext(NavContext);

  return (
    <Fragment>
      <RightNavDesktop panelOpen={rightPanelOpen} />
    </Fragment>
  );
}
