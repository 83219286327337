import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export default class Keno extends Game {

    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        super(casinoProgram, house, gameSpecPubkey, GameType.Keno, commitmentLevel);
    }

    static async load(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        const game = new Keno(
            casinoProgram,
            house,
            gameSpecPubkey,
        )
        await game.loadState();
        return game
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: object,
        wager: number,
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey,
    ): Promise<TransactionInstruction> {
        const instanceRequest = {
            keno: {
                riskLevel: inputs.riskLevel,
                numSelected: inputs.chosenNumbers.length,
                numBets: inputs.numberOfBets
            }
        };

        const betRequest = {
            keno: {
                chosenNumbers: Buffer.from(inputs.chosenNumbers.sort((a, b) => (a - b))),
                wager: new anchor.BN(wager)
            }
        };

        const numberOfBets = inputs.numberOfBets;

        return await this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberOfBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }

    getBetMetas(bets: object[]) {
        let totalPayout = 0;
        let totalProfit = 0;
        let totalWager = 0;
        let edgePercentage = 0


        bets.forEach((bet) => {
            const multiplierMeta = this.getMultiplierForNumberSelected(bet?.numSelected || 1)
            const maxMultiplier = !!multiplierMeta?.multipliers ? multiplierMeta?.multipliers?.[multiplierMeta?.multipliers.length - 1]: 0;
            const edge = multiplierMeta?.edge || 0

            const payoutOnBet = maxMultiplier * bet.wager

            // SET PAYOUT/PROBABILITY
            bet.payout = payoutOnBet;
            bet.probability = 0;
            bet.multiplier = maxMultiplier;

            // INCREMENT METRICS
            totalPayout += payoutOnBet;
            totalProfit += payoutOnBet - bet.wager;
            totalWager += bet.wager;
            edgePercentage = edge
        });

        return {
            payout: totalPayout,
            profit: totalProfit,
            wager: totalWager,
            numberOfBets: bets.length,
            bets: bets,
            edgePercentage: edgePercentage,
        };
    }

    get config() {
        return this?.state?.config?.keno
    }

    get maxBets() {
        return this.config?.maxBets
    }

    get maxNumSelected() {
        return this.config?.maxNumSelected
    }

    get multipliers() {
        return this.config?.multipliers?.[0]
    }

    get formattedMultipliers() {
        return this.multipliers?.map((multiplier) => {
            return {
                ...multiplier,
                edge: multiplier.edgePerMillion / Math.pow(10, 6),
                multipliers: multiplier.multipliersPerMillion?.map((multi: number) => {
                    return multi / Math.pow(10, 6)
                })
            }
        })
    }

    getMultiplierForNumberSelected(numberSelected: number) {
        return this.formattedMultipliers?.find((multiplier) => {
            return multiplier.numSelected == numberSelected
        })
    }

    get tableSize() {
        return this.config?.tableSize
    }
}