import React, { Fragment, useContext, useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { SidebarDesktop } from "./SideBarDesktop";
import { SideBarMobile } from "./SideBarMobile";
import { getPlatformGames } from "../../utils/config/utils";
import Nav from "./Nav";
import BarFooter from "./BarFooter";
import NetworkStatus from "./NetworkStatus";
import { NavContext } from "../../contexts/NavContext";
import { RightNavMobile } from "../right-nav/RightNavMobile";
import { HealthContext } from "../../contexts/HealthContext";
import { convertDocEleToReact } from "../../utils/svg/create-svg";
import { UserProfileMobile } from "../profile/UserProfileMobile";
import { SearchMobile } from "../search/SearchMobile";
import { RightNavType } from "../../types/nav";
import IconButton from "../common/button/IconButton";
import { GameType } from "../../sdk/enums";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

export function SideBar() {
  const { loggedIn } = useContext(WrappedWalletContext);
  const { toggleRightPanel, setRightPanelType, rightPanelType } = useContext(NavContext);

  const platformGames = useMemo(() => {
    return getPlatformGames().filter((game) => game?.type !== GameType.BinaryOption);
  }, []);

  const [gameSvgs, setGameSvgs] = useState(new Map());
  const domParser = new DOMParser();
  const fetchGameSvg = async (svgUrl: string) => {
    fetch(svgUrl).then((res) =>
      res.text().then((svgText) => {
        setGameSvgs(
          gameSvgs.set(
            svgUrl,
            convertDocEleToReact(
              domParser.parseFromString(svgText, "image/svg+xml")?.documentElement,
              null,
            ),
          ),
        );
      }),
    );
  };
  useEffect(() => {
    if (platformGames) {
      platformGames.forEach((game) => {
        fetchGameSvg(game.icon);
      });
    }
  }, [platformGames]);

  const {
    leftPanelOpen,
    rightPanelOpen,
    toggleLeftPanel,
    userProfilePanelOpen,
    toggleUserProfilePanel,
  } = useContext(NavContext);
  const { solanaHealthy, isOnline } = useContext(HealthContext);
  const connected = useMemo(() => {
    return isOnline == true && solanaHealthy == true;
  }, [solanaHealthy, isOnline]);

  const getIconName = (icon: string) => {
    const iconName = icon.substring(icon.lastIndexOf("/") + 1, icon.lastIndexOf("."));
    if (iconName === "coinflip") {
      return "cointoss";
    } else if (iconName === "zeebroSlots") {
      return "slots";
    } else if (iconName === "hurdles") {
      return "randomness";
    } else {
      return iconName;
    }
  };

  const location = useLocation();

  // ACTIVE PAGE USED TO KNOW WHAT TO SET AS SELECTED IN LEFT NAV
  const activePage = useMemo(() => {
    if (location == null) {
      return;
    }

    if (location.pathname == "") {
      return "home";
    } else if (location.pathname.includes("game")) {
      const urlContents = location.pathname.split("/");

      if (urlContents != null && urlContents.length > 0) {
        const gameSpecPubkey = urlContents[urlContents.length - 1];

        return gameSpecPubkey;
      }
    }
  }, [location]);

  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    if (searchInput != "" || searchExpanded == true) {
      setSearchInput("");
      setSearchExpanded(false);
    }
  }, [leftPanelOpen, userProfilePanelOpen, rightPanelOpen]);

  return (
    <Fragment>
      <SideBarMobile sidebarOpen={leftPanelOpen}>
        <div className="flex gap-x-2">
          <SearchMobile
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            searchExpanded={searchExpanded}
            setSearchExpanded={setSearchExpanded}
            onClose={() => {
              toggleLeftPanel(false);
              setSearchInput("");
              setSearchExpanded(false);
            }}
          />
          {loggedIn ? (
            <IconButton
              className="mr-2 bg-gray-800"
              size="lg"
              iconName="bell"
              onClick={() => {
                if (rightPanelType != RightNavType.NOTIFICATIONS) {
                  setRightPanelType(RightNavType.NOTIFICATIONS);

                  if (rightPanelOpen == false) {
                    toggleRightPanel();
                    toggleLeftPanel();
                  }
                } else {
                  toggleRightPanel();
                  toggleLeftPanel();
                }
              }}
            />
          ) : (
            ""
          )}
        </div>

        {searchExpanded == false ? (
          <Fragment>
            <Nav
              games={platformGames}
              getIconName={getIconName}
              leftBarIsOpen={true}
              toggleLeftPanel={toggleLeftPanel}
              closeAfterClick={true}
              footerContent={
                <Fragment>
                  <BarFooter
                    sidebarOpen={true}
                    className={`mt-5`}
                    toggleLeftBar={() => {}}
                    displayProfile={false}
                  />
                  <NetworkStatus connected={connected} className="mt-5 pb-5" />
                </Fragment>
              }
            />
            {/*  */}
          </Fragment>
        ) : (
          ""
        )}
      </SideBarMobile>
      <RightNavMobile panelOpen={rightPanelOpen} />
      <UserProfileMobile panelOpen={userProfilePanelOpen} />

      {/* Static sidebar for desktop */}
      <SidebarDesktop leftBarIsOpen={leftPanelOpen}>
        <Nav
          games={platformGames}
          getIconName={getIconName}
          leftBarIsOpen={leftPanelOpen}
          toggleLeftPanel={toggleLeftPanel}
          closeAfterClick={false}
        />
        <BarFooter
          sidebarOpen={leftPanelOpen}
          className={`${leftPanelOpen ? "mb-2" : "mb-0"}`}
          toggleLeftBar={toggleLeftPanel}
          displayProfile={true}
        />
        {leftPanelOpen && <NetworkStatus connected={connected} />}
      </SidebarDesktop>
    </Fragment>
  );
}
