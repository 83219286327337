import { PublicKey } from "@solana/web3.js";

import { ITokenCheckMeta } from "../../contexts/BalanceContext"
import { ClaimableStatus, IClaimable, IClaimableMeta } from "../../sdk/betStream"
import House from "../../sdk/house"
import Platform from "../../sdk/platform"
import Player from "../../sdk/playerAccount"
import { MS_IN_DAY } from "./useRewardsMeta"
import { toClaimTooltip } from "./tooltip"
import { IPlayerClaimsFilter, daysInPeriod } from "../../utils/supabase/types";
import { getFormattedPlayerClaims } from "../../utils/supabase/supabase";

export enum ClaimType {
    LEVEL_UP = 'levelUpBonus',
    RAKEBACK = 'rakeback',
    DAILY = 'dailyBonus',
    WEEKLY = 'weeklyBonus',
    MONTHLY = 'monthlyBonus',
    REFERRAL = 'referral'
}

export const loadOldClaims = async (player: Player, startDate?: Date, endDate?: Date, tokensByIdentifier?: Map<string, ITokenCheckMeta>) => {
    let filters: IPlayerClaimsFilter = {
        player: player.publicKey.toString(),
    }

    if (startDate != null) {
        const startEpoch = startDate.getTime() / 1000

        filters.timeFrom = Math.floor(startEpoch)
    }

    if (endDate != null) {
        const endEpoch = endDate.getTime() / 1000

        filters.timeTo = Math.ceil(endEpoch)
    }


    return await getFormattedPlayerClaims(filters)
}

export const createEmptyClaimsMap = (earliestDate: Date, latestDate: Date): Map<number, Map<number, IClaimableMeta>> => {
    const claimsMap = new Map<number, Map<number, IClaimableMeta>>()
    const numberOfDays = Math.ceil((latestDate.getTime() - earliestDate.getTime()) / MS_IN_DAY)

    for (let i = 0; i <= numberOfDays; i++) {
        const date = new Date(earliestDate)
        date.setUTCDate(earliestDate.getUTCDate() + i)

        const month = date.getUTCMonth()
        const day = date.getUTCDate()
        const emptyClaimable: IClaimableMeta = {
            claimables: [],
            totals: {
                valueBaseUi: 0,
                valueUsdUi: 0
            },
            startDay: date,
            status: date.getTime() > Date.now() ? ClaimableStatus.NOTHING_TO_CLAIM : ClaimableStatus.NOTHING_CLAIMED
        }

        if (claimsMap.has(month)) {
            claimsMap.get(month)?.set(day, emptyClaimable)
        } else {
            const map = new Map<number, IClaimableMeta>()
            map.set(day, emptyClaimable)

            claimsMap.set(month, map)
        }
    }

    return claimsMap
}

export const loadClaimsCalendar = async (player: Player, earliestDate: Date, latestDate: Date, house: House, tokensByIdentifier: Map<string, ITokenCheckMeta>, platform: Platform): Promise<IClaimableMeta[]> => {
    // GET EMPTY MAPS
    const claimsMap = createEmptyClaimsMap(earliestDate, latestDate)

    // LOAD HM DATA
    const oldClaims = await loadOldClaims(player, earliestDate, latestDate, tokensByIdentifier)

    // ADD HM DATA
    oldClaims.forEach((claim) => {
        // ENSURE CLAIM IS WITHIN DATE RANGES
        if (earliestDate.getTime() > claim.startDate.getTime() || latestDate.getTime() < claim.startDate.getTime()) {
            console.log(`Outside range`, earliestDate, latestDate, claim.relatesTo)
            return
        }

        // GET MONTH AND DAY, ADD TO MAP
        const claimMonth = claim.startDate.getUTCMonth()
        const claimDay = claim.startDate.getUTCDate()
        const token = tokensByIdentifier.get(claim.token)

        // SET UI AND USD VALUES
        if (claim.valueBase > 0) {
            claim.valueBaseUi = claim.valueBase / Math.pow(10, token?.houseToken?.decimals || 6)
            claim.valueUsd = house.approximateTokenAmountToBase(new PublicKey(token?.houseToken?.pubkey || ''), claim.valueBase)
            claim.valueUsdUi = house.approximateTokenAmountToBaseUI(new PublicKey(token?.houseToken?.pubkey || ''), claim.valueBase)
        }

        // SET THE TOOLTIP
        claim.tooltip = toClaimTooltip(claim)


        const claimMeta = claimsMap.get(claimMonth)?.get(claimDay)
        if (claimMeta != null) {
            claimMeta.claimables.push(claim)
        }
    })

    const token = tokensByIdentifier.get(platform?.rewardTokenPubkey?.toString() || '')

    // ADD DAILY, WEEKLY, MONTHLY, RAKEBACK, LEVEL UP
    const collectibles = player?.collectable
    if (collectibles != null) {
        // ADDING CLAIMS AND FOREFITS FROM COLLECTIBLES
        Array.from([collectibles.daily, collectibles.weekly, collectibles.monthly, collectibles.rakeback, collectibles.levelUp]).forEach((reward) => {
            // IF ITS COLLECTIBLE (ADD TO TODAY)
            if (reward != null) {
                if (reward.amountAvailableToCollectUi > 0) {
                    const collectMonth = reward.currentPeriodStartDate.getUTCMonth()
                    const collectDay = reward.currentPeriodStartDate.getUTCDate()

                    // to claimable
                    const claimable: IClaimable = {
                        token: player?.platform.rewardTokenConfig?.pubkey || '',
                        type: reward.rewardTypeString,
                        valueBase: reward.amountAvailableToCollect,
                        valueBaseUi: reward.amountAvailableToCollectUi,
                        tokenIcon: token?.context?.imageDarkPng,
                        valueUsdUi: house.approximateTokenAmountToBase(platform.rewardTokenPubkey, reward.amountAvailableToCollectUi),
                        tokenAmountSpread: 0, // TODO 
                        tokenAmountUpFront: 0, // TODO
                        status: ClaimableStatus.CLAIMABLE,
                        startDate: reward.currentPeriodStartDate,
                        endDate: reward.currentPeriodEndDate
                    }

                    claimable.tooltip = toClaimTooltip(claimable)

                    claimsMap.get(collectMonth)?.get(collectDay)?.claimables.push(claimable)
                }

                // IF ITS ACCRUING - ADD TO THE END OF THE ACCRUAL PERIOD
                if (reward.amountCurrentlyAccruingUi > 0) {
                    const rewardDays = daysInPeriod(reward.rewardTypeString)
                    const collectStart = new Date(reward.currentPeriodEndDate)
                    collectStart.setUTCDate(reward.currentPeriodEndDate.getUTCDate() + 1)

                    const collectEnd = new Date(reward.currentPeriodEndDate)
                    collectEnd.setUTCDate(collectStart.getUTCDate() + rewardDays)
                    collectEnd.setUTCSeconds(collectEnd.getUTCSeconds() - 1)

                    const collectMonth = reward.currentPeriodEndDate.getUTCMonth()
                    const collectDay = reward.currentPeriodEndDate.getUTCDate() + 1 // SHOULD BE + DURATION

                    const claimable: IClaimable = {
                        token: player?.platform.rewardTokenConfig?.pubkey || '',
                        type: reward.rewardTypeString,
                        valueBase: reward.amountCurrentlyAccruing,
                        valueBaseUi: reward.amountCurrentlyAccruingUi,
                        tokenIcon: token?.context?.imageDarkPng,
                        valueUsdUi: house.approximateTokenAmountToBase(platform.rewardTokenPubkey, reward.amountAvailableToCollectUi),
                        tokenAmountSpread: 0, // TODO 
                        tokenAmountUpFront: 0, // TODO
                        status: ClaimableStatus.ACCRUING,
                        startDate: collectStart,
                        endDate: collectEnd
                    }

                    claimable.tooltip = toClaimTooltip(claimable)

                    claimsMap.get(collectMonth)?.get(collectDay)?.claimables.push(claimable)
                }

                // ADD THE FOREFITS
                if (reward.impliedForfeits.length > 0) {
                    reward.impliedForfeits.forEach((forefit) => {
                        const rewardDays = daysInPeriod(reward.rewardTypeString)
                        const collectStart = new Date(reward.currentPeriodEndDate)
                        collectStart.setUTCDate(reward.currentPeriodEndDate.getUTCDate() + 1)

                        const collectEnd = new Date(reward.currentPeriodEndDate)
                        collectEnd.setUTCDate(collectStart.getUTCDate() + rewardDays)
                        collectEnd.setUTCSeconds(collectEnd.getUTCSeconds() - 1)

                        const forefitMonth = forefit.relatesTo.getUTCMonth()
                        const forefitDay = forefit.relatesTo.getUTCDate()
                        const valueBaseUi = forefit.valueBase / Math.pow(10, player?.platform.rewardTokenConfig?.houseToken.decimals || 6)

                        const claimable: IClaimable = {
                            token: player?.platform.rewardTokenConfig?.pubkey || '',
                            type: reward.rewardTypeString,
                            valueBase: forefit.valueBase,
                            valueBaseUi: forefit.valueBaseUi || valueBaseUi,
                            tokenIcon: token?.context?.imageDarkPng,
                            valueUsdUi: house.approximateTokenAmountToBase(platform?.rewardTokenPubkey, reward.amountAvailableToCollectUi),
                            tokenAmountSpread: 0, // TODO 
                            tokenAmountUpFront: 0, // TODO 
                            status: ClaimableStatus.FOREFIT,
                            startDate: collectStart,
                            endDate: collectEnd
                        }

                        claimable.tooltip = toClaimTooltip(claimable)

                        claimsMap.get(forefitMonth)?.get(forefitDay)?.claimables.push(claimable)
                    })
                }
            }
        })
    }

    const claimables: IClaimableMeta[] = []
    for (let [month, monthMap] of claimsMap.entries()) {
        for (let [day, claimable] of monthMap.entries()) {
            // SET TOTALS, SET TOKEN DATA
            let status: ClaimableStatus | undefined

            const valueBase = claimable.claimables.reduce((result, item) => {
                // UPDATE STATUS
                status = item.status

                result += (item.valueBase || 0)
                return result
            }, 0)
            const valueBaseUi = valueBase / Math.pow(10, platform?.rewardTokenConfig?.houseToken.decimals || 6)

            claimable.totals = {
                tokenIcon: token?.context?.imageDarkPng || '',
                valueBaseUi: valueBaseUi,
                valueUsdUi: house.approximateTokenAmountToBase(platform?.rewardTokenPubkey, valueBaseUi)
            }

            // SET THE STATUS
            if (status != null) {
                claimable.status = status
            }

            claimables.push(claimable)
        }
    }

    return claimables
}