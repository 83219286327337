import React, { PropsWithChildren } from "react";
import {twMerge} from "tailwind-merge";

interface ISidebarDesktop extends PropsWithChildren {
  leftBarIsOpen: boolean;
}

export function SidebarDesktop({ children, leftBarIsOpen }: ISidebarDesktop) {
  return (
    <div
      className={twMerge(
        "side-bar-desktop hidden bg-gray-800 md:fixed mt-4 pb-3 px-3 md:inset-y-0 md:z-30 md:flex",
        leftBarIsOpen ? "w-left-bar-width" : "w-left-bar-collapsed-width",
        "flex-col pt-top-bar-height transition-all relative"
      )}
    >
      {children}
    </div>
  );
}
