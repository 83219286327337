import { useContext } from "react";
import { NavContext } from "../../contexts/NavContext";
import { RightNavType } from "../../types/nav";
import { Chat } from "./Chat";
import { Notifications } from "./Notifications";
import { SCROLLBAR_CLASSES } from "../../styles/commonClasses";

interface IProps {
  panelOpen: boolean;
}

export function RightNavDesktop({ panelOpen }: IProps) {
  const { rightPanelType } = useContext(NavContext);

  return (
    <div
      className={`hidden md:fixed md:inset-y-0 lg:z-30 md:flex md:flex-col pt-top-bar-height right-0 ${
        panelOpen == true ? "md:w-right-bar-width" : "md:w-0"
      } transition-all`}
    >
      <div
        className={`flex grow flex-col gap-y-5 overflow-y-auto bg-gray-800 ${SCROLLBAR_CLASSES}`}
      >
        <nav className="flex flex-1 flex-col justify-start">
          {rightPanelType == RightNavType.CHAT ? <Chat /> : ""}
          {rightPanelType == RightNavType.NOTIFICATIONS ? <Notifications /> : ""}
        </nav>
      </div>
    </div>
  );
}
