import { useUserMeta } from "../../hooks/useUserMeta";
import { CurrentPlayerAvatar } from "../common";

export const UserDetails = () => {
  const { userName, avatar, playerAccountOwnerPublicKey } = useUserMeta();

  return (
    <div
      className={`
        ms-2 cursor-pointer text-gray-300 flex items-center lg:space-x-3 ps-0 lg:ps-3 rounded-lg
        border-solid border-2 border-gray-600 bg-gray-700 hover:bg-gray-600 active:bg-gray-800 flex-1
      `}
    >
      <div className="font-sm hidden lg:flex">{userName}</div>
      <div className="flex items-center justify-center">
        <CurrentPlayerAvatar
          playerAvatarKey={avatar}
          playerPublicKey={playerAccountOwnerPublicKey}
          className="rounded lg:rounded-r-lg w-9 h-9 aspect-square"
        />
      </div>
    </div>
  );
};
