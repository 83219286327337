import { AccountInfo, Commitment, Connection, Context } from "@solana/web3.js";
import GameTokenSpec from "../../sdk/permisionless/gameTokenSpec";
import { useEffect, useMemo, useRef, useState } from "react";
import GameTokenInstance from "../../sdk/permisionless/gameTokenInstance";

export const usePermisionlessTokenSpecUpdates = (gameTokenSpec: GameTokenSpec | undefined, client: Connection | undefined, commitment: Commitment = 'processed') => {
    const specWs = useRef<number>()
    const instanceWs = useRef<number>()

    const gameTokenSpecRef = useRef<GameTokenSpec>()
    const clientRef = useRef<Connection>()

    const [updatedSpec, setUpdatedSpec] = useState<GameTokenSpec>()
    const [updatedTokenInstance, setUpdatedTokenInstance] = useState<GameTokenInstance>()

    useEffect(() => {
        gameTokenSpecRef.current = gameTokenSpec
        clientRef.current = client
    }, [gameTokenSpec, client])

    const gameTokenSpecForWs = useRef<string>()

    useEffect(() => {
        async function handleUpdates(accountInfo: AccountInfo<Buffer>, context: Context) {
            if (gameTokenSpecRef.current != null) {
                let gts = GameTokenSpec.loadFromBuffer(gameTokenSpecRef.current?.gameSpec, gameTokenSpecRef.current?.publicKey, accountInfo.data)

                if (gts.state != null) {
                    gts = await gts.refreshGameTokenInstance('processed')
                }
                
                setUpdatedSpec(gts)
            }
        }

        async function closeWs(conn: Connection) {
            try {
                if (specWs.current != null) {
                    await conn.removeAccountChangeListener(specWs.current)
                }
            } catch (err) {
                console.warn({ err })
            }
        }

        async function handleInstanceUpdates(accountInfo: AccountInfo<Buffer>, context: Context) {
            if (gameTokenSpecRef.current != null) {
                let gti = GameTokenInstance.loadFromBuffer(gameTokenSpecRef.current, gameTokenSpecRef.current.gameTokenInstancePubkey, accountInfo.data)
            
                setUpdatedTokenInstance(gti)
            }
        }

        async function closeInstanceWs(conn: Connection) {
            try {
                if (instanceWs.current != null) {
                    await conn.removeAccountChangeListener(instanceWs.current)
                }
            } catch (err) {
                console.warn({ err })
            }
        }

        async function refreshAndHandleUpdates(spec: GameTokenSpec, connection: Connection, commitment: Commitment) {
            await closeWs(connection)
            await closeInstanceWs(connection)

            // REFRESH THE STATE
            let updated = await spec.refreshGameTokenSpec(commitment)
            updated = await spec.refreshGameTokenInstance(commitment)

            // SET THE FIRST VERSION
            setUpdatedSpec(updated)
            setUpdatedTokenInstance(updated.currentGameTokenInstance)

            // SETUP WS FOR BOTH
            specWs.current = connection.onAccountChange(updated.publicKey, handleUpdates, commitment)
            instanceWs.current = connection.onAccountChange(updated.gameTokenInstancePubkey, handleInstanceUpdates, commitment)
        }

        if (client == null || commitment == null) {
            return
        }

        if(gameTokenSpec == null) {
            setUpdatedSpec(undefined)
            setUpdatedTokenInstance(undefined)

            if (specWs.current != null) {
                client.removeAccountChangeListener(specWs.current)
                specWs.current = undefined
            }

            if (instanceWs.current != null) {
                client.removeAccountChangeListener(instanceWs.current)
                instanceWs.current = undefined
            }

            return
        }

        const gameTokenSpecPubkey = gameTokenSpec.publicKey.toString()

        if (gameTokenSpecForWs.current == gameTokenSpecPubkey) {
            return
        }

        // ONLY WANT TO START NEW WS IF PUBKEY CHANGED
        gameTokenSpecForWs.current = gameTokenSpecPubkey

        refreshAndHandleUpdates(gameTokenSpec, client, commitment)
    }, [gameTokenSpec, client, commitment])

    return useMemo(() => {
        return {
            updatedSpec: updatedSpec,
            updatedTokenInstance: updatedTokenInstance
        }
    }, [updatedSpec, updatedTokenInstance])
}