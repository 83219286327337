import { createContext, useEffect, useMemo } from "react";
import { PublicKey } from "@solana/web3.js";
import SolanaRpc from "../utils/solana/rpc";
import { useSolWeb3 } from "../hooks/web3/useSolWeb3";
import { sendWalletConnected } from "../utils/analytics/googleAnalytics";

export interface ISocialWalletContext {
  // SOLANA
  loggedIn: boolean;
  loginSocial: (wallet: string, provider?: string) => Promise<void>;
  logoutSocial: () => Promise<void>;
  solanaRpc: SolanaRpc | undefined;
  walletPubkey: PublicKey | null;
}

export const SocialWalletContext = createContext<ISocialWalletContext>({} as ISocialWalletContext);

interface Props {
  children: any;
}

export const SocialWalletProvider = ({ children }: Props) => {
  // WANT TO SWITCH HERE WEB3 AUTH OR REGULAR WALLET ADAPTER...
  const { loggedIn, connect, disconnect, rpc, walletAddress, web3Auth } = useSolWeb3();

  // SEND MESSAGE TO GA/GTM WHEN WALLET CONNECTS
  useEffect(() => {
    async function getWalletMetaAndSendToGA() {
      const walletUserInfo = await web3Auth?.getUserInfo()
      const walletPubkey = walletAddress?.toString()
      const walletAdapter = web3Auth?.connectedAdapterName

      let combined = {
        wallet_pubkey: walletPubkey,
        wallet_adapter: walletAdapter,
        email: undefined,
        name: undefined,
        type_of_login: undefined,
        verifier: undefined,
        verifier_id: undefined
      }

      if (walletUserInfo != null) {
        combined.email = walletUserInfo.email
        combined.name = walletUserInfo.name
        combined.type_of_login = walletUserInfo.typeOfLogin
        combined.verifier = walletUserInfo.verifier
        combined.verifier_id = walletUserInfo.verifierId
      }

      sendWalletConnected(combined)
    }

    if (walletAddress == null || rpc == null || web3Auth == null) {
      return
    }

    // GET INFO ABOUT THEIR SESSION TO SEND TO ANALYTICS
    getWalletMetaAndSendToGA()
  }, [rpc, walletAddress, web3Auth])

  return (
    <SocialWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: loggedIn,
          loginSocial: connect,
          logoutSocial: disconnect,
          solanaRpc: rpc,
          walletPubkey: walletAddress,
        }),
        [loggedIn, connect, disconnect, rpc, walletAddress],
      )}
    >
      {children}
    </SocialWalletContext.Provider>
  );
};
