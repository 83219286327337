import { HELIUS_API_KEY } from "../../sdk/constants";
import { ENV_NAME } from "../env/env";

export enum NetworkType {
  DEVNET = "DEVNET",
  TESTNET = "TESTNET",
  MAINNET = "MAINNET",
  LOCALNET = "LOCALNET",
}
export const SOLANA_CLUSTER_URLS = {
  [NetworkType.DEVNET]: `https://devnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
  [NetworkType.TESTNET]: "https://api.testnet.solana.com",
  [NetworkType.MAINNET]: `https://mainnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
  [NetworkType.LOCALNET]: "http://127.0.0.1:8899",
};

export const SOLANA_WS_URLS = {
  [NetworkType.DEVNET]: `wss://devnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
  [NetworkType.TESTNET]: `wss://devnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
  [NetworkType.LOCALNET]: `wss://devnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
  [NetworkType.MAINNET]: `wss://mainnet.helius-rpc.com/?api-key=${HELIUS_API_KEY}`,
};

// TODO - UPDATE WITH ETH ENDPOINTS
export const ETH_CLUSTER_URLS = {
  [NetworkType.DEVNET]: "https://api.devnet.solana.com",
  [NetworkType.TESTNET]: "https://api.testnet.solana.com",
  [NetworkType.MAINNET]: "https://rpc.ankr.com/eth",
  [NetworkType.LOCALNET]: "http://127.0.0.1:8899",
};

export const defaultNetwork: NetworkType = NetworkType[ENV_NAME as keyof typeof NetworkType];
export const defaultNetworkUrl = SOLANA_CLUSTER_URLS[defaultNetwork];
export const defaultNetworkWs = SOLANA_WS_URLS[defaultNetwork];
export const defaultNetworkUrlEth = ETH_CLUSTER_URLS[defaultNetwork];
