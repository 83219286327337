import React from "react";

import Icon, { IconSizes } from "../common/icon/Icon";
import platformRanks from "../../config/platform_ranks.json"
import { getS3StaticFolderUrl } from "../../utils/config/utils";

export interface IRankIcon {
  defaultIcon?: React.ReactNode;
  rankName?: string;
  size?: IconSizes;
  className?: string;
}

export const RankIcon: React.FC<IRankIcon> = ({
  rankName= "",
  className = "",
  size = "md",
  defaultIcon
}) => {
  return (
    platformRanks.includes(rankName)
      ? <Icon
        iconUrl={getS3StaticFolderUrl(
          `/static/ranks-and-rewards/ranks-emblems/${rankName.split(" ").join("-")}.png`
        )}
        size={size}
        className={className}
      />
      : <>{defaultIcon}</>
  )
};