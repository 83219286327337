import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export default class Dice extends Game {
  constructor(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    super(casinoProgram, house, gameSpecPubkey, GameType.Dice, commitmentLevel);
  }

  static async load(casinoProgram: anchor.Program, house: House, gameSpecPubkey: PublicKey) {
    const game = new Dice(casinoProgram, house, gameSpecPubkey);
    await game.loadState();

    return game;
  }

  async placeBetIx(
    player: PlayerAccount,
    inputs: object,
    wager: number,
    tokenMintPubkey: PublicKey,
    clientSeed: Buffer,
    owner: PublicKey,
    referrer?: PublicKey,
  ) {
    const instanceRequest = {
      dice: {
        numRolls: inputs.bets.length,
      },
    };

    const betRequest = {
      dice: {
        lines: inputs.bets.map((bet) => bet.line),
        wager: new anchor.BN(wager),
      },
    };

    const numberOfBets = inputs.bets.length;

    return this.initAndBetSoloIxn(
      player,
      tokenMintPubkey,
      numberOfBets,
      betRequest,
      instanceRequest,
      clientSeed,
      owner,
      referrer,
    );
  }

  get houseEdge() {
    return this.gameConfig ? Number(this.gameConfig.dice.edgePerMillion) / 1_000_000 : null;
  }

  get multiplierRoundingUnit() {
    return this.gameConfig ? Number(this.gameConfig.dice.roundingDenominator) / 1_000_000 : null;
  }

  get rangeUpper() {
    return this.gameConfig ? Number(this.gameConfig.dice.rangeUpper) : null;
  }

  get selectionMin() {
    return this.gameConfig ? Number(this.gameConfig.dice.selectionMin) : null;
  }

  get selectionMax() {
    return this.gameConfig ? Number(this.gameConfig.dice.selectionMax) : null;
  }

  get selectionIncrement() {
    return this.gameConfig ? Number(this.gameConfig.dice.selectionIncrement) : null;
  }

  getAcceptableLine(rawLine: number) {
    if (rawLine <= this.selectionMin) {
      return this.selectionMin;
    } else if (rawLine >= this.selectionMax) {
      return this.selectionMax;
    } else {
      return (rawLine / this.selectionIncrement) * this.selectionIncrement;
    }
  }

  getRoundedMultiplier(line: number) {
    const probability = this.getProbability({ line: line });
    const roundingUnit = this.multiplierRoundingUnit || 1;
    const houseEdge = this.houseEdge || 0.02;
    const multiplierPreRounding = (1 - houseEdge) / probability;

    return (
      Math.round(
        Math.floor(multiplierPreRounding / roundingUnit) * roundingUnit * Math.pow(10, 6),
      ) / Math.pow(10, 6)
    );
  }

  getProbability(inputs: object) {
    return 1 - inputs.line / this.rangeUpper;
  }

  // TODO - UPDATE MULTIPLIER
  getMultiplier(inputs: object): number {
    if (inputs == null || inputs.line == null) {
      return 10;
    }

    return this.getRoundedMultiplier(inputs.line);
  }

  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let dollarEdge = 0;

    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      dollarEdge += (1 - multiplier * probability) * bet.wager;
    });

    return {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      dollarEdge: dollarEdge,
      edgePercentage: dollarEdge / totalWager,
    };
  }
}
