import React, { FC, useContext, useEffect } from "react";

import { ModalProps } from "./types";
import { useRankMeta } from "../../hooks/useRankMeta";
import Icon from "../common/icon/Icon";
import { PlayerContext } from "../../contexts/PlayerContext";
import { NumberType, formatZeebitNumber } from "../../utils/currency/formatting";
import { BaseModal } from "../common";
import Button from "../common/button/Button";
import {useGetRewardsButton} from "../rewards/GetRewardsButton";
import { useRewardButtonClick } from "../../hooks/rewards/useRewardButtonClick";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

const LevelUpModal: FC<ModalProps> = ({
   visible,
   hideModal,
}) => {
  const { rankMeta } = useRankMeta();
  const { changeIsLevelUpModalState } = useContext(PlayerContext);
  const { claimReward, isLoading } = useRewardButtonClick();
  const { rewardsButton } = useGetRewardsButton(false, false, true, claimReward, isLoading);

  const handleModalClose = () => {
    changeIsLevelUpModalState(true);
    hideModal();
  };

  useEffect(() => {
    changeIsLevelUpModalState(false)
  }, []);

  return (
    <BaseModal
      title="Level Up Available"
      open={visible}
      onClose={handleModalClose}
      classes={{
        dialog: `w-[342px] sm:max-w-[520px] bg-gray-800`
      }}
    >
      <div
        data-id="level-up-modal"
        className={`
          flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative
        `}
      >
        <div className="flex w-full flex-col items-center">
          <img
            src={getS3StaticFolderUrl("/static/ranks-and-rewards/Level-Up-reward.png")}
            alt={`Level Up image`}
            className={`mx-auto w-[280px]`}
          />
          <div className={`mt-1 text-brand-off-white text-[24px] leading-8 text-center`}>
            Congratulations! You can now level up to&nbsp;
            <span className="text-gold-200">{rankMeta?.nextRank.name}</span>
          </div>
        </div>


        <div className="flex flex-col gap-y-1 items-center bg-gray-600 rounded w-full py-3">
          <div className={`flex items-center font-normal text-gray-300 text-sm uppercase`}>
            <span>{rankMeta?.nextRank.name}&nbsp;rewards</span>
          </div>
          {
            rankMeta?.nextRank.benefit.defaultRakebackRatePerThousand
            && <div className={`flex items-center font-normal text-gray-50`}>
              <Icon
                iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Rakeback.png")}
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
              {formatZeebitNumber((rankMeta?.nextRank.benefit.defaultRakebackRatePerThousand || 0) / 1000, NumberType.PROBABILITY)} %
              Rakeback Rate
            </div>
          }
          {
            rankMeta?.nextRank.benefit.monthlyBonusAccrualRatePerThousand
            && <div className={`flex items-center font-normal text-gray-50`}>
              <Icon
                iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Monthly.png")}
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
              {formatZeebitNumber((rankMeta?.nextRank.benefit.monthlyBonusAccrualRatePerThousand || 0) / 1000, NumberType.PROBABILITY)} %
              Monthly Bonus Allocation
            </div>
          }
          {
            !!rankMeta?.nextRank.benefit.weeklyBonusAccrualRatePerThousand
            && <div className={`flex items-center font-normal text-gray-50`}>
              <Icon
                iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Weekly.png")}
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
              {formatZeebitNumber((rankMeta?.nextRank.benefit.weeklyBonusAccrualRatePerThousand || 0) / 1000, NumberType.PROBABILITY)} %
              Weekly Bonus Allocation
            </div>
          }
          {
            !!rankMeta?.nextRank.benefit.dailyBonusAccrualRatePerThousand
            && <div className={`flex items-center font-normal text-gray-50`}>
              <Icon
                iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Daily.png")}
                size="xl"
                className="bg-gray-900 border-2 border-gray-900 mr-2 rounded mb-1"
              />
              {formatZeebitNumber((rankMeta?.nextRank.benefit.dailyBonusAccrualRatePerThousand || 0) / 1000, NumberType.PROBABILITY)}%
              Daily Bonus Allocation
            </div>
          }
        </div>
      </div>
      <div className={`flex flex-col gap-y-3 mt-3`}>
        {rewardsButton}
       
        <Button
          variant="secondary"
          className={`w-full`}
          onClick={handleModalClose}
        >
          Level Up Later
        </Button>
      </div>
    </BaseModal>
  );
};

export default LevelUpModal;
