import React, { FC, ReactElement, useContext, useEffect, useMemo, useState } from "react";

import { ModalProps } from "./types";
import { BaseModal } from "../common";
import SettingsIcon from "../../assets/icons/settings.svg";
import Slider from "../common/slider/Slider";
import FormItem from "../common/form-item/FormItem";
import { CasinoSwitch, CasinoSwitchSize } from "../common/switch/switch";
import IconFont from "../common/iconFont/IconFont";
import { UserPreferenceContext } from "../../contexts/UserPreferenceContext";
import { AudioContext } from "../../contexts/AudioContext";
import { Tabs } from "../../modals/wallet/Tabs";
import { HouseContext } from "../../contexts/HouseContext";
import { toMaxPriorityFee } from "../../utils/solana/priorityFees";
import { NATIVE_MINT } from "@solana/spl-token";
import {twMerge} from "tailwind-merge";

export enum SettingsTab {
  PLATFORM = "Platform",
  NETWORK = "Network",
}

export interface SettingsModalProps extends ModalProps {
  tab: SettingsTab,
  volume: string,
  setVolume: Function,
  musicVolume: string,
  setMusicVolume: Function
}

const SettingsModal: FC<SettingsModalProps> = ({
  visible,
  hideModal,
  tab,
  volume,
  setVolume,
  musicVolume,
  setMusicVolume
}) => {
  const [selectedTab, setSelectedTab] = useState<SettingsTab>(SettingsTab.PLATFORM)
  useEffect(() => {
    if (!!tab && selectedTab != tab) {
      setSelectedTab(tab);
    }
  }, [tab]);

  const {
    enableHotkeys,
    setEnableHotkeys,
    enableMaxBet,
    setEnableMaxBet,
    isTokenValuesInUSD,
    setIsTokenValuesInUSD,
    isSolGasValuesVisible,
    setIsSolGasValuesVisible,
    isResultAnimationSkipped,
    setIsResultAnimationSkipped,
    priorityFees,
  } = useContext(UserPreferenceContext);

  const platformTab = <>
    <FormItem
      label="Adjust Sound Level"
      className={twMerge(
        "p-3 w-full bg-gray-600 rounded",
        "[&>label]:text-base [&>label]:font-semibold [&>label]:text-gray-200"
      )}
    >
      <div className="flex w-full items-center text-gray-200">
        <button onClick={() => setVolume(0)}>
          <IconFont name="volume_off" size="xl" className="pt-0.5" />
        </button>
        <Slider
          step={0.01}
          max={1}
          min={0}
          gradient
          value={parseFloat(volume)}
          onChange={(value) => {
            setVolume(value);
          }}
          showValue={false}
          className="!gap-0"
          icon={<IconFont name="volume_max" size="xl" className="pt-0.5" />}
        />
      </div>
    </FormItem>
    <FormItem
      label="Adjust Music Level"
      className={twMerge(
        "p-3 w-full bg-gray-600 rounded",
        "[&>label]:text-base [&>label]:font-semibold [&>label]:text-gray-200"
      )}
    >
      <div className="flex w-full items-center text-gray-200">
        <button onClick={() => setMusicVolume(0)}>
          <IconFont name="volume_off" size="xl" className="pt-0.5" />
        </button>
        <Slider
          step={0.01}
          max={1}
          min={0}
          gradient
          value={parseFloat(musicVolume)}
          onChange={(value) => {
            setMusicVolume(value);
          }}
          showValue={false}
          className="!gap-0"
          icon={<IconFont name="volume_max" size="xl" className="pt-0.5" />}
        />
      </div>
    </FormItem>
    <ActionLine
      title="Display Token Values in USD"
      subTitle=""
      action={
        <CasinoSwitch
          disabled={false}
          checked={isTokenValuesInUSD}
          onChange={setIsTokenValuesInUSD}
          size={CasinoSwitchSize.LARGE}
        />
      }
    />
    <ActionLine
      hidden
      title="Show Max Bet Button"
      action={
        <CasinoSwitch
          disabled={false}
          checked={enableMaxBet}
          onChange={setEnableMaxBet}
          size={CasinoSwitchSize.LARGE}
        />
      }
    />
    <ActionLine
      hidden
      title="Skip Result Animation"
      action={
        <CasinoSwitch
          disabled={false}
          checked={isResultAnimationSkipped}
          onChange={setIsResultAnimationSkipped}
          size={CasinoSwitchSize.LARGE}
        />
      }
    />
    <ActionLine
      hidden
      title="Enable Hotkeys"
      isSeparatorHidden
      action={
        <CasinoSwitch
          disabled={false}
          checked={enableHotkeys}
          onChange={setEnableHotkeys}
          size={CasinoSwitchSize.LARGE}
        />
      }
    />
  </>

  const { house } = useContext(HouseContext);
  const priorityFeeMeta = useMemo(() => {
    const priorityFeeLamports = toMaxPriorityFee(priorityFees) / Math.pow(10, 6);
    const standarLamportsPerTx = 5000;
    const totalLamportsPerTx = priorityFeeLamports + standarLamportsPerTx;
    const totalSolPerTx = totalLamportsPerTx / Math.pow(10, 9);

    const feeUsd = house?.approximateTokenAmountToBaseUI(NATIVE_MINT, totalLamportsPerTx) || 0;

    return {
      sol: totalSolPerTx.toFixed(7),
      usd: feeUsd.toFixed(7),
    };
  }, [house, priorityFees]);

  const networkTab = (
    <>
      {/* <ActionLine
      title="Select Default Explorer"
      action={
        <Dropdown
          onTop={true}
          items={[SolanaExplorer.SOLANA_FM, SolanaExplorer.SOL_SCAN, SolanaExplorer.SOLANA_EXPLORER].map((explorer) => {
            return {
              name: explorer,
              value: explorer
            }
          })}
          containerStyles="mr-3"
          onSelect={explorer => {
            setSolanaExplorer(explorer.value as SolanaExplorer)
          }}
          initialSelectedItem={
            { value: solanaExplorer, name: solanaExplorer }
          }
        />
      }
    /> */}
      <ActionLine
        title="Show SOL Gas Values"
        action={
          <CasinoSwitch
            disabled={false}
            checked={isSolGasValuesVisible}
            onChange={setIsSolGasValuesVisible}
            size={CasinoSwitchSize.LARGE}
          />
        }
      />
      {/* <FormItem
      label="Select Priority Fees"
      className={`
              gap-y-3 p-3 w-full bg-gray-600 rounded font-normal text-gray-200
              [&>label]:text-base [&>label]:font-semibold [&>label]:text-gray-200
            `}
    >
      <div>
        In periods of Solana network congestion, increasing priority fees can help to speed up your bets.
      </div>

      <Tabs selectedTab={priorityFees} updateSelectedTab={setPriorityFees} tabs={PriorityFees} />

      <div className="flex justify-between">
        <div>Estimate Fees</div>
        <div className="flex items-center gap-x-1">
          <Icon iconUrl="/static/tokens/sol.png" size="sm" />{` ${priorityFeeMeta.sol} | $${priorityFeeMeta.usd}`}
        </div>
      </div>
    </FormItem> */}
    </>
  );

  return (
    <BaseModal
      open={visible}
      icon={<IconFont name="settings" size="xl" />}
      onClose={hideModal}
      title="Settings"
      classes={{
        dialog: "!bg-gray-700",
      }}
    >
      <div data-id="settings-modal" className="flex flex-col items-center justify-center gap-y-5">
        <Tabs selectedTab={selectedTab} updateSelectedTab={setSelectedTab} tabs={SettingsTab} />

        <div className="flex w-full flex-col items-center gap-y-3 text-gray-400">
          {selectedTab == SettingsTab.PLATFORM ? platformTab : ""}
          {selectedTab == SettingsTab.NETWORK ? networkTab : ""}
        </div>
      </div>
    </BaseModal>
  );
};

interface IActionLine {
  title: string;
  subTitle: string;
  action: ReactElement;
  isSeparatorHidden?: boolean;
  hidden?: boolean;
}

const ActionLine = ({ title, subTitle, action, isSeparatorHidden, hidden }: IActionLine) => {
  {
    /* 406 */
  }
  return (
    <div
      className={`flex flex-1 flex-col items-start gap-y-3 self-stretch ${hidden ? "hidden" : ""}`}
    >
      {/* 395 */}
      <div className="flex items-center gap-3 self-stretch text-sm">
        {/* 392 */}
        <div className="flex flex-1 flex-col items-start">
          <div className="text-base font-semibold text-gray-200">{title}</div>
          <div className="font-normal text-gray-400">{subTitle}</div>
        </div>
        {action}
      </div>
      {!isSeparatorHidden && <div className="h-[1px] self-stretch bg-gray-600"></div>}
    </div>
  );
};

export default SettingsModal;
