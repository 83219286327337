import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export enum BaccaratSide {
    PLAYER = 0,
    TIE = 1,
    BANKER = 2,
};

export const BACCARRAT_NAME = "baccarat"

export default class Baccarat extends Game {

    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        super(
            casinoProgram,
            house,
            gameSpecPubkey,
            GameType.Baccarat,
            commitmentLevel
        )
    }

    static async load(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        const game = new Baccarat(
            casinoProgram,
            house,
            gameSpecPubkey,
        )
        await game.loadState();
        
        return game
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: object,
        wager: number,
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey,
    ): Promise<TransactionInstruction> {
        const instanceRequest = {
            baccarat: {
                numHands: inputs.numberOfHands
            }
        };
        const betRequest = {
            baccarat: {
                playerWager: new anchor.BN(inputs.playerWagerBasis),
                tieWager: new anchor.BN(inputs.tieWagerBasis),
                bankerWager: new anchor.BN(inputs.bankerWagerBasis)
            }
        };

        const betsPerHand = (inputs.playerWagerBasis ? 1 : 0) + (inputs.tieWagerBasis ? 1 : 0) + (inputs.bankerWagerBasis ? 1 : 0);
        const numberOfBets = inputs.numberOfHands * betsPerHand;

        return await this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberOfBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }

    get gameConfig() {
        return this.state ? this.state.config.baccarat : null;
    }

    get formattedGameConfig() {
        if (this.gameConfig == null) {
            return 
        }

        return {
            ...this.gameConfig,
            bankerEdge: Number(this.gameConfig.bankerEdgePerMillion) / 1_000_000,
            bankerMultiplier: Number(this.gameConfig.bankerMultiplierPerMillion) / 1_000_000,
            playerEdge: Number(this.gameConfig.playerEdgePerMillion) / 1_000_000,
            playerMultiplier: Number(this.gameConfig.playerMultiplierPerMillion) / 1_000_000,
            tieEdge: Number(this.gameConfig.tieEdgePerMillion) / 1_000_000,
            tieMultiplier: Number(this.gameConfig.tieMultiplierPerMillion) / 1_000_000,
        }
    }

    getMultiplier(input: object) {
        return 2
    }

    getProbability(input: object) {
        return 0.5
    }

    // EACH BET HAS WAGER, NUM COINS, NUM CORRECT
    getBetMetas(bets: object[]) {

        let totalPayout = 0;
        let totalProfit = 0;
        let totalWager = 0;
        let edgeDollar = 0;
        let totalWagerBasis = 0;

        const bankerMultiplier = this.formattedGameConfig.bankerMultiplier
        const bankerEdge = this.formattedGameConfig.bankerEdge
        const tieMultiplier = this.formattedGameConfig.tieMultiplier
        const tieEdge = this.formattedGameConfig.tieEdge
        const playerMultiplier = this.formattedGameConfig.playerMultiplier
        const playerEdge = this.formattedGameConfig.playerEdge

        bets.forEach((bet) => {
            // BET HAS PLAYER, TIE, BANKER
            const playerBet = bet.playerWager || 0
            const playerPayout = playerBet * playerMultiplier
            const playerProfit = playerPayout - playerBet
            const tieBet = bet.tieWager || 0
            const tiePayout = tieBet * tieMultiplier
            const tieProfit = tiePayout - tieBet
            const bankerBet = bet.bankerWager || 0
            const bankerPayout = bankerBet * bankerMultiplier
            const bankerProfit = bankerPayout - bankerBet

            const wageredInHand = playerBet + tieBet + bankerBet
            totalWager += wageredInHand
            edgeDollar += (playerBet * playerEdge) + (tieBet * tieEdge) + (bankerBet * bankerEdge)

            const maxPayout = Math.max(playerPayout, tiePayout, bankerPayout)
            totalPayout += maxPayout
            const maxProfit = Math.max(playerProfit, tieProfit, bankerProfit)
            totalProfit += maxProfit
        });

        return {
            payout: totalPayout,
            profit: totalProfit,
            wager: totalWager,
            numberOfBets: bets.length,
            bets: bets,
            edgeDollar: edgeDollar,
            totalWagerBasis: totalWagerBasis,
            edgePercentage: edgeDollar / totalWager, // USED IN CALCULATING MAX BET VIA KELLY
        };
    }
}