import { ENV_NAME } from "../env/env";

export enum NetworkType {
  DEVNET = "DEVNET",
  TESTNET = "TESTNET",
  MAINNET = "MAINNET",
  LOCALNET = "LOCALNET",
}
export const SOLANA_CLUSTER_URLS = {
  [NetworkType.DEVNET]: "https://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79",
  [NetworkType.TESTNET]: "https://api.testnet.solana.com",
  [NetworkType.MAINNET]: "https://global.rpc.hellomoon.io/22ffc301-fa90-4d55-b08f-914e49062b8d",
  [NetworkType.LOCALNET]: "http://127.0.0.1:8899",
};

export const SOLANA_WS_URLS = {
  [NetworkType.DEVNET]: "wss://devnet.helius-rpc.com/?api-key=a65cb0fb-ae28-4e32-8c27-22a028b0cd79",
  [NetworkType.TESTNET]: "wss://rpc-devnet.hellomoon.io/22ffc301-fa90-4d55-b08f-914e49062b8d",
  [NetworkType.LOCALNET]: "wss://rpc-devnet.hellomoon.io/22ffc301-fa90-4d55-b08f-914e49062b8d",
  [NetworkType.MAINNET]: "wss://global.rpc.hellomoon.io/22ffc301-fa90-4d55-b08f-914e49062b8d",
};

// TODO - UPDATE WITH ETH ENDPOINTS
export const ETH_CLUSTER_URLS = {
  [NetworkType.DEVNET]: "https://api.devnet.solana.com",
  [NetworkType.TESTNET]: "https://api.testnet.solana.com",
  [NetworkType.MAINNET]: "https://rpc.ankr.com/eth",
  [NetworkType.LOCALNET]: "http://127.0.0.1:8899",
};

export const defaultNetwork: NetworkType = NetworkType[ENV_NAME as keyof typeof NetworkType];
export const defaultNetworkUrl = SOLANA_CLUSTER_URLS[defaultNetwork];
export const defaultNetworkWs = SOLANA_WS_URLS[defaultNetwork];
export const defaultNetworkUrlEth = ETH_CLUSTER_URLS[defaultNetwork];
