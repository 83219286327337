import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";


export type CrashBet = {
    targetMultiplier: number,
    wager: number
}

export default class Crash extends Game {

    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        super(casinoProgram, house, gameSpecPubkey, GameType.Crash, commitmentLevel);
    }

    static async load(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        const game = new Crash(
            casinoProgram,
            house,
            gameSpecPubkey,
        )
        await game.loadState();
        return game
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: object,
        wager: number,
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey,
    ): Promise<TransactionInstruction> {
        const instanceRequest = {
            crash: {}
        };

        const betRequest = {
            crash: {
                bets: inputs.bets.map((b) => ({
                    targetMultiplierPerMillion: new anchor.BN(Math.floor(b.multiplier * 1_000_000)),
                    wager: new anchor.BN(b.wagerBasis)
                })),
            }
        };
        const numberOfBets = inputs.bets.length;

        return await this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberOfBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }

      // EACH BET HAS WAGER, NUM COINS, NUM CORRECT
  getBetMetas(bets: object[]) {
    let totalPayout = 0;
    let totalProfit = 0;
    let totalWager = 0;
    let edgeDollar = 0;


    bets.forEach((bet) => {
      const multiplier = this.getMultiplier(bet);
      const payoutOnBet = multiplier * bet.wager;
      const probability = this.getProbability(bet);

      // SET PAYOUT/PROBABILITY
      bet.payout = payoutOnBet;
      bet.probability = probability;
      bet.multiplier = multiplier;

      // INCREMENT METRICS
      totalPayout += payoutOnBet;
      totalProfit += payoutOnBet - bet.wager;
      totalWager += bet.wager;
      edgeDollar += (1 - probability * multiplier) * bet.wager;
    });

    const meta = {
      payout: totalPayout,
      profit: totalProfit,
      wager: totalWager,
      numberOfBets: bets.length,
      bets: bets,
      edgeDollar: edgeDollar,
      edgePercentage: edgeDollar / totalWager,
    };

    return meta
  }

  get config() {
    return this?.state?.config?.crash
  }

  get edgePerMillion() {
    return !!this.config.edgePerMillion ? Number(this.config.edgePerMillion): undefined
  }

  get edgePercentage() {
    return !!this.edgePerMillion ? this.edgePerMillion / Math.pow(10, 6): undefined
  }

  get maxBets(): number | undefined {
    return this.config?.maxBets
  }

  get maxMultiplierPerMillion() {
    return !!this.config.maxMultiplierPerMillion ? Number(this.config.maxMultiplierPerMillion): undefined
  }

  get maxMultiplier() {
    return !!this.maxMultiplierPerMillion ? this.maxMultiplierPerMillion / Math.pow(10, 6): undefined
  }

  get roundingDenominator() {
    return !!this.config.roundingDenominator ? Number(this.config.roundingDenominator) / Math.pow(10, 6): undefined
  }

  getMultiplier(inputs: object) {
    if (this.roundingDenominator == null) {
      return inputs.multiplier;
    }

    return (inputs.multiplier / this.roundingDenominator) * this.roundingDenominator;
  }

  getProbability(inputs: object) {
    if (this.edgePercentage == null) {
      return 1 / inputs.multiplier;
    }

    return (1 - this.edgePercentage) / inputs.multiplier;
  }
}