import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import GameSpec, { IGameToken } from "../sdk/gameSpec";
import { HouseContext } from "./HouseContext";
import { ProgramContext } from "./ProgramContext";
import { Program } from "@coral-xyz/anchor";
import House from "../sdk/house";
import { GameType } from "../sdk/enums";
import { ComputeBudgetProgram, PublicKey, Transaction } from "@solana/web3.js";
import { getPlatformGames } from "../utils/config/utils";
import { IPlatformGame } from "../types/game";
import { PlayerContext } from "./PlayerContext";
import { NetworkContext } from "./NetworkContext";
import { BalanceContext } from "./BalanceContext";
import { GAME_STATUS_TAKING_BETS, TOKEN_STATUS_TAKING_BETS } from "../sdk/constants";
import CoinFlip from "../sdk/games/CoinFlip";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import Plinko from "../sdk/games/Plinko";
import Limbo from "../sdk/games/Limbo";
import Dice from "../sdk/games/Dice";
import Roulette from "../sdk/games/Roulette";
import { sendNudgeForPlaceBet } from "../utils/slack/slack";
import { useNavigate } from "react-router";
import Wheel from "../sdk/games/Wheel";
import { NetworkType, defaultNetwork } from "../utils/chain/network";
import Mines from "../sdk/games/Mines";
import Keno from "../sdk/games/Keno";
import Crash from "../sdk/games/Crash";
import SlotsThree from "../sdk/games/SlotsThree";
import Hurdles from "../sdk/games/Hurdles";
import { WrappedWalletContext } from "./WrappedWalletContext";
import { confirmTransaction } from "../utils/solana/utils";
import Baccarat from "../sdk/games/Baccarat";

export interface IGameSpecValidation {
  takingBets: boolean;
  tokenSupported: boolean;
  tokenTakingBets: boolean;
  gameToken: IGameToken | undefined;
}

interface ResultModalData {
  title?: string;
  multiplier?: number;
  payout?: number;
  isModalVisible?: boolean;
  hideTimeout?: number;
  tokenIcon?: string;
  modalBgColor?: string;
  shouldHideBackground?: boolean;
  className?: string;
  onModalClose?: () => void;
}

export interface IGameContext {
  gameSpec: GameSpec | undefined;
  gameSpecLoaded: boolean;
  gameConfig: IPlatformGame | undefined;
  initAndBetSolo: (
    inputs: object,
    tokenMint: PublicKey,
    wager: number,
    clientSeed: Buffer,
    onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
    onErrorCallback?: Function,
    onUserRejected?: Function,
    secondsBeforeExpiry?: number,
  ) => Promise<string | undefined>;
  setResultModalData: Function;
  resetResultModalData: Function;
  resultModalData: ResultModalData;
}

export const GameContext = createContext<IGameContext>({} as IGameContext);

export const WIN_MODAL_ANIMATION_DURATION = 200;

export const loadAssociatedGameSpec = async (
  gameType: GameType,
  program: Program,
  hse: House,
  gsPubkey: PublicKey,
) => {
  switch (gameType) {
    case GameType.CoinFlip:
      return await CoinFlip.load(program, hse, gsPubkey);
    case GameType.Plinko:
      return Plinko.load(program, hse, gsPubkey);
    case GameType.Limbo:
      return Limbo.load(program, hse, gsPubkey);
    case GameType.Dice:
      return await Dice.load(program, hse, gsPubkey);
    case GameType.Roulette:
      return await Roulette.load(program, hse, gsPubkey);
    case GameType.Wheel:
      return await Wheel.load(program, hse, gsPubkey);
    case GameType.Mines:
      return await Mines.load(program, hse, gsPubkey);
    case GameType.Keno:
      return await Keno.load(program, hse, gsPubkey);
    case GameType.Crash:
      return await Crash.load(program, hse, gsPubkey);
    case GameType.SlotsThree:
      return await SlotsThree.load(program, hse, gsPubkey);
    case GameType.Hurdles:
      return await Hurdles.load(program, hse, gsPubkey);
    case GameType.Baccarat:
      return await Baccarat.load(program, hse, gsPubkey);
    default:
      throw new Error(`UNKNOWN GAME SPEC: ${gameType}`);
  }
};

interface Props {
  gameSpecPubkeyString: string | undefined;
  children: any;
}

export const GameProvider = ({ gameSpecPubkeyString, children }: Props) => {
  const [gameSpec, setGameSpec] = useState<GameSpec>();
  const [gameSpecLoaded, setGameSpecLoaded] = useState(false);
  const { house } = useContext(HouseContext);
  const { meta } = useContext(ProgramContext);
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { playerAccount, playerMeta, loadPlayerAccountAndStartWs, counter } =
    useContext(PlayerContext);
  const { client, recentBlockhash, networkCounter } = useContext(NetworkContext);

  const gameSpecPubkey = useMemo(() => {
    if (gameSpecPubkeyString == null) {
      return;
    }

    return new PublicKey(gameSpecPubkeyString);
  }, [gameSpecPubkeyString]);

  const gameConfig = useMemo(() => {
    if (gameSpecPubkeyString == null) {
      return;
    }

    const games = getPlatformGames();

    return games.find((game) => {
      return game.gameSpecPubkey == gameSpecPubkeyString;
    });
  }, [gameSpecPubkeyString]);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadGameSpec(
      program: Program,
      hse: House,
      gsPubkey: PublicKey,
      gameType: GameType,
    ) {
      try {
        const gs = await loadAssociatedGameSpec(gameType, program, hse, gsPubkey);
        setGameSpec(gs);
        setGameSpecLoaded(true);
      } catch (e) {
        console.warn(`Error loading the game spec.`, e);
        navigate(`/error`);
      }
    }

    if (
      house == null ||
      meta == null ||
      meta.casinoProgram == null ||
      gameSpecPubkey == null ||
      gameConfig == null
    ) {
      return;
    }
    // TODO - GET PUBKEY AND TYPE FROM GAME_ID
    loadGameSpec(meta.casinoProgram, house, gameSpecPubkey, gameConfig.type);
  }, [house, meta, gameSpecPubkey, gameConfig]);

  const initAndBetSolo = useCallback(
    async (
      inputs: object,
      tokenMint: PublicKey,
      wager: number,
      clientSeed: Buffer,
      onSuccessfulSendCallback?: Function, // callbackFn(txnHash);
      onErrorCallback?: Function,
      onUserRejected?: Function,
    ) => {
      if (
        playerAccount == null ||
        walletPubkey == null ||
        solanaRpc == null ||
        gameSpec == null ||
        playerAccount.platform == null
      ) {
        console.warn(
          "Issue with player acc, wallet pubkey or solana rpc.",
          playerAccount,
          walletPubkey,
          solanaRpc,
          gameSpec,
        );
        return;
      }

      try {
        const tx = new Transaction();

        const setHeapLimitIx = ComputeBudgetProgram.requestHeapFrame({
          bytes: 8 * 32 * 1024,
        });

        tx.add(setHeapLimitIx);

        // INIT PLAYER IF DOESNT EXIST
        const playerExists = await playerAccount.checkPlayerAccountInitialized();
        const referrer =
          playerExists == false && playerMeta != null && playerMeta.referralAccount != null
            ? new PublicKey(playerMeta.referralAccount)
            : undefined;

        if (!playerExists) {
          const username =
            playerMeta != null && playerMeta.username != null && playerMeta.username.length > 0
              ? playerMeta.username
              : walletPubkey.toString().slice(0, 10);

          const initPlayer = await playerAccount.initializePlayerAccountIxn(
            username,
            playerAccount.house.latestTermsVersion,
            playerAccount.platform.latestTermsVersion,
            referrer,
            walletPubkey,
          );
          tx.add(initPlayer);
        } else {
          // CHECK IF WE NEED TO FRONT RUN WITH SYNC OR CLAIM REWARDS
          const preIxn = await playerAccount.getPreInstruction();

          if (preIxn != null) {
            tx.add(preIxn);
          }
        }

        // INIT GAME AND PLACE BET
        const initAndBetSoloIx = await gameSpec.placeBetIx(
          playerAccount,
          inputs,
          wager,
          tokenMint,
          clientSeed,
          walletPubkey,
          referrer,
        );
        tx.add(initAndBetSoloIx);

        const sig = await solanaRpc.sendTransaction(
          tx,
          client,
          walletPubkey,
          meta?.errorByCodeByProgram,
          recentBlockhash,
        );

        console.log({
          sig,
        });

        if (onSuccessfulSendCallback) {
          onSuccessfulSendCallback(sig);
        }

        // NOW LOAD THE NEW PLAYER ACCOUNT
        if (playerExists == false) {
          // NEED TO CONFIRM THE TX BEFORE LOADING STATE
          const confirmedSig = await confirmTransaction(sig, client, recentBlockhash);

          await loadPlayerAccountAndStartWs();
        }

        return sig;
      } catch (err) {
        // PUT IN FOR CRUDE TESTING OF ISSUES, TO BE REMOVED
        if (err == null || err?.message == "User rejected the request.") {
          if (onUserRejected != null) {
            onUserRejected();
          }
          return;
        }

        console.warn("Error placing bet", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }

        const message: object = {
          playerAccount: playerAccount?.publicKey?.toString(),
          game: gameSpec?.publicKey?.toString(),
          inputs: inputs,
          wager: wager,
          tokenMint: tokenMint?.toString(),
          walletPubkey: walletPubkey?.toString(),
          referrer: playerAccount != null ? playerAccount?.publicKey?.toString() : undefined,
          error: err,
          houseBalances: house?.tokens?.map((token) => {
            return {
              token: token.pubkey,
              balance: token.availableTradingBalance,
            };
          }),
        };

        await sendNudgeForPlaceBet(message);
      }
    },
    [
      solanaRpc,
      walletPubkey,
      playerAccount,
      counter,
      gameSpec,
      client,
      meta,
      house,
      playerMeta,
      recentBlockhash,
      networkCounter,
    ],
  );

  // VALIDATIONS
  const { selectedTokenMeta } = useContext(BalanceContext);
  const [validation, setValidation] = useState<IGameSpecValidation>();

  useEffect(() => {
    // ONLY VALIDATE IF GAME SPEC AND SELECTED TOKEN IN CONTEXT
    if (gameSpec == null || selectedTokenMeta == null) {
      return;
    }

    const selectedTokenString = selectedTokenMeta.mint;

    const gameToken = gameSpec.tokens.find((token) => {
      return token.pubkey == selectedTokenString;
    });

    setValidation({
      takingBets: gameSpec.status != null && GAME_STATUS_TAKING_BETS.includes(gameSpec.status),
      tokenSupported: gameToken != null,
      tokenTakingBets: gameToken != null && TOKEN_STATUS_TAKING_BETS.includes(gameToken.status),
      gameToken: gameToken,
    });
  }, [gameSpec, selectedTokenMeta]);

  const { gameValidation } = useContext(ErrorHandlingContext);
  useEffect(() => {
    if (gameSpec == null || validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_NOT_ACTIVE,
        title: "Game not active",
        message: "The game is not currently taking bets.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_NOT_ACTIVE);
    }

    if (validation.tokenSupported == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_TOKEN_NOT_SUPPORTED,
        title: "Game doesnt currently support selected token",
        message: "The game does not currently support selected token.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_TOKEN_NOT_SUPPORTED);
    }

    if (validation.tokenTakingBets == false) {
      gameValidation.addErrorMessage({
        type: ErrorType.GAME_TOKEN_NOT_ACTIVE,
        title: "Bets are not currently being taken for selected token",
        message: "Bets are not currently being taken for selected token.",
      });
    } else {
      gameValidation.removeErrorMessage(ErrorType.GAME_TOKEN_NOT_ACTIVE);
    }
  }, [gameSpec, validation]);

  const resultModalDefaultData = {
    title: "Payout",
    isModalVisible: false,
    multiplier: 0,
    payout: 0,
    hideTimeout: 3000,
    tokenIcon: "usdc",
    shouldHideBackground: false,
    className: "",
    onModalClose: () => {},
  };
  const [resultModalData, setResultModalData] = useState<ResultModalData>(resultModalDefaultData);

  useEffect(() => {
    let timeoutId = null;
    if (resultModalData?.isModalVisible) {
      timeoutId = setTimeout(
        () => {
          setResultModalData((prevState) => ({ ...prevState, isModalVisible: false }));

          setTimeout(() => {
            resultModalData.onModalClose?.();
            setResultModalData(resultModalDefaultData);
          }, WIN_MODAL_ANIMATION_DURATION);
        },
        (resultModalData.hideTimeout || resultModalDefaultData.hideTimeout) -
          WIN_MODAL_ANIMATION_DURATION,
      );
    }

    return () => {
      if (resultModalData?.isModalVisible && timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [resultModalData, setResultModalData]);

  return (
    <GameContext.Provider
      value={useMemo(
        () => ({
          gameSpec: gameSpec,
          gameSpecLoaded: gameSpecLoaded,
          gameConfig: gameConfig,
          initAndBetSolo: initAndBetSolo,
          setResultModalData: (newResultModalData: ResultModalData) => {
            setResultModalData((prevResultModalData) => ({
              ...prevResultModalData,
              ...newResultModalData,
            }));
          },
          resetResultModalData: () => setResultModalData(resultModalDefaultData),
          resultModalData,
        }),
        [gameSpec, gameConfig, initAndBetSolo, gameSpecLoaded, resultModalData],
      )}
    >
      {children}
    </GameContext.Provider>
  );
};
