import {
    Connection,
    PublicKey,
    Transaction,
    Commitment,
} from "@solana/web3.js";
import { IChainBalance } from "../../contexts/BalanceContext";
import { ZEEBIT_LOOKUP_TABLE } from "../../sdk/constants";
import { IdlErrorCode } from "@coral-xyz/anchor/dist/cjs/idl";
import { IRecentBlockhash } from "../../contexts/NetworkContext";
import { RPC_WRITE_ENPOINTS } from "../../admin/sdk/constants";
import { confirmTransaction, handleSendTransaction, loadAssociatedTokenBalances, loadLamportBalance, loadTokenBalance } from "./utils";
import { BLOCKHASH_COMMITMENT, ISolanaRpc } from "./rpc";
import { WalletContextState } from "@solana/wallet-adapter-react";

export default class NonSocialSolanaRpc implements ISolanaRpc {
    private client: Connection;
    private writeClients: Connection[];
    wallet: WalletContextState;
    pubkey?: PublicKey;

    constructor(nonSocialWallet: WalletContextState, client: Connection) {
        this.wallet = nonSocialWallet;
        this.client = client;
        this.writeClients = RPC_WRITE_ENPOINTS.map((connection) => {
            return new Connection(connection, 'processed')
        })
    }

    getPubkey = async (): Promise<string> => {
        if (this.wallet.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return this.wallet.publicKey?.toString();
    };

    getLamportBalance = async (): Promise<IChainBalance> => {
        if (this.wallet.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadLamportBalance(this.wallet.publicKey, this.client)
    };

    getTokenBalance = async (mint: PublicKey, decimals: number): Promise<IChainBalance> => {
        if (this.wallet.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadTokenBalance(this.wallet.publicKey, mint, decimals, this.client)
    };

    getAssociatedTokenBalances = async (): Promise<IChainBalance[]> => {
        if (this.wallet.publicKey == null) {
            return Promise.reject("No wallet pubkey connected.")
        }

        return await loadAssociatedTokenBalances(this.wallet.publicKey.toString(), this.client)
    };

    signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
        if (this.wallet.signMessage == null) {
            return Promise.reject("No method to sign message available.")
        }
        return await this.wallet.signMessage(message);
    };

    sendAndConfirmTransaction = async (
        transaction: Transaction,
        client: Connection,
        feePayer: PublicKey,
        errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
        blockhash?: IRecentBlockhash,
        commitment: Commitment | undefined = "processed"
    ): Promise<string> => {
        try {
            const latestBlockHash = blockhash != null ? blockhash : await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
            const signature = await this.sendTransaction(
                transaction,
                client,
                feePayer,
                errorByCodeByProgram,
                latestBlockHash,
            );

            await confirmTransaction(signature, client, latestBlockHash, commitment)

            return signature;
        } catch (err: any) {
            return Promise.reject(err);
        }
    };

    sendTransaction = async (
        transaction: Transaction,
        client: Connection,
        feePayer: PublicKey,
        errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
        blockhash?: IRecentBlockhash,
        lookupTable: PublicKey = ZEEBIT_LOOKUP_TABLE
    ): Promise<string> => {
        if (this.wallet.signTransaction == null) {
            return Promise.reject("No method to sign transaction available.")
        }

        return await handleSendTransaction(
            this.writeClients,
            this.wallet,
            transaction,
            client,
            feePayer,
            errorByCodeByProgram,
            blockhash,
            lookupTable
        )
    };

    signTransaction = async (transaction: Transaction): Promise<Transaction> => {
        if (this.wallet.signTransaction == null) {
            return Promise.reject("No method to sign transaction available.")
        }

        return await this.wallet.signTransaction(transaction);
    };
}
