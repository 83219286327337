import { useMemo, useState } from "react";
import { useModal } from "react-modal-hook";

import WalletConnectionModal, { WalletModalType } from "../../modals/WalletConnectionModal";
import AuthorizationModal from "../../modals/AuthorizationModal";

export const useWalletConnectionModal = (defaultTab: WalletModalType) => {
  const [walletConnectionTab, setWalletConnectionTab] = useState(defaultTab);
  const [showWalletConnectModal, hideWalletConnectModal] = useModal(
    ({ in: open }) => (
      <WalletConnectionModal
        open={open}
        closeModal={() => {
          hideWalletConnectModal();
        }}
        setTab={setWalletConnectionTab}
        tab={walletConnectionTab}
      />
    ),
    [walletConnectionTab],
  );
  const [showOnboardingModal, hideOnboardingModal] = useModal(
    ({ in: open }) => (
      <AuthorizationModal
        open={open}
        closeModal={() => {
          hideOnboardingModal();
        }}
        setTab={setWalletConnectionTab}
        tab={walletConnectionTab}
      />
    ),
    [walletConnectionTab],
  );

  return useMemo(() => {
    return {
      walletConnectionTab: walletConnectionTab,
      setWalletConnectionTab: setWalletConnectionTab,
      showWalletConnectModal: () => {
        // showWalletConnectModal();
        showOnboardingModal();
      },
      hideWalletConnectModal: () => {
        // hideWalletConnectModal();
        hideOnboardingModal();
      },
    };
  }, []);
};
