import React, { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import Button from "../../components/common/button/Button";
import { AggregatedBalancesContext, IMergedToken } from "../../contexts/AggregatedBalancesContext";
import Icon from "../../components/common/icon/Icon";
import { Dropdown } from "../../components/common";
import { SOL_TOKEN_PUBKEY } from "../../constants/sol";
import { BalanceContext } from "../../contexts/BalanceContext";
import { getPlatformTokens } from "../../utils/config/utils";
import {defaultNetwork, NetworkType} from "../../utils/chain/network";
import IconUrl from "../../components/common/icon/Icon";
import IconFont from "../../components/common/iconFont/IconFont";
import Input from "../../components/common/input/Input";
import CopyIcon from "../../assets/icons/copy.svg";
import FormItem from "../../components/common/form-item/FormItem";
import { ToasterContext } from "../../contexts/ToasterContext";
import { BASE_TOAST_CONFIG, BaseToast } from "../../components/toast/BaseToast";
import LinkIcon from "../../assets/icons/link.svg";
import { TokenType } from "../../sdk/enums";
import { WarningMessage } from "../wallet/common/Warning";
import { Tab, TabSize } from "../../components/common/tab/Tab";
import { useSolAirdrop } from "../../hooks/useSolAirdrop";
import { Loading } from "../Loading";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

interface IFundWalletStepProps {
  onClose: Function;
};

export const FundWalletStep: React.FC<IFundWalletStepProps> = ({ onClose }) => {
  const { mergedTokens } = useContext(AggregatedBalancesContext);
  const { solBalances } = useContext(BalanceContext);
  const { walletPubkey } = useContext(WrappedWalletContext);
  const toast = useContext(ToasterContext);

  const [selectedToken, setSelectedToken] = useState<IMergedToken>();

  const isWrappedAsset = useMemo(() => {
    return selectedToken?.house?.type == TokenType.OtherWrappedTradingCurrency;
  }, [selectedToken]);

  const wrappedWarning = useMemo(() => {
    if (isWrappedAsset == false) {
      return;
    }

    return {
      title: `CAUTION: Are you funding from a centralized exchange?`,
      message: `This is wrapped ${selectedToken?.context?.symbol} token and not a native ${selectedToken?.context?.symbol} accepted by most exchanges. Transferring this token to centralized exchanges will likely lead to the tokens being lost and irrecoverable. This token can be sent to other Solana wallets or swapped for a different token supported by your centralized exchange. `,
      link: (
        <a className="hover:underline" target="__blank" href="https://spl.solana.com/token">
          Learn more about wrapped tokens {`>`}
        </a>
      ),
    };
  }, [selectedToken, isWrappedAsset]);

  const solContext = useMemo(() => {
    const allGames = getPlatformTokens(defaultNetwork);

    return allGames.find((token) => {
      return token.pubkey == SOL_TOKEN_PUBKEY.toString();
    });
  }, []);

  const walletMergedTokens = useMemo(() => {
    if (mergedTokens == null) {
      return [];
    }

    const hasSol = mergedTokens.find((mergedToken) => {
      return mergedToken.context?.pubkey == SOL_TOKEN_PUBKEY.toString();
    });

    if (hasSol != null) {
      return [...mergedTokens];
    }

    // ENSURE SOL IS AVAILABLE FOR FUND/TRANSFER
    const walletMerged = [...mergedTokens];
    const sol: IMergedToken = {
      wallet: solBalances?.native,
      platform: undefined,
      house: undefined,
      context: solContext,
    };
    walletMerged.push(sol);

    return walletMerged;
  }, [mergedTokens, solBalances, solContext]);

  const copyLinkToClipboard = useCallback(
    (toCopy: string) => {
      window.navigator.clipboard.writeText(toCopy);
      toast(
        <BaseToast
          icon={<Icon size="lg" icon={<LinkIcon />} />}
          type={"success"}
          message={"Successfully copied wallet pubkey to clipboard."}
        />,
        BASE_TOAST_CONFIG,
      );
    },
    [history, toast],
  );

  useEffect(() => {
    if (selectedToken == null && walletMergedTokens != null) {
      setSelectedToken(walletMergedTokens[0]);
    } else if (walletMergedTokens != null && selectedToken != null) {
      setSelectedToken(
        walletMergedTokens.find((token) => {
          return token.context?.pubkey == selectedToken.context?.pubkey;
        }),
      );
    }
  }, [walletMergedTokens]);

  const { isSolAirdropLoading, handleAirdropSol } = useSolAirdrop();

  if (isSolAirdropLoading) {
    return <Loading title="Loading" message="Please wait, airdropping sol..." />;
  }

  return (
    <div className="flex flex-col items-start gap-y-5 self-stretch">
      <div className="flex w-full">
        <div className="hidden sm:flex items-end gap-x-2">
          <Tab
            active={true}
            size={TabSize.SM}
            // hoverClasses=""
            extraClasses="[&]:bg-gray-500"
            onClick={() => {}}
          >
            <div className="flex gap-1.5 items-center">
              <IconFont name="download" /> Transfer
            </div>
          </Tab>
          {/*TODO: Uncomment once "Fund from Wallet" functionality implemented*/}
          {/*<Tab*/}
          {/*  active={false}*/}
          {/*  size={TabSize.SM}*/}
          {/*  hoverClasses=""*/}
          {/*  onClick={() => {}}*/}
          {/*>*/}
          {/*  <div className="flex gap-1.5 items-center">*/}
          {/*    <img*/}
          {/*      src="/static/tokens/sol-options.svg"*/}
          {/*      className="h-[18px] mb-0.5"*/}
          {/*    />*/}
          {/*    Fund from Wallet*/}
          {/*  </div>*/}
          {/*</Tab>*/}
        </div>
      </div>
      <div
        className={twMerge(
          "flex w-full p-3 items-center gap-x-3 self-stretch rounded-md bg-gray-600",
          "flex-col gap-y-5 min-h-[460px] relative"
        )}
      >
        <FormItem
          className="self-stretch"
          label="Asset"
        >
          <Dropdown
            items={
              mergedTokens?.map(token => ({
                value: token.context?.pubkey,
                data: token,
                key: token.context?.pubkey,
                name: `${token?.context?.name} (${token?.context?.symbol})`,
              })) || []
            }
            classes={{
              input: "h-[48px] bg-gray-500 hover:bg-gray-500 [&[data-headlessui-state='open']]:bg-gray-500",
              menu: "top-1 bg-gray-500 px-2 w-full",
            }}
            containerStyles="w-full sm:w-full"
            triggerButton={
              ({ open }) => (
                <div
                  className="flex items-center justify-between relative w-full px-2"
                >
                  <div className="flex items-center cursor-pointer w-full">
                    <IconUrl
                      iconUrl={getS3StaticFolderUrl(
                        `/static/tokens/${selectedToken?.context?.symbol.toLocaleLowerCase()}.svg`
                      )}
                      className="w-[24px] h-[24px] mr-3"
                    />
                    <div className="flex flex-col gap-y-0.5">
                      <div className="text-base font-semibold">
                        {selectedToken?.context?.name} ({selectedToken?.context?.symbol})
                      </div>
                    </div>
                  </div>
                  <IconFont
                    className={`transition-all ${open ? "rotate-180" : ""} text-[24px]`}
                    name="arrow_down"
                  />
                </div>
              )
            }
            itemComponent={
              ({ item }) => (
                <button
                  className="flex w-full bg-gray-500 rounded-lg hover:bg-gray-400 items-center p-2"
                  onClick={() => {
                    setSelectedToken(item.data);
                  }}
                >
                  <IconUrl
                    iconUrl={getS3StaticFolderUrl(
                      `/static/tokens/${item.data?.context?.symbol.toLocaleLowerCase()}.svg`
                    )}
                    className="w-[24px] h-[24px] mr-3 flex-shrink-0"
                  />
                  <div className="flex flex-col gap-y-0.5 relative top-0.5 text-left">
                    <div className="flex justify-start text-base font-semibold capitalize">
                      {item.name}
                    </div>
                  </div>
                </button>
              )
            }
          />
        </FormItem>
        {
          isWrappedAsset
          && (
            <WarningMessage
              link={wrappedWarning?.link}
              title={wrappedWarning?.title}
              message={wrappedWarning?.message}
            />
          )
        }
        <FormItem
          className="self-stretch"
          label={`Your ${selectedToken?.context?.symbol} fund address`}
          onClick={() => {
            copyLinkToClipboard(walletPubkey?.toString());
          }}
        >
          <Input
            name={selectedToken?.context?.name || "Unknown"}
            type="string"
            variant="regular"
            classes={{
              label: "font-sm font-normal",
              wrapper: "self-stretch relative",
            }}
            placeholder={walletPubkey?.toString()}
            value={walletPubkey?.toString()}
            rightInfo={
              <>
                <button
                  className="absolute w-full h-full top-0 left-0"
                  onClick={() => {
                    copyLinkToClipboard(walletPubkey?.toString());
                  }}
                />
                <div
                  className={`pe-1 cursor-pointer`}
                  onClick={() => {
                    copyLinkToClipboard(walletPubkey?.toString());
                  }}
                >
                  <Icon icon={<CopyIcon/>} size="lg"/>
                </div>
              </>
            }
          />
        </FormItem>

        <WarningMessage
          message={`
            Transfers must be made on Solana (SPL) network, and that tokens transferred to the wrong address or network will result in tokens being lost and irrecoverable.
          `}
          classNames="mb-12 text-gray-50"
        />
        <div className="flex w-full font-normal text-sm text-gray-300 absolute bottom-0 p-3">
          <span>
            <span className="font-semibold">Tip:</span> Use your Solana funding (public key) address to transfer Solana to your wallet.
            You can transfer from other Solana wallets or any major crypto exchanges.
          </span>
        </div>
      </div>
      <div className="flex items-start gap-x-3 self-stretch">
        <Button className="flex-1 bg-gray-500" variant="gray" size="md" onClick={onClose}>
          Close
        </Button>
        {
          defaultNetwork == NetworkType.DEVNET
          && <Button className="flex-1" variant="primary" size="md" onClick={handleAirdropSol}>
            Airdrop SOL
          </Button>
        }
      </div>
    </div>
  );
};
