import { useContext, useEffect, useMemo, useState } from "react";
import { Dropdown } from "./Dropdown";
import { IMergedToken } from "../../contexts/AggregatedBalancesContext";
import { Term } from "../registration/common/Term";
import Button from "../../components/common/button/Button";
import { Info } from "./common/Info";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import FormItem from "../../components/common/form-item/FormItem";
import Input from "../../components/common/input/Input";
import { HouseContext } from "../../contexts/HouseContext";
import { formatCurrency } from "../../utils/currency/formatting";
import Icon from "../../components/common/icon/Icon";
import InputButton from "../../components/common/input/InputButton";
import { WarningMessage } from "./common/Warning";
import { SOL_TOKEN_PUBKEY } from "../../constants/sol";
import { NetworkContext } from "../../contexts/NetworkContext";
import { ProgramContext } from "../../contexts/ProgramContext";
import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { ToasterContext } from "../../contexts/ToasterContext";
import { TransferToast } from "../../components/toast/Transfer";
import { BASE_TOAST_CONFIG } from "../../components/toast/BaseToast";
import { BalanceContext } from "../../contexts/BalanceContext";
import { TokenType } from "../../sdk/enums";
import { ZEEBIT_DOCS_URL } from "../../constants/constants";
import { TokenAmount } from "../../components/zeebros-page/commonComponents";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

// USED FOR MAX SOL TX CALCULATIONS
const MIN_RENT_EXEMPT_LAMPORTS = LAMPORTS_PER_SOL * 0.00203928;

interface ITransferTab {
  mergedTokens: IMergedToken[] | undefined;
  selectedToken: IMergedToken | undefined;
  setSelectedToken: Function;
}
export const Transfer = ({ mergedTokens, selectedToken, setSelectedToken }: ITransferTab) => {
  // CONTEXT
  const { house } = useContext(HouseContext);
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { client, recentBlockhash } = useContext(NetworkContext);
  const { meta } = useContext(ProgramContext);
  const { solBalances } = useContext(BalanceContext);
  const toast = useContext(ToasterContext);

  // STATE
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [stringAmount, setStringAmount] = useState("0");
  const [amountError, setAmountError] = useState<string>();
  const amountBase = useMemo(() => {
    if (selectedToken?.house?.pubkey == null || amount == null || amount == 0) {
      return 0;
    }

    return house?.approximateTokenAmountToBase(selectedToken?.house?.pubkey, amount || 0);
  }, [house, selectedToken, amount]);

  const maxAmount = useMemo(() => {
    if (selectedToken?.wallet?.uiAmount == null) {
      return 0;
    }

    // CHECK FOR SOL (SOL MAX = MAX - GAS - MIN RENT EXEMPT)
    if (selectedToken?.context?.isNative == true) {
      const rentAndGasLamports = 5000 + MIN_RENT_EXEMPT_LAMPORTS;
      const factor = Math.pow(10, selectedToken.context.decimals);
      const amountBasis = selectedToken.wallet.basis - rentAndGasLamports;
      const maxWithoutGas = Math.floor((amountBasis / factor) * factor) / factor;

      return maxWithoutGas > 0 ? maxWithoutGas : 0;
    }

    return selectedToken?.wallet?.uiAmount;
  }, [selectedToken]);

  useEffect(() => {
    if (amount > 0 && amount > maxAmount) {
      setAmountError("Insufficient token balance.");
    } else {
      setAmountError(undefined);
    }
  }, [amount, maxAmount]);

  const [address, setAddress] = useState<string>();
  const [addressPubkey, setAddressPubkey] = useState<PublicKey>();
  const [addressError, setAddressError] = useState<string>();

  useEffect(() => {
    if (address == null) {
      return;
    }

    try {
      const addressPubkey = new PublicKey(address);
      setAddressError(undefined);
      setAddressPubkey(addressPubkey);
    } catch (err) {
      setAddressError("Please enter a valid Solana pubkey.");
      setAddressPubkey(undefined);
    }
  }, [address]);

  useEffect(() => {
    if (selectedToken == null && mergedTokens != null) {
      setSelectedToken(mergedTokens[0]);
    } else if (mergedTokens != null && selectedToken != null) {
      setSelectedToken(
        mergedTokens.find((token) => {
          return token.context?.pubkey == selectedToken.context?.pubkey;
        }),
      );
    }
  }, [mergedTokens]);

  const [acceptedTerm, setAcceptedTerm] = useState(false);
  const showTransferToast = (type: "success" | "error", token?: { icon: any; amount: number }) => {
    toast(<TransferToast type={type} token={token} />, BASE_TOAST_CONFIG);
  };

  const transferFunds = async () => {
    setLoading(true);

    try {
      const fromPubkey = walletPubkey;
      const toPubkey = addressPubkey;
      const token = new PublicKey(selectedToken?.context?.pubkey || "");
      const tokenString = token.toString();
      const tokenIcon = (
        <img src={selectedToken?.context?.imageDarkPng} className="h-[16px] w-[16px]" />
      );
      const amountBasis = Math.floor(amount * Math.pow(10, selectedToken?.context?.decimals || 6));

      let transaction = new Transaction();

      if (tokenString == SOL_TOKEN_PUBKEY.toString()) {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: fromPubkey,
            toPubkey: toPubkey,
            lamports: amountBasis,
          }),
        );

        const sentTx = await solanaRpc?.sendTransaction(
          transaction,
          client,
          walletPubkey,
          meta?.errorByCodeByProgram,
          recentBlockhash,
        );

        showTransferToast("success", {
          icon: tokenIcon,
          amount: amount,
        });
      } else {
        let fromAta = await getAssociatedTokenAddress(token, fromPubkey);
        let toAta = await getAssociatedTokenAddress(token, toPubkey);

        try {
          let toAtaAcc = await getAccount(client, toAta);
        } catch (err) {
          console.warn("No ATA found, creating one.");
          transaction.add(
            createAssociatedTokenAccountInstruction(fromPubkey, toAta, toPubkey, token),
          );
        }

        transaction.add(createTransferInstruction(fromAta, toAta, fromPubkey, amountBasis));

        const sentTx = await solanaRpc?.sendTransaction(
          transaction,
          client,
          walletPubkey,
          meta?.errorByCodeByProgram,
          recentBlockhash,
        );

        showTransferToast("success", {
          icon: tokenIcon,
          amount: amount,
        });

        // TODO - MAYBE SHOW CONFIRMATION SCREEN?
        setAmount(0);
        setStringAmount("0");
      }
    } catch (err) {
      console.warn(err);
      console.warn(`Issue with transfer. ${err}`);

      showTransferToast("error", {
        icon: "",
        amount: amount,
      });
    } finally {
      setLoading(false);

      // RESET THE INPUTS
      setAmount(0);
      setStringAmount("0");
    }
  };
  const [transferFee, setTransferFee] = useState<number>(5000 / LAMPORTS_PER_SOL);
  useEffect(() => {
    async function checkForAta(tokenPubkey: PublicKey, wallet: PublicKey, connection: Connection) {
      try {
        const ata = await getAssociatedTokenAddress(tokenPubkey, wallet);
        const account = await getAccount(connection, ata);
      } catch (err) {
        setTransferFee((5000 + MIN_RENT_EXEMPT_LAMPORTS) / LAMPORTS_PER_SOL);
      }
    }

    if (selectedToken?.context?.isNative == true || addressPubkey == null) {
      setTransferFee(5000 / LAMPORTS_PER_SOL);
      return;
    }

    // CHECK IF THERE IS AN ATA, OTHERWISE ADD RENT
    if (selectedToken?.context?.pubkey != null && addressPubkey != null && client != null) {
      checkForAta(new PublicKey(selectedToken?.context?.pubkey), addressPubkey, client);
    }
  }, [selectedToken, addressPubkey, client]);

  const [gasWarning, setGasWarning] = useState<string>();

  // CHECK ENOUGH GAS FOR TX
  useEffect(() => {
    if (solBalances == null || transferFee == null) {
      return;
    }

    const solBalance = solBalances.native?.uiAmount || 0;

    if (transferFee > solBalance) {
      setGasWarning(`Insufficient gas for transfer. ${transferFee} SOL required.`);
    } else {
      setGasWarning(undefined);
    }
  }, [transferFee, solBalances]);

  const disableTransferFunds = useMemo(() => {
    return (
      acceptedTerm == false ||
      amount == 0 ||
      amountError != undefined ||
      addressError != undefined ||
      addressPubkey == undefined ||
      gasWarning != null
    );
  }, [acceptedTerm, amount, amountError, addressError, addressPubkey, gasWarning]);

  const isWrappedAsset = useMemo(() => {
    return selectedToken?.house?.type == TokenType.OtherWrappedTradingCurrency;
  }, [selectedToken]);

  const wrappedWarning = useMemo(() => {
    if (isWrappedAsset == false) {
      return;
    }

    return {
      title: `CAUTION: Are you transferring to a centralized exchange?`,
      message: `This is wrapped ${selectedToken?.context?.symbol} token and not a native ${selectedToken?.context?.symbol} accepted by most exchanges. Transferring this token to centralized exchanges will likely lead to the tokens being lost and irrecoverable. This token can be sent to other Solana wallets or swapped for a different token supported by your centralized exchange. `,
      link: (
        <a className="hover:underline" target="__blank" href={ZEEBIT_DOCS_URL}>
          Learn more about wrapped tokens {`>`}
        </a>
      ),
    };
  }, [selectedToken, isWrappedAsset]);

  // 621
  return (
    <div className="flex flex-col items-center gap-y-6 self-stretch">
      {/* TOKENS DROPDOWN */}
      <Dropdown
        tokens={mergedTokens || []}
        selectedToken={selectedToken}
        updateSelectedToken={setSelectedToken}
      />

      {isWrappedAsset == true ? (
        <WarningMessage
          link={wrappedWarning?.link}
          title={wrappedWarning?.title}
          message={wrappedWarning?.message}
        />
      ) : (
        ""
      )}

      {/* AMOUNT */}
      <FormItem
        label={"Amount"}
        rightLabel={<TokenAmount token="usdc" amount={formatCurrency(amountBase || 0, 2)} />}
        error={amountError}
        className="self-stretch"
      >
        <Input
          leftInfo={
            <Icon iconUrl={selectedToken?.context?.imageDarkSvg} className="mb-[2px] mr-[5px]" />
          }
          rightInfo={
            <div className="ml-[5px] flex gap-1">
              <InputButton
                className="px-2 py-1"
                onClick={() => {
                  setStringAmount(String(maxAmount / 2));
                  setAmount(maxAmount / 2);
                }}
              >
                Half
              </InputButton>
              <InputButton
                onClick={() => {
                  setStringAmount(String(maxAmount));
                  setAmount(maxAmount);
                }}
              >
                Max
              </InputButton>
            </div>
          }
          error={amountError}
          wrapperClassName="flex-1"
          className="h-[42px] flex-row"
          type={"string"}
          value={stringAmount || String(stringAmount)}
          onChange={(e) => {
            setAmount(Number(e.target.value));
            setStringAmount(e.target.value);
          }}
        />
      </FormItem>

      {/* SOL ADDRESS */}
      <FormItem label={"Wallet Address"} error={addressError} className="self-stretch">
        <Input
          error={addressError}
          wrapperClassName="flex-1"
          className="h-[42px] flex-row"
          type={"string"}
          value={address || ""}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
      </FormItem>

      {/* WARNING */}
      <Term
        className="rounded-md bg-gray-700 p-3"
        text={`I understand that transfers must be made on Solana (SPL) network, and that tokens transferred to the wrong address or network will result in tokens being lost and irrecoverable.`}
        accepted={acceptedTerm}
        setAccepted={setAcceptedTerm}
      />

      {/* TX BUTTON */}
      <div className="flex flex-col items-start gap-y-2 self-stretch">
        <Button
          className="self-stretch"
          isLoading={loading}
          disabled={disableTransferFunds == true}
          onClick={transferFunds}
          variant="primary"
          size="lg"
        >
          Transfer
        </Button>
        {gasWarning != null ? (
          <div className="text-sm font-normal text-response-error">{gasWarning}</div>
        ) : (
          ""
        )}
      </div>

      {/* WARNING 2 */}
      <Info>
        <div className="">
          {`There is no minimum transaction size for transferring funds from your wallet. However, if transferring to an exchange address you may need to ensure that you comply with their minimums or limits. 
The expected gas cost of this transfer is ${transferFee} SOL.`}
        </div>
      </Info>
    </div>
  );
};
