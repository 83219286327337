import { IPlatformRank } from "../../contexts/PlatformContext";
import { ICollectable, CollectableStatus, ClaimableStatus } from "../../sdk/betStream";
import { RewardType } from "../../sdk/enums";
import { toFormattedRewardTypeString } from "../../sdk/utils";
import { formatZeebitNumber, NumberType } from "../../utils/currency/formatting";
import { IPlayerClaim } from "../../utils/hellomoon/hellomoon";
import { ClaimType } from "./claims";

const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];

export const formatDate_DD_MMM = (date: Date) => {
    return `${date.getDate()} ${monthNames[date.getMonth()]}`
}

export const formatFullDate = (date: Date) => {
    // HH:MM  (GMT+X) DD MMM
    const offsetHours = Math.round((10 * date.getTimezoneOffset()) / 60 ) / 10
    const offsetString = offsetHours > 0 ? `+${offsetHours}`: offsetHours < 0 ? `${offsetHours}`: ''

    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')} (GMT${offsetString}) ${date.getDate()} ${monthNames[date.getMonth()]}`
}

export const getNothingToClaimMessage = (claimType: ClaimType, date: Date) => {
    return `You did not have any eligible ${toFormattedRewardTypeString(claimType)} claims on ${formatDate_DD_MMM(date)}`
}

export const getZeroFutureClaimButEligible = () => {
    return `Earn future reward claims by playing games`
}

export const getZeroFutureClaimAndNotEligible = (claimType: ClaimType, minimumRank: IPlatformRank) => {
    return `${toFormattedRewardTypeString(claimType)} benefits unlock at level ${minimumRank.name}`
}

export const toCollectTooltip = (collectable: ICollectable) => {
    const endDay = new Date(collectable.startDay)
    endDay.setUTCDate(endDay.getUTCDate() + 1)
    endDay.setUTCSeconds(endDay.getUTCSeconds() - 1)

    if (collectable.status == CollectableStatus.COLLECTED) {
        return `You successfully collected $${formatZeebitNumber(collectable.amountUi, NumberType.DOLLAR_AMOUNT)} on ${formatFullDate(collectable.startDay)}`
    } else if (collectable.status == CollectableStatus.FOREFIT) {
        return `You forgot to collect rewards which were available between ${formatFullDate(collectable.startDay)} and ${formatFullDate(endDay)}`
    } else if (collectable.status == CollectableStatus.COLLECTABLE) {
        return `You have $${formatZeebitNumber(collectable.amountUi, NumberType.DOLLAR_AMOUNT)} available to collect right now until ${formatFullDate(endDay)}`
    } else if (collectable.status == CollectableStatus.COLLECTABLE_IN_FUTURE) {
        return `A balance of $${formatZeebitNumber(collectable.amountUi, NumberType.DOLLAR_AMOUNT)} will become available to collect at ${formatFullDate(collectable.startDay)}`
    } else if (collectable.status == CollectableStatus.NOTHING_COLLECTED) {
        return `You did not have anything to collect on ${formatFullDate(collectable.startDay)}`
    } else if (collectable.status == CollectableStatus.NOTHING_TO_COLLECT) {
        return `There is currently no balance available to collect. Earn and claim rewards by playing games.`
    }
}

export const toClaimTooltip = (claim: IPlayerClaim) => {
    if (claim.status == ClaimableStatus.NOTHING_CLAIMED) {
        return `You did not have any eligible ${toFormattedRewardTypeString(claim.type)} claims on ${formatDate_DD_MMM(claim.startDate)}”`
    }

    if (claim.status == ClaimableStatus.NOTHING_TO_CLAIM) {
        return `Earn future reward claims by playing games`
    }

    if (claim.type == ClaimType.LEVEL_UP) {
        if (claim.status == ClaimableStatus.CLAIMED) {
            return `You successfully claimed $${formatZeebitNumber(claim.valueBaseUi, NumberType.DOLLAR_AMOUNT)} on ${formatDate_DD_MMM(claim.startDate)}`
        } else if (claim.status == ClaimableStatus.FOREFIT) {
            return `You forgot to claim your ${toFormattedRewardTypeString(claim.type)} – it was available to claim between ${formatFullDate(claim.startDate)} and ${formatFullDate(claim.endDate)}`
        } else if (claim.status == ClaimableStatus.ACCRUING) {
            return `You are currently earning a ${toFormattedRewardTypeString(claim.type)} which will be available to claim when you next level up.`
        } else if (claim.status == ClaimableStatus.CLAIMABLE) {
            return `You have a $${formatZeebitNumber(claim.valueBaseUi, NumberType.DOLLAR_AMOUNT)} ${toFormattedRewardTypeString(claim.type)} reward available to claim right now, or until you next level up.`
        }
    } else {
        if (claim.status == ClaimableStatus.CLAIMED) {
            return `You successfully claimed your ${toFormattedRewardTypeString(claim.type)} of  $${formatZeebitNumber(claim.valueBaseUi, NumberType.DOLLAR_AMOUNT)} on ${claim.startDate.toLocaleDateString()}`
        } else if (claim.status == ClaimableStatus.FOREFIT) {
            return `You forgot to claim your ${toFormattedRewardTypeString(claim.type)} – it was available to claim between ${formatFullDate(claim.startDate)} and ${formatFullDate(claim.endDate)}`
        } else if (claim.status == ClaimableStatus.ACCRUING) {
            return `You are currently earning a ${toFormattedRewardTypeString(claim.type)} which will be available to claim ${formatFullDate(claim.startDate)}`
        } else if (claim.status == ClaimableStatus.CLAIMABLE) {
            return `You have a $${formatZeebitNumber(claim.valueBaseUi, NumberType.DOLLAR_AMOUNT)} ${toFormattedRewardTypeString(claim.type)} reward available to claim right now, or until ${formatFullDate(claim.endDate)}.`
        }
    }
}