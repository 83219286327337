import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { ModalProvider as ReactModalProvider } from "react-modal-hook";

import "react-toastify/dist/ReactToastify.css";

import { ErrorHandlingProvider } from "./contexts/ErrorHandlingContext";
import { SocialWalletProvider } from "./contexts/WalletContext";
import { BalanceProvider } from "./contexts/BalanceContext";
import { HealthProvider } from "./contexts/HealthContext";
import { BrowserPreferenceProvider } from "./contexts/BrowserPreferenceContext";
import { HouseProvider } from "./contexts/HouseContext";
import { PlayerProvider } from "./contexts/PlayerContext";
import { AggregatedBalancesProvider } from "./contexts/AggregatedBalancesContext";
import { BetstreamingProvider } from "./contexts/BetstreamingContext";
import { NetworkProvider } from "./contexts/NetworkContext";
import { ProgramProvider } from "./contexts/ProgramContext";
import { ModalProvider } from "./contexts/ModalContext";
import { PlatformProvider } from "./contexts/PlatformContext";
import { NavProvider } from "./contexts/NavContext";
import { RandomnessDispatcherProvider } from "./contexts/RandomnessDispatcherContext";
import { UserPreferenceProvider } from "./contexts/UserPreferenceContext";
import { BannerProvider } from "./contexts/BannerContext";
import { DataCachingProvider } from "./contexts/DataCachingContext";
import { NftStakingProvider } from "./contexts/NftStakingContext";
import { ReferralProvider } from "./contexts/ReferralContext";
import { BinaryOptionsProvider } from "./contexts/BinaryOptionsContext";

import { ToasterContext } from "./contexts/ToasterContext";
import { NetworkType, defaultNetwork } from "./utils/chain/network";
import { MainContainer } from "./pages/MainContainer";
import { PermisionlessContainer } from "./containers/PermisionlessContainer";
import { HomepagePreloader } from "./pages/preloaders/HomepagePreloader";
import { GamePagePreloader } from "./pages/preloaders/GamePagePreloader";
import { WalletContext } from "./contexts/SolanaWalletContext";
import { WrappedWalletProvider } from "./contexts/WrappedWalletContext";

const HomePage = lazy(() => import('./pages/Homepage'));
const ZeebrosPage = lazy(() => import('./pages/ZeebrosPage'));
const PermisionlessDiscoveryPage = lazy(() => import('./pages/PermisionlessDiscoveryPage'));
const PermisionlessMintPage = lazy(() => import('./pages/MintJackpotPage'));
const CreateJackpotPage = lazy(() => import('./pages/CreateJackpotPage'));
const ManageJackpotPage = lazy(() => import('./pages/ManageJackpotPage'));
const BinaryOptionsPage = lazy(() => import('./pages/BinaryOptionPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const RewardsPage = lazy(() => import('./pages/RewardsPage'));
const LeaderboardPage = lazy(() => import('./pages/LeaderboardPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const PromotionsPage = lazy(() => import('./pages/PromotionsPage'));
const Error404Page = lazy(() => import('./pages/Error404Page'));
const GameContainer = lazy(() => import('./containers/GameContainer'));

export const App = () => {

  return (
    <ToasterContext.Provider value={toast}>
      <ErrorHandlingProvider>
        <HealthProvider>
          <NetworkProvider>
            <ProgramProvider>
              <WalletContext>
                <SocialWalletProvider>
                  <WrappedWalletProvider>
                    <UserPreferenceProvider>
                      <BrowserPreferenceProvider>
                        <BalanceProvider>
                          <RandomnessDispatcherProvider>
                            <HouseProvider>
                              <PlatformProvider>
                                <BetstreamingProvider>
                                  <PlayerProvider>
                                    <NftStakingProvider>
                                      <ReferralProvider>
                                        <DataCachingProvider>
                                          <AggregatedBalancesProvider>
                                            <ModalProvider>
                                              <ReactModalProvider rootComponent={TransitionGroup}>
                                                <BinaryOptionsProvider>
                                                  <NavProvider>
                                                    <BannerProvider>
                                                      <BrowserRouter>
                                                        <Routes>
                                                          <Route path="" element={<MainContainer />}>
                                                            <Route
                                                              path="zeebros"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <ZeebrosPage />
                                                                </Suspense>
                                                              }
                                                            />
                                                            <Route
                                                              path="leaderboard"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <LeaderboardPage />
                                                                </Suspense>
                                                              }
                                                            />
                                                            <Route
                                                              path="promotions"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <PromotionsPage />
                                                                </Suspense>
                                                              }
                                                            />
                                                            <Route
                                                              path="profile"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <ProfilePage />
                                                                </Suspense>
                                                              }
                                                            />
                                                            {
                                                              defaultNetwork == NetworkType.DEVNET
                                                                ? <Route
                                                                  path="rewards"
                                                                  element={
                                                                    <Suspense fallback={<HomepagePreloader />}>
                                                                      <RewardsPage />
                                                                    </Suspense>
                                                                  }
                                                                />
                                                                : ''
                                                            }
                                                            <Route
                                                              index
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <HomePage />
                                                                </Suspense>
                                                              }
                                                            />

                                                            {/* ROUTES FOR SHAREABLE BETS */}
                                                            <Route
                                                              path="bet/:betType/:betSignature/:betId"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <HomePage />
                                                                </Suspense>
                                                              }
                                                            />
                                                            <Route
                                                              path="bet/:betType/:betSignature"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <HomePage />
                                                                </Suspense>
                                                              }
                                                            />

                                                            {/* GAME CONTEXT - spec state and instance state, logs listener, game result history, bet result history. load state, start WS */}
                                                            <Route
                                                              path="game/:gameSpecPubkeyString"
                                                              element={
                                                                <Suspense fallback={<GamePagePreloader />}>
                                                                  <GameContainer />
                                                                </Suspense>
                                                              }
                                                            />
                                                            {/* BINARY OPTION */}
                                                            {
                                                              defaultNetwork != NetworkType.MAINNET
                                                              && <Route
                                                                path="options"
                                                                element={
                                                                  <Suspense fallback={<GamePagePreloader />}>
                                                                    <BinaryOptionsPage />
                                                                  </Suspense>
                                                                }
                                                              />
                                                            }

                                                            {/* PERMISIONLESS FOR MINT */}
                                                            {
                                                              defaultNetwork != NetworkType.MAINNET
                                                              &&
                                                              <>
                                                                {/* JACKPOT DISCOVERY PAGE */}
                                                                <Route
                                                                  path="jackpot/:type"
                                                                  element={
                                                                    <PermisionlessContainer>
                                                                      <Suspense fallback={<HomepagePreloader />}>
                                                                        <PermisionlessDiscoveryPage />
                                                                      </Suspense>
                                                                    </PermisionlessContainer>
                                                                  }
                                                                />

                                                                {/* MINT JACKPOT PAGE */}
                                                                <Route
                                                                  path="jackpot/:type/:mintPubkey"
                                                                  element={
                                                                    <PermisionlessContainer>
                                                                      <Suspense fallback={<GamePagePreloader />}>
                                                                        <PermisionlessMintPage />
                                                                      </Suspense>
                                                                    </PermisionlessContainer>
                                                                  }
                                                                />

                                                                {/* MINT JACKPOT PAGE */}
                                                                <Route
                                                                  path="jackpot/:type/:mintPubkey/manage"
                                                                  element={
                                                                    <PermisionlessContainer>
                                                                      <Suspense fallback={<HomepagePreloader />}>
                                                                        <ManageJackpotPage />
                                                                      </Suspense>
                                                                    </PermisionlessContainer>
                                                                  }
                                                                />

                                                                {/* CREATE JACKPOT PAGE */}
                                                                <Route
                                                                  path="jackpot/:type/create"
                                                                  element={
                                                                    <PermisionlessContainer>
                                                                      <Suspense fallback={<HomepagePreloader />}>
                                                                        <CreateJackpotPage />
                                                                      </Suspense>
                                                                    </PermisionlessContainer>
                                                                  }
                                                                />
                                                              </>
                                                            }

                                                            <Route
                                                              path="account"
                                                              element={
                                                                <Suspense fallback={<HomepagePreloader />}>
                                                                  <AccountPage />
                                                                </Suspense>
                                                              }
                                                            />

                                                            <Route
                                                              path="*"
                                                              element={
                                                                <Suspense fallback={<></>}>
                                                                  <Error404Page backHome={``} />
                                                                </Suspense>
                                                              }
                                                            />
                                                          </Route>
                                                          <Route
                                                            path="*"
                                                            element={
                                                              <Suspense fallback={<></>}>
                                                                <Error404Page backHome={``} />
                                                              </Suspense>
                                                            }
                                                          />
                                                        </Routes>
                                                      </BrowserRouter>
                                                    </BannerProvider>
                                                  </NavProvider>
                                                </BinaryOptionsProvider>
                                              </ReactModalProvider>
                                            </ModalProvider>
                                          </AggregatedBalancesProvider>
                                        </DataCachingProvider>
                                      </ReferralProvider>
                                    </NftStakingProvider>
                                  </PlayerProvider>
                                </BetstreamingProvider>
                              </PlatformProvider>
                            </HouseProvider>
                          </RandomnessDispatcherProvider>
                        </BalanceProvider>
                      </BrowserPreferenceProvider>
                    </UserPreferenceProvider>
                  </WrappedWalletProvider>
                </SocialWalletProvider>
              </WalletContext>
            </ProgramProvider>
          </NetworkProvider>
        </HealthProvider>
      </ErrorHandlingProvider>
    </ToasterContext.Provider>
  );
};
