import * as anchor from "@coral-xyz/anchor";
import { PublicKey, TransactionInstruction } from "@solana/web3.js";

import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export default class Wheel extends Game {

    constructor(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        super(
            casinoProgram,
            house,
            gameSpecPubkey,
            GameType.Wheel,
            commitmentLevel
        )
    }

    static async load(
        casinoProgram: anchor.Program,
        house: House,
        gameSpecPubkey: PublicKey,
    ) {
        const game = new Wheel(
            casinoProgram,
            house,
            gameSpecPubkey,
        )
        await game.loadState();
        return game
    }

    async placeBetIx(
        player: PlayerAccount,
        inputs: object,
        wager: number,
        tokenMintPubkey: PublicKey,
        clientSeed: Buffer,
        owner: PublicKey,
        referrer?: PublicKey,
    ): Promise<TransactionInstruction> {
        // INPUTS ARE 
        // {
        //     reelIdx: number,
        //     numSpins: number,
        //     wagerPerSpin: number
        // }

        const instanceRequest = {
            wheel: {
                reelIdx: inputs.reelIdx,
                numSpins: inputs.numSpins,
            }
        };

        const betRequest = {
            wheel: {
                wagerPerSpin: new anchor.BN(wager)
            }
        };

        const numberOfBets = inputs.numSpins;

        return await this.initAndBetSoloIxn(
            player,
            tokenMintPubkey,
            numberOfBets,
            betRequest,
            instanceRequest,
            clientSeed,
            owner,
            referrer,
        );
    }

    veryifyGameInstanceResultGivenRandomness(
        randomness: number[],
        tableSize: number,
        numberSelected: number
    ) {

        var drawnNumbers: number[] = [];

        for (let i = 0; i < numberSelected; i++) {

            const twoByteBuffer = Buffer.from(randomness.slice((i * 2), (i + 1) * 2));
            const r = twoByteBuffer.readUInt16LE(0);

            var v = (r % (tableSize - i)) + 1;

            while (drawnNumbers.includes(v)) {
                v += 1;
            }

            drawnNumbers.push(v);

        };

        return {
            drawnNumbers: drawnNumbers
        }
    };

    getBetMetas(bets: object[]) {
        let wager = 0
        let profit = 0
        let payout = 0
        let edgePercentage = 0

        const reelMaxMultipliersPerMillion = this.gameConfig?.wheel?.reelMaxMultiplierPerMillion
        const reelEdgesPerMillion = this.gameConfig?.wheel?.reelEdgesPerMillion

        bets.forEach((bet) => {
            const maxMultiplier = reelMaxMultipliersPerMillion?.[bet?.reelIdx] / Math.pow(10, 6)
            const edge = reelEdgesPerMillion?.[bet?.reelIdx] / Math.pow(10, 6)
            edgePercentage = edge

            wager += bet.wager
            
            const betMaxPayout = maxMultiplier * bet.wager
            payout += betMaxPayout

            const betMaxProfit = betMaxPayout - bet.wager
            profit += betMaxProfit
        })

        return {
            bets: bets,
            wager: wager,
            profit: profit,
            payout: payout,
            edgePercentage: edgePercentage
        }
    }
}