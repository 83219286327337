import React, { Fragment, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import Icon from "../common/icon/Icon";
import ChatCircleIcon from "./../../assets/icons/chat.svg";
import WidgetBot from "@widgetbot/react-embed";
import { GameContext } from "../../contexts/GameContext";
import { useUserMeta } from "../../hooks/useUserMeta";
import {Button} from "../common";
import {useWalletConnectionModal} from "../../hooks/modals/useWalletConnectionModal";
import {WalletModalType} from "../../modals/WalletConnectionModal";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

const getAvatarUrl = (walletPubkey: string) => {
  return `https://api.dicebear.com/7.x/bottts-neutral/png?seed=${walletPubkey}`;
};

// DISCORD CONFIG
const DISCORD_SERVER_ID = "1165176981751603272";
const DISCORD_SHARD_URL = "https://emerald.widgetbot.io";

// DISCORD CHANNELS
const MAIN_CHANNEL = "1174746652507127948"; // casino chat

export const Chat = () => {
  const { walletPubkey } = useContext(WrappedWalletContext);
  const { gameSpec } = useContext(GameContext);
  const { showWalletConnectModal } = useWalletConnectionModal(
    WalletModalType.CONNECT,
  );

  // METHOD TO GET THE CHANNEL WHEN THE GAME CHANGES (1 channel per game)
  const channel = useMemo(() => {
    return MAIN_CHANNEL;
  }, [gameSpec]);

  const avatarUrl = useMemo(() => {
    return getAvatarUrl(walletPubkey?.toString() || "");
  }, [walletPubkey]);

  const { userName } = useUserMeta();
  const [widgetBot, setWidgetBot] = useState<ReactElement>();

  // NEED TO UPDATE THE WIDGETBOT IF ANY OF THESE CHANGE
  const userMeta = useMemo(() => {
    return {
      avatar: avatarUrl,
      userName: userName,
      channel: channel,
    };
  }, [avatarUrl, userName, channel]);

  const widgetReady = useMemo(() => {
    return (
      userMeta != null &&
      userMeta.avatar != null &&
      userMeta.channel != null &&
      userMeta.userName != null
    );
  }, [userMeta]);

  // TODO - USERNAME NOT UPDATING IF LOGOUT/IN, NEED TO PASS URL FOR IMAGE,
  useEffect(() => {
    if (widgetReady == false || userMeta.userName != userName) {
      return;
    }

    setWidgetBot(
      <WidgetBot
        server={DISCORD_SERVER_ID}
        channel={userMeta.channel}
        shard={DISCORD_SHARD_URL}
        username={`${userMeta.userName}`}
        avatar={`${userMeta.avatar}`}
        accessibility={[]}
        settingsGroup=""
        defer={false}
        className="flex w-full h-full p-0"
        focusable={false}
        options={{}}
      />,
    );
  }, [userMeta, widgetReady]);

  // SET CHANNEL ID BASED ON THE GAME

  const loginToChat = useMemo(() => {
    return (
      <Fragment>
        <Icon weight="thin" className="w-[60px] h-[60px]" inheritStroke={true} icon={<ChatCircleIcon />} />

        <div className="flex flex-col items-center gap-y-4 w-full px-7">
          <div className="text-xl font-bold -mb-3">Chat</div>
          <div className="font-normal text-gray-400 text-center leading-5">Please connect to see and use chat</div>
          <Button
            variant="secondary"
            size="sm"
            isLoading={false}
            onClick={() => showWalletConnectModal()}
          >
            Connect
          </Button>
        </div>
      </Fragment>
    );
  }, []);

  return (
    <div className="inline-flex flex-col flex-grow justify-center items-center gap-y-4 text-gray-50">
      {widgetReady == false || walletPubkey == null ? loginToChat : widgetBot}
    </div>
  );
};
