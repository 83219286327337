import React, { useEffect, useState } from "react";

import { BaseModal, Countdown } from "../common";
import { ModalProps } from "../modals/types";
import RewardsModalsStepper from "./RewardsModalsStepper";
import Icon from "../common/icon/Icon";
import { formatZeebitNumber, NumberType } from "../../utils/currency/formatting";
import Button from "../common/button/Button";
import Tooltip from "../common/tooltip/Tooltip";
import IconFont from "../common/iconFont/IconFont";
import Scrollbar from "../common/scrollbar/Scrollbar";
import { IRewardTransactionMeta } from "../../sdk/betStream";
import TableGrid from "../common/table-grid/TableGrid";
import { headerCellWithTooltip } from "../zeebros-page/commonComponents";
import rewardsList from "../../config/rewards_list.json";
import { getS3StaticFolderUrl } from "../../utils/config/utils";

const RewardsLoading: React.FC<{rewardsType: RewardsTypes}> = ({ rewardsType }) => (
  <div
    data-id="claiming-rewards-loading"
    className={`
      flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] relative
    `}
  >
    {
      {
        [RewardsTypes.refferal]: (
          <img
            className="w-full animate-spin-y"
            src={getS3StaticFolderUrl("/static/ranks-and-rewards/referrals-icon.png")}
            alt="referrals icon"
          />
        ),
        [RewardsTypes.rewards]: (
          <video autoPlay muted loop className="w-full">
            <source src={getS3StaticFolderUrl("/static/ranks-and-rewards/Rewards-Animation.webm")}/>
          </video>
        ),
      }[rewardsType]
    }
    <div className={`flex items-center font-semibold text-gray-300 text-lg`}>
      <span>Claiming Rewards</span>
    </div>
  </div>
);

interface ILevelUpProps {
  levelUpData: IRewardTransactionMeta["levelUp"]
};

const LevelUp: React.FC<ILevelUpProps> = ({ levelUpData }) => (
  <div
    data-id="rewards-level-up-modal"
    className={`
      flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] p-2 [&]:!pr-2 sm:p-5 mt-5 rounded-md bg-top bg-contain
    `}
    style={{
      backgroundImage: `
        url("${getS3StaticFolderUrl("/static/ranks-and-rewards/stars-banner.png")}"),
        linear-gradient(180deg, #65593C 0%, #383958 70%)
      `,
      backgroundRepeat: "no-repeat",
    }}
  >
    <div className="flex w-full flex-col items-center">
      <img
        src={getS3StaticFolderUrl("/static/ranks-and-rewards/Level-Up-reward.png")}
        alt={`Level Up image`}
        className={`mx-auto mt-8 -mb-2 w-[130px] sm:w-[160px]`}
      />
      <div className="text-brand-off-white text-xl sm:text-[24px] leading-8 text-center font-semibold">
        You’ve levelled up to&nbsp;
        <span className="text-gold-200">{levelUpData?.newRank?.name}</span>
      </div>
      <div className="flex text-lg font-semibold items-center mt-2">
        <img
          src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
          className="w-[16px] h-[16px] rounded-full mr-1 mb-1"
          alt="token"
        />
        {}
        {formatZeebitNumber((levelUpData?.claimable?.valueBaseUi || 0), NumberType.DOLLAR_AMOUNT)}
        &nbsp;Rewarded
      </div>
    </div>

    <div className="flex flex-col gap-y-2 items-center bg-gray-700 rounded w-full py-3">
      {
        levelUpData?.newRank?.benefit.rakebackBoostPerThousand
        && <div
          className={`
            flex items-center font-normal justify-center
            ${levelUpData?.newRank?.benefit.rakebackBoostPerThousand ? "text-gray-50" : "text-gray-300"}
          `}
          style={{...(!levelUpData?.newRank?.benefit.rakebackBoostPerThousand && {filter: "grayscale()"})}}
        >
          <Icon
            iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/thunder-icon.png")}
            size="xl"
            className="bg-gray-800 border-2 border-gray-800 mr-2 rounded mb-1"
          />
          {
            levelUpData?.newRank?.benefit.rakebackBoostPerThousand
              ? (
                formatZeebitNumber(
                  (levelUpData?.newRank?.benefit.rakebackBoostPerThousand || 0) / 1000,
                  NumberType.PROBABILITY,
                  levelUpData?.newRank?.benefit.rakebackBoostPerThousand < 100 ? 2 : 3
                ) + " %"
              )
              : "No"
          }
          &nbsp;Rakeback Boost for&nbsp;
          {
            levelUpData.claimable.spreadDays
          }
          &nbsp;days
        </div>
      }
      <div className="flex w-full flex-col sm:flex-row gap-y-2">
        <div className="flex flex-col w-full gap-y-2">
          <div
            className={`
              flex items-center font-normal justify-center
              ${levelUpData?.newRank?.benefit.defaultRakebackRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
            style={{...(!levelUpData?.newRank?.benefit.defaultRakebackRatePerThousand && {filter: "grayscale()"})}}
          >
            <Icon
              iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Rakeback.png")}
              size="xl"
              className="bg-gray-800 border-2 border-gray-800 mr-2 rounded mb-1"
            />
            {
              levelUpData?.newRank?.benefit.defaultRakebackRatePerThousand
                ? (
                  formatZeebitNumber(
                    (levelUpData?.newRank?.benefit.defaultRakebackRatePerThousand || 0) / 1000,
                    NumberType.PROBABILITY,
                    levelUpData?.newRank?.benefit.defaultRakebackRatePerThousand < 100 ? 2 : 3
                  ) + " %"
                )
                : "No"
            }
            &nbsp;Rakeback Rate
          </div>
          <div
            className={`
              flex items-center font-normal justify-center
              ${levelUpData?.newRank?.benefit.weeklyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
            style={{...(!levelUpData?.newRank?.benefit.weeklyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}
          >
            <Icon
              iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Weekly.png")}
              size="xl"
              className="bg-gray-800 border-2 border-gray-800 mr-2 rounded mb-1"
            />
            {
              levelUpData?.newRank?.benefit.weeklyBonusAccrualRatePerThousand
                ? (
                  formatZeebitNumber(
                    (levelUpData?.newRank?.benefit.weeklyBonusAccrualRatePerThousand || 0) / 1000,
                    NumberType.PROBABILITY,
                    levelUpData?.newRank?.benefit.weeklyBonusAccrualRatePerThousand < 100 ? 2 : 3
                  ) + " %"
                )
                : "No"
            }
            &nbsp;Weekly Bonus Rate
          </div>
        </div>

        <div className="flex flex-col w-full gap-y-2">
          <div
            className={`
              flex items-center font-normal justify-center
              ${levelUpData?.newRank?.benefit.monthlyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
            style={{...(!levelUpData?.newRank?.benefit.monthlyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}
          >
            <Icon
              iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Monthly.png")}
              size="xl"
              className="bg-gray-800 border-2 border-gray-800 mr-2 rounded mb-1"
            />
            {
              levelUpData?.newRank?.benefit.monthlyBonusAccrualRatePerThousand
                ? (
                  formatZeebitNumber(
                    (levelUpData?.newRank?.benefit.monthlyBonusAccrualRatePerThousand || 0) / 1000,
                    NumberType.PROBABILITY,
                    levelUpData?.newRank?.benefit.monthlyBonusAccrualRatePerThousand < 100 ? 2 : 3
                  ) + " %"
                )
                : "No"
            }
            &nbsp;Monthly Bonus Rate
          </div>
          <div
            className={`
              flex items-center font-normal justify-center
              ${levelUpData?.newRank?.benefit.dailyBonusAccrualRatePerThousand ? "text-gray-50" : "text-gray-300"}
            `}
            style={{...(!levelUpData?.newRank?.benefit.dailyBonusAccrualRatePerThousand && {filter: "grayscale()"})}}
          >
            <Icon
              iconUrl={getS3StaticFolderUrl("/static/ranks-and-rewards/Daily.png")}
              size="xl"
              className="bg-gray-800 border-2 border-gray-800 mr-2 rounded mb-1"
            />
            {
              levelUpData?.newRank?.benefit.dailyBonusAccrualRatePerThousand
                ? (
                  formatZeebitNumber(
                    (levelUpData?.newRank?.benefit.dailyBonusAccrualRatePerThousand || 0) / 1000,
                    NumberType.PROBABILITY,
                    levelUpData?.newRank?.benefit.dailyBonusAccrualRatePerThousand < 100 ? 2 : 3
                  ) + " %"
                )
                : "No"

            }
            &nbsp;Daily Bonus Rate
          </div>
        </div>
      </div>
    </div>
  </div>
);

interface IRewardsClaimedProps {
  claimedData: IRewardTransactionMeta["claimed"]
  claimedTotals: IRewardTransactionMeta["claimedTotals"],
  hideBreakdown?: boolean
};

export const RewardsClaimed: React.FC<IRewardsClaimedProps> = ({
  claimedData,
  claimedTotals,
  hideBreakdown = false
}) => {
  const [isBreakdownVisible, setIsBreakdownVisible] = useState(false);
  const [isBreakdownHiding, setIsBreakdownHiding] = useState(false);

  const handleHideBreakdown = () => {
    setIsBreakdownHiding(true);

    setTimeout(() => {
      setIsBreakdownHiding(false);
      setIsBreakdownVisible(false);
    }, 300);
  };

  const mapRewardTypeToKey: {[key: string]: string} = {
    dailyBonus: "Daily",
    levelUpBonus: "Level-Up",
    monthlyBonus: "Monthly",
    weeklyBonus: "Weekly",
    rakeback: "Rakeback",
  };

  const awardedNowAmount = (claimedData || [])
    .reduce(
      (amount, reward) => (amount + reward?.tokenAmountUpFrontUi ?? 0)
      , 0
    );
  const collectibleAmount = (claimedData || [])
    .reduce(
      (amount, reward) => (amount + reward?.tokenAmountSpreadUi ?? 0)
      , 0
    );

  const formattedRewardsList = claimedData?.map((item, index) => {
    const rewardMeta = rewardsList.find(reward => reward.key === mapRewardTypeToKey[item.type]) || {};

    return ({
      id: item.type,
      reward: (
        <div className="flex">
          <div>
            <Icon
              iconUrl={
                rewardsList.find(reward => reward.key === mapRewardTypeToKey[item.type])?.iconUrl
              }
              className="bg-gray-700 border-2 border-gray-700 mr-2 rounded mb-0 w-[40px] h-[40px]"
            />
          </div>
          <div className="flex flex-col">
            <div>{rewardMeta?.shortTitle}</div>
            <div className="text-gray-300">
              ${formatZeebitNumber(item?.valueUsdUi || 0, NumberType.DOLLAR_AMOUNT)}
            </div>
          </div>
        </div>
      ),
      awardedNow: {
        value: (
          <div className={`flex flex-col justify-center`}>
            <div className={`flex justify-center ${item?.tokenAmountUpFrontUi ? "" : "text-gray-300"}`}>
              <img
                src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                className="w-[14px] h-[14px] rounded-full mb-1 relative top-0.5"
                alt="token"
              />&nbsp;
              {formatZeebitNumber(item?.tokenAmountUpFrontUi || 0, NumberType.DOLLAR_AMOUNT)}
            </div>
            <div className={`flex justify-center text-gray-300`}>
              Now
            </div>
          </div>
        ),
      },
      collectible: {
        value: (
             <div className="flex flex-col justify-center w-full">
                <div className={`flex justify-center ${item?.tokenAmountSpreadUi ? "" : "text-gray-300"}`}>
                    ${formatZeebitNumber(item?.tokenAmountSpreadUi || 0, NumberType.DOLLAR_AMOUNT)}
                </div>
                {
                  <div className="flex text-gray-300 w-full justify-center">
                    {item?.spreadDays}
                    {item?.spreadDays === 1 ? " Day" : " Days"}
                  </div>
                }
            </div>
          ),
        classes: item.minimumRank ? "col-span-2" : ""
      },
      classes: "[&>div]:bg-gray-600 [&>div]:py-2"
    })
  });

  return (
    <div
      data-id="rewards-claimed-modal"
      className={`
        flex flex-col max-h-[70vh]
      `}
    >
      <Scrollbar handleColor="var(--gray-600)">
        <div
          className={`
            flex flex-col gap-3 sm:gap-5 items-center p-2 sm:p-5 mt-5 rounded-md bg-top pt-16
          `}
          style={{
            backgroundImage: `
              url("${getS3StaticFolderUrl("/static/ranks-and-rewards/rewards-banner.png")}"),
              linear-gradient(180deg, #3B4C88 0%, #383958 85%)
            `,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          <div className="flex w-full flex-col items-center justify-center sm:mt-12">
            <div className="text-lg font-semibold">
              You’ve Claimed
            </div>
            <div className="flex text-[48px] font-semibold leading-10 mt-6">
              <img
                src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                className="w-[36px] h-[36px] rounded-full mb-1 relative bottom-0.5"
                alt="token"
              />&nbsp;
              {formatZeebitNumber((claimedTotals?.valueBaseUi || 0), NumberType.DOLLAR_AMOUNT)}
            </div>
            <div className="flex font-semibold text-gray-200 mt-2">
              {/*TODO: Convert into dollars amount*/}
              = ${formatZeebitNumber((claimedTotals?.valueUsdUi || 0), NumberType.DOLLAR_AMOUNT)}&nbsp;
              <Tooltip key="information" content={"USD amount"}>
                <IconFont name="information"/>
              </Tooltip>
            </div>
            <div className="flex mt-3 gap-x-2">
              {
                claimedData?.map(reward => (
                  <>
                    {
                      mapRewardTypeToKey[reward?.type]
                      && <Icon
                        iconUrl={getS3StaticFolderUrl(
                          `/static/ranks-and-rewards/${mapRewardTypeToKey[reward?.type]}.png`
                        )}
                        className="w-[32px] h-[32px] bg-gray-700 border-2 border-gray-700 rounded mb-1"
                      />
                    }
                  </>
                ))
              }
            </div>
            <div className="flex flex-col w-1/2 my-2">
              <div className="flex w-1/2 h-3 border-r border-gray-400"/>
              <div className="flex w-full h-3 border-t border-x border-gray-400"/>
            </div>

            <div className="flex w-full justify-around text-lg font-semibold">
              <div className="flex bg-gray-700 rounded p-1 sm:p-3 items-center">
                <img
                  src={getS3StaticFolderUrl("/static/ranks-and-rewards/wallet-awarded-now.png")}
                  className="w-[30px] sm:w-[40px] rounded-full mr-1 sm:mr-3"
                  alt="token"
                />
                <div className="flex flex-col">
                  <div className="flex">
                    <img
                      src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                      className="w-[16px] h-[16px] rounded-full mr-1 relative top-1"
                      alt="token"
                    />
                    {formatZeebitNumber(awardedNowAmount, NumberType.DOLLAR_AMOUNT)}
                  </div>
                  <div className="flex text-xs sm:text-sm font-normal text-gray-300">
                    AWARDED NOW&nbsp;
                    <Tooltip
                      content={"For some claims, part of the bonus is collected immediately and is transferred directly to your wallet."}
                      className="hidden sm:flex"
                    >
                      <IconFont name="information"/>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex bg-gray-700 rounded p-1 sm:p-3 items-center">
                <img
                  src={getS3StaticFolderUrl("/static/ranks-and-rewards/calendar-collectible.png")}
                  className="w-[30px] sm:w-[40px] rounded-full mr-3"
                />
                <div className="flex flex-col">
                  <div className="flex">
                    <img
                      src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
                      className="w-[16px] h-[16px] rounded-full mr-1 relative top-1"
                    />
                    {formatZeebitNumber(collectibleAmount, NumberType.DOLLAR_AMOUNT)}
                  </div>
                  <div className="flex text-xs sm:text-sm font-normal text-gray-300">
                    COLLECTIBLE&nbsp;
                    <Tooltip
                      content={
                        "For some claims, part of the bonus is allocated to your reward calendar and can be collected across future days.\n" +
                        "The proportion allocated to the reward calendar, and the number of days the collection is spread across varies by reward type. View the reward breakdown to learn more."
                      }
                      className="hidden sm:flex"
                    >
                      <IconFont name="information"/>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={hideBreakdown ? "opacity-0 -z-10" : "flex w-full justify-center"}>
            {
              isBreakdownVisible
                ? (
                  <div
                    className={`
                    flex flex-col gap-y-1 items-center bg-gray-700 rounded w-full p-3 pt-5
                    ${isBreakdownHiding ? "animated-accordion-close" : "animated-accordion-open"}
                  `}
                  >
                    <button
                      className="flex items-center"
                      onClick={handleHideBreakdown}
                    >
                      Hide Breakdown&nbsp;
                      <IconFont name="arrow_up" size="lg"/>
                    </button>
                    <TableGrid
                      rows={formattedRewardsList}
                      columns={[
                        {
                          header: headerCellWithTooltip("REWARD ($)"),
                          accessor: "reward",
                          headerClasses: "text-md"
                        },
                        {
                          header: headerCellWithTooltip(
                            "AWARDED NOW",
                            `For some claims, part of the bonus is collected immediately and is transferred directly to your wallet.`
                          ),
                          accessor: "awardedNow",
                          headerClasses: "text-md"
                        },
                        {
                          header: headerCellWithTooltip(
                            "COLLECTIBLE",
                            `For some claims, part of the bonus is allocated to your reward calendar and can be collected across future days.
                          The proportion allocated to the reward calendar, and the number of days the collection is spread across varies by reward type. View the reward breakdown to learn more.
                          `
                          ),
                          accessor: "collectible",
                          headerClasses: "text-md"
                        },
                      ]}
                      classes={{
                        header: "[&>div]:pb-0 data-[id=header-cell]:text-md [&[data-id='header-cell']]:text-md text-md"
                      }}
                      variant="regular"
                      emptyMessage=" "
                    />
                  </div>
                )
                : (
                  <button
                    className="flex items-center mt-5.5"
                    onClick={() => setIsBreakdownVisible(true)}
                  >
                    Show Breakdown&nbsp;
                    <IconFont name="arrow_down" size="lg" />
                  </button>
                )
            }
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

interface IRewardsCollectedProps {
  collectedData: IRewardTransactionMeta["collected"]
};

const RewardsCollected: React.FC<IRewardsCollectedProps> = ({ collectedData }) => (
  <div
    data-id="rewards-collected-modal"
    className={`
      flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] p-5 mt-5 rounded-md bg-top bg-cover pt-16
    `}
    style={{
      backgroundImage: `
        url("${getS3StaticFolderUrl("/static/ranks-and-rewards/rewards-banner.png")}"),
        linear-gradient(180deg, #3B4C88 0%, #383958 85%)
      `,
      backgroundRepeat: "no-repeat",
    }}
  >
    <div className="flex w-full flex-col items-center justify-center">
      <div className="text-lg font-semibold">
        You’ve Collected
      </div>
      <div className="flex text-[48px] font-semibold leading-10 mt-6 relative z-10">
        <img
          src={getS3StaticFolderUrl("/static/tokens/usdc.png")}
          className="w-[36px] h-[36px] rounded-full mb-1 relative bottom-0.5"
          alt="token"
        />&nbsp;
        {formatZeebitNumber(collectedData?.amountUi || 0, NumberType.DOLLAR_AMOUNT)}
      </div>
      <div className="flex font-semibold text-gray-300 mt-2 relative z-10">
        = ${formatZeebitNumber(collectedData?.amountUsdUi || 0, NumberType.DOLLAR_AMOUNT)}&nbsp;
        <Tooltip key="information" content={"USD amount"}>
          <IconFont name="information"/>
        </Tooltip>
      </div>
      <div className="w-[260px] h-[132px] relative bottom-16">
        <img
          className="w-[260px] absolute animate-move-up-down z-0"
          src={getS3StaticFolderUrl("/static/ranks-and-rewards/collectible-hand.png")}
        />
      </div>
    </div>

    <div className="flex gap-y-1 items-center bg-gray-700 rounded w-full p-3">
      <img
        src={getS3StaticFolderUrl("/static/ranks-and-rewards/collectible-coins.png")}
        className="h-[44px] relative mr-3"
        alt="token"
      />
      <div className="flex flex-col text-sm">
        <div className="font-semibold">Collectable Remaining</div>
        <div className="font-normal">
          Your reward calendar currently has <span className="font-semibold">${formatZeebitNumber(collectedData?.remaining?.amountUsdUi || 0, NumberType.DOLLAR_AMOUNT)}</span>
          &nbsp;of future collections across the next <span className="font-semibold">{collectedData?.remaining?.numberDays} days</span>.
        </div>
      </div>
    </div>
  </div>
);

interface IBonusActivatedProps {
  rakebackBoost: IRewardTransactionMeta["rakebackBoost"]
}
const BonusActivated: React.FC<IBonusActivatedProps> = ({ rakebackBoost }) => (
  <div
    data-id="rewards-claimed-modal"
    className={`
          flex flex-col gap-3 sm:gap-5 items-center max-h-[70vh] p-5 mt-5 rounded-md bg-top bg-contain pt-16
        `}
    style={{
      backgroundImage: `
        url("${getS3StaticFolderUrl("/static/ranks-and-rewards/thunder-banner.png")}"),
        linear-gradient(180deg, #385845 0%, #383958 100%)
      `,
      backgroundRepeat: "no-repeat",
    }}
  >
    <div className="flex w-full flex-col items-center justify-center">
      <div className="text-lg font-semibold">
        Boost Activated
      </div>
      <video autoPlay muted loop className="w-full px-36">
        <source src={getS3StaticFolderUrl("/static/ranks-and-rewards/Booster-Animation.webm")} />
      </video>
    </div>
    <div className="flex flex-col items-center">
      <div className="flex text-lg sm:text-[24px] font-semibold justify-center text-center">
        +
        {formatZeebitNumber((rakebackBoost?.boostRate || 0), NumberType.PROBABILITY, rakebackBoost?.boostRate < 100 ? 3 : 2)}
        % Rakeback Boost Activated
      </div>
      <div className="text-xl font-normal mt-3 text-green-500">
        {
          rakebackBoost?.boostUntil
          && <Countdown targetDate={rakebackBoost?.boostUntil}/>
        }
      </div>
    </div>
  </div>
);

export enum RewardsTypes {
  refferal = "refferal",
  rewards = "rewards",
}

interface IRewardsModal extends ModalProps {
  isLoading: boolean;
  rewardsResponse?: IRewardTransactionMeta;
  rewardsType?: RewardsTypes;
};

const RewardsModal: React.FC<IRewardsModal> = ({
  visible,
  hideModal,
  isLoading,
  rewardsResponse,
  rewardsType = RewardsTypes.rewards
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isLevelUp = rewardsResponse?.levelUp;
  const hasRewardsClaimedStep = rewardsResponse?.claimed?.length;
  const hasRewardsCollectedStep = rewardsResponse?.collected;
  const hasBonusActivatedStep = rewardsResponse?.rakebackBoost;

  const levelUpStep = (
    isLevelUp
      ? [
        {
          content: (<LevelUp levelUpData={rewardsResponse?.levelUp} />),
          title: "Level Up",
        }
      ] : []
  );

  const rewardsClaimedStep = (
    hasRewardsClaimedStep
      ? [
        {
          content: (
            <RewardsClaimed
              claimedData={rewardsResponse?.claimed}
              claimedTotals={rewardsResponse?.claimedTotals}
              hideBreakdown={rewardsType === RewardsTypes.refferal}
            />
          ),
          title: rewardsType === RewardsTypes.refferal ? "Referral Reward Claimed" : "Rewards Claimed",
        }
      ] : []
  );

  const rewardsCollectedStep = (
    hasRewardsCollectedStep
      ? [
        {
          content: (<RewardsCollected collectedData={rewardsResponse?.collected} />),
          title: "Rewards Collected",
        }
      ] : []
  );

  const bonusActivatedStep = (
    hasBonusActivatedStep
      ? [
        {
          content: (<BonusActivated rakebackBoost={rewardsResponse?.rakebackBoost} />),
          title: "Bonus Activated",
        }
      ] : []
  );

  const steps = [
    ...levelUpStep,
    ...rewardsClaimedStep,
    ...rewardsCollectedStep,
    ...bonusActivatedStep
  ];

  useEffect(() => {
    if (!isLoading && !steps[currentStep]?.content) {
      hideModal();
    }
  }, [steps, currentStep, isLoading]);

  return (
    <BaseModal
      title={steps?.[currentStep]?.title}
      open={visible}
      withHeading={!isLoading}
      onClose={isLoading
        ? () => {}
        : hideModal
      }
      classes={{
        dialog: `
          ${isLoading ? "w-[320px] sm:max-w-[320px]" : "w-[342px] sm:max-w-[600px]"}
          bg-gray-800 px-3 sm:px-6
        `
      }}
    >
      {
        isLoading
          ? <RewardsLoading rewardsType={rewardsType} />
          : (
            <>
              <RewardsModalsStepper steps={steps.length} currentStep={currentStep}/>
              {
                steps[currentStep]?.content
              }
            </>
          )
      }
      {
        !isLoading
        && steps[currentStep]?.content
        && (
          <div className={`flex gap-x-3 mt-5`}>
            {
              !!currentStep
              && (
                <Button
                  variant="secondary-gray"
                  className={`w-full`}
                  onClick={() => {
                    setCurrentStep(step => step - 1)
                  }}
                >
                  Back
                </Button>
              )
            }
            {
              currentStep !== steps.length - 1
                ? (
                  <Button
                    variant="secondary"
                    className={`w-full`}
                    onClick={() => {
                      setCurrentStep(step => step + 1)
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className={`w-full`}
                    onClick={hideModal}
                  >
                    Continue Playing
                  </Button>
                )
            }

          </div>
        )
      }
    </BaseModal>
  );
}

export default RewardsModal;
