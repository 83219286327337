import React, { FC, useLayoutEffect, useMemo, useRef } from "react";
import gsap from "gsap";

import { XpLoadProps } from "./XpLoad";

const BareXpLoad: FC<XpLoadProps> = ({ startValue = 0, endValue: actualEndValue, total }) => {
  const arcRef = useRef<HTMLInputElement | null>(null);
  const endValue = useMemo(
    () => (actualEndValue > total ? total + 1 : actualEndValue),
    [total, actualEndValue],
  );
  useLayoutEffect(() => {
    const strokeRange = 502;
    const strokeInVal = strokeRange / total;
    const startStroke = Math.floor(startValue * strokeInVal);
    const endStroke = Math.floor(endValue > total ? total * strokeInVal : endValue * strokeInVal);

    const relativeDuration = Math.floor(((endValue - startValue) / total) * 30) / 10;

    const duration = endValue === 0 ? 0 : relativeDuration;
    const ease = endValue + total / 10 > total ? "easy.out" : "elastic.out(0.2,0.1)";
    const delay = 0.5;
    const timeObj = {
      delay: delay,
      repeatDelay: 0.5,
    };

    gsap.timeline(timeObj).fromTo(
      arcRef.current,
      {
        "stroke-dasharray": `${startStroke} 835`,
      },
      {
        "stroke-dasharray": `${endStroke} 835`,
        ease: ease,
        duration: duration,
      },
    );
  }, [startValue, actualEndValue, total]);

  const circle = {
    strokeLinecap: "round",
    transformOrigin: "center",
    strokeWidth: "7px",
    strokeDasharray: "614, 835",
    transform: "rotate(-90deg)",
  };
  const gas = useMemo(
    () => ({
      strokeWidth: endValue === 0 ? "0" : "7px",
      filter: "drop-shadow(0px 0px 12px #A34EFE80)",
      animation: "strock_aniamtion 1.5s ease-in-out infinite",
    }),
    [endValue],
  );

  return (
    <div className="flex justify-center items-center w-full h-full">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 230 230"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle style={circle} cx="50%" cy="50%" r="80" stroke="var(--gray-900)"></circle>
        <circle
          ref={arcRef}
          style={{ ...circle, ...gas }}
          cx="50%"
          cy="50%"
          r="80"
          stroke="url(#gradient)"
        ></circle>

        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="100%">
            <stop offset="0%" stopColor="var(--brand-pink)" />
            <stop offset="100%" stopColor="var(--brand-purple)" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default BareXpLoad;
