import React, { useCallback, useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { WALLET_ADAPTERS } from "@web3auth/base";

import { BaseModal } from "./BaseModal";
import Icon from "../components/common/icon/Icon";
import GoogleIcon from "../assets/icons/google-colored.svg";
import TwitterIcon from "../assets/icons/twitter-colored.svg";
import DiscordIcon from "../assets/icons/discoard-colored.svg";
import Button from "../components/common/button/Button";
import { Loading } from "./Loading";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";
import { getS3StaticFolderUrl } from "../utils/config/utils";

export enum OnboardingModalType {
  REGISTER = "register",
  CONNECT = "connect",
}

enum DetectedWalletsComponentMode {
  WEB3 = "web3",
  DETECTED = "detected",
  SUPPORTED = "supported",
}

interface IOnboardingModalProps {
  open: boolean;
  closeModal: (value: boolean) => void;
  tab?: OnboardingModalType;
}

// MODAL USED TO CONNECT OR REGISTER WALLET
const AuthorizationModal: React.FC<IOnboardingModalProps>  = ({
  open,
  closeModal,
  tab
}) => {
  const { loggedIn, connect, select } = useContext(WrappedWalletContext);
  const [isWalletsSelectorVisible, setIsWalletsSelectorVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginWithLoading = useCallback(
    async (walletAdapter: string, provider?: string) => {
      setIsLoading(true);

      try {
        if (provider == null) {
          select(walletAdapter)

          return
        }
        
        await connect(walletAdapter, provider);
      } catch (err) {
        console.warn("Issue logging into social wallet.", err);
        setIsLoading(false);
      }
    },
    [connect],
  );

  useEffect(() => {
    if (loggedIn) {
      if (open) {
        closeModal(true);
      }

      if (isWalletsSelectorVisible) {
        setIsWalletsSelectorVisible(false);
      }

      if (isLoading) {
        setIsLoading(false);
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (isWalletsSelectorVisible) {
      setIsWalletsSelectorVisible(false);
    }
  }, [tab]);

  return (
    <BaseModal
      open={open}
      withX
      hideModal={closeModal}
      classes={{
        dialog: `
          p-0 bg-gray-700 relative
          [&>div>.close-button]:absolute [&>div>.close-button]:top-4.5 [&>div>.close-button]:right-4
          [&>div>.close-button>div]:text-[22px] [&>div>.close-button]:z-50
        `,
      }}
    >
      {isLoading ? (
        <Loading title="Loading" message="please wait a few seconds..." />
      ) : (
        <div
          data-id="register-or-connect-modal"
          className="flex w-[80vw] gap-y-3 md:w-[800px] min-h-[500px]"
        >
          <div className="hidden md:flex bg-brand-purple-light w-1/2 self-stretch relative">
            <video autoPlay muted loop className="scale-[107%] relative">
              <source src={getS3StaticFolderUrl("/static/onboarding-video.webm")} />
            </video>
          </div>
          <div className="flex flex-col w-full md:w-1/2 p-5 pb-10 relative bg-gray-700 z-10">
            <div className="flex font-semibold text-gray-200">
            <div className="capitalize">{tab}</div>
            </div>
            <div className="flex font-semibold text-gray-50 mt-6 text-lg">
              {
                isWalletsSelectorVisible
                  ? (
                    <>
                      {
                        tab === OnboardingModalType.CONNECT
                          ? "Connect with your Solana wallet."
                          : "Register with your existing Solana wallet."
                      }
                    </>
                  ) : (
                    tab === OnboardingModalType.CONNECT
                      ? "Welcome back! Connecting with your social accounts offers best experience without interruptions on Zeebit."
                      : (
                        <div className="flex flex-col">
                          <span>Create your social wallet to play on Zeebit.</span>
                          <span className="font-normal text-[16px]">Fully non-custodial and powered by web3auth.</span>
                        </div>
                      )
                  )
              }
            </div>
            {
              isWalletsSelectorVisible
                ? (<>
                  <SolanaWalletsList
                    mode={DetectedWalletsComponentMode.DETECTED}
                    login={handleLoginWithLoading}
                    setIsWalletsSelectorVisible={setIsWalletsSelectorVisible}
                  />
                  <SolanaWalletsList
                    mode={DetectedWalletsComponentMode.SUPPORTED}
                    login={handleLoginWithLoading}
                    setIsWalletsSelectorVisible={setIsWalletsSelectorVisible}
                  />
                  <div className="flex absolute bottom-5 right-0 w-full px-5">
                    <Button
                      onClick={() => {
                        setIsWalletsSelectorVisible(false);
                      }}
                      size="md"
                      variant="gray"
                      className="self-stretch w-full bg-gray-500"
                    >
                      Back
                    </Button>
                  </div>
                </>)
                : (<>
                  <Web3WalletSelector connectionType={tab} loginSocial={handleLoginWithLoading}/>
                  <SolanaWalletsList
                    login={handleLoginWithLoading}
                    setIsWalletsSelectorVisible={setIsWalletsSelectorVisible}
                  />
                </>)
            }
          </div>
        </div>
      )}
    </BaseModal>
  );
}

export default AuthorizationModal;

interface IWeb3WalletSelectorProps {
  connectionType?: OnboardingModalType;
  loginSocial: (wallet: string, provider?: string | undefined) => Promise<void>;
}

const Web3WalletSelector: React.FC<IWeb3WalletSelectorProps>  = ({
  connectionType,
  loginSocial
}) => (
  <div className="flex flex-col gap-y-3 self-stretch mt-6">
    <div className="flex items-center gap-x-2">
      <div className="flex text-sm text-gray-300 font-normal">Recommended</div>
      <div className="flex h-[1px] w-full bg-gray-400"/>
    </div>
    <div className="flex flex-col gap-y-2 self-stretch">
      {
        [
          { icon: <GoogleIcon/>, type: "google" },
          { icon: <TwitterIcon/>, type: "twitter" },
          { icon: <DiscordIcon/>, type: "discord" },
        ].map(walletButtonData => (
          <Button
            className="h-[42px] self-stretch [&:hover>div>div>div>span]:text-gray-50"
            onClick={async () => {
              await loginSocial(WALLET_ADAPTERS.OPENLOGIN, walletButtonData.type);
            }}
            variant="secondary"
            size="md"
          >
            <div className="flex w-full h-full items-center gap-x-1.5 text-gray-900 text-sm font-semibold">
              {walletButtonData.icon}
              <span>
                <span className="capitalize">{connectionType}</span>
                &nbsp;with <span className="capitalize">{walletButtonData.type}</span>
              </span>
            </div>
          </Button>
        ))
      }
    </div>
  </div>
);

export interface ISolanaWalletsListProps {
  login: Function;
  setIsWalletsSelectorVisible: Function;
  containerClasses?: string;
  mode?: DetectedWalletsComponentMode;
}

export const SolanaWalletsList: React.FC<ISolanaWalletsListProps> = ({
  login,
  containerClasses = "",
  setIsWalletsSelectorVisible,
  mode = DetectedWalletsComponentMode.WEB3
}) => {
  const { wallets } = useContext(WrappedWalletContext)
  const availableWallets = wallets
    .filter(wallet => (
      ((wallet.adapter.name.toLowerCase() in window) || mode === DetectedWalletsComponentMode.SUPPORTED) && (!(wallet.adapter.name.toLowerCase() in window) || mode !== DetectedWalletsComponentMode.SUPPORTED)
    ))

  return (
    <div className={`flex w-full flex-col mt-5 gap-y-3 ${containerClasses}`}>
      <div className="flex items-center gap-x-2">
        <div className="flex text-sm text-gray-300 font-normal whitespace-nowrap">
          {
            mode !== DetectedWalletsComponentMode.SUPPORTED
              ? `${availableWallets.length > 0 ? "Wallet" : "No wallets"}${availableWallets.length > 1 ? "s" : ""} detected`
              : `Supported Wallet${availableWallets.length > 1 ? "s" : ""}`
          }
        </div>
        <div className="flex h-[1px] w-full bg-gray-400"/>
      </div>
      <div
        className={`
          wallets-container flex w-full gap-2 flex-wrap
          ${mode !== DetectedWalletsComponentMode.WEB3 ? "flex-col" : ""}
        `}
      >
        {
          availableWallets.length
            ? (
              availableWallets.map((wallet, index) => (
                <Button
                  key={index}
                  disabled={mode === DetectedWalletsComponentMode.SUPPORTED}
                  onClick={async () => {
                    await login(wallet.adapter.name);
                  }}
                  size="md"
                  variant="secondary"
                  className={`
                    h-[42px] self-stretch
                    ${
                      mode !== DetectedWalletsComponentMode.WEB3
                        ? "flex-col"
                        : "w-1/2 bg-gray-500 text-gray-50 flex-1"
                    }
                    ${
                      mode === DetectedWalletsComponentMode.SUPPORTED
                        ? "disabled:bg-gray-600 disabled:text-gray-50"
                        : ""
                    }
                  `}
                  icon={<Icon inheritFill={false} inheritStroke={false} size="md" icon={<img src={wallet.adapter.icon} />} />}
                >
                  <span className="text-sm font-semibold capitalize">
                    {wallet.adapter.name}
                  </span>
                </Button>
              ))
            ) : (<>
              <Button
                key="solana-wallets-link"
                onClick={() => setIsWalletsSelectorVisible(true)}
                size="md"
                variant="regular"
                className="h-[42px] w-full self-stretch flex-1 bg-gray-500 text-gray-50 [&>div]:items-center"
                icon={
                  <SVG
                    src={getS3StaticFolderUrl("/static/tokens/sol-options.svg")}
                    className="[&>path]:fill-gray-50"
                    height={18}
                  />
                }
              >
                <span className="text-sm font-semibold">
                  Solana wallets
                </span>
              </Button>
            </>)
        }
      </div>
      <div
        className={`
          flex w-full justify-center
          ${mode !== DetectedWalletsComponentMode.WEB3 ? "hidden" : ""}
        `}
      >
        {
          availableWallets.length
            ? (
              <button
                className="text-gray-300 hover:text-gray-200 text-sm font-normal underline mt-2"
                onClick={() => setIsWalletsSelectorVisible(true)}
              >
                List of supported Solana wallets
              </button>
            ) : (
              <div className="text-gray-300 text-sm font-normal mt-2">
                *Social wallets offer for best experience
              </div>
            )
        }
      </div>
    </div>
  );
};
