import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { AccountMeta, PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import Platform from '../platform';
import { Metaplex, } from "@metaplex-foundation/js";
import { MPL_TOKEN_METADATA_PROGRAM_ID } from '@metaplex-foundation/mpl-token-metadata';
import { sha256 } from 'js-sha256';
import Attribute from "./attribute";
import StakeReceipt from "./stakeReceipt";
import Distribution from "./distribution";
import CollectionTable from "./collectionTable";
import Zeebro from "./zeebro";
import { MPL_TOKEN_AUTH_RULES_PROGRAM_ID } from '@metaplex-foundation/mpl-token-auth-rules';
import { MAX_ACCOUNTS_PER_COLLECT_PER_IXN, METAPLEX_AUTHORIZATION_RULES_ACCOUNT, RAKEBACK_ATTRIBUTE_ID } from "./constants";
import { SwitchboardProgram } from "@switchboard-xyz/solana.js";
import { readLatestOracleValue } from "../switchboard";
import { ZEEBRO_TRAITS_BY_ATTRIBUTE } from "./zeebrosCollection";
import Trait from "./trait";

export interface IDistributionMeta {
    attribute: {
        name: string;
        id: number;
        value: Attribute
    };
    traits: {
        name: string;
        id: number;
        image: string;
        value: Trait
    }[];
    distributionTime: Date | null | undefined;
    freezeTime: Date | null | undefined;
    attributeSelectionTime: Date | null | undefined;
    jackpotWinnersPool: number;
    traitWinnersPool: number
}

export interface ISyncMeta {
    mintPubkey: PublicKey
    applyRakebackBoost?: boolean
    applyAvatar?: boolean
}

export interface IAverageExpectancyValue {
    basis: number,
    ui: number
}

export default class NftStaking {

    private _program: Program;
    private _switchboardProgram: SwitchboardProgram;
    private _platform: Platform;
    private _mainPubkey: PublicKey;
    private _mainState: any;
    private _collectionTable: CollectionTable;
    private _attributes: Attribute[];
    private _currentDistribution: Distribution;
    private _nextDistribution: Distribution | null;
    private _eventParser: anchor.EventParser;
    private _metaplex: Metaplex;
    private _floorPriceSol: number
    private _solOracleValue: number
    private _tokenOracleValue: number
    private _averageZeebroExpectancy: IAverageExpectancyValue

    constructor(
        nftStakingProgram: anchor.Program,
        switchboardProgram: SwitchboardProgram,
        mainPubkey: PublicKey,
        platform: Platform,
    ) {
        this._program = nftStakingProgram;
        this._switchboardProgram = switchboardProgram;
        this._eventParser = new anchor.EventParser(
            this.program.programId,
            new anchor.BorshCoder(this.program.idl)
        );
        this._mainPubkey = mainPubkey;
        this._platform = platform;
        this._metaplex = Metaplex.make(this.program.provider.connection);
        this._attributes = [];
    };

    static async load(
        nftStakingProgram: anchor.Program,
        switchboardProgram: SwitchboardProgram,
        mainPubkey: PublicKey,
        platform: Platform,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const nftStaking = new NftStaking(
            nftStakingProgram,
            switchboardProgram,
            mainPubkey,
            platform
        )
        await nftStaking.loadState(commitmentLevel);
        return nftStaking
    };

    async loadState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const mainState = await this.program.account.main.fetchNullable(
            this._mainPubkey,
            commitmentLevel
        );
        if (mainState) {
            this._mainState = mainState;

        } else {
            throw new Error(`A valid main account was not found at the pubkey provided: ${this._mainPubkey}`)
        }

        await Promise.all([
            this.loadCollectionTable(mainState.collectionTable as PublicKey, commitmentLevel),
            this.loadAttributes(commitmentLevel),
            this.loadDistributions(commitmentLevel),
            this.loadOracleValues()
        ])
        return
    }

    async loadCollectionTable(
        collectionTablePubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        this._collectionTable = await CollectionTable.load(
            this,
            collectionTablePubkey,
            commitmentLevel
        );
    }

    async loadDistributions(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const currentDistributionPubkey = this.deriveDistributionPubkey(Number(this.currentInstanceNonce));
        const nextDistributionPubkey = this.deriveDistributionPubkey(Number(this.currentInstanceNonce) + 1);

        const distributionAccountStates = await this.program.account.distribution.fetchMultiple(
            [currentDistributionPubkey, nextDistributionPubkey],
            commitmentLevel
        );

        this._currentDistribution = Distribution.loadFromState(
            this,
            currentDistributionPubkey,
            Number(this.currentInstanceNonce),
            distributionAccountStates[0]
        );
        if (distributionAccountStates[1]) {
            this._nextDistribution = Distribution.loadFromState(
                this,
                nextDistributionPubkey,
                Number(this.currentInstanceNonce) + 1,
                distributionAccountStates[1]
            );
        } else {
            this._nextDistribution = null;
        }

    }

    async loadAttributes(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const attributePubkeys = [...Array(Number(this.mainState.countAttributes)).keys()].map((a) => (this.deriveAttributePubkey(a)));
        const attributeStates = await this.program.account.attribute.fetchMultipleAndContext(attributePubkeys, commitmentLevel);
        attributeStates.forEach((as, i) => {
            this._attributes.push(Attribute.loadFromState(this, i, attributePubkeys[i], as.data))
        });
    }

    async loadOracleValues() {
        if (this._switchboardProgram == null || this.floorPriceOracle == null || this.tokenOracle == null || this.solOracle == null) {
            return
        }

        const floorPriceSol = await readLatestOracleValue(this._switchboardProgram, this.floorPriceOracle)
        const solPrice = await readLatestOracleValue(this._switchboardProgram, this.solOracle)
        const tokenPrice = await readLatestOracleValue(this._switchboardProgram, this.tokenOracle)

        this._floorPriceSol = Number(floorPriceSol)
        this._solOracleValue = Number(solPrice)
        this._tokenOracleValue = Number(tokenPrice)
    }

    getAttributeState(
        attributeIdx: number,
    ) {
        return this._attributes[attributeIdx];
    }

    getAttributeTraitState(
        attributeIdx: number,
        traitIdx: number
    ) {
        const attributeState = this.getAttributeState(attributeIdx);
        return attributeState?.traits[traitIdx]
    }

    static deriveMainPubkey(
        collectionPubkey: PublicKey,
        programId: PublicKey,
    ): PublicKey {
        const [mainPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("main"),
                collectionPubkey.toBuffer(),
            ],
            programId
        );
        return mainPubkey
    };

    static deriveDistributionPubkey(
        mainPubkey: PublicKey,
        instanceNonce: number,
        programId: PublicKey,
    ): PublicKey {
        const [distributionPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("distribution"),
                mainPubkey.toBuffer(),
                new anchor.BN(instanceNonce).toArrayLike(Buffer, 'le', 2)
            ],
            programId
        );
        return distributionPubkey
    };

    deriveDistributionPubkey(
        instanceNonce: number,
    ): PublicKey {
        return NftStaking.deriveDistributionPubkey(
            this._mainPubkey,
            instanceNonce,
            this.program.programId
        )
    }

    deriveAttributePubkey(
        attributeId: number,
    ): PublicKey {
        const [attributePubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("attribute"),
                this._mainPubkey.toBuffer(),
                new anchor.BN(attributeId).toArrayLike(Buffer, 'le', 2)
            ],
            this.program.programId
        );
        return attributePubkey
    }

    static async deriveAssociatedTokenAccountPubkey(
        tokenMintPubkey: PublicKey,
        ownerPubkey: PublicKey,
        allowOffTheCurve: boolean = false,
    ): Promise<PublicKey> {
        return await getAssociatedTokenAddress(
            tokenMintPubkey,
            ownerPubkey,
            allowOffTheCurve
        );
    };

    static deriveMetadataAccountPubkey(
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        const [pda, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("metadata"),
                new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString()).toBuffer(),
                tokenMintPubkey.toBuffer()
            ],
            new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString())
        );
        return pda
    };

    static deriveTokenRecordAccountPubkey(
        tokenMintPubkey: PublicKey,
        ataPubkey: PublicKey
    ): PublicKey {
        const [pda, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("metadata"),
                new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString()).toBuffer(),
                tokenMintPubkey.toBuffer(),
                anchor.utils.bytes.utf8.encode("token_record"),
                ataPubkey.toBuffer(),
            ],
            new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString())
        );
        return pda
    };

    static deriveMasterEditionAccountPubkey(
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        const [pda, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("metadata"),
                new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString()).toBuffer(),
                tokenMintPubkey.toBuffer(),
                anchor.utils.bytes.utf8.encode("edition"),
            ],
            new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString())
        );
        return pda
    };

    static deriveMetadataDelegateRecordAccountPubkey(
        tokenMintPubkey: PublicKey,
        updateAuthorityPubkey: PublicKey,
        delegatePubkey: PublicKey,
        metadataDelegateRole: 'authority_item_delegate' | 'collection_delegate' | 'use_delegate' | 'data_delegate' | 'programmable_config_delegate' | 'data_item_delegate' | 'collection_item_delegate' | 'prog_config_item_delegate',
    ): PublicKey {
        const [pda, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("metadata"),
                new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString()).toBuffer(),
                tokenMintPubkey.toBuffer(),
                anchor.utils.bytes.utf8.encode(metadataDelegateRole),
                updateAuthorityPubkey.toBuffer(),
                delegatePubkey.toBuffer(),
            ],
            new PublicKey(MPL_TOKEN_METADATA_PROGRAM_ID.toString())
        );
        return pda
    };



    findNftMintPubkeyInCollectionTable(
        nftMintPubkey: PublicKey,
    ): number {
        return this.collectionTable.rows.findIndex((ir) => (
            ir.nftMint.toString() == nftMintPubkey.toString()
        ))
    }





    static deriveRandomDispatcherPubkey(
        randomProgramId: PublicKey
    ) {
        const [randomnessDispatcherSignerPubkey, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("dispatcher"),
            ],
            randomProgramId
        );
        return randomnessDispatcherSignerPubkey;
    }

    static getInstructionDiscriminatorFromSnakeCase(
        ixnNameSnakeCase: string
    ): number[] {
        const preimage = `${"global"}:${ixnNameSnakeCase}`;
        const discriminatorBytes = Buffer.from(sha256.digest(preimage)).slice(0, 8);
        var discriminatorU8s: number[] = [];
        discriminatorBytes.forEach((b) => { discriminatorU8s.push(Number(b)) });
        return discriminatorU8s
    }

    static deriveCallbackAttributeSelectionDiscriminator() {
        return NftStaking.getInstructionDiscriminatorFromSnakeCase("callback_attribute_selection")
    }

    static deriveCallbackResultSelectionDiscriminator() {
        return NftStaking.getInstructionDiscriminatorFromSnakeCase("callback_result_selection")
    }

    deriveCallbackTablePubkey(
        randomnessRequestPubkey: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("callback_table"),
                randomnessRequestPubkey.toBuffer()
            ],
            this.randomnessProgramPubkey
        );
        return pk
    }

    deriveRandomnessRequestPubkey(
        callerNonce: anchor.BN,
        callerUniquePubkey: PublicKey
    ) {
        const [randomnessRequestPubkey, randomnessRequestBump] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("request"),
                this.mainPubkey.toBuffer(),
                callerUniquePubkey.toBuffer(),
                callerNonce.toArrayLike(Buffer, 'le', 8)
            ],
            this.randomnessProgramPubkey
        );
        return randomnessRequestPubkey
    }

    async listenToMainForLogs(
        callbackFunction: (logs: any, context: any) => void,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const websocketId = this.program.provider.connection.onLogs(
            this.mainPubkey,
            callbackFunction,
            commitmentLevel,
        );
    };

    get program() {
        return this._program
    }

    get mainState() {
        return this._mainState
    }

    get mainPubkey() {
        return this._mainPubkey
    }

    get eventParser() {
        return this._eventParser
    }

    get collectionPubkey() {
        return this._mainState ? this._mainState.collection : null
    }

    get countPopulation() {
        return this._mainState ? this._mainState.countPopulation : null
    }

    get countStaked() {
        return this._mainState ? this._mainState.countStaked : null
    }


    get countAttributes() {
        return this._mainState ? this._mainState.countAttributes : null
    }

    get countTraits() {
        return this._mainState ? this._mainState.countTraits : null
    }

    get collectionTablePubkey() {
        return this._collectionTable.publicKey
    }

    get collectionTable() {
        return this._collectionTable
    }
    get tokenDecimals() {
        return this._mainState.tokenDecimals
    }

    get allCollectionMintPubkeys(): PublicKey[] | null {
        return this.collectionTable.rows.length ? this.collectionTable.rows.map((r) => (r.nftMint)) : null
    }

    get currentDistribution(): Distribution {
        return this._currentDistribution
    }

    get currentDistributionAttributeIsSelected(): boolean {
        return this.currentDistribution.selectedAttribute ? true : false
    }

    get currentDistributionSelectedAttribute(): Attribute | null {
        return this.currentDistributionAttributeIsSelected ? this._attributes[this.currentDistribution?.selectedAttribute] : null
    }

    get nextDistribution(): Distribution | null {
        return this._nextDistribution
    }

    get distributionMeta(): IDistributionMeta | undefined {
        const distribution = this?.currentDistribution || this?.nextDistribution;
        // TODO - CHECK WHEN NEXT DISTRIBUTION IS TO BE USED...

        if (distribution == null) {
            return
        }

        return {
            attribute: {
                id: distribution?.selectedAttribute,
                name: distribution?.selectedAttributeName,
                value: this.attributes[distribution?.selectedAttribute]
            },
            traits: distribution?.selectedAttribute != null ? Object.keys(ZEEBRO_TRAITS_BY_ATTRIBUTE[distribution?.selectedAttribute])?.map(
                (trait: string) => {
                    return {
                        id: Number(trait),
                        name: ZEEBRO_TRAITS_BY_ATTRIBUTE[distribution?.selectedAttribute][Number(trait)],
                        image: Trait.getImageUrl(distribution?.selectedAttribute, Number(trait)),
                        value: this.attributes?.[distribution?.selectedAttribute]?.traits?.[Number(trait)]
                    };
                },
            ): [],
            distributionTime: distribution?.distributionTime,
            freezeTime: distribution.freezeTime,
            attributeSelectionTime: distribution.attributeSelectionTime,
            jackpotWinnersPool: distribution?.jackpotPrizeUI,
            traitWinnersPool: distribution?.traitSelectedPrizeUI
        };
    }

    get randomnessProgramPubkey() {
        return this._mainState ? this._mainState.randomnessProgram : null
    }

    get randomnessDispatcherSignerPubkey() {
        return this._mainState ? this._mainState.randomnessDispatcherSigner : null
    }

    get currentInstanceNonce() {
        return this._mainState ? this._mainState.instanceNonce : null
    }
    get metaplex() {
        return this._metaplex
    }
    get attributes() {
        return this._attributes
    }

    get mainStatus() {
        return this.mainState?.status != null ? Object.keys(this.mainState?.status)?.[0] : null
    }

    get floorPriceOracle(): PublicKey | undefined {
        return this.mainState?.floorPriceOracle
    }

    get tokenMint(): PublicKey | undefined {
        return this.mainState?.tokenMint
    }

    get tokenOracle(): PublicKey | undefined {
        return this.mainState?.tokenOracle
    }

    get solOracle(): PublicKey | undefined {
        return this.mainState?.solOracle
    }
 
    get floorPriceSol() {
        return this._floorPriceSol
    }

    get solPrice() {
        return this._solOracleValue
    }

    get tokenPrice() {
        return this._tokenOracleValue
    }

    get floorPriceBase() {
        return this.floorPriceSol * this.solPrice
    }

    get floorPriceToken() {
        return this.floorPriceBase * this.tokenPrice
    }

    // USED TO CHECK IF STAKING/UNSTAKING IS AVAILABLE
    get isMainFrozen() {
        return this.mainStatus == null || this.mainStatus == 'frozenForDistribution'
    }

    get isFrozen() {
        const frozen = this?.isMainFrozen
    
        if (frozen) {
            return true
        }
    
        const freezeTimePassed = this?.distributionMeta?.freezeTime != null && new Date() > this?.distributionMeta?.freezeTime
        
        return freezeTimePassed
    }

    get averageZeebroExpectancy() {
        return this._averageZeebroExpectancy
    }

    setAverageZeebroExpectancy(allZeebros: Zeebro[]) {
        const averageExpectancy = allZeebros?.reduce((result, item) => {
            result += (item.expectedValue / this.countPopulation)
            return result
          }, 0)

        this._averageZeebroExpectancy = {
            basis: averageExpectancy,
            ui: averageExpectancy / Math.pow(10, this.tokenDecimals)
        }
    }

    deriveStakeReceiptAccountPubkey(
        tokenMintPubkey: PublicKey,
    ): PublicKey {
        const [pda, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("stake_receipt"),
                tokenMintPubkey.toBuffer()
            ],
            this.program.programId
        );
        return pda
    };

    async checkOwnerHasAta(
        tokenMintPubkey: PublicKey,
        ownerPubkey?: PublicKey
    ): Promise<boolean> {
        try {
            const ataInfo = await this.program.provider.connection.getTokenAccountBalance(
                await NftStaking.deriveAssociatedTokenAccountPubkey(
                    tokenMintPubkey,
                    ownerPubkey ? ownerPubkey : this.program.provider.publicKey
                )
            );
            if (ataInfo != null) {
                return true
            };
        } catch (e) {
            return false
        }
    };

    async getStakeReceiptsForOwner(
        ownerPubkey: PublicKey
    ): Promise<StakeReceipt[]> {
        return StakeReceipt.getStakeReceiptsForOwner(this, ownerPubkey);
    }

    async getZeebrosForOwner(
        ownerPubkey: PublicKey
    ): Promise<Zeebro[]> {
        return Zeebro.loadAllForOwner(this, ownerPubkey);
    }

    async getStakeTx(ownerPubkey: PublicKey, playerPubkey: PublicKey = anchor.web3.SystemProgram.programId, nftMintPubkey: PublicKey,
        applyRakebackBoost: boolean,
        applyAvatar: boolean, overwriteMeta?: ISyncMeta[], initPlayerIx?: TransactionInstruction | undefined): Promise<Transaction> {
        const numberOfAttributes = this.mainState.countAttributes;
        const attributePubkeys = Array.from(Array(numberOfAttributes).keys()).map((i) => (
            { pubkey: this.deriveAttributePubkey(i), isWritable: true, isSigner: false } as AccountMeta
        ));
        const collectionTableIdx = this.findNftMintPubkeyInCollectionTable(nftMintPubkey);

        if (collectionTableIdx < 0 || collectionTableIdx >= this.mainState.countPopulation) {
            throw new Error("Invalid collectionTableIdx")
        }

        const nftTokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(nftMintPubkey, ownerPubkey);
        const nftMetadataPubkey = NftStaking.deriveMetadataAccountPubkey(nftMintPubkey);
        const ownerTokenRecordPubkey = NftStaking.deriveTokenRecordAccountPubkey(nftMintPubkey, nftTokenAccountPubkey);
        const editionPubkey = NftStaking.deriveMasterEditionAccountPubkey(nftMintPubkey);
        const stakeReceiptPubkey = this.deriveStakeReceiptAccountPubkey(nftMintPubkey);
        const distributionPubkey = this.deriveDistributionPubkey(this.currentInstanceNonce);
        const tokenMintPubkey = this.mainState.tokenMint;
        const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, ownerPubkey)
        const hasAta = await this.checkOwnerHasAta(tokenMintPubkey, ownerPubkey);
        const platformAuthorityPermission = this._platform.derivePlatformPermissionAccountPubkey(this.mainPubkey);


        const tx = new Transaction()

        if (!hasAta) {
            tx.add(createAssociatedTokenAccountInstruction(
                ownerPubkey,
                tokenAccountPubkey,
                ownerPubkey,
                tokenMintPubkey
            ))
        };

        if (initPlayerIx != null) {
            tx.add(initPlayerIx)
        }

        const ix = await this.program.methods.stake({
            id: collectionTableIdx,
            applyRakebackBoost: applyRakebackBoost,
            applyAvatar: applyAvatar
        }).accounts({
            payer: ownerPubkey,
            nftAuthority: ownerPubkey,
            owner: ownerPubkey,
            player: playerPubkey, // TODO: Replace
            stakeReceipt: stakeReceiptPubkey,
            main: this._mainPubkey,
            collectionTable: this.collectionTable.publicKey,
            distribution: distributionPubkey,
            nftMint: nftMintPubkey,
            nftTokenAccount: nftTokenAccountPubkey,
            metadata: nftMetadataPubkey,
            edition: editionPubkey,
            ownerTokenRecord: ownerTokenRecordPubkey,
            delegateTokenRecord: MPL_TOKEN_METADATA_PROGRAM_ID, // TODO: Replace
            metaplexAuthorizationRules: METAPLEX_AUTHORIZATION_RULES_ACCOUNT, // TODO: Replace
            metaplexAuthorizationRulesProgram: MPL_TOKEN_AUTH_RULES_PROGRAM_ID,
            metaplexTokenMetadataProgram: MPL_TOKEN_METADATA_PROGRAM_ID,
            cashierProgram: this.mainState.cashierProgram,
            platform: this.mainState.platform,
            platformAuthorityPermission: platformAuthorityPermission,
            tokenProgram: TOKEN_PROGRAM_ID,
            sysvarInstructions: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId
        }).remainingAccounts(
            attributePubkeys
        ).instruction();


        // CHECK IF WE NEED TO SYNC PREV NFT TO REMOVE RAKEBACK BOOST AND AVATAR
        if (overwriteMeta != null) {
            const syncIxs = await Promise.all(overwriteMeta.map((meta) => this.getSyncIx(playerPubkey, ownerPubkey, meta.mintPubkey, meta.applyRakebackBoost, meta.applyAvatar)))

            syncIxs.forEach((ix) => tx.add(ix))
        }

        tx.add(ix);

        return tx
    }

    async getUnStakeTx(ownerPubkey: PublicKey, player: PublicKey = SystemProgram.programId, nftMintPubkey: PublicKey, overwriteMeta?: ISyncMeta[]): Promise<Transaction> {
        const numberOfAttributes = this.mainState.countAttributes;
        const attributePubkeys = Array.from(Array(numberOfAttributes).keys()).map((i) => (
            { pubkey: this.deriveAttributePubkey(i), isWritable: true, isSigner: false } as AccountMeta
        ));
        const collectionTableIdx = this.findNftMintPubkeyInCollectionTable(nftMintPubkey);
        const nftTokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(nftMintPubkey, ownerPubkey);
        const nftMetadataPubkey = NftStaking.deriveMetadataAccountPubkey(nftMintPubkey);
        const ownerTokenRecordPubkey = NftStaking.deriveTokenRecordAccountPubkey(nftMintPubkey, nftTokenAccountPubkey);
        const editionPubkey = NftStaking.deriveMasterEditionAccountPubkey(nftMintPubkey);
        const stakeReceiptPubkey = this.deriveStakeReceiptAccountPubkey(nftMintPubkey);
        const distributionPubkey = this.deriveDistributionPubkey(this.currentInstanceNonce);
        const tokenOraclePubkey = this.mainState.tokenOracle;
        const solOraclePubkey = this.mainState.solOracle;
        const floorPriceOraclePubkey = this.mainState.floorPriceOracle;
        const tokenMintPubkey = this.mainState.tokenMint;
        const tokenVaultPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, this.mainPubkey, true);
        const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, ownerPubkey, true);
        const platformAuthorityPermission = this._platform.derivePlatformPermissionAccountPubkey(this.mainPubkey);

        const tx = new Transaction()

        const ix = await this.program.methods.unstake({
            id: collectionTableIdx
        }).accounts({
            payer: ownerPubkey,
            nftAuthority: ownerPubkey,
            owner: ownerPubkey,
            player: player,
            stakeReceipt: stakeReceiptPubkey,
            main: this._mainPubkey,
            collectionTable: this.collectionTable.publicKey,
            distribution: distributionPubkey,
            nftMint: nftMintPubkey,
            nftTokenAccount: nftTokenAccountPubkey,
            metadata: nftMetadataPubkey,
            edition: editionPubkey,
            ownerTokenRecord: ownerTokenRecordPubkey,
            delegateTokenRecord: MPL_TOKEN_METADATA_PROGRAM_ID, // TODO: Replace
            metaplexAuthorizationRules: METAPLEX_AUTHORIZATION_RULES_ACCOUNT, // TODO: Replace
            metaplexAuthorizationRulesProgram: MPL_TOKEN_AUTH_RULES_PROGRAM_ID,
            metaplexTokenMetadataProgram: MPL_TOKEN_METADATA_PROGRAM_ID,
            tokenMint: tokenMintPubkey,
            tokenVault: tokenVaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenOracle: tokenOraclePubkey,
            solOracle: solOraclePubkey,
            floorPriceOracle: floorPriceOraclePubkey,
            cashierProgram: this.mainState.cashierProgram,
            platform: this.mainState.platform,
            platformAuthorityPermission: platformAuthorityPermission,
            tokenProgram: TOKEN_PROGRAM_ID,
            sysvarInstructions: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
            systemProgram: anchor.web3.SystemProgram.programId
        }).remainingAccounts(
            attributePubkeys
        ).instruction();

        tx.add(ix);

        // CHECK IF WE NEED TO SYNC NEW NFT TO REMOVE RAKEBACK BOOST AND AVATAR
        if (overwriteMeta != null) {
            const syncIxs = await Promise.all(overwriteMeta.map((meta) => this.getSyncIx(player, ownerPubkey, meta.mintPubkey, meta.applyRakebackBoost, meta.applyAvatar)))

            syncIxs.forEach((ix) => tx.add(ix))
        }

        return tx;
    }

    async getFundDistributionTx(
        ownerPubkey: PublicKey,
        platformProfitShareAmount: number,
        royaltiesShareAmount: number,
        unstakingShareAmount: number,
        discretionaryAmount: number,): Promise<Transaction> {
        const distributionPubkey = this.deriveDistributionPubkey(this.currentInstanceNonce);
        const tokenMintPubkey = this.mainState.tokenMint;
        const tokenVaultPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, this.mainPubkey, true);
        const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, ownerPubkey, true);

        const tx = new Transaction()

        const ix = await this.program.methods.fundDistribution({
            platformProfitShareAmount: new anchor.BN(platformProfitShareAmount),
            royaltiesShareAmount: new anchor.BN(royaltiesShareAmount),
            unstakingShareAmount: new anchor.BN(unstakingShareAmount),
            discretionaryAmount: new anchor.BN(discretionaryAmount),
        }).accounts({
            payer: ownerPubkey,
            main: this.mainPubkey,
            distribution: distributionPubkey,
            tokenMint: tokenMintPubkey,
            tokenVault: tokenVaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).instruction();

        tx.add(ix)

        return tx;
    }

    async getSyncTx(
        player: PublicKey,
        ownerPubkey: PublicKey,
        nftMintPubkey: PublicKey,
        applyRakebackBoost?: boolean,   // null = don't do anything, true = apply, false = remove
        applyAvatar?: boolean): Promise<Transaction> {
        const tx = new Transaction()
        const syncIx = await this.getSyncIx(player, ownerPubkey, nftMintPubkey, applyRakebackBoost, applyAvatar)

        tx.add(syncIx);

        return tx;
    }

    async getSyncIx(
        player: PublicKey,
        owner: PublicKey,
        nftMintPubkey: PublicKey,
        applyRakebackBoost?: boolean,   // null = don't do anything, true = apply, false = remove
        applyAvatar?: boolean,           // null = don't do anything, true = apply, false = remove
    ) {

        // For an NFT which you already have staked (i.e. have an active StakeReceipt)
        // A player can choose to:
        //  - Apply the rakeback boost to their player account (any existing boost applied would have to be removed first)
        //  - Remove the rakeback boost to their player account (i.e. in anticipation of applying another)
        //  - Apply their NFT as their verified avatar
        //  - Remove the NFT as their verified avatar
        // This ixn should be used in conjunction with stake an unstake instructions. For example:
        //  - If staking and the staker wants to overwrite their existing rakeback boost with the new NFT their staking,
        //     they should attach a pre-ixn to sync which removes the pre-existing rakeback boost (applyRakebackBoost=false)
        //     in front of the stake ixn for the new NFT with applyRakebackBoost=true
        //  - If unstaking and the user has selected a fallback NFT to use for rakeback boost instead, then attache a 
        //     post-ixn to sync the other NFT (the unstake ixn will automatically remove the rakeback boost that had been
        //     provided by the unstaked NFT) to apply the fallback NFT's rakeback boost.
        //  - Similarly for Avatar, though it's less crucial as this could be set/overwritten at any time.

        const collectionTableIdx = this.findNftMintPubkeyInCollectionTable(nftMintPubkey);
        const stakeReceiptPubkey = this.deriveStakeReceiptAccountPubkey(nftMintPubkey);
        const platformAuthorityPermission = this._platform.derivePlatformPermissionAccountPubkey(this.mainPubkey);
        const rakebackAttributePubkey = this.deriveAttributePubkey(RAKEBACK_ATTRIBUTE_ID);

        return await this.program.methods.sync({
            id: collectionTableIdx,
            applyRakebackBoost: (applyRakebackBoost == undefined ? null : applyRakebackBoost),
            applyAvatar: (applyAvatar == undefined ? null : applyAvatar),
        }).accounts({
            owner: owner,
            player: player,
            platform: this.mainState.platform,
            platformAuthorityPermission: platformAuthorityPermission,
            stakeReceipt: stakeReceiptPubkey,
            nftMint: nftMintPubkey,
            main: this._mainPubkey,
            collectionTable: this.collectionTable.publicKey,
            attribute: rakebackAttributePubkey,
            cashierProgram: this.mainState.cashierProgram,
        }).instruction()
    };

    async getCollectTxns(allZeebros: Zeebro[], owner: PublicKey): Promise<Transaction[]> {
        // Only include stake receipts with something to collect!
        const stakeReceiptPubkeys: PublicKey[] = [];
        allZeebros.forEach((zeebro) => {
            if (zeebro.balanceToCollect > 0 && zeebro.stakeReceipt?.publicKey != null) {
                stakeReceiptPubkeys.push(zeebro.stakeReceipt?.publicKey);
            }
        });

        const numberChunks = Math.ceil(stakeReceiptPubkeys.length / MAX_ACCOUNTS_PER_COLLECT_PER_IXN);
        const txPromises: Promise<Transaction>[] = []
        for (let i = 0; i < numberChunks; i++) {

            const stakeReceiptPubkeysChunk = stakeReceiptPubkeys.slice(
                i * MAX_ACCOUNTS_PER_COLLECT_PER_IXN,
                Math.min(stakeReceiptPubkeys.length, (i + 1) * MAX_ACCOUNTS_PER_COLLECT_PER_IXN)
            );

            txPromises.push(this.getCollectTx(stakeReceiptPubkeysChunk, owner))
        };

        return Promise.all(txPromises)
    }

    async getCollectTx(stakeReceiptPubkeys: PublicKey[], owner: PublicKey): Promise<Transaction> {
        const tx = new Transaction()

        const ix = await this.getCollectIx(stakeReceiptPubkeys, owner)
        tx.add(ix)

        return tx
    }

    async getCollectIx(stakeReceiptPubkeys: PublicKey[], owner: PublicKey): Promise<TransactionInstruction> {
        const stateReceptAccountMetas = stakeReceiptPubkeys.map(
            (pk) => ({ pubkey: pk, isSigner: false, isWritable: true })
        );
        const tokenMintPubkey = this.mainState.tokenMint;
        const tokenVaultPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, this.mainPubkey, true);
        const tokenAccountPubkey = await NftStaking.deriveAssociatedTokenAccountPubkey(tokenMintPubkey, owner, true);

        return await this.program.methods.collect(
            {}
        ).accounts({
            owner: owner,
            main: this._mainPubkey,
            collectionTable: this.collectionTable.publicKey,
            tokenMint: tokenMintPubkey,
            tokenVault: tokenVaultPubkey,
            tokenAccount: tokenAccountPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).remainingAccounts(
            stateReceptAccountMetas
        ).instruction()
    }
}
