import * as anchor from "@coral-xyz/anchor";
import { AddressLookupTableProgram, Commitment, ComputeBudgetProgram, PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, NATIVE_MINT, getAssociatedTokenAddressSync } from '@solana/spl-token';
import GameSpec from "./gameSpec";
import GameTokenInstance from "./gameTokenInstance";
import { IFormattedTokenSpecState, IJackpotConfig } from "./types";

export type JackpotGameTokenSpecConfig = {
    sharesOfPoolPerThousand: number[],
    protocolRakePerThousand: number,
    sponsorRakePerThousand: number,
    timeInterval: anchor.BN,
    minTimeToBet: anchor.BN,
    timeAlignment: anchor.BN
}
export type GameTokenSpecConfig = JackpotGameTokenSpecConfig | null;


export default class GameTokenSpec {

    private _gameSpec: GameSpec;
    private _pubkey: PublicKey;
    private _state: any;
    private _eventParser: anchor.EventParser;
    private _currentGameTokenInstance: GameTokenInstance | undefined;

    constructor(
        gameSpec: GameSpec,
        pubkey: PublicKey,
    ) {
        this._gameSpec = gameSpec;
        this._pubkey = pubkey;
    };

    static async load(
        gameSpec: GameSpec,
        pubkey: PublicKey,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const gameTokenSpec = new GameTokenSpec(
            gameSpec,
            pubkey,
        )
        await gameTokenSpec.loadState(commitmentLevel);
        return gameTokenSpec
    };

    static loadFromBuffer(
        gameSpec: GameSpec,
        pubkey: PublicKey,
        accountBuffer: Buffer
    ) {
        let state
        
        if (accountBuffer.length > 0) {
            state = gameSpec.program.coder.accounts.decode(
                "GameTokenSpec",
                accountBuffer
            );
        }

        const gameTokenSpec = new GameTokenSpec(
            gameSpec,
            pubkey,
        )
        gameTokenSpec._state = state;
        return gameTokenSpec;
    };

    async loadState(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.program.account.gameTokenSpec.fetchNullable(
            this._pubkey,
            commitmentLevel
        );
        if (state) {
            this._state = state;
        } else {
            throw new Error(`A valid account was not found at the pubkey provided: ${this.publicKey}`)
        }
        this._currentGameTokenInstance = await GameTokenInstance.load(
            this,
            this.gameTokenInstancePubkey
        );
        return
    }

    async refreshGameTokenSpec(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        const state = await this.program.account.gameTokenSpec.fetchNullable(
            this._pubkey,
            commitmentLevel
        );
        if (state) {
            this._state = state;
        }

        return this
    }

    async fetchGameTokenInstance(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        return await GameTokenInstance.load(
            this,
            this.gameTokenInstancePubkey
        );
    }

    async refreshGameTokenInstance(
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        this._currentGameTokenInstance = await this.fetchGameTokenInstance(commitmentLevel)

        return this
    }

    static async getGameTokenInstance(
        tokenSpec: GameTokenSpec,
        commitmentLevel: anchor.web3.Commitment = "processed"
    ) {
        return await GameTokenInstance.load(
            tokenSpec,
            tokenSpec.gameTokenInstancePubkey
        );
    }

    get gameSpec() {
        return this._gameSpec
    }

    get program() {
        return this._gameSpec.program
    }

    get publicKey() {
        return this._pubkey
    }

    get state() {
        return this._state
    }

    get statusString() {
        return this.state != null ? Object.keys(this.state.status)[0] : undefined
    }

    get currentGameTokenInstance() {
        return this._currentGameTokenInstance
    }

    static deriveGameTokenSpecPubkey(
        gameSpecPubkey: PublicKey,
        tokenMintPubkey: PublicKey,
        programId: PublicKey,
    ) {
        var seeds = [
            anchor.utils.bytes.utf8.encode("game_token_spec"),
            gameSpecPubkey.toBuffer(),
            tokenMintPubkey.toBuffer()
        ];
        const [pk, _] = PublicKey.findProgramAddressSync(
            seeds,
            programId
        );
        return pk
    }

    get gameTokenInstancePubkey() {
        var seeds = [
            anchor.utils.bytes.utf8.encode("game_token_instance"),
            this.publicKey.toBuffer(),
        ];
        const [pk, _] = PublicKey.findProgramAddressSync(
            seeds,
            this.gameSpec.program.programId
        );
        return pk
    }

    get tokenMintPubkey(): PublicKey {
        return this._state ? this._state.tokenMint : undefined
    }

    get treasuryVaultPubkey(): PublicKey {
        return this.gameSpec.deriveTreasuryVaultPubkey(this.tokenMintPubkey)
    }

    get gameVaultPubkey(): PublicKey {
        return this.gameSpec.deriveGameVaultPubkey(this.tokenMintPubkey)
    }

    get instanceNonce(): anchor.BN {
        return this._state ? this._state.instanceNonce : null
    }

    get config(): any {
        return this.state?.config
    }

    get jackpotConfig(): any {
        return this.config?.jackpot
    }

    // HELPERS FOR FORMATTING STATE
    get formattedJackpotConfig(): IJackpotConfig | undefined {
        return this.jackpotConfig != null ? {
            minTimeToBet: Number(this.jackpotConfig.minTimeToBet), // BETS ALLOWED UPTO N SECONDS BEFORE SETTLEMENT
            protocolRakePerThousand: Number(this.jackpotConfig.protocolRakePerThousand), // PROTOCOL RAKE OF JACKPOT
            protocolRake: Number(this.jackpotConfig.protocolRakePerThousand) / 1_000,
            sharesOfPoolPerThousand: this.jackpotConfig.sharesOfPoolPerThousand, // PAYOUTS FOR 1,2,3
            sharesOfPool: this.jackpotConfig.sharesOfPoolPerThousand.map((val: number) => {
                return val / 1_000
            }),
            sponsorRakePerThousand: Number(this.jackpotConfig.sponsorRakePerThousand), // SPONSOR RAKE OF JACKPOT
            sponsorRake: Number(this.jackpotConfig.sponsorRakePerThousand) / 1_000,
            timeAlignment: Number(this.jackpotConfig.timeAlignment), // 15 MIN PAST THE HOUR
            timeInterval: Number(this.jackpotConfig.timeInterval) // EVERY HOUR
        } : undefined
    }

    get formattedState(): IFormattedTokenSpecState | undefined {
        return this.state != null ? {
            ...this.state,
            formattedJackpotConfig: this.formattedJackpotConfig,
            gasReserve: Number(this.state.gasReserve),
            instanceNonce: Number(this.state.instanceNonce),
            minimumBet: Number(this.state.minimumBet), // MIN BET USED FOR VALIDATION
            protocolBalance: Number(this.state.protocolBalance), // AMOUNT OWED TO PROTOCOL
            sponsorBalance: Number(this.state.sponsorBalance), // AMOUNT OWED TO SPONSOR
            statusString: Object.keys(this.state.status)[0] // STATUS OF THE INSTANCE
        } : undefined
    }

    deriveTokenAccountOrWalletPubkey(
        ownerPubkey: PublicKey
    ): PublicKey {
        if (this.tokenMintPubkey == NATIVE_MINT) {
            return ownerPubkey
        } else {
            return getAssociatedTokenAddressSync(this.tokenMintPubkey, ownerPubkey, false)
        }
    }

    static deriveTokenAccountOrWalletPubkey(
        tokenMint: PublicKey,
        ownerPubkey: PublicKey
    ): PublicKey {
        if (tokenMint.toString() == NATIVE_MINT.toString()) {
            return ownerPubkey
        } else {
            return getAssociatedTokenAddressSync(tokenMint, ownerPubkey, false)
        }
    }

    deriveRandomnessRequestPubkey(
        callerNonce: anchor.BN,
        callerUniquePubkey: PublicKey
    ) {
        const [randomnessRequestPubkey, randomnessRequestBump] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("request"),
                this.gameSpec.deriveGlobalSignerPubkey().toBuffer(),
                callerUniquePubkey.toBuffer(),
                callerNonce.toArrayLike(Buffer, 'le', 8)
            ],
            this.gameSpec.state.randomnessProgram
        );
        return randomnessRequestPubkey
    }

    deriveCallbackTablePubkey(
        randomnessRequestPubkey: PublicKey
    ): PublicKey {
        const [pk, _] = PublicKey.findProgramAddressSync(
            [
                anchor.utils.bytes.utf8.encode("callback_table"),
                randomnessRequestPubkey.toBuffer()
            ],
            this.gameSpec.state.randomnessProgram
        );
        return pk
    }

    async placeBetTx(
        walletPubkey: PublicKey,
        betRequest: any, // Game Specific Struct,
        clientSeed: Buffer, // 4 bytes
        commitment: Commitment = 'processed'
    ): Promise<Transaction> {
        await this.refreshGameTokenSpec('processed');
        await this.refreshGameTokenInstance(commitment);

        const tokenAccountOrWalletPubkey = this.deriveTokenAccountOrWalletPubkey(walletPubkey);
        const globalSignerPubkey = this.gameSpec.deriveGlobalSignerPubkey();
        const recentBlockSlot = Math.floor((await this.program.provider.connection.getSlot()) / 20) * 20; // Must be a recentBlockSlot that is a multiple of 20

        // Check if this exists, or whether one needs to be created
        let gameInstanceLookupTablePubkey: PublicKey;
        let gameInstanceLookupTableIxn: TransactionInstruction;

        if (this.currentGameTokenInstance.exists && Date.now() > (this.currentGameTokenInstance.lastBetTimestamp - 1) * 1000) {
            throw Error("Can't bet within final timeframe");
        } else if (this.currentGameTokenInstance.exists) { // Has an existing instance and within betting time
            gameInstanceLookupTablePubkey = this.currentGameTokenInstance.lookupTablePubkey;
        } else {
            [gameInstanceLookupTableIxn, gameInstanceLookupTablePubkey] = AddressLookupTableProgram.createLookupTable({
                authority: globalSignerPubkey,
                payer: globalSignerPubkey,
                recentSlot: recentBlockSlot,
            })
        }

        const randomnessRequestPubkey = this.deriveRandomnessRequestPubkey(this.instanceNonce.add(new anchor.BN(1)), this.gameTokenInstancePubkey);
        const callbackTablePubkey = this.deriveCallbackTablePubkey(randomnessRequestPubkey)

        return await this.program.methods.placeBet({
            numBets: 1,
            recentBlockSlot: new anchor.BN(recentBlockSlot),
            betRequest: betRequest,
            clientSeed: clientSeed
        }).accounts({
            owner: walletPubkey,
            gameSpec: this.gameSpec.publicKey,
            gameTokenSpec: this.publicKey,
            gameTokenInstance: this.gameTokenInstancePubkey,
            lookupTable: gameInstanceLookupTablePubkey,
            tokenMint: this.tokenMintPubkey,
            tokenAccountOrWallet: tokenAccountOrWalletPubkey,
            gameVault: this.gameVaultPubkey,
            treasuryVault: this.treasuryVaultPubkey,
            globalSigner: globalSignerPubkey,
            randomnessRequest: randomnessRequestPubkey,
            callbackTable: callbackTablePubkey,
            randomnessDispatcherSigner: this.gameSpec.state.randomnessDispatcherSigner,
            gameProgram: this.program.programId,
            randomnessProgram: this.gameSpec.state.randomnessProgram,
            systemProgram: anchor.web3.SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            addressLookupTableProgram: AddressLookupTableProgram.programId,
        })
            .remainingAccounts([])
            .transaction();
    };

    static async initializeTx(
        walletPubkey: PublicKey,
        gameSpec: GameSpec,
        tokenMintPubkey: PublicKey,
        minimumBet: number,
        gasTopUpLamports: number,
        config: GameTokenSpecConfig = {
            sharesOfPoolPerThousand: [600, 300, 100],// 60%, 30%, 10%
            protocolRakePerThousand: 15,// 1.5%
            sponsorRakePerThousand: 15, // 1.5%
            timeInterval: new anchor.BN(60),// 60s cycle
            minTimeToBet: new anchor.BN(10), // Last 10s every 60s no bet
            timeAlignment: new anchor.BN(0), // shift
        },
        sponsorPaysGas: boolean = true,
        status: "active" | "inactive" = "active"
    ): Promise<Transaction> {
        const globalSignerPubkey = gameSpec.deriveGlobalSignerPubkey();
        const gameVaultPubkey = gameSpec.deriveGameVaultPubkey(tokenMintPubkey);
        const treasuryVaultPubkey = gameSpec.deriveTreasuryVaultPubkey(tokenMintPubkey);

        var configObj = {};
        configObj[gameSpec.gameTypeString] = config;

        var statusObj = {};
        statusObj[status] = {};

        return await gameSpec.program.methods.gameTokenSpecInit({
            config: configObj,
            status: statusObj,
            minimumBet: new anchor.BN(minimumBet),
            sponsorPaysGas: sponsorPaysGas,
            gasTopUp: new anchor.BN(gasTopUpLamports),
        }).accounts({
            payer: walletPubkey,
            sponsor: walletPubkey,
            treasury: gameSpec.treasuryPubkey,
            tokenMint: tokenMintPubkey,
            gameVault: gameVaultPubkey,
            gameSpec: gameSpec.publicKey,
            treasuryVault: treasuryVaultPubkey,
            globalSigner: globalSignerPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).transaction()
    };

    // UPDATE
    static async updateTx(
        walletPubkey: PublicKey,
        gameTokenSpec: GameTokenSpec,
        minimumBet: number,
        gasTopUpLamports: number,
        config: GameTokenSpecConfig = {
            sharesOfPoolPerThousand: [600, 300, 100],// 60%, 30%, 10%
            protocolRakePerThousand: 15,// 1.5%
            sponsorRakePerThousand: 15, // 1.5%
            timeInterval: new anchor.BN(60),// 60s cycle
            minTimeToBet: new anchor.BN(10), // Last 10s every 60s no bet
            timeAlignment: new anchor.BN(0), // shift
        },
        sponsorPaysGas: boolean = true,
        status: "active" | "inactive" = "active"
    ): Promise<Transaction> {
        const globalSignerPubkey = gameTokenSpec.gameSpec.deriveGlobalSignerPubkey();

        var configObj = {};
        configObj[gameTokenSpec.gameSpec.gameTypeString] = config;

        var statusObj = {};
        statusObj[status] = {};

        return await gameTokenSpec.gameSpec.program.methods.gameTokenSpecUpdate({
            config: configObj,
            status: statusObj,
            minimumBet: new anchor.BN(minimumBet),
            sponsorPaysGas: sponsorPaysGas,
            gasTopUp: new anchor.BN(gasTopUpLamports),
        }).accounts({
            payer: walletPubkey,
            sponsor: walletPubkey,
            gameSpec: gameTokenSpec.gameSpec.publicKey,
            gameTokenSpec: gameTokenSpec.publicKey,
            globalSigner: globalSignerPubkey,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).transaction()
    };

    // SWEEP FEES
    static async sweepTx(
        walletPubkey: PublicKey,
        gameTokenSpec: GameTokenSpec
    ): Promise<Transaction> {
        console.log({
            walletPubkey,
            gameTokenSpec
        })

        const globalSignerPubkey = gameTokenSpec.gameSpec.deriveGlobalSignerPubkey();
        const gameVaultPubkey = gameTokenSpec.gameSpec.deriveGameVaultPubkey(gameTokenSpec.tokenMintPubkey);
        const sponsorAtaOrWallet = GameTokenSpec.deriveTokenAccountOrWalletPubkey(gameTokenSpec.tokenMintPubkey, walletPubkey)

        return await gameTokenSpec.gameSpec.program.methods.gameTokenSpecSweep({}).accounts({
            sponsor: walletPubkey,
            gameSpec: gameTokenSpec.gameSpec.publicKey,
            gameTokenSpec: gameTokenSpec.publicKey,
            tokenMint: gameTokenSpec.tokenMintPubkey,
            globalSigner: globalSignerPubkey,
            gameVault: gameVaultPubkey,
            sponsorTokenAccount: sponsorAtaOrWallet,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        }).transaction()
    };

    // CLOSE
    static async closeTx(
        walletPubkey: PublicKey,
        gameTokenSpec: GameTokenSpec
    ): Promise<Transaction> {
        const globalSignerPubkey = gameTokenSpec.gameSpec.deriveGlobalSignerPubkey();
        const gameVaultPubkey = gameTokenSpec.gameSpec.deriveGameVaultPubkey(gameTokenSpec.tokenMintPubkey);
        const sponsorAtaOrWallet = GameTokenSpec.deriveTokenAccountOrWalletPubkey(gameTokenSpec.tokenMintPubkey, walletPubkey)

        return await gameTokenSpec.gameSpec.program.methods.gameTokenSpecClose({}).accounts({
            sponsor: walletPubkey,
            tokenMint: gameTokenSpec.tokenMintPubkey,
            gameVault: gameVaultPubkey,
            gameSpec: gameTokenSpec.gameSpec.publicKey,
            gameTokenSpec: gameTokenSpec.publicKey,
            gameTokenInstance: gameTokenSpec.gameTokenInstancePubkey,
            globalSigner: globalSignerPubkey,
            sponsorTokenAccount: sponsorAtaOrWallet,
            tokenProgram: TOKEN_PROGRAM_ID,
            associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            systemProgram: anchor.web3.SystemProgram.programId
        }).transaction()
    };
}
