// SOLANA WEB3 CONSTANTS

import { CHAIN_NAMESPACES } from "@web3auth/base";
import { NetworkType, defaultNetwork, defaultNetworkUrl } from "../utils/chain/network";
import { OPENLOGIN_NETWORK } from "@web3auth/openlogin-adapter";

// Get your Client ID from Web3Auth Dashboard
const WEB3_SOL_CLIENT_ID_DEV =
  "BKugGpuWVSTgjHko2G12iJSr-qBn5Num8nBlHjWrRzOqQ1oDdbm3CSKtEt-dPuGF-kVnK9cGNj8F1I03f7ONUBo";
const WEB3_SOL_CLIENT_ID_PROD =
  "BNQrhmdUT3RH-mp0zY6S7texqJaHyCDMEJhLcTmFbRYWFtzOJS3oo29Tf9v6QCIaMkWozVJtBp0_XrrhJ50KK-c";

export const WEB3_SOL_CLIENT =
  defaultNetwork == NetworkType.MAINNET ? WEB3_SOL_CLIENT_ID_PROD : WEB3_SOL_CLIENT_ID_DEV;

export const WEB3_CHAIN_CONFIG = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  chainId: defaultNetwork == NetworkType.MAINNET ? "0x1" : "0x3", // Please use 0x1 for Mainnet, 0x2 for Testnet, 0x3 for Devnet
  rpcTarget: defaultNetworkUrl,
  displayName: defaultNetwork == NetworkType.MAINNET ? "Solana Mainnet" : "Solana Devnet",
  blockExplorer: "https://explorer.solana.com",
  ticker: "SOL",
  tickerName: "Solana Token",
};

export const WEB3_AUTH_NO_MODAL_CONFIG = {
  clientId: WEB3_SOL_CLIENT,
  chainConfig: WEB3_CHAIN_CONFIG,
  web3AuthNetwork:
    defaultNetwork == NetworkType.MAINNET
      ? OPENLOGIN_NETWORK.SAPPHIRE_MAINNET
      : OPENLOGIN_NETWORK.SAPPHIRE_DEVNET,
};

// ETH WEB3 CONSTANTS
// Get your Client ID from Web3Auth Dashboard
export const WEB3_ETH_CLIENT_ID_DEV =
  "BAtmCMNaKsPjwWGrCAoMFKJid4ryIKiopzc6-KRUmwy6zr9N-8GjWTV3k5ZYkWARhcIZNtaPB8S3UfLSxLaM4JM";
export const WEB3_ETH_CLIENT_ID_PROD =
  "BBRxlva02niwMsc1ciLIMU7Al4sNPss5kAyYQa4F4MA5UIIvGnMw18fDV3y3qd2d4eC7wc3iShaKDo38KHvBo2U";
