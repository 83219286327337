import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { PublicKey } from "@solana/web3.js";
import { ISolanaRpc } from "../utils/solana/rpc";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { Wallet, useWallet } from "@solana/wallet-adapter-react";
import NonSocialSolanaRpc from "../utils/solana/rpc-non-social";
import { NetworkContext } from "./NetworkContext";
import { SocialWalletContext } from "./WalletContext";
import { WalletName } from "@solana/wallet-adapter-base";

export interface IWrappedWalletContext {
  // SOLANA
  loggedIn: boolean;
  connect: (wallet: string, provider?: string) => Promise<void>;
  disconnect: () => Promise<void>;
  solanaRpc: ISolanaRpc | undefined;
  walletPubkey: PublicKey | null;
  wallets: Wallet[]
  select: (walletName: WalletName | null) => void
  wallet: Wallet | null    
  connected: boolean,
  connecting: boolean
}

export const WrappedWalletContext = createContext<IWrappedWalletContext>({} as IWrappedWalletContext);

interface Props {
  children: any;
}

export const WrappedWalletProvider = ({ children }: Props) => {
  const { walletValidation } = useContext(ErrorHandlingContext);

  // WANT TO SWITCH HERE WEB3 AUTH OR REGULAR WALLET ADAPTER...
  const socialWallet = useContext(SocialWalletContext);
  const nonSocialWallet = useWallet();

  const publicKey = useMemo(() => {
    if (nonSocialWallet.publicKey != null) {
      return nonSocialWallet.publicKey
    } else if (socialWallet.walletPubkey != null) {
      return socialWallet.walletPubkey
    } else {
      return null
    }
  }, [nonSocialWallet, socialWallet])

  const disconnect = useCallback(async (): Promise<void> => {
    if (nonSocialWallet.publicKey != null && nonSocialWallet.disconnect != null) {
      return await nonSocialWallet.disconnect()
    } else if (socialWallet.walletPubkey != null && socialWallet.logoutSocial != null) {
      return await socialWallet.logoutSocial()
    }

    return Promise.reject('Neither the wallet or social wallet are connected.')
  }, [nonSocialWallet, socialWallet])

  const connect = useCallback(async (walletName?: string, provider?: string): Promise<void> => {
    if (socialWallet.loginSocial != null && walletName != null && provider != null) {
      return await socialWallet.loginSocial(walletName, provider)
    } else if (nonSocialWallet.connect != null) {
      if (nonSocialWallet.wallet?.adapter.name != null) {
        return await nonSocialWallet.wallet?.adapter.connect()
      }

      return await nonSocialWallet.connect()
    }

    return Promise.reject('Neither the wallet or social wallet are connected.')
  }, [socialWallet, nonSocialWallet])

  const connected = useMemo(() => {
    return socialWallet.loggedIn || nonSocialWallet.connected
  }, [socialWallet, nonSocialWallet])

  // CHECK WALLET CONNECTED - Change label of button and disable button (Connect to bet)
  useEffect(() => {
    if (socialWallet.walletPubkey == null && nonSocialWallet.publicKey == null) {
      walletValidation.addErrorMessage({
        type: ErrorType.WALLET_NOT_CONNECTED,
        title: "Wallet Not Connected",
        message: "You must connect your wallet place a bet.",
      });
    } else {
      walletValidation.removeErrorMessage(ErrorType.WALLET_NOT_CONNECTED);
    }
  }, [socialWallet.walletPubkey, nonSocialWallet.publicKey]);

  const { client } = useContext(NetworkContext);
  const rpc = useMemo(() => {
    if (socialWallet.walletPubkey != null && socialWallet.solanaRpc != null) {
        return socialWallet.solanaRpc
    } else if (nonSocialWallet.publicKey != null && client != null) {
        // WANT TO RETURN THE RPC CLASS
        return new NonSocialSolanaRpc(nonSocialWallet, client)
    }
  }, [socialWallet.walletPubkey, nonSocialWallet.publicKey, client])

  useEffect(() => {
    if (nonSocialWallet.wallet?.adapter.name) {
      connect(nonSocialWallet.wallet?.adapter.name)
      .then((resp) => {
        console.log('Successfully connected to the wallet!', resp)
      })
      .catch((e) => {
        console.error('Error connecting to the wallet...', e)
      })
    }
  }, [nonSocialWallet.wallet?.adapter.name])

  return (
    <WrappedWalletContext.Provider
      value={useMemo(
        () => ({
          loggedIn: connected,
          solanaRpc: rpc,
          walletPubkey: publicKey,
          wallets: nonSocialWallet.wallets,
          select: nonSocialWallet.select, 
          wallet: nonSocialWallet.wallet, 
          disconnect: disconnect,
          connect: connect,
          connected: connected,
          connecting: nonSocialWallet.connecting
        }),
        [connect, disconnect, rpc, publicKey, connected, nonSocialWallet.wallets, nonSocialWallet.select, nonSocialWallet.wallet, nonSocialWallet.connecting],
      )}
    >
      {children}
    </WrappedWalletContext.Provider>
  );
};
