import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import House from "../house";
import Game from "../gameSpec";
import PlayerAccount from "../playerAccount";
import { GameType } from "../enums";

export default class Hurdles extends Game {
  constructor(
    casinoProgram: anchor.Program,
    house: House,
    gameSpecPubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    super(casinoProgram, house, gameSpecPubkey, GameType.Hurdles, commitmentLevel);
  }

  static async load(casinoProgram: anchor.Program, house: House, gameSpecPubkey: PublicKey) {
    const game = new Hurdles(casinoProgram, house, gameSpecPubkey);
    await game.loadState();

    return game;
  }

  // METHOD TO FORMAT THE STATE
  get formattedTokens() {
    return this.state?.tokens?.map((token) => {
      return {
        ...token,
        activeLiability: token.activeLiability.toNumber(),
        activeWager: token.activeWager.toNumber(),
        pastPaidOut: token.pastPaidOut.toNumber(),
        pastWagered: token.pastWagered.toNumber(),
        pubkeyString: token.pubkey.toString(),
        statusString: Object.keys(token.status)[0]
      }
    })
  }

  get formattedConfig() {
    if (this.state?.config?.hurdles == null) {
      return
    }

    return {
      ...this.state?.config?.hurdles,
      edge: this.state?.config?.hurdles.edgePerMillion / 1_000_000,
      tokenConfigs: this.state?.config?.hurdles?.tokenConfigs?.map((tc) => {
        return {
          ...tc,
          drift: tc.driftPerMillion / 1_000_000,
          thresholdForZero: tc.thresholdForZeroPerMillion / 1_000_000,
          unscaledExpectation: tc.unscaledExpectationPerMillion / 1_000_000,
          volatility: tc.volatilityPerMillion / 1_000_000
        }
      })
    }
  }

  get formattedTokenConfigs() {
    return this.formattedConfig?.tokenConfigs
  }

  get maxBetsPerIxn() {
    return this.state?.maxBetsPerIxn
  }

  async placeBetIx(
    player: PlayerAccount,
    inputs: object,
    wager: number,
    tokenMintPubkey: PublicKey,
    clientSeed: Buffer,
    owner: PublicKey,
    referrer?: PublicKey,
  ) {
    const instanceRequest = { 
        hurdles: {
            num_tokens: inputs.allocations.length
        }
    };

    const betRequest = { 
        hurdles: {
            allocations: inputs.allocations.map((a)=>(new anchor.BN(a)))
        }
    };

    const numberOfBets = inputs.allocations.filter(v => v !== 0).length;

    return this.initAndBetSoloIxn(
      player,
      tokenMintPubkey,
      numberOfBets,
      betRequest,
      instanceRequest,
      clientSeed,
      owner,
      referrer,
    );
  }

  getMultiplier(inputs: object): number {
    return 0
  }

  getBetMetas(bets: object[]) {
    const edgePercentage = this.formattedConfig.edge
    const tokenAllocations = this.formattedTokenConfigs
    const sumOfTargetAllocationsForAllTokens = tokenAllocations.reduce((result, item) => {
      result += item.targetAllocationNumerator

      return result
    }, 0)
    const betAllocations: number[] | undefined = bets != null && bets.length == 1 ? bets[0]?.allocationsUi: undefined
    const totalWagerAccrossAllocations = betAllocations?.reduce((result, item) => {
      result += item

      return result
    }, 0) || 0
    const tokenPayouts = betAllocations?.map((allocation, index) => {
      return allocation * (sumOfTargetAllocationsForAllTokens / tokenAllocations[index].targetAllocationNumerator)
    })
    const maxPayout = tokenPayouts != null ? Math.max(...tokenPayouts): 0
    const profit = maxPayout - totalWagerAccrossAllocations

    const result = {
      payout: maxPayout,
      profit: profit,
      wager: totalWagerAccrossAllocations,
      numberOfBets: bets.length,
      bets: bets,
      dollarEdge: 0,
      edgePercentage: edgePercentage,
    };


    return result
  }
}
