import { createAvatar } from "@dicebear/core";
import { botttsNeutral } from "@dicebear/collection";

import Player from "../../sdk/playerAccount";
import { truncatePubkey } from "../string/string";
import { formatDateToDayAndMonth, toDayString } from "../date/date";
import { getWagerMeta, IDailyWager } from "../config/wagers";
import { formatCurrency } from "../currency/formatting";
import { getBaseDecimals } from "../config/utils";

export const getPlayerUsername = (player?: Player) => {
  if (player?.username?.length) {
    return player.username;
  }

  if (player?.ownerPubkey != null) {
    return truncatePubkey(player.ownerPubkey.toString(), 3);
  }

  return "";
};

export const loadPlayerAvatar = (player?: Player) => {
  const walletPubkey = player?.ownerPubkey.toString()

  if (walletPubkey == null) {
    return "";
  }

  const avatar = createAvatar(botttsNeutral, {
    seed: walletPubkey?.toString(),
  });

  return avatar.toString();
};

export interface IDailyStreak {
  day: string;
  date: string;
  wageredBasis: number;
  wageredUi: string;
  dailyMeta: IDailyWager;
}

export const getPlayerWageredMeta = (player: Player | undefined) => {
  if (!player?.sevenDayRollingWagered) {
    return;
  }

  const baseDecimals = getBaseDecimals();
  const wageredEachDay: IDailyStreak[] = [];
  let totalWageredBasis = 0;

  player.sevenDayRollingWagered?.forEach((wager) => {
    totalWageredBasis += wager.value;

    wageredEachDay.push({
      day: toDayString(wager.timestamp),
      date: formatDateToDayAndMonth(wager.timestamp),
      wageredBasis: wager.value,
      wageredUi: `$${formatCurrency(wager.value / Math.pow(10, baseDecimals), 2)}`,
      dailyMeta: getWagerMeta(wager.value),
    });
  });

  return {
    wageredBasis: totalWageredBasis,
    wageredEachDay: [...wageredEachDay].reverse(),
    wageredUi: `$${formatCurrency(totalWageredBasis / Math.pow(10, baseDecimals), 2)}`,
    week: getWagerMeta(totalWageredBasis),
  };
};
