import React, { useContext } from "react";
import SVG from "react-inlinesvg";
import { twMerge } from "tailwind-merge";
import { PublicKey } from "@solana/web3.js";

import { NftStakingContext } from "../../../contexts/NftStakingContext";
import { AvatarType } from "../../../hooks/useUserMeta";
import { generatePlayerAvatar } from "./utils";

export interface IAvatarProps {
  playerPublicKey?: PublicKey;
  playerAvatarKey?: PublicKey;
  className?: string;
  width?: number;
  height?: number;
  shouldGenerateAvatar?: boolean;
}

export const PlayerAvatar: React.FC<IAvatarProps> = ({
  playerPublicKey,
  playerAvatarKey,
  width,
  height,
  className,
  shouldGenerateAvatar
}) => {
  let avatarMeta: {
    type: AvatarType,
    value: string
  };

  if (shouldGenerateAvatar) {
    avatarMeta = generatePlayerAvatar(playerPublicKey)
  } else {
    const { zeebrosMeta } = useContext(NftStakingContext);
    const zeebroByMint = zeebrosMeta?.zeebroByMint;
    const zeebroAvatar = zeebroByMint?.get(playerAvatarKey?.toString() || "");

    avatarMeta = {
      type: AvatarType.Zeebro,
      value: zeebroAvatar?.imageUrl || ""
    };

    if (
      !zeebroAvatar
      && playerAvatarKey?.toString().length
      && playerAvatarKey?.toString().includes("111111111111")
    ) {
      avatarMeta = generatePlayerAvatar(playerPublicKey)
    };
  }

  const imageProps = {
    src: avatarMeta.value,
    width: width,
    height: height,
    className: `bg-animated-gradient-gray-700-gray-800 ${className}`
  };

  return (
    imageProps.src.length
      ? (
        <>
          {
            avatarMeta?.type == AvatarType.SVG_STRING
              ? (<SVG {...imageProps} />)
              : (<img {...imageProps} />)
          }
        </>
      ) : (
        <div
          className={twMerge(
            width ? `w-[${width}px]` : "",
            height ? `h-[${height}px]` : "",
            imageProps.className
          )}
        />
      )
  );
};