import React from "react";

import { BaseModal } from "./BaseModal";
import Button from "../components/common/button/Button";
import IconFont from "../components/common/iconFont/IconFont";
import { getS3StaticFolderUrl } from "../utils/config/utils";

interface IGeoblockModal {
  open: boolean;
  hideModal: Function;
}

export const GeoblockModal: React.FC<IGeoblockModal> = ({ open, hideModal }) => {
  return (
    <BaseModal
      open={open}
      hideModal={hideModal}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex w-full items-center justify-center">
          <img
            className="h-[180px] aspect-square"
            src={getS3StaticFolderUrl("/static/geoblock-banner.png")}
            alt="geoblock banner"
          />
        </div>

        <div className="flex w-full flex-col items-center gap-y-5 text-gray-50">
          <div className="flex flex-col items-center gap-y-3 self-stretch text-center max-w-[480px]">
            <div className="text-[24px] font-semibold text-gray-50">Access Restricted</div>
            <div className="font-normal text-sm">
              We are sorry but Zeebit is unavailable in your location.
              Please read Zeebit’s documentation on Gitbook to learn more.
              Make sure your VPN is turned off.
            </div>
          </div>

          <div className="flex w-full items-start gap-x-3 [&>button]:w-1/2">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                window.open(
                  "https://zeebit.gitbook.io/zeebit.io/information/prohibited-jurisdictions-platform",
                  "_blank"
                )?.focus();
              }}
            >
              <div className="flex gap-1.5 items-center">
                <IconFont name="gitbook" /> Learn More
              </div>
            </Button>

            <Button
              variant="gray"
              size="sm"
              onClick={() => {
                hideModal();
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
