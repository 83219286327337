import { PublicKey } from "@solana/web3.js";

import { ITokenCheckMeta } from "../../contexts/BalanceContext"
import { ICollectable, CollectableStatus } from "../../sdk/betStream"
import House from "../../sdk/house"
import Platform from "../../sdk/platform"
import Player from "../../sdk/playerAccount"
import { IHMPlayerCollectsFilter, getFormattedPlayerCollects } from "../../utils/hellomoon/hellomoon"
import { MS_IN_DAY } from "./useRewardsMeta"

export const loadOldCollects = async (player: Player, startDate?: Date, endDate?: Date): Promise<ICollectable[]> => {
    let filters: IHMPlayerCollectsFilter = {
        player: player.publicKey.toString(),
    }

    if (startDate != null) {
        const startEpoch = startDate.getTime() / 1000

        filters.timeFrom = Math.floor(startEpoch)
    }

    if (endDate != null) {
        const endEpoch = endDate.getTime() / 1000

        filters.timeTo = Math.ceil(endEpoch)
    }

    return await getFormattedPlayerCollects(filters)
}

const createEmptyCollectsMap = (earliestDate: Date, latestDate: Date): Map<number, Map<number, ICollectable>> => {
    const collectsMap = new Map<number, Map<number, ICollectable>>()
    const numberOfDays = Math.ceil((latestDate.getTime() - earliestDate.getTime()) / MS_IN_DAY)

    for (let i = 0; i <= numberOfDays; i++) {
        const date = new Date(earliestDate)
        date.setUTCDate(earliestDate.getUTCDate() + i)

        const month = date.getUTCMonth()
        const day = date.getUTCDate()
        const emptyCollectable: ICollectable = {
            amount: 0,
            amountUi: 0,
            amountUsdUi: 0,
            token: '',
            startDay: date,
            status: CollectableStatus.NOTHING_COLLECTED
        }

        if (collectsMap.has(month)) {
            collectsMap.get(month)?.set(day, emptyCollectable)
        } else {
            const map = new Map<number, ICollectable>()
            map.set(day, emptyCollectable)

            collectsMap.set(month, map)
        }
    }

    return collectsMap
}

export const loadCollectsCalendar = async (player: Player, earliestDate: Date, latestDate: Date, house: House, tokensByIdentifier: Map<string, ITokenCheckMeta>, platform: Platform): Promise<ICollectable[] | undefined> => {
    // EMPTY MAP
    const collectsMap = createEmptyCollectsMap(earliestDate, latestDate)

    // FORMAT VALUES FROM ENDPOINT
    const oldCollects = await loadOldCollects(player, earliestDate)
    oldCollects.forEach((collect, index) => {
        const month = collect.eventTime.getUTCMonth()
        const day = collect.eventTime.getUTCDate()

        if (earliestDate.getTime() > collect.eventTime.getTime()) {
            return
        }

        if (collect.eventTime.getTime() > latestDate.getTime()) {
            return
        }


        const token = tokensByIdentifier.get(collect.token)

        // SET AMOUNT UI, AMOUNT USD, TOKEN ICON
        if (collect.amount > 0) {
            const amountUi = collect.amount / Math.pow(10, token?.houseToken?.decimals || 6)
            const amountUsd = house.approximateTokenAmountToBase(new PublicKey(collect.token), collect.amount)
            const amountUsdUi = house.approximateTokenAmountToBaseUI(new PublicKey(collect.token), collect.amount)

            collect.amountUi = amountUi
            collect.amountUsd = amountUsd
            collect.amountUsdUi = amountUsdUi
        }

        collect.tokenIcon = token?.context?.imageDarkPng || ''
        collect.startDay = collect.rewardDate

        collectsMap.get(month)?.set(day, collect)
    })

    // FORMAT VALUES FROM REWARD CALENDAR - THIS GIVES YOU TODAY -> TODAY + 27 Days
    const rewardCalendar = player?.rewardCalendar
    if (rewardCalendar == null) {
        // nothing to do as no reward calendar on player acc
    } else {
        const today = new Date()
        const todaysDay = today.getUTCDate()
        const todaysMonth = today.getUTCMonth()

        rewardCalendar.formattedImpliedDaySlots.forEach((impliedDaySlot) => {
            const month = impliedDaySlot.startDay.getUTCMonth()
            const day = impliedDaySlot.startDay.getUTCDate()

            // DATA FROM OLD COLLECTS
            const collectFromApi = collectsMap.get(month)?.get(day)

            // CHECK IS WITHIN DATE RANGES
            if (earliestDate.getTime() > impliedDaySlot.startDay.getTime() || impliedDaySlot.startDay.getTime() > latestDate.getTime()) {
                return
            }

            const token = tokensByIdentifier.get(platform.rewardTokenConfig?.pubkey || '')

            // SET AMOUNT UI, AMOUNT USD, TOKEN ICON
            if (impliedDaySlot.amount > 0) {
                const amountUi = impliedDaySlot.amount / Math.pow(10, token?.houseToken?.decimals || 6)
                const amountUsd = house.approximateTokenAmountToBase(platform.rewardTokenPubkey, impliedDaySlot.amount)
                const amountUsdUi = house.approximateTokenAmountToBaseUI(new PublicKey(platform.rewardTokenPubkey), impliedDaySlot.amount)

                impliedDaySlot.amountUi = amountUi
                impliedDaySlot.amountUsd = amountUsd
                impliedDaySlot.amountUsdUi = amountUsdUi
            }

            // SET STATUS (EITHER COLLECTABLE TODAY, OR COLLECTABLE IN FUTURE)
            if (month == todaysMonth && day == todaysDay) {
                impliedDaySlot.status = CollectableStatus.COLLECTABLE
            } else if (impliedDaySlot.startDay.getTime() > Date.now() && impliedDaySlot.amount > 0) {
                impliedDaySlot.status = CollectableStatus.COLLECTABLE_IN_FUTURE
            }

            if (impliedDaySlot.status == null) {
                impliedDaySlot.status = CollectableStatus.NOTHING_TO_COLLECT
            }

            impliedDaySlot.tokenIcon = token?.context?.imageDarkPng || ''

            if (collectFromApi == null || collectFromApi.amount == 0 || impliedDaySlot.amount > 0) {
                collectsMap.get(month)?.set(day, impliedDaySlot)
            }
        })
    }

    const collects: ICollectable[] = []
    for (let [month, monthMap] of collectsMap.entries()) {
        for (let [day, collectable] of monthMap.entries()) {
            collects.push(collectable)
        }
    }

    return collects
}