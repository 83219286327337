import axios from "axios"

export interface IJupToken {
    mint?: string
    address: string,
    name: string,
    symbol: string,
    decimals: number,
    tags: string[],
    logoURI: string,
    daily_volume: number
}

export interface IGetTokenListFilter {
    mint?: string[]
}

export const getTokenList = async (filters?: IGetTokenListFilter): Promise<IJupToken[]> => {
    // GET THE TOKEN LIST FROM JUPITER
    if (filters?.mint == null) {
        return []
    }

    const tokens: IJupToken[] = []

    const responses = await Promise.all(filters.mint.map((mint) => {
        return axios({
            method: 'get',
            url: `https://tokens.jup.ag/token/${mint}`,
            headers: {
                "Content-Type": "application/json"
            }
        })
    }))

    responses.forEach((response) => {
        tokens.push({
            ...response.data,
            mint: response.data.address
        })
    })

    return tokens
}

export interface IJupTokenPrice {
    id: string
    mintSymbol: string
    vsToken: string
    vsTokenSymbol: string
    price: number
    time: number
    mint?: string
}

export interface IGetLatestTokenPricesFilter {
    mints?: string[]
}

export const getLatestTokenPrices = async (filters?: IGetLatestTokenPricesFilter): Promise<IJupTokenPrice[]> => {
    const response = await axios({
        method: 'get',
        url: `https://price.jup.ag/v6/price?ids=${filters?.mints?.join(',')}`,
        headers: {
            "Content-Type": "application/json"
        }
    })

    return Object.values(response.data.data).map((token: any) => {
        return {
            ...token,
            mint: token.id
        }
    })
}